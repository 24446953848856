import { memo, useEffect, useCallback } from 'react';
import cn from 'classnames';
import Button from '@/components/GButton';
import iconLeaderboard from '@/assets/images/icon--leaderboard.png';
import { LeaderboardTabEnum } from '@/types/leaderboard';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import { leaderboardActions } from '@/modules/Leaderboards/store';
import { selectLeaderboardModule } from '@/modules/Leaderboards/selectors';
import useLoadLeaderboardStats from '@/hooks/useLoadLeaderboardStats';

const LeaderboardTabs = () => {
  const dispatch = useDispatch();
  const { onChangeLeaderboardTab } = useLoadLeaderboardStats();
  const { currentTab } = useSelector(selectLeaderboardModule);
  const onSelectLeaderboardTab = useCallback(
    (tabName: LeaderboardTabEnum) => {
      dispatch(leaderboardActions.selectTab(tabName));
      onChangeLeaderboardTab();
    },
    [dispatch, onChangeLeaderboardTab],
  );

  useEffect(() => {
    onSelectLeaderboardTab(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="leaderboard-layout--tabs">
      <div className="group-icon">
        <div className="icon--leaderboard">
          <img loading="lazy" src={iconLeaderboard} alt="Leaderboard" />
        </div>
      </div>
      <div className="group-tabs">
        <Button
          className={cn('btn--as-tab', {
            active: currentTab === LeaderboardTabEnum.INCUBATORS,
          })}
          onClick={() => onSelectLeaderboardTab(LeaderboardTabEnum.INCUBATORS)}
        >
          Top Incubators
        </Button>
        <Button
          className={cn('btn--as-tab', {
            active: currentTab === LeaderboardTabEnum.EGGS,
          })}
          onClick={() => onSelectLeaderboardTab(LeaderboardTabEnum.EGGS)}
        >
          Top Eggs
        </Button>
        <Button
          className={cn('btn--as-tab', {
            active: currentTab === LeaderboardTabEnum.VALIDATORS,
          })}
          onClick={() => onSelectLeaderboardTab(LeaderboardTabEnum.VALIDATORS)}
        >
          Top Validators
        </Button>
      </div>
    </div>
  );
};

export default memo(LeaderboardTabs);

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Heading } from '@/components/Typography';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import MultiRewardEggCracker from '@/components/MultiRewardEggCracker';

const winners = [
  417, 418, 482, 484, 424, 467, 483, 439, 441, 459, 445, 447, 452, 453, 454,
  457, 458, 460, 461, 464, 466, 472, 473, 474, 475, 480, 481, 485, 486,
];

const GameplayPage = () => {
  return (
    <MasterLayoutBody isRegularPage>
      <div
        className="js-container confe-container"
        style={{
          top: '0px !important',
        }}
      ></div>
      <Container className="storypage--block storypage--block-1">
        <Row>
          <Col md={12}>
            <Heading className="text-center mb-3">
              EggForce & Casper Network AMA 🥚🔨
            </Heading>
            <div className="gameplay-helper--root">
              <MultiRewardEggCracker
                winners={winners}
                resultType="string"
                noOfPicker={15}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </MasterLayoutBody>
  );
};

export default GameplayPage;

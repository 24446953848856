export const mockPackages = [
  {
    id: 1,
    name: 'Silver Pack',
    amount: 6,
    percent: 0,
    priceDefault: 30000000000,
    priceSale: 30000000000,
  },
  {
    id: 2,
    name: 'Platinum Pack',
    amount: 10,
    percent: 0,
    priceDefault: 50000000000,
    priceSale: 50000000000,
  },
];

export const mockEgg = {
  tokenId: '51',
  metadata: [
    {
      key: 'Class',
      value: 'Water',
    },
    {
      key: 'Level',
      value: 'Rock',
    },
    {
      key: 'Year of creation',
      value: '2023',
    },
    {
      key: 'token_uri',
      value: 'https://assets.eggforce.io/egg/water-rock.png',
    },
  ],
  ownerAccountHash: 'demo--account',
  contractAddress: 'hash-123abc456',
  contractName: 'Eggforce NFT',
  name: 'eggforce_nft',
  symbol: 'EGGF',
  isInstallment: false,
  egg: {
    snc: 0,
    accumulatedSnc: 0,
    status: 'minted',
    stakedAmount: 0,
    isProcessing: false,
    nextLevelXp: 84,
  },
};

export const mockValidators = [
  {
    publicKey:
      '01e6c56c86ca97d7387d0c989c061ceeb205eeb04adf9ec41569292120ed9ae4a5',
    name: 'OriginStake',
    icon: {
      url: 'https://assets.eggforce.io/ogs-blue-version.webp',
      iconSize: [60, 60],
    },
    position: {
      lat: 79.07181201408547,
      lng: -50.625,
    },
    xpBoost: 0.01,
    delegatorsStaked: 27113053830755,
    selfStaked: 178907405837265,
    totalStaked: 206020459668020,
    fee: 10,
    description:
      'OriginStake is thrilled to offer extra benefits to our new customers. New delegators now have the opportunity to Earn Extra Up to 80% Rewards per month. Stake your $CSPR with us.',
    verified: true,
    background: '/static/media/chapter1--03.8daed415df320753bdaa.webp',
    shortMeta:
      'OriginStake is thrilled to offer extra benefits to our new customers. New delegators now have the opportunity to Earn Extra Up to 80% Rewards per month. Stake your $CSPR with us.',
    isActiveValidator: false,
    isFull: false,
  },
  {
    publicKey:
      '0145fb72c75e1b459839555d70356a5e6172e706efa204d86c86050e2f7878960f',
    name: 'C-Stake',
    icon: {
      url: 'https://assets.eggforce.io/c-stake-icon.webp',
      iconSize: [60, 60],
    },
    position: {
      lat: 69.28725695167886,
      lng: -78.75000000000001,
    },
    xpBoost: 0.1,
    delegatorsStaked: 1238570124796434,
    selfStaked: 222739097762074,
    totalStaked: 1461309222558508,
    fee: 10,
    description:
      "Our node is hosted on a dedicated above spec future-proof server using renewable energy. Our team has been associated with the Casper Network since mainnet by being owners of the popular Casper Hodlers community group. Frequent giveaways for our delegators which include Casper, Ledgers, NFT's & more!",
    verified: true,
    background: '/static/media/chapter1--03.8daed415df320753bdaa.webp',
    shortMeta:
      "Our node is hosted on a dedicated above spec future-proof server using renewable energy. Our team has been associated with the Casper Network since mainnet by being owners of the popular Casper Hodlers community group. Frequent giveaways for our delegators which include Casper, Ledgers, NFT's & more!",
    isActiveValidator: true,
    isFull: false,
  },
];

export const mockDragon = {
  tokenId: '14',
  metadata: [
    {
      key: 'name',
      value: 'Dragon #479',
    },
    {
      key: 'description',
      value:
        'EggForce is a story and community-driven, high quality hand-drawn 10,000 Egg NFTs collection on Casper Network',
    },
    {
      key: 'image',
      value: 'https://dragons.eggforce.io/elemental/479-water.gif',
    },
    {
      key: 'dna',
      value: '51d1d254c6fe544e736e69255e7a921259690146',
    },
    {
      key: 'origin',
    },
    {
      key: 'edition',
      value: 479,
    },
    {
      key: 'class',
      value: 'Water',
    },
    {
      key: 'rarity',
      value: 'Elemental',
    },
    {
      key: 'date',
      value: 1692289783075,
    },
    {
      key: 'attributes',
      value: [
        {
          trait_type: 'body-elemental',
          value: 'black',
        },
        {
          trait_type: 'mouth-elemental',
          value: 'saliva',
        },
        {
          trait_type: 'mane-elemental',
          value: 'fire',
        },
        {
          trait_type: 'eyes',
          value: 'rinnegan',
        },
        {
          trait_type: 'nose',
          value: 'platinum',
        },
        {
          trait_type: 'fangs',
          value: 'basic',
        },
      ],
    },
  ],
  ownerAccountHash: 'demo--account',
  contractAddress: 'hash-123abc456',
  contractName: 'Eggforce Dragon NFT',
  name: 'eggforce_dragon_nft',
  symbol: 'EGGD',
};

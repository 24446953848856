import { Heading } from '@/components/Typography';

const StyleguideBlock = ({ children, title} : any) => {
  return (
    <div style={{ padding: "32px 0" }}>
      <div style={{ 
        borderBottom: "1px solid white",
        marginBottom: "1rem"
      }}>
        <Heading h={3} className="mb-2 mt-2">{title}</Heading>
      </div>
      {children}
    </div>
  )
}

export default StyleguideBlock;

import OptionItem from '@/components/OptionItem';
import imgSNC from '@/assets/images/icon--snc.png';
import profileAvatar from '@/assets/images/icon--thetree-with-bg.webp';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectLeaderboardModule } from '@/modules/Leaderboards/selectors';
import CustomScrollbar from '@/components/CustomScrollbar';
import MiddleTruncatedText from '@/components/MiddleTruncatedText';
import useCurrentUser from '@/hooks/useCurrentUser';
import { formatXPValue } from '@/modules/NFTDetail/utils';
import LeaderboardCurrentUserRank from './LeaderboardCurrentUserRank';
import LeaderboardRankNumber from './LeaderboardRankNumber';

const LeaderboardUsers = () => {
  const user = useCurrentUser();
  const { data } = useSelector(selectLeaderboardModule);
  const [hatchers, rank] = data;

  return (
    <div>
      {user && rank && (
        <div className="leaderboard--current-user">
          <LeaderboardCurrentUserRank rank={rank} />
        </div>
      )}
      <div className="table-leaderboard--wrapper for-users">
        <CustomScrollbar>
          {hatchers.map((item: any, index: number) => (
            <div className="item--row" key={`leaderboard-row--${index}`}>
              <div className="column column-1">
                <LeaderboardRankNumber value={index + 1} />
              </div>
              <div className="column column-2">
                <OptionItem
                  className="is-leaderboard-item"
                  text={item.ownerAccountHash}
                  textNode={
                    <MiddleTruncatedText>
                      {item.ownerAccountHash}
                    </MiddleTruncatedText>
                  }
                  src={profileAvatar}
                  isProfileBadge
                />
              </div>
              <div className="column column-3">
                <div className="leaderboard-item--text-inline">
                  <span className="value">
                    {formatXPValue(item.accumulatedSnc)} SNC
                  </span>
                  <img
                    loading="lazy"
                    className="icon icon-xp"
                    src={imgSNC}
                    alt="SNC"
                  />
                </div>
              </div>
            </div>
          ))}
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default LeaderboardUsers;

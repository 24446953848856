import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Heading } from '@/components/Typography';
import mockData from './mockData';

const SNCQASection = () => {
  return (
    <div className="snc-block--child snc-block--q-a">
      <Heading className="text-center text-gradient" h={3}>
        Q & A
      </Heading>
      <Accordion
        defaultActiveKey="0"
        flush
        className="eggForce-accordion--root snc-accordion--root"
      >
        {mockData.map((question, index) => {
          const isFC = Boolean(typeof question.answer === 'function');
          const item = (
            isFC ? <question.answer /> : question.answer
          ) as React.ReactNode;
          return (
            <Accordion.Item eventKey={String(index)} key={`question--${index}`}>
              <Accordion.Header className="eggForce-accordion--header">
                {question.question}
              </Accordion.Header>
              <Accordion.Body className="eggForce-accordion--body">
                {item}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default SNCQASection;

import { useCallback, useEffect } from 'react';
import { ToastContentProps, toast } from 'react-toastify';
import isFunction from 'lodash/isFunction';
import {
  casperDashConnector,
  casperWalletConnector,
  casperSignerConnector,
  casperLedgerConnector,
} from '@/services/WalletConnectors';
import { useConnect } from '@casperdash/usewallet';
import type { WalletConnectorType } from '@/services/WalletConnectors';
import ToastErrorWallet from '@/modules/CasperSigner/ConnectWallet/ToastErrorWallet';

const useInitWalletInstances = () => {
  const renderToastErrorWallet = useCallback(
    (connector: WalletConnectorType, error: any) => {
      console.error(error.message);
      return (props: ToastContentProps) => (
        <ToastErrorWallet
          {...props}
          connector={connector}
          message={error.message}
        />
      );
    },
    [],
  );

  const { connect: connectWithCasperSigner } = useConnect({
    connector: casperSignerConnector.instance,
    onError: (error: any) => {
      toast(renderToastErrorWallet(casperSignerConnector, error), {
        toastId: 'error--connect-casper-signer',
      });
    },
  });

  const { connect: connectWithCasperDash } = useConnect({
    connector: casperDashConnector.instance,
    onError: (error: any) => {
      toast(renderToastErrorWallet(casperDashConnector, error), {
        toastId: 'error--connect-casperdash',
      });
    },
  });

  const { connect: connectWithCasperWallet } = useConnect({
    connector: casperWalletConnector.instance,
    onError: (error: any) => {
      toast(renderToastErrorWallet(casperWalletConnector, error), {
        toastId: 'error--connect-casper-wallet',
      });
    },
  });

  const { connect: connectWithCasperLedger } = useConnect({
    connector: casperLedgerConnector.instance,
    onError: (error: any) => {
      toast(renderToastErrorWallet(casperLedgerConnector, error), {
        toastId: 'error--connect-ledger',
      });
    },
  });

  useEffect(() => {
    const mapper = [
      {
        instance: casperDashConnector,
        connectFn: connectWithCasperDash,
      },
      {
        instance: casperSignerConnector,
        connectFn: connectWithCasperSigner,
      },
      {
        instance: casperWalletConnector,
        connectFn: connectWithCasperWallet,
      },
      {
        instance: casperLedgerConnector,
        connectFn: connectWithCasperLedger,
      },
    ];

    mapper.forEach((item) => {
      const { instance, connectFn } = item;
      if (!isFunction(instance.connectFunction)) {
        instance.setConnector(connectFn);
      }
    });

    // if (!isFunction(casperDashConnector.connectFunction)) {
    //   casperDashConnector.setConnector(connectWithCasperDash);
    // }

    // if (!isFunction(casperSignerConnector.connectFunction)) {
    //   casperSignerConnector.setConnector(connectWithCasperSigner);
    // }

    // if (!isFunction(casperWalletConnector.connectFunction)) {
    //   casperWalletConnector.setConnector(connectWithCasperWallet);
    // }

    // if (!isFunction(casperLedgerConnector.connectFunction)) {
    //   casperLedgerConnector.setConnector(connectWithCasperLedger);
    // }
  }, [
    connectWithCasperDash,
    connectWithCasperLedger,
    connectWithCasperSigner,
    connectWithCasperWallet,
  ]);
};

export default useInitWalletInstances;

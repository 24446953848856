import { Heading } from "../Typography";
import StoryImage from "../StoryImage";
import image1 from "../../assets/chapter-images/chapter1--01.webp";
import image2 from "../../assets/chapter-images/chapter1--02.webp";
import image3 from "../../assets/chapter-images/chapter1--03.webp";
import image4 from "../../assets/chapter-images/chapter1--04.webp";

const ChapterOne = () => {
  return (
    <div className="chapter-wrapper--root">
      <Heading>Formation of Dragon Empire</Heading>
    
      <p>Since the beginning of time, a terrifying meteorite explosion carrying a seed from the chaos fell on the unknown planet submerged in the sea of water. From the depths of darkness, the seed germinated, weaving its roots to all corners of the planet and draining water to grow up into the divine tree of life, Selvyn.</p>
      <StoryImage src={image1} alt="Tree of life, Selvyn" />
      <p>When the initial leaves incessantly absorbed the sun and the moon energy, life gradually separated from the roots of the plant, forming everything. Over hundreds of millions years, under the Selvyn's protection, the New World Callista was created and separated into several continents spaced by contrasting raging day and night ocean currents.</p>
      <p>Striking root in the heart of the planet _ central continent of Woodland, the Selvyn flourishes. It divides the continent into 2 conflicting parts as fire and water.</p>
      <p>The East (Anatto) is a vast land of abundance, and prosperity whereas the West (Zephyr) is entitled as a barren and arid land, where the sign of life is nowhere to be found. As being told, the Woodland hegemon is the Osirase dragon. According to legends, they are born from Selvyn’s nodules.</p>
      <p>Responsible for protecting the tree of life Selvyn from the attack of foreign forces, these ferocious beings, embodiments of pure power and magic. Their knowledge and understanding transcend the limits of conventional creatures and is appraised as the wisest being on the planet.</p>
      <p>Despite possessing such power, the Osirase was still unable to bring Western land to light as the fear they have for this cursed place.</p>
      <StoryImage src={image2} alt="Despite possessing such power, the Osirase was still unable to bring Western land to light as the fear they have for this cursed place." />

      <p>The formation and evolution of dragons have been witnessed for hundreds of millennia. When the Osirase civilization reached its zenith, a series of species tragedies occurred. The rapid growth of dragons far exceeded the speed of powering Selvyn's planet.</p>
      <p>Resources are gradually depleted, the Osirase race is beyond the danger of extinction. The Dragon King had to make a cruel statement: "Only powerful dragon warriors deserve to exist to maintain the breed."</p>
      
      <p>Woodland witnessed the first purge of the dragons. With no rules imposed, they destroy each other freely, bloodily, and savagely. Protesting the wrongful killing of their fellow tribesmen and this absurd war, a group of dragons revolted and then suffered extermination hunt from the Dragon King army. Getting cornered, they decided to flee to the legend-cursed land, Zephyr. A vow was made by these pacifist dragons that they would never set their foot back in their homeland, preferring to exile in a dead place than to bloodshed their own breed or die under the hands of the Dragon King.</p>
      <p>Meanwhile in Anatto, after a bloody mutual slaughter, the balance is restored. The purebred Osirase Empire made an oath "determined to protect the divine tree to its last breath”. The blood and corpses of the dragon ancestors lay down to form crystalline ores scattered throughout Anatto.</p>
      <StoryImage src={image3} alt='"Determined to protect the divine tree to its last breath"' />
      <p>Thousands of years passed, and the dragons who were thought of as dying in Zephyr suddenly evolved, became more powerful and braver to withstand the harshness of the West and the Athetic Empire was formed.</p>
      <p>Bearing a deeply destructive grudge against the Osirases, they have survived in seclusion of the continent. Athetic gathered a powerful, hidden dragon army, ready to respond if it detected any strangers invading the territory.</p>
      <p>One day, when the pureblood Osirase Empire spotted the spirit tree showing signs of aging, the Dragon King sent reconnaissance platoons under the tree to detect the cause. After the tropical storm, a hundreds of years old secret sap-mining tunnel system was revealed hundreds of meters below the ground level.</p>
      <p>Fearing that a foreign force was plotting to destroy the Selvyn and harm the pureblood dragon race, hundreds of Osirase legions carried the ultimate weapons and rushed towards Selvyn to prepare for the battle to protect the divine tree.</p>
      <StoryImage src={image4} alt='"Determined to protect the divine tree to its last breath"' />
      <p>The questions were formed, where did this tunnel system originate from? What actions will the Osirase take to protect Selvyn and pacify the continent? Does this tunnel system have anything to do with the Athetic, the group of exiled dragons who was thought to have perished in Zephyr? These mysteries will gradually be revealed.</p>
    </div>
  );
}

export default ChapterOne;

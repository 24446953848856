import React, { useCallback, useMemo, useState } from 'react';
import { useSpring, a } from '@react-spring/web';
import cn from 'classnames';
import LoadingBox from '@/components/LoadingBox';
import CardRewardModal from './CardRewardModal';
import CardFullsize from './CardFullsize';

interface IProps {
  front: string;
  back: string;
  flipped: boolean;
  isSelected: boolean;
  onFlip: (order: number) => boolean | object;
  order: number;
  setSelectedCard: (order: number | undefined) => void;
  selectedCard: undefined | number;
  onShowLimitedModal: () => void;
}

const CardGame = ({
  onShowLimitedModal,
  selectedCard,
  setSelectedCard,
  isSelected,
  order,
  flipped,
  front,
  back,
  onFlip,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showFullsize, setFullsizeModal] = useState<boolean>(false);
  const [showRewardModal, setRewardModalOpen] = useState<boolean>(false);
  const [isRewardEntry, setRewardEntry] = useState<boolean>(false);
  const [result, setResult] = useState<string>('');

  const shouldFlip = useMemo(() => {
    return Boolean(isSelected && flipped);
  }, [flipped, isSelected]);
  const { transform, opacity } = useSpring({
    opacity: shouldFlip ? 0 : 1,
    transform: `perspective(600px) rotateY(${shouldFlip ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  const onClaimReward = useCallback(async () => {
    setRewardModalOpen(false);
  }, []);

  const handleUnflippedCard = useCallback(async () => {
    if (selectedCard) {
      return;
    }
    setLoading(true);
    const result = await onFlip(order);

    /**
     * Result can be:
     * - Undefined: something went wrong
     * - "money": got reward
     * - "wishes": better luck next time
     *
     */
    if (result && typeof result === 'string') {
      setFullsizeModal(true);

      // Accept any results from Lottery
      setRewardEntry(true);
      setResult(result);
      setRewardModalOpen(true);
    }

    if (result === undefined) {
      setSelectedCard(undefined);
    }

    setLoading(false);
  }, [selectedCard, onFlip, order, setSelectedCard]);

  const onClickFlippingHandler = useCallback(async () => {
    // Show limit reached when one card is flipped
    if (flipped && !isSelected) {
      onShowLimitedModal();
      return;
    }

    // Flip card
    if (!shouldFlip) {
      handleUnflippedCard();
      return;
    }

    if (shouldFlip) {
      setFullsizeModal(true);
      return;
    }
  }, [
    flipped,
    handleUnflippedCard,
    isSelected,
    onShowLimitedModal,
    shouldFlip,
  ]);

  return (
    <div className="flipping-card--container">
      <CardFullsize
        image={back}
        onClose={() => setFullsizeModal(false)}
        open={showFullsize}
        isRewardEntry={isRewardEntry}
        result={result}
      />
      <CardRewardModal
        onClose={onClaimReward}
        open={showRewardModal}
        result={result}
      />
      <div
        className="flipping-card--card-wrapper"
        onClick={onClickFlippingHandler}
      >
        {loading && <LoadingBox className="flipping-card--loading" />}
        <a.div
          className={cn(`flipping-card--card back`, {
            active: isSelected,
          })}
          style={{
            opacity: opacity.to((o) => 1 - o),
            transform,
            rotateY: '180deg',
            backgroundImage: `url(${back})`,
          }}
        />
        <a.div
          className={`flipping-card--card front`}
          style={{
            opacity,
            transform,
          }}
        >
          <img src={front} alt="Front" />
        </a.div>
      </div>
    </div>
  );
};

export default CardGame;

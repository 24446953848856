import Big from 'big.js';
import isEmail from 'validator/lib/isEmail';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';

export const required = (value: any) => (value ? undefined : 'Required');

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

export const mustBeGreaterThan = (minValue: any) => (value: any) => {
  if (value === undefined) {
    return undefined;
  }
  const fieldValue = Big(value);
  const minValueBig = Big(minValue);

  if (fieldValue.lte(minValueBig)) {
    return `Amount must be greater than ${minValue} SNC`;
  }

  return undefined;
};

export const mustBeSmallerThan = (maxValue: any) => (value: any) => {
  if (value === undefined) {
    return undefined;
  }
  const fieldValue = Big(value);
  const maxValueBig = Big(maxValue);

  if (fieldValue.gt(maxValueBig)) {
    return `Amount must be smaller than ${maxValue} SNC`;
  }

  return undefined;
};

export const mustBeDifferentFromCurrentKey =
  (currentKey: any) => (value: any) => {
    if (!currentKey || value === undefined) {
      return undefined;
    }

    if (currentKey === value.trim()) {
      return `Please use a different Casper wallet address`;
    }

    return undefined;
  };

export const mustBeCasperWalletAddress = (value: any) =>
  isValidPublicKey(value) ? undefined : 'Please use a Casper wallet address';

export const mustBeEmail = (value: any) =>
  isEmail(value) ? undefined : 'Please enter valid email address';

import Balancer from 'react-wrap-balancer';
import { Heading } from '@/components/Typography';
import SNCRegistrationForm from '@/components/SNCRegistrationForm';
import MODULES_PERMISSION from '@/constants/modulesPermission';

const SNCWhatIsSNC = () => {
  return (
    <div className="snc-block--child snc-block--whatisSNC">
      <Heading className="text-gradient text-center mb-1">
        WELCOME TO THE NEW ERA OF EGGFORCENFT
      </Heading>
      <Heading h={2} className="text-center mb-4">
        Starting with the <strong>10,000 $CSPR</strong> Airdrop Campaign
      </Heading>
      <div className="body">
        <Balancer className="body-content">
          Submit the form below to receive the airdrop unique code to get{' '}
          <strong>$CSPR</strong> and <strong>SNC tokens</strong> NOW!
        </Balancer>
      </div>
      {MODULES_PERMISSION.USE_SNC_REGISTRATION_FORM && (
        <div style={{ margin: '80px 0' }}>
          <SNCRegistrationForm />
        </div>
      )}
    </div>
  );
};

export default SNCWhatIsSNC;

import { useEffect } from 'react';
import cn from 'classnames';
import { useTransition, useSpringRef, animated } from 'react-spring';

const Reward = ({
  hit,
  delay,
  setRewardInview,
  isVisible = false,
  isRewardInview,
  children,
  animationType = 'jump',
  className = "",
}: any) => {
  const transApi = useSpringRef();
  const animateStates = {
    from: { opacity: 0, scale: 0.55, xyz: [0, -80, 0] },
    enter: { opacity: 1, scale: 1.25, xyz: [0, 0, 0] },
    leave: { opacity: 0, scale: 0.55, xyz: [0, -40, 0] },
  };
  const transition = useTransition(isVisible, {
    ...animateStates,
    config: {
      tension: 180,
      friction: 12,
    },
    ref: transApi,
    delay: delay,
    onRest: () => {
      isVisible && setRewardInview(true);
    },
  });

  useEffect(() => {
    if (isVisible) {
      transApi.start();
    }
  }, [isVisible, transApi]);

  // Hide reward on reset
  useEffect(() => {
    if (!hit) {
      transApi.set(animateStates.leave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit, transApi]);

  return transition(({ opacity, scale, xyz }, item) => {
    return (
      <>
        <animated.div
          className={cn(
            `egg-cracker--reward`,
            {
              [animationType]: isVisible,
            },
            className,
          )}
          style={{
            opacity,
            scale: scale.to((value) => value),
            transform: xyz.to(
              (x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`,
            ),
          }}
        >
          {children}
        </animated.div>
        {isRewardInview && <div className="egg-cracker--reward-shadow" />}
      </>
    );
  });

  // return (
  //   <animated.div
  //     className="egg-cracker--reward"
  //     style={{
  //       opacity,
  //       scale: scale.to((value) => value),
  //       transform: xyz.to((x, y, z) => `translate3d(${x}px, ${y}px, ${z}px)`),
  //     }}
  //   >
  //     {children}
  //   </animated.div>
  // );
};

// const Reward = ({ delay, setRewardInview, isVisible = false, children }: any) => {
//   const { opacity, scale, xyz } = useSpring({
//     delay,
//     reverse: false,
//     config: {
//       easing: easings.easeInElastic,
//       tension: 120,
//       friction: 14
//     },
//     to: {
//       scale: isVisible ? 1.2 : 0.25,
//       xyz: isVisible ? [0, 0, 0] : [0, -80, 0],
//       opacity: isVisible ? 1 : 0
//     },
//     onRest: () => isVisible && setRewardInview(true)
//   });

//   return (
//     <animated.div className="egg-cracker--reward" style={{
//       opacity,
//       scale: scale.to(value => value),
//       transform: xyz.to((x,y,z) => `translate3d(${x}px, ${y}px, ${z}px)`)
//     }}>{children}</animated.div>
//   )
// };

export default Reward;

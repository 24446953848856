import { useCallback } from 'react';
import { Check2Circle } from 'react-bootstrap-icons';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import useCurrentUser from '@/hooks/useCurrentUser';
import GModal from '@/components/GModal';
import { Heading } from '@/components/Typography';
import MiddleTruncatedText from '@/components/MiddleTruncatedText';
import SocialSharing from '@/components/SocialSharing';
import CSPRExplorerButton from '@/components/CSPRExplorerButton';
import Button from '@/components/GButton';
import { NFTCollectionModalActions } from '@/modules/EggCollection/store';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
import { packagesDetailActions } from '@/modules/WorldMint/store';
interface IProps {
  onClose?: () => void;
  open?: boolean;
  className?: string;
  mintTransaction?: string;
  remainingSlot?: number;
}

const ClaimAccountModal = ({
  mintTransaction,
  remainingSlot,
  className,
  open = false,
  onClose,
}: IProps) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();

  const onCloseHandler = useCallback(() => {
    if (MODULES_PERMISSION.USE_HAPPY_HOUR) {
      dispatch(CustomEventModalActions.hideModal());
      dispatch(packagesDetailActions.showQuickbuykModal());
    }

    onClose && onClose();
  }, [dispatch, onClose]);

  const onViewNFTHandler = useCallback(() => {
    dispatch(NFTCollectionModalActions.showModal());
    onCloseHandler();
  }, [dispatch, onCloseHandler]);

  return (
    <GModal
      closeButton={false}
      show={open}
      modalType="notification"
      backdrop="static"
      keyboard={false}
      onHide={onCloseHandler}
      className={className}
    >
      <div>
        <div className="claim-modal--header">
          <div className="claim-modal--icon">
            <Check2Circle />
          </div>
        </div>
        <div className="claim-modal--body">
          <Heading className="primary" h={2}>
            Successfully claimed!
          </Heading>
          {mintTransaction && (
            <>
              <div className="acc-claimed">
                <p>Account claimed:</p>
                <div className="acc-claimed--address">
                  <MiddleTruncatedText>{user?.activeKey}</MiddleTruncatedText>
                </div>
              </div>
              <div className="acc-claimed">
                <p>Remaining slot:</p>
                <div className="acc-claimed--address">{remainingSlot}</div>
              </div>
              <div className="acc-claimed">
                <p>Minting Transaction:</p>
                <div className="acc-claimed--address">
                  <CSPRExplorerButton hash={mintTransaction} />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="claim-modal--buttons">
          <Button
            style={{ minWidth: 200 }}
            size="small"
            onClick={onCloseHandler}
            btnStyle="3"
          >
            OK
          </Button>
          <Button size="small" btnStyle="3" onClick={onViewNFTHandler}>
            View Claimed Hammers
          </Button>
        </div>
        <div className="claim-modal--social">
          <SocialSharing url="https://go.eggforce.io/selvyn-hammer" />
        </div>
      </div>
    </GModal>
  );
};

export default ClaimAccountModal;

import { useCallback, useState, useEffect } from 'react';
import useCurrentUser from '@/hooks/useCurrentUser';
import { useAppDispatch as useDispatch } from '@/app/hooks';
// import { validateAccount } from "@/modules/Account/actions";
// import messages from '@/components/QuickBuyModal/messages';
import { hasFlippedCard } from './actions';

type ErrorGame = {
  type: string;
  reason: string;
};

const useFlippingCard = () => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<undefined | ErrorGame>(undefined);

  const onInitialChecking = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      /**
       * Should we use `permissions` from authModule.
       * This saves one canMint request
       * Comment's reason: we will disable the validator account check in this event.
       */
      // const isValidAccount: any = await dispatch(validateAccount());

      // if (!isValidAccount) {
      //   setError({
      //     type: "2",
      //     reason: messages.labelNoPermission.defaultMessage
      //   });
      // }

      // if (isValidAccount) {
      const hasAlreadyPlayed: any = await dispatch(hasFlippedCard());
      // const hasAlreadyPlayed = false;

      if (hasAlreadyPlayed) {
        setError({
          type: '3',
          reason: '',
        });
      }
      // }
    } catch (error: any) {
      console.error(`🚀 ~ onInitialChecking ~ error`, error.message);
      setError({
        type: '1',
        reason: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, user]);

  useEffect(() => {
    setLoading(true);
    onInitialChecking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // error: false,
    error,
    loading,
  };
};

export default useFlippingCard;

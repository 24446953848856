import {
  CLListType,
  CLType,
  CLPublicKey,
  CLValueBuilder,
  CLKeyType,
  CLKeyParameters,
  CLByteArrayType,
  CLAccountHash,
  CLAccountHashType,
  CLValue,
} from 'casper-js-sdk';
import { None, Some } from 'ts-results';
import isString from 'lodash/isString';
import { isConnected } from '@casperdash/usewallet-core';
/**
 * Check value is public key.
 * @param {String}  - Public key.
 * @return {Boolean} - Is valid public key
 */
export const isValidPublicKey = (publicKey: string) => {
  try {
    if (!isString(publicKey)) {
      return false;
    }

    const pbKey = CLPublicKey.fromHex(publicKey);
    return pbKey ? true : false;
  } catch (error) {
    return false;
  }
};

/**
 *
 * Check Casper connection has been unlocked or not
 */
export const isCasperWalletConnected = async () => {
  try {
    const isWalletConnected = await isConnected();

    return isWalletConnected;
  } catch (error) {
    return undefined;
  }
};

export const getCLValueOptionalByteArray = (arr: Uint8Array) => {
  const type = new CLListType(new CLByteArrayType(32));
  return getOptionalValue(
    arr === null || arr === undefined || arr.length === 0
      ? null
      : CLValueBuilder.byteArray(arr),
    type,
  );
};

export const getCLOptionalAccountHash = (acc: CLAccountHash | null) => {
  return getOptionalValue(acc, new CLAccountHashType());
};

export const getOptionalKey = (value: CLKeyParameters) => {
  const type = new CLListType(new CLKeyType());
  return getOptionalValue(value ? CLValueBuilder.key(value) : null, type);
};

export const getOptionalValue = (value: CLValue | null, type: CLType) => {
  if (value === null || value === undefined) {
    return CLValueBuilder.option(None, type);
  }
  return CLValueBuilder.option(Some(value), type);
};

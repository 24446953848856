import type { FC } from 'react';
import cn from 'classnames';
import { Check2 } from 'react-bootstrap-icons';
import React, { useMemo, memo } from 'react';
import OptionItem from '@/components/OptionItem';
import Sample2 from '@/assets/images/hammer-sample.png';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import messages from '@/modules/EggMerging/messages';
import { generateCSSElementalClasses } from '@/helpers/nft';

export interface BoxProps {
  item: any;
  isDeploying?: boolean;
  onClick: (id: string) => void;
  selected?: boolean;
}

export const Box: FC<BoxProps> = memo(function Box({
  item,
  isDeploying = false,
  onClick,
  selected = false,
}) {
  const { tokenId } = item;
  const classNFT = 'Fire';

  return (
    <div
      className="nft-grid-item nft-grid-egg"
      key={`grid-item--${item.tokenId}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(tokenId);
      }}
    >
      <OptionItem
        className={cn('is-egg-nft hammer')}
        thumbClassname={cn({
          ...generateCSSElementalClasses(classNFT, 'bg'),
        })}
        src={item.imageUrl ? item.imageUrl : Sample2}
        isVertical
      />
      {selected && (
        <div
          className={cn('tag--icon', {
            'tag--selected': selected,
          })}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-hammer--staked`}>
                {messages.infoEggSelected.defaultMessage}
              </Tooltip>
            }
          >
            <Check2 />
          </OverlayTrigger>
        </div>
      )}
      <span
        className={cn('label--id', generateCSSElementalClasses(classNFT, 'bg'))}
      >
        {item.tokenId}
      </span>
    </div>
  );
});

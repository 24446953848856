const messages = {
  overduePayment: {
    id: 'InstallmentPaymentDetailModal.overduePayment',
    defaultMessage:
      'This is an overdue payment. Penalty fee of 10% will be included.',
  },
  commonFailed: {
    id: 'InstallmentPaymentDetailModal.commonFailed',
    defaultMessage:
      "Something didn't look right. Selvyn has been noticed and having a look. You can safely close this",
  },
  paySuccess: {
    id: 'InstallmentPaymentDetailModal.paySuccess',
    defaultMessage:
      'Successfully deployed! Selvynn is checking installment payment slots. You can safely close this',
  },
  refundSuccess: {
    id: 'InstallmentPaymentDetailModal.refundSuccess',
    defaultMessage:
      'Successfully deployed! Selvynn is checking installment payment slots. You can safely close this',
  },
  errorLoadingBNPL: {
    id: 'InstallmentPaymentDetailModal.errorLoadingBNPL',
    defaultMessage:
      'Cannot load Installment Package information. Please try again later.',
  },
  errorOutOfStockBNPL: {
    id: 'InstallmentPaymentDetailModal.errorOutOfStockBNPL',
    defaultMessage:
      'Not enough eggs available for selected Installment Plan. Please select another plan or try again later.',
  },
};
export default messages;

import { useEffect } from 'react';
import Confettiful from "./Confettiful";

type Props = {
  hit: boolean;
  shouldMoveNext: boolean;
}
const CongratsOverlay = ({ hit, shouldMoveNext }:Props) => {
  useEffect(() => {
    // Show confetti
    if (!shouldMoveNext && hit) {
      // @ts-ignore
      window.confettiful = new Confettiful(document.querySelector('.js-container'));
      return;
    }
    
    // Remove confetti
    // @ts-ignore
    window.confettiful?.remove();

    return () => {
      // @ts-ignore
      window.confettiful?.remove();
    }
  }, [hit, shouldMoveNext]);

  return null;
};

export default CongratsOverlay;

import { CLKey, CLPublicKey, RuntimeArgs } from 'casper-js-sdk';
import { TransferEggDetailsSpec } from '@/types/EggActions';
import { getTokenIDsCLValue } from './casperServiceTS';
import contracService from './contractServices';
import CONFIG from '@/constants/settings';

const buildTransferDeploy = (
  baseAccount: CLPublicKey,
  args: {
    recipient: CLPublicKey;
    token_ids: string[];
  },
  paymentAmount: number,
  contract: string,
) => {
  if (!args?.token_ids?.length) {
    return;
  }

  const runtimeArgs = RuntimeArgs.fromMap({
    recipient: new CLKey(args.recipient),
    token_ids: getTokenIDsCLValue(args.token_ids),
  });

  // Set contract before calling callEntryPoint
  contracService.contract.setContractHash(`hash-${contract}`);

  // Build Transfer deploy
  const deploy = contracService.contract.callEntrypoint(
    'transfer',
    runtimeArgs,
    baseAccount,
    CONFIG.CHAIN_NAME,
    String(paymentAmount),
  );

  return deploy;
};

export const getTransferNFTDeploy = ({
  tokenIds,
  fromAddress,
  recipient,
  fee,
  contract,
}: TransferEggDetailsSpec) => {
  try {
    const fromAccPk = CLPublicKey.fromHex(fromAddress);

    return buildTransferDeploy(
      fromAccPk,
      {
        recipient: CLPublicKey.fromHex(recipient),
        token_ids: tokenIds,
      },
      fee,
      contract,
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get Transfer NFT deploy.`);
  }
};

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import imgHatch from '@/assets/images/icon--unhatch.png';
import imgSNC from '@/assets/images/icon--snc.png';
import imgXP from '@/assets/images/icon--xp.png';
import logoCSPR from '@/assets/images/logo--cspr.svg';

interface NFTStatsSpec {
  data: {
    level: string;
    pointSNC: number;
    pointXP: string;
    stakedAmount: number;
    booster?: {
      rate: number;
    };
  };
}

const NFTStats = ({ data }: NFTStatsSpec) => {
  return (
    <div className="horizontal-attributes--wrapper">
      <div className="each-attribute level">
        <div className="icon">
          <img
            loading="lazy"
            className="img level"
            src={imgHatch}
            alt="Level"
          />
        </div>
        <div className="label">{data.level}</div>
      </div>
      <div className="each-attribute snc">
        <div className="icon">
          <img loading="lazy" className="img snc" src={imgSNC} alt="Level" />
        </div>
        <div className="label snc">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip--snc-accumulated`}>
                The total accumulated SNC produced by this Egg.
              </Tooltip>
            }
          >
            <span className="text-snc">{data.pointSNC} SNC</span>
          </OverlayTrigger>
        </div>
      </div>
      <div className="each-attribute xp">
        <div className="icon">
          <img loading="lazy" className="img xp" src={imgXP} alt="Level" />
        </div>
        <div className="label">{data.pointXP} XP</div>
      </div>
      <div className="each-attribute cspr">
        <div className="icon">
          <img loading="lazy" className="img cspr" src={logoCSPR} alt="Level" />
        </div>
        <div className="label">{data.stakedAmount} CSPR</div>
      </div>
    </div>
  );
};

export default NFTStats;

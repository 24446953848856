import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Heading } from '@/components/Typography';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import GameplayDiagram from '@/components/GameplayDiagram';
import imgGameplayImg1 from '@/assets/images/gameplay--img-1.webp';
import imgGameplayImg2 from '@/assets/images/gameplay--img-2.webp';
import imgGameplayImg3 from '@/assets/images/gameplay--img-3.webp';

const GameplayPage = () => {
  return (
    <MasterLayoutBody isRegularPage>
      <Container className="storypage--block storypage--block-1">
        <Row>
          <Col md={12}>
            <Heading className="text-center mb-4">
              How is a dragon born?
            </Heading>
            <div className="gameplay--how-to">
              <div className="column">
                <div className="square-wrapper">
                  <div className="square-item">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${imgGameplayImg1})` }}
                    />
                  </div>
                </div>
                <p className="text">Be the Egg's owner</p>
              </div>
              <div className="column leaft">
                <div className="square-wrapper">
                  <div className="square-item">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${imgGameplayImg2})` }}
                    />
                  </div>
                </div>
                <p className="text">Incubate to upgrade your Eggs</p>
              </div>
              <div className="column">
                <div className="square-wrapper">
                  <div className="square-item">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${imgGameplayImg3})` }}
                    />
                  </div>
                </div>
                <p className="text">Own Dragon NFT to earn extra reward</p>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <GameplayDiagram />
          </Col>
        </Row>
      </Container>
    </MasterLayoutBody>
  );
};

export default GameplayPage;

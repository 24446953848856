import React from 'react';
import QASNCUtilities from './QA_SNCUtilities';

const mockData = [
  {
    question: 'What are Egg NFT utilities?',
    answer: () => <QASNCUtilities />,
  },
  {
    question: 'Will I be able to trade SNC for CSPR?',
    answer:
      'Definitely. You can trade it to another Crypto currencies on CEX or DEX',
  },
  {
    question: 'What does CEP-18 mean?',
    answer:
      "CEP-18 standard for the Casper blockchain, known as the Casper Fungible Token. The Casper Fungible Token standard is the Casper Platform's ER-C20 equivalent. It defines a set of rules that dictate the total supply of tokens, how the tokens are transferred, how transactions are approved, and how token data is accessed.",
  },
  {
    question: 'Will I get this airdrop for every egg I buy? Or only once?',
    answer:
      'Each wallet is eligible for only one code. Each code can only get the airdrop once.',
  },
  {
    question:
      'Can I choose how to use SNC? (f.e. spread it out over multiple eggs)',
    answer:
      'You can withdraw SNC to your wallet and choose the Eggs you want to level them up',
  },
  {
    question: 'SNC is the main currency in EF. So what can I buy with it?',
    answer:
      'You can buy SNC Boost Item, NFT Land (WIP), Dragon Habitant (WIP) and more.',
  },
  {
    question: ' How much SNC do I need to level up my egg(s)?',
    answer:
      'Join the gameplay and unveil the secret now! Pro-tip: Accumulate full SNC (depends on egg levels) at each level, swap into XP to level up your egg(s).',
  },
];

export default mockData;

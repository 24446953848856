import { Heading } from '@/components/Typography';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { useAppSelector as useSelector } from '@/app/hooks';
import { GuideStep } from '@/modules/EggForceJourneyDemoModule/store';
import iconStep2 from '@/assets/images/journey-step--2.png';

const DragonGuideStep2 = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step2) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-2">
      <div className="dragon-guide--step">
        <img src={iconStep2} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Mint your first eggs</Heading>
        <p>
          Good one! <br />
          Assuming this is your CSPR account, with 1 mil CSPR creditted. Click{' '}
          <strong>Mint Eggs</strong> and choose one of our packgages. You can
          either select a 3 or 5-egg package.
        </p>
        <p>Once again, no worries. This is just demo only.</p>
      </div>
    </div>
  );
};

export default DragonGuideStep2;

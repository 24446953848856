import { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

interface Props {
  children: ReactNode;
  show: boolean;
  heading: string,
  onHide: () => void;
}

const ChapterFullscreenModal = (props: Props) => {
  const { show, heading, onHide, children } = props;
  return (
    <Modal className="modal--chapter-fullscreen-viewer" show={show} fullscreen onHide={onHide}>
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="modal--story-chapter-body">
          {children}
        </Container>
      </Modal.Body>
    </Modal>
  )
};

export default ChapterFullscreenModal;

import cn from 'classnames';
import { easings, useSpring, animated } from 'react-spring';
import imgHammer from '@/assets/images/hammer-inverse.webp';

const hammerCommonConfigs = {
  easing: easings.easeInOutQuart,
  mass: 1,
  tension: 210,
  friction: 20,
  frequency: 60
};

const hammerIntervals = [0, 0.01, 0.05, 0.2, 0.42, 0.5, 0.55, 1];
const hammerDegree = [-20, -21, -20, -17, -21, -50, -20, -20];

const Hammer = ({ isRewardInview, onNextStep, hit, currentStep, durationAuto, durationManual }: any) => {
  const { hammer } = useSpring({
    config: {
      ...hammerCommonConfigs,
      duration: durationAuto,
    },
    loop: true,
    from: {
      hammer: 0
    },
    to: {
      hammer: 1
    }
  });
  const { hammerOnce } = useSpring({
    config: {
      ...hammerCommonConfigs,
      duration: durationManual
    },
    delay: 0,
    reset: true,
    loop: false,
    from: {
      hammerOnce: 0
    },
    to: {
      hammerOnce: 1
    },
    onRest: () => {
      if (hit && currentStep < 2) {
        setTimeout(() => {
          onNextStep();
        }, durationManual);
      }
    }
  });

  const hammerOutStyles = useSpring({
    to: {
      opacity: 0,
      delay: 500,
      transform: `translate(180px, -30%) scale(.85)`,
    },
    onRest: () => {
      console.log(">>> OUT:: ")
    }
  });

  const freeStyles = {
    transform: hammer.to({
      range: hammerIntervals,
      output: hammerDegree
    }).to((value) => {
      return `translate(160px, -30%) rotate(${value}deg)`;
    })
  }
  const hitStyles = {
    transform: hammerOnce.to({
      range: [0, 0.5, 1],
      output: [-20, -50, -20]
    }).to((value) => `translate(160px, -30%) rotate(${value}deg)`),
  }

  const finalStyle = isRewardInview 
    ? hammerOutStyles
    : hit ? hitStyles : freeStyles;

  return (
    <animated.img
      alt="Hammer"
      src={imgHammer}
      style={finalStyle}
      className={cn('egg-cracker--hammer')}
    />
  )
}

export default Hammer;

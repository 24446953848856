import { useState, useMemo, useEffect, useCallback } from 'react';
import GModal from '@/components/GModal';
import Big from 'big.js';
import { getMintingFee } from '@/modules/WorldMint/utils';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { selectCutomPackModalStore } from '@/modules/WorldMint/selectors';
import PackageItems from '@/components/QuickBuyModal/PackageItems';
import PurchaseStatusModal from '@/components/QuickBuyModal/PurchaseStatusModal';
import AccountMiniHeader from '@/components/AccountMiniHeader';

// Dragons Mock
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  GuideStep,
  EggForceJourneyDemoModuleActions,
} from '@/modules/EggForceJourneyDemoModule/store';
import {
  updateMockBalance,
  addEggs,
} from '@/modules/EggForceJourneyDemoModule/actions';
import MockPurchaseConfirmPackageModal from './MockPurchaseConfirmPackageModal';
import { mockPackages } from './mockData';

const MockQuickBuyModal = ({
  className,
  open = false,
  isDeploying = false,
  onMint,
  onBuyBNPL,
  onClaimHandler,
}: any) => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { packagesModal, currentGuideStep } = store;
  const formatter = '0,0[.]00';

  const packages = mockPackages;

  const customPackModal = useSelector(selectCutomPackModalStore);

  const [isPurchaseSuccess, setPurchaseStatus] = useState<boolean | undefined>(
    undefined,
  );
  const [selectedPackageIndex, setSelectedPackage] = useState<number | null>(
    null,
  );
  const [onPurchaseResultOpen, setPurchaseResultOpen] =
    useState<boolean>(false);
  const [onPurchaseReviewOpen, setPurchaseReviewOpen] =
    useState<boolean>(false);
  const onClosePurchaseStatusModal = useCallback(() => {
    setPurchaseResultOpen(false);
  }, []);
  const onCloseHandler = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.setPackagesListModal(false));
  }, [dispatch]);
  const onClosePurchaseReviewModal = useCallback(
    () => setPurchaseReviewOpen(false),
    [],
  );

  const onBuyProcessHandler = useCallback(
    async (data: any) => {
      try {
        // TypePurchasePackageParams | TypePurchasePackageBNPLParams
        const { totalPackageValue, amountEgg } = data;

        const amountFee = getMintingFee(amountEgg);
        const amountPackage = Big(totalPackageValue);
        const amountLocked = amountPackage.plus(amountFee);

        dispatch(
          addEggs({
            amount: amountEgg,
          }),
        );
        dispatch(
          updateMockBalance({
            amount: amountLocked,
          }),
        );
        setPurchaseStatus(true);
        setPurchaseResultOpen(true);
        onClosePurchaseReviewModal();
      } catch (error: any) {
        return;
      } finally {
      }
    },
    [dispatch, onClosePurchaseReviewModal],
  );

  const onSelectPackageHandler = useCallback(async (selectedIndex: number) => {
    setSelectedPackage(selectedIndex);
    setPurchaseReviewOpen(true);
  }, []);

  const onConfirmPurchasePackage = useCallback(
    async (data: any) => {
      await onBuyProcessHandler(data);
      if (currentGuideStep === GuideStep.step2) {
        dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step3));
      }
    },
    [currentGuideStep, dispatch, onBuyProcessHandler],
  );

  const renderPrimaryActions = useMemo(() => {
    return (
      <PackageItems
        formatter={formatter}
        packages={packages}
        onSelect={onSelectPackageHandler}
        onSelectCustomPack={() => {}}
        isDeploying={isDeploying}
      />
    );
  }, [isDeploying, onSelectPackageHandler, packages]);

  useEffect(() => {
    return () => {
      setPurchaseStatus(undefined);
      setPurchaseResultOpen(false);
    };
  }, []);

  return (
    <GModal
      show={packagesModal.open}
      backdrop="static"
      keyboard={false}
      onHide={onCloseHandler}
      className={className}
    >
      <div className="quickbuy-body">
        <AccountMiniHeader />
        {/* <ClaimedSalesStats /> */}
        {renderPrimaryActions}
        <MockPurchaseConfirmPackageModal
          formatter={formatter}
          open={onPurchaseReviewOpen}
          packages={packages}
          selectedPackageIndex={selectedPackageIndex}
          isDeploying={isDeploying}
          onConfirm={onConfirmPurchasePackage}
          onClose={onClosePurchaseReviewModal}
        />
        <PurchaseStatusModal
          open={onPurchaseResultOpen}
          onClose={() => {
            onClosePurchaseStatusModal();
            onCloseHandler();
          }}
          isSuccess={isPurchaseSuccess}
          isCustomPack={Boolean(customPackModal.open)}
        />
      </div>
      <div className="body--bottom-gradient"></div>
    </GModal>
  );
};

export default MockQuickBuyModal;

import { useState, useMemo } from 'react';
import cn from 'classnames';
import GModal from '@/components/GModal';
import { Heading } from '@/components/Typography';
import HappyHourEventBlock from './HappyHourEventBlock';
import AnnoncementBlock from './AnnoncementBlock';
import TreasureHunt from './TreasureHunt';
import EggMintingEventBlock from './EggMintingEventBlock';
import TurkishDonationCampaign from './TurkishDonationCampaign';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import DummyBlock from "./DummyBlock";

interface IProps {
  onClose?: () => void;
  open?: boolean;
  className?: string;
}

const configs = [
  {
    order: 1,
    name: 'TREASURE HUNT',
    component: TreasureHunt,
  },
  {
    order: 2,
    name: 'Egg Minting is officially launched',
    component: EggMintingEventBlock,
  },
  {
    order: 3,
    name: 'EGGFORCENFT DONATION CAMPAIGN: OWN EGG NFTS - HEAL TURKEY',
    component: TurkishDonationCampaign,
  },
  {
    order: 4,
    name: '🔥HAPPY HOUR: EXTENSION🔥',
    component: HappyHourEventBlock,
  },
];

const AnnoucementsModal = ({ open, onClose }: IProps) => {
  const INIT_ITEM_INDEX = 1;
  const [currItem, setCurrentItem] = useState<number>(INIT_ITEM_INDEX);
  const currAnnoncement = useMemo(() => {
    return configs.find(({ order }) => order === currItem);
  }, [currItem]);

  return (
    <GModal
      show={open}
      onHide={onClose}
      dialogClassName="modal-dialog-scrollable"
      className="annoucements--modal"
      title={
        <Heading h={3} className="mt-1">
          Announcements
        </Heading>
      }
    >
      <Container fluid>
        <Row>
          <Col sm={12} md={3}>
            <ul className="glistgroup--ul announcements--list-item">
              {configs.map((item) => (
                <li
                  key={`announcement--${item.order}`}
                  className={cn('event-list-item group-item', {
                    current: Boolean(currItem === item.order),
                  })}
                  onClick={() => setCurrentItem(item.order)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </Col>
          <Col sm={12} md={9}>
            <div>
              {currAnnoncement && (
                <AnnoncementBlock title={currAnnoncement.name}>
                  <currAnnoncement.component />
                </AnnoncementBlock>
              )}
            </div>
          </Col>
        </Row>
        {/* <CustomScrollbar isAlwaysVisible>
        </CustomScrollbar> */}
      </Container>
    </GModal>
  );
};

export default AnnoucementsModal;

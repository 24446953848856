import type { RootState } from '@/app/store';

const selectInstallmentPaymentsListStore = (state: RootState) =>
  state.installmentsPayment;

const selectInstallmentPaymentsStatusModal = (state: RootState) =>
  state.installmentsPayment.statusModal;

const selectInstallmentPaymentsQASupportModal = (state: RootState) =>
  state.installmentsPayment.supportQAModal;

const selectInstallmentPaymentsDetailModal = (state: RootState) =>
  state.installmentsPayment.detailModal;

const selectRefundReviewModal = (state: RootState) =>
  state.installmentsPayment.refundReviewModal;

export {
  selectInstallmentPaymentsListStore,
  selectInstallmentPaymentsStatusModal,
  selectInstallmentPaymentsQASupportModal,
  selectInstallmentPaymentsDetailModal,
  selectRefundReviewModal,
};

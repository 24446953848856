import { useRef } from 'react';
import { Heading } from '@/components/Typography';
import SocialSharingBlock from '@/components/SocialSharingBlock';
import videoPoster from '@/assets/short-terms/egg_level_banner-transformed.jpg';

const FlashGameModal = () => {
  // Add a list of snapshot keyframes here
  // const MATCHED_KEYFRAMES = [1.757748];
  // const [isReward, setRewardModal] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const urlVideo = `${process.env.PUBLIC_URL}/happy-eggforce-1st-birthday.mp4`;

  // const isMatchedPlaceholder = useCallback(
  //   (currentFrame: number) => {
  //     return MATCHED_KEYFRAMES.some((frame) => {
  //       const placeholderFrameAsBig = Big(frame);
  //       const currentFrameAsBig = Big(currentFrame);
  //       const ALLOWED_DIFF = 0.01;
  //       const diff = placeholderFrameAsBig.minus(currentFrameAsBig);
  //       console.log(
  //         `🚀 ~ returnMATCHED_KEYFRAMES.some ~ diff:`,
  //         diff.toNumber(),
  //       );
  //       return diff.gt(0) && diff.lte(ALLOWED_DIFF);
  //     });
  //   },
  //   [MATCHED_KEYFRAMES],
  // );

  const onClickVideo = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      // if (!video.paused) {
      //   // console.log(`🚀 ~ onClickVideo ~ timeStamp:`, timeStamp);
      //   if (isMatchedPlaceholder(video.currentTime)) {
      //     setRewardModal(true);
      //   }
      // }
      video.paused ? video.play() : video.pause();
    }
  };

  return (
    <div className="luckyMoney--root" style={{}}>
      {/* <Heading h={4} className="mt-3 mb-3">
        Capture <strong>5000 CSPR</strong> with a little #HPBDEggForce challenge
      </Heading> */}
      <video
        poster={videoPoster}
        className="luckyMoney--video"
        onContextMenu={(evt) => evt.preventDefault()}
        ref={videoRef}
        loop
        onClick={onClickVideo}
        autoPlay
      >
        <source src={urlVideo} type="video/mp4" />
      </video>
      <div className="game-instruction--background">
        <div className="game-instruction">
          <div className="game-instruction--box">
            {/* <Heading h={4} className="main mt-3 mb-3">
              Capture <strong>5000 CSPR</strong> with a little #HPBDEggForce
              challenge
            </Heading> */}
            <Heading h={4} className="main mt-3 mb-3">
              How to win 10,000 CSPR #HBDEggForce challenge?
            </Heading>
            <Heading h={5}>Instruction</Heading>
            <ul>
              <li>
                Capture the <strong>exact moment</strong> when the flying
                EggForce Dragon card <strong>aligns perfectly</strong> with one
                of the empty spaces that showcase our partners' logos.
              </li>
              <li>
                Capture/Screenshot the moment when the card game aligns with one
                of the empty spaces.
              </li>
              <li>
                <strong>Post the screenshot on X/Twitter</strong>, make sure you
                use hashtag <strong>#HBDEggForceNFT</strong> and tag{' '}
                <strong>
                  <a
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/EggForceNFT"
                  >
                    @EggForceNFT
                  </a>
                </strong>
                .
              </li>
              <li>
                Follow{' '}
                <strong>
                  <a
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/EggForceNFT"
                  >
                    @EggForceNFT
                  </a>
                </strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="game-social-sharing">
          <SocialSharingBlock
            twitterProps={{
              title: `I won the 10k #CSPR #HBDEggForce challenge
Thanks to @EggForceNFT @OriginStake @Mlem_app @casperdash_io @casperlads @speedystaking @NexfiApp @casperdelegate @casperpunks_ CasperStats.io (@grindytech)
Join NOW`,
              via: '',
              hashtags: ['HBDEggForceNFT', 'CSPR', 'rewards'],
              url: 'https://go.eggforce.io/link',
              label: 'Share My luck',
            }}
          />
        </div>
      </div>
      {/* <FlashGameRewardModal
        open={isReward}
        onClose={() => setRewardModal(false)}
        result=""
      /> */}
    </div>
  );
};

export default FlashGameModal;

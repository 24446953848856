import { useCallback } from 'react';
import Big from 'big.js';
import useCurrentUser from '@/hooks/useCurrentUser';
import useConfirmDeploy from '@/hooks/useConfirmDeploy';
import { getClaimDeploy, getStakeDeploy } from '@/services/stakeServices';
import messages from '@/components/EggHatchSelectingModal/messages';
import { csprToMote } from '@/helpers/balance';
import { StakeDetailsSpec, ClaimDetailsSpec } from '@/types/EggActions';

const useEggHatching = () => {
  const user = useCurrentUser();
  const { executeDeployWithoutPut, isDeploying, isError } = useConfirmDeploy();
  const buildStakeDeployFn = useCallback(
    (stakeDetails: StakeDetailsSpec) => () => getStakeDeploy(stakeDetails),
    [],
  );
  const buildClaimDeployFn = useCallback(
    (claimDetails: ClaimDetailsSpec) => () => getClaimDeploy(claimDetails),
    [],
  );
  const onStakeHandler = useCallback(
    async (stakeDetails: StakeDetailsSpec) => {
      if (!user) {
        return undefined;
      }

      const result = await executeDeployWithoutPut(
        buildStakeDeployFn(stakeDetails),
        stakeDetails.fromAddress,
        stakeDetails.validator,
      );
      console.log(`🚀 ~ onStakeHandler::result:`, result);

      return result;
    },
    [user, buildStakeDeployFn, executeDeployWithoutPut],
  );

  const onClaimHandler = useCallback(
    async (claimDetails: ClaimDetailsSpec) => {
      if (!user) {
        return undefined;
      }

      const result = await executeDeployWithoutPut(
        buildClaimDeployFn(claimDetails),
        claimDetails.fromAddress,
        claimDetails.toAddress,
      );
      console.log(`🚀 ~ onClaimHandler::result:`, result);

      return result;
    },
    [user, executeDeployWithoutPut, buildClaimDeployFn],
  );

  const validate = useCallback(
    (data: any) => {
      let errors: any = {};
      const { balance } = user;
      const { fee, amount, minAmount } = data;
      const minAmount_MOTE = csprToMote(minAmount);
      const amount_MOTE = csprToMote(amount);
      const fee_MOTE = csprToMote(fee);
      if (!balance) {
        errors.balance = 'Error reading balance from current user';
      }
      const balanceUser = Big(balance ?? 0);

      if (amount === undefined || Big(amount_MOTE).lte(0)) {
        errors.amount = messages.errorMoreThanZero.defaultMessage;
      } else if (Big(amount_MOTE).gt(0) && balance) {
        const total = Big(amount_MOTE).plus(Big(fee_MOTE));
        console.log(
          `🚀 ~ useEggHatching ~ total:`,
          fee_MOTE,
          total.toNumber(),
          balanceUser.toNumber(),
        );

        if (total.gt(balanceUser)) {
          errors.amount = messages.errorLargerThanBalance.defaultMessage;
        }
      }

      // eslint-disable-next-line no-mixed-operators
      if (
        !errors.amount &&
        ((balance && Big(balance).lte(minAmount_MOTE)) ||
          Big(amount_MOTE).lt(minAmount_MOTE))
      ) {
        errors.amount = messages.errorLessThanMinAmount.defaultMessage;
      }

      // if (
      //   selectedValidator &&
      //   !selectedValidator.hasDelegated &&
      //   selectedValidator.numOfDelegator >= getConfigKey('MAX_DELEGATOR_PER_VALIDATOR')
      // ) {
      //   errors.validator = 'Max delegators';
      // }

      return errors;
    },
    [user],
  );

  return {
    hatch: onStakeHandler,
    undelegate: onStakeHandler,
    claim: onClaimHandler,
    isDeploying,
    isError,
    validate,
  };
};

export default useEggHatching;

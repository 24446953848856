import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector as useSelector } from '@/app/hooks';
import imgSNC from '@/assets/images/icon--snc.png';
import NFTDragonImage from '@/assets/images/img--nft-benefits.webp';
import iconEggs from '@/assets/images/TR-08.png';
import NFTHeaderStatItem from '@/components/AccountINFTHeader/NFTHeaderEggItem';
import { formatXPValue } from '@/modules/NFTDetail/utils';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import BalanceChip from '@/components/BalanceChip';
import { NFTTypeEnum } from '@/types/NFTItem';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';

const MockProfileMenu = () => {
  const dispatch = useAppDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { balance, nftsModal, snc, hasDragon, currentGuideStep } = store;
  const { subSnc, eggsTotal, dragonsTotal } = useMemo(() => {
    const totalEggs = nftsModal.data.filter(
      (item: any) => item.name === NFTTypeEnum.EGG,
    );
    const totalDragons = nftsModal.data.filter(
      (item: any) => item.name === NFTTypeEnum.DRAGON,
    );

    let subSnc = 0;
    totalEggs.forEach((egg) => {
      if (egg.egg.snc) {
        subSnc += egg.egg.snc;
      }
    });
    return {
      subSnc,
      eggsTotal: totalEggs.length,
      dragonsTotal: totalDragons.length,
    };
  }, [nftsModal.data]);

  useEffect(() => {
    if (!hasDragon && dragonsTotal === 1) {
      dispatch(EggForceJourneyDemoModuleActions.updateHasDragon(true));
      if (currentGuideStep === GuideStep.step3c) {
        dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step4));
      }
    }
  }, [currentGuideStep, dispatch, dragonsTotal, hasDragon]);
  return (
    <div className="mock--account profile-avatar--container">
      <div className="account-nft-header--root">
        <NFTHeaderStatItem
          tooltipId="tooltip--total-unclaimed-snc"
          tooltipLabel={`Total unclaimed SNC: ${snc}`}
          icon={imgSNC}
          value={`${formatXPValue(snc + subSnc)} SNC`}
          className="snc"
        />
        <NFTHeaderStatItem
          tooltipId="tooltip--total-eggs"
          tooltipLabel="Total minted eggs"
          icon={iconEggs}
          value={eggsTotal}
          className="egg-total"
        />
        <NFTHeaderStatItem
          tooltipId="tooltip--total-dragons"
          tooltipLabel="Total minted dragons"
          icon={NFTDragonImage}
          value={dragonsTotal}
          className="dragon-total"
        />
      </div>
      <BalanceChip forceShowBalance={true} balance={balance} />
    </div>
  );
};

export default MockProfileMenu;

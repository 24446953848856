import { useMemo, useCallback } from 'react';
import cn from 'classnames';
import Balancer from 'react-wrap-balancer';
import { Form as FinalForm, Field } from 'react-final-form';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import useSiteConfigurations from '@/hooks/useSiteConfigurations';
import NetworkFeePanel from '@/components/NetworkFeePanel';

import { Heading } from '@/components/Typography';

import {
  mustBeDifferentFromCurrentKey,
  mustBeCasperWalletAddress,
  composeValidators,
  required,
} from '@/services/reactFinalFormServices';
import useCurrentUser from '@/hooks/useCurrentUser';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';
import { GInputField } from '@/components/GInput';
interface SNCClaimingFormSpec {
  receiver: string;
}

const TransferEggModal = ({
  open,
  onHide,
  onConfirm,
  disableConfirm = false,
}: any) => {
  const user = useCurrentUser();
  const siteConfigs = useSiteConfigurations();

  const initValues = useMemo(
    () => ({
      receiver: '',
    }),
    [],
  );

  const onSubmit = useCallback(
    (values: SNCClaimingFormSpec) => {
      if (isValidPublicKey(values.receiver)) {
        onConfirm(values.receiver);
      }
    },
    [onConfirm],
  );

  if (!open) {
    return null;
  }

  return (
    <GModal
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      show={open}
      className={`compact-modal snc-swapping-modal`}
      disabledClose={disableConfirm}
    >
      <div className="snc-swapper--header mb-3">
        <Heading h={3} className={cn('text-center mb-0 primary-heading')}>
          Egg Transfer
        </Heading>
        <div className="helper-wrapper">
          <p className="helper">
            <Balancer ratio={0.125}>
              Enter receiver Casper address to transfer Egg
            </Balancer>
          </p>
        </div>
      </div>
      <FinalForm
        onHide={onHide}
        onSubmit={onSubmit}
        initialValues={initValues}
        render={(props) => {
          const { handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <div className="compact-modal--box success">
                <div className="body">
                  <Field
                    label="Receiver Wallet Address (*)"
                    name="receiver"
                    type="text"
                    component={GInputField}
                    validate={composeValidators(
                      required,
                      mustBeCasperWalletAddress,
                      mustBeDifferentFromCurrentKey(user.activeKey),
                    )}
                  />
                </div>
                <div className="compact-modal--network-fee-wrapper">
                  <NetworkFeePanel fee={siteConfigs.EGG_TRANSFER_FEE!} />
                </div>
                <div className="actions">
                  <Button
                    className="small"
                    type="submit"
                    disabled={disableConfirm}
                    btnStyle="6"
                    size="small"
                  >
                    Transfer
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </GModal>
  );
};

export default TransferEggModal;

import { useCallback } from 'react';
import GModal from '@/components/GModal';
import Accordion from 'react-bootstrap/Accordion';
import { selectInstallmentPaymentsQASupportModal } from '@/modules/InstallmentsPayment/selectors';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import { Heading } from '@/components/Typography';
import Balancer from 'react-wrap-balancer';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
interface IProps {
  className?: string;
}

const InstallmentPaymentQASupportModal = (props: IProps) => {
  const dispatch = useDispatch();
  const paymentQASupportModal = useSelector(
    selectInstallmentPaymentsQASupportModal,
  );
  const { open } = paymentQASupportModal;
  const onCloseHandler = useCallback(() => {
    dispatch(InstallmentsPaymentActions.hideQASupportModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      onHide={onCloseHandler}
      blurOverlay
      className={'installment-plan--introduction'}
    >
      <Heading h={4}>Installment Payments</Heading>
      <div>
        <Accordion
          defaultActiveKey="0"
          flush
          className="eggForce-accordion--root nft-detail"
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header className="eggForce-accordion--header">
              What's installment purchase?
            </Accordion.Header>
            <Accordion.Body className="eggForce-accordion--body">
              <Balancer>
                <p>
                  An installment plan is a way to pay for something in smaller,
                  more manageable chunks instead of paying for the entire cost
                  all at once.{' '}
                </p>
                <p>
                  For example, let's say that you want to purchase Gold Package
                  that costs 1000 CSPR, but you don't have 1000 CSPR available
                  to pay for it upfront.{' '}
                </p>
                <p>
                  With an installment plan, you could break up that 1500 CSPR
                  cost into smaller monthly payments. EggForce supports a
                  <strong> 3-month installment plan</strong>, that means you
                  would make 3 equal monthly payments over the course of 3
                  months in order to fully pay off the 1000 CSPR.
                </p>
              </Balancer>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="eggForce-accordion--header">
              Sounds good? How does this purchase get calculated? Any penalty?
            </Accordion.Header>
            <Accordion.Body className="eggForce-accordion--body">
              <Balancer>
                <p>Here's an example of how it could work:</p>
                <p>
                  Let's say you want to purchase{' '}
                  <strong>Gold Package that costs 1000 CSPR.</strong> With this
                  installment plan, you would{' '}
                  <strong>
                    make the first payment of 333.33 CSPR (1000 / 3){' '}
                  </strong>{' '}
                  at the time of purchase, plus a{' '}
                  <strong>
                    1% registration fee of 10 CSPR (1000 x 1% = 10)
                  </strong>
                  , bringing{' '}
                  <strong>your first payment total to 343.33 CSPR</strong>.
                </p>
                <p>
                  For the remaining balance of 666.67 CSPR (1000 - 333.33), you
                  would be{' '}
                  <strong>
                    charged a 2% penalty fee on any missed or late payments.
                  </strong>
                </p>
                <p>
                  So{' '}
                  <strong>
                    if you missed your second payment, you would be charged a 2%
                    penalty fee of 13.33 CSPR (666.67 x 2% = 13.33), making your
                    new balance owed 693.33 CSPR
                  </strong>
                  .
                </p>
                <p>
                  <strong>
                    If you make all three payments on time, you would end up
                    paying 1030 CSPR (333.33 x 3 + 10)
                  </strong>
                  . But if you miss any payments, you would need to pay the 2%
                  penalty fee on the remaining balance each time.
                </p>
                <p>
                  <strong>
                    It's important to make your payments on time to avoid these
                    extra fees, as they can add up and make your purchase more
                    expensive in the long run.
                  </strong>
                </p>
              </Balancer>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header className="eggForce-accordion--header">
              What if I cancel my installment upfront? Can I refund my already
              paid amount?
            </Accordion.Header>
            <Accordion.Body className="eggForce-accordion--body">
              <Balancer>
                <p>
                  Let's say you have made two payments of 333.33 CSPR each on
                  this installment plan, but you decide to return the item after
                  the second payment.
                </p>
                <p>
                  In this case, you have paid 666.66 CSPR so far, plus the 1%
                  registration fee of 10 CSPR,{' '}
                  <strong>for a total of 676.66 CSPR.</strong>{' '}
                </p>
                <p>
                  If you wish to cancel and refund,{' '}
                  <strong>
                    you would be refunded the total amount paid so far, minus
                    any fees or penalties.
                  </strong>
                </p>
              </Balancer>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </GModal>
  );
};

export default InstallmentPaymentQASupportModal;

import { useCallback } from 'react';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import { loadLeaderboardData } from '@/modules/Leaderboards/actions';
const useLoadLeaderboardStats = () => {
  const dispatch = useDispatch();
  const onChangeLeaderboardTab = useCallback(() => {
    dispatch(loadLeaderboardData());
  }, [dispatch]);

  return {
    onChangeLeaderboardTab,
  };
};

export default useLoadLeaderboardStats;

import DragonSectionWrapper from './DragonSectionWrapper';
import imageDragonBanner from '@/assets/images/banner-dragons.jpg';
const DragonHeroBanner = () => {
  return (
    <DragonSectionWrapper name="hero-banner">
      <div className="gradient--top"></div>
      <img className="hero-image" src={imageDragonBanner} alt="Dragons" />
      <div className="gradient--bottom"></div>
    </DragonSectionWrapper>
  );
};

export default DragonHeroBanner;

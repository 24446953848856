import { useAppDispatch } from '@/app/hooks';
import { dragonManagementActions } from '@/modules/DragonManagementModule/store';
import type { DeployItem } from '@/types/deploy';

const HatchingEggIcon = ({ deploy }: { deploy: DeployItem }) => {
  const dispatch = useAppDispatch();
  const layerVideoNormal = `${process.env.PUBLIC_URL}/EggEffect.webm`;
  const { uri } = deploy.metadata as any;
  const onOpenDragonHatchPreviewer = () => {
    dispatch(dragonManagementActions.showDragonPreviewModal());
  };
  return (
    <div className="profile-avatar--dragon-hatching">
      <div className="wrapper">
        <video
          onContextMenu={(evt) => evt.preventDefault()}
          className="video"
          autoPlay
          playsInline
          muted
          loop
        >
          <source src={layerVideoNormal} />
        </video>
        <div className="image-wrapper" onClick={onOpenDragonHatchPreviewer}>
          {uri && <img className="image-egg" src={uri} alt="Egg" />}
        </div>
      </div>
    </div>
  );
};

export default HatchingEggIcon;

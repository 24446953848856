import Balancer from 'react-wrap-balancer';
import cn from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@/components/GButton';
import DragonSectionWrapper from '../DragonSectionWrapper';
import DragonHeaderPrimary from '../DragonHeaderPrimary';
// import dragonSample from '@/assets/images/dragons-placeholder.png';
import dragonCelestial from '@/assets/images/element--celestial.png';
import dragonLegendary from '@/assets/images/element--legendary.png';
import dragonRare from '@/assets/images/element--rare.png';
import dragonElemental from '@/assets/images/element--elemental.png';
import iconTreeSelvyn from '@/assets/images/icon--treeSelvyn.webp';

const DragonRarityCollections = () => {
  return (
    <DragonSectionWrapper name="rarity-collections">
      <Container>
        <Row>
          <Col md={12}>
            <DragonHeaderPrimary heading="Dragon Rarity">
              <Balancer className="body-content">
                Not every dragon is the same! You can hatch your own eggs into
                dragons at any level.
                <p style={{ fontStyle: 'italic' }}>
                  Protip: Merge platinum eggs together and break the remaining
                  egg with a Hammer NFT to increase your chances of a Legendary
                  Dragon.
                </p>
              </Balancer>
            </DragonHeaderPrimary>
            <div className="body">
              {/* <div className="dragon-rarities--wrapper">
                <div className="column--each-dragon-rarity full">
                  <div className="image-wrapper">
                    <img src={dragonSample} alt="Elemental" />
                  </div>
                </div>
              </div> */}
              <div className="dragon-rarities--wrapper">
                <div className="column--each-dragon-rarity">
                  <div className="image-wrapper">
                    <img src={dragonElemental} alt="Elemental" />
                  </div>
                </div>
                <div className="column--each-dragon-rarity">
                  <div className="image-wrapper">
                    <img src={dragonRare} alt="Elemental" />
                  </div>
                </div>
                <div className="column--each-dragon-rarity">
                  <div className="image-wrapper">
                    <img src={dragonLegendary} alt="Elemental" />
                  </div>
                </div>
                <div className="column--each-dragon-rarity">
                  <div className="image-wrapper">
                    <img src={dragonCelestial} alt="Elemental" />
                  </div>
                </div>
              </div>
              <div className="bottom--to-callista-world">
                <Button
                  asLink
                  href={'/world'}
                  className={'btn--enter-real-Eggforce-world '}
                >
                  <span className="icon">
                    <img
                      src={iconTreeSelvyn}
                      alt={'Start to explore the real EggForce world'}
                    />
                  </span>
                  <span className="label">
                    Start to explore <br />
                    the real EggForce world
                  </span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </DragonSectionWrapper>
  );
};

export default DragonRarityCollections;

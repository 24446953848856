// @ts-ignore
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useEffect, useCallback } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import { sharedToastProps } from '@/services/toast';
import { getEggforceConfiguration } from './modules/Auth/actions';
import useAffiliateCode from '@/hooks/useAffiliateCode';
import { useAppDispatch as useDispatch } from './app/hooks';
import {
  CasperProvider,
  createClient,
  CasperDashConnector,
  CasperSignerConnector,
  CasperWalletConnector,
} from '@casperdash/usewallet';
import { CasperLedgerConnector } from '@casperdash/usewallet-ledger';

import { Analytics } from './constants/publicURL';
import Layout from './components/Layout';
import Homepage from './screens/Homepage';
import NoMatchPage from './screens/404';
import routesConfig, { IRoute } from './routes/configs';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';
import './assets/scss/app.scss';
import GlobalConfigs from './constants/settings';

const client = createClient({
  connectors: [
    new CasperDashConnector(),
    new CasperWalletConnector(),
    new CasperLedgerConnector(),
    new CasperSignerConnector(),
  ],
  autoConnect: true,
});

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  useAffiliateCode();

  const onUpdateBodyElement = useCallback(() => {
    const body = document.querySelector('body');

    if (location.pathname === '/world') {
      body?.classList.add('screen--worldmap');
      return;
    }

    body?.classList.remove('screen--worldmap');
  }, [location]);

  useEffect(() => {
    dispatch(getEggforceConfiguration());
    ReactGA.initialize(Analytics.ga);
    TagManager.initialize({
      gtmId: Analytics.gtm,
    });

    if (GlobalConfigs.EFFECT_SNOW) {
      const body = document.querySelector('body');
      body?.classList.add('effect--snow');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [location]);

  /**
   * Fix flicky scrollbar when mouse over elements with tooltip
   */
  useEffect(() => {
    onUpdateBodyElement();
  }, [onUpdateBodyElement]);

  return (
    <CasperProvider client={client}>
      <CookiesProvider>
        <ParallaxProvider>
          <ToastContainer {...sharedToastProps} />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Homepage />} />
              {routesConfig
                .filter((route) => route.enabled)
                .map((route: IRoute) => (
                  <Route
                    key={route.to}
                    path={route.to.substring(1, route.to.length)}
                    element={<route.component />}
                  />
                ))}
              <Route path="*" element={<NoMatchPage />} />
            </Route>
          </Routes>
        </ParallaxProvider>
      </CookiesProvider>
    </CasperProvider>
  );
}

export default App;

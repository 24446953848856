import { useMemo } from 'react';
import CompactModal from '@/components/CompactModal';
import SocialSharingBlock from '@/components/SocialSharingBlock';

interface IProps {
  onClose: () => void;
  open: boolean;
  className?: string;
  image: string;
  result: string;
  isRewardEntry?: boolean;
}

const CardFullsize = (props: IProps) => {
  const { isRewardEntry, onClose, image, open, result } = props;
  const text = useMemo(() => {
    if (isRewardEntry) {
      return `I've won the FREE daily @EggForceNFT lottery 🥳\nMy prize: ${result} 🤯\n
You can play too! Just buy Egg #NFTs at http://eggforce.io and start winning awesome prizes.\n
More info on the official link at`;
    }

    return `I’ve got the message @EggForceNFT. ! \n
Hi friend - come, mint your Eggs at eggforce.io and earn rewards https://discord.gg/HAsNrYpYH5 \n @Casper_Network $CSPR`;
  }, [isRewardEntry, result]);
  return (
    <CompactModal
      backdrop
      onHide={onClose}
      keyboard={true}
      showIcon={false}
      open={open}
      modalClassname="image-only"
    >
      <div className="compact-modal--image-box">
        <img src={image} alt="Back" className="card--image" />
      </div>
      <div className="compact-modal--actions">
        <SocialSharingBlock
          hideDiscord
          twitterProps={{
            title: text,
            via: '',
            hashtags: ['YourEggYourPower', 'NFT', 'CSPR'],
            url: 'https://go.eggforce.io/link',
            label: 'Claim',
            customBackgroundClass: 'use--egg-bg',
            onShareWindowClose: () => {
              onClose();
            },
          }}
        />
      </div>
    </CompactModal>
  );
};

export default CardFullsize;

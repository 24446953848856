import { Heading } from '@/components/Typography';
import Balancer from 'react-wrap-balancer';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
// import imgClock from '@/assets/images/icon--clock.svg';
// import imgOne from '@/assets/images/gameplay-icon-1.webp';
// import imgEgg1 from '@/assets/images/img--egg-claw.webp';
// import imgLogoEggForce from '@/assets/images/eggforce--logo__ver2_color.webp';
// import imgGift from '@/assets/images/icon--rewards.svg';
// import imgWallet from '@/assets/images/icon--wallet.svg';
// import imgReward from '@/assets/images/NFTs-eggs.webp';
// import imgLevelUp from '@/assets/images/zyro-image.webp';

import imgSNCChest from '@/assets/images/icon--claim-snc.png';
import imgSNC from '@/assets/images/icon--snc.png';
import iconHatch from '@/assets/images/icon--hatch.png';

const SNCFlowDiagram = () => {
  return (
    <div className="snc-block--child snc-block--gameplay">
      <Heading className="heading-primary text-gradient text-center mb-4">
        WHAT IS EGGFORCENFT?
      </Heading>
      <div className="body">
        <Balancer className="body-content">
          EggForceNFT is a dynamic NFT collection that allows you to level up
          your NFTs by staking CSPR. By staking CSPR, you can earn both CSPR
          staking rewards and SNC (Selvyn's Nodule Coin) tokens to transform
          your Egg NFTs into Dragon NFTs.
        </Balancer>
      </div>
      <div className="gameplay--how-to">
        <div className="column">
          <div className="square-wrapper">
            <div className="square-item">
              <div
                className="image image-1"
                style={{ backgroundImage: `url(${iconHatch})` }}
              />
            </div>
          </div>
          <p className="text">Mint eggs & start incubating by staking CSPR</p>
        </div>
        <div className="column leaft">
          <div className="square-wrapper">
            <div className="square-item">
              <div
                className="image"
                style={{ backgroundImage: `url(${imgSNC})` }}
              />
            </div>
          </div>
          <p className="text">
            Receive staking CSPR rewards & SNC tokens from incubating process
          </p>
        </div>
        <div className="column">
          <div className="square-wrapper">
            <div className="square-item">
              <div
                className="image"
                style={{ backgroundImage: `url(${imgSNCChest})` }}
              />
            </div>
          </div>
          <p className="text">
            Repeat the staking to accumulate SNC to level up Eggs
          </p>
        </div>
      </div>
      {/* <div className="snc-gameplay-helper--root"> */}
      {/* <div className="snc-gameplay-helper--top">
          <div className="snc-gameplay-point point-1">
            <img
              src={imgOne}
              alt=""
              className="snc-gameplay-icon icon-dragon"
            />
          </div>
          <div className="snc-gameplay-line line-4" />
        </div> */}
      {/* <div className="snc-gameplay-helper--bottom">
          <div className="snc-gameplay-line line-1">
            <div className="snc-gameplay-line-text point">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <span>
                      Connect your Casper Wallet account via EggForce.
                    </span>
                    <br />
                    <span>
                      Then, you can start minting new eggs and prepare for
                      hatching.
                    </span>
                  </Tooltip>
                }
              >
                <img
                  src={imgReward}
                  alt=""
                  className="snc-gameplay-icon icon-text-1"
                />
              </OverlayTrigger>
              <span className="snc-gameplay-point point-pulse purple"></span>
            </div>
          </div>
          <div className="snc-gameplay-line line-2 is-horz">
            <div className="snc-gameplay-line-text point">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <span>
                      Start hatching your minted Eggs with preffered validator.
                      The more you hatch, the more SNC reward you'll get.
                    </span>
                  </Tooltip>
                }
              >
                <img
                  src={imgClock}
                  alt=""
                  className="snc-gameplay-icon icon-text-2"
                />
              </OverlayTrigger>
              <span className="snc-gameplay-point point-pulse white"></span>
            </div>
          </div>
          <div className="snc-gameplay-line line-3">
            <div className="snc-gameplay-line-text point">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <span>
                      Hatching will start accumulating SNC, you then can use
                      this amount of SNC to level up your egg or transfer back
                      to your Casper account.
                    </span>
                  </Tooltip>
                }
              >
                <img
                  src={imgSNC}
                  alt=""
                  className="snc-gameplay-icon icon-text-3"
                />
              </OverlayTrigger>
              <span className="snc-gameplay-point point-pulse"></span>
            </div>
          </div>
          <div className="snc-gameplay-line line-5 is-horz">
            <div className="snc-gameplay-line-text point">
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-top-2`}>
                    <span>Level up Eggs by swapping SNC into XP</span>
                  </Tooltip>
                }
              >
                <img
                  src={imgClock}
                  alt=""
                  className="snc-gameplay-icon icon-text-2"
                />
              </OverlayTrigger>
              <span className="snc-gameplay-point point-pulse white"></span>
            </div>
          </div>
          <div className="snc-gameplay-point point-5">
            <img
              src={imgWallet}
              alt=""
              className="snc-gameplay-icon icon-wallet"
            />
            <span className="text">Casper Wallet</span>
          </div>
          <div className="snc-gameplay-point point-2">
            <img
              src={imgLevelUp}
              alt=""
              className="snc-gameplay-icon icon-eggforce"
            />
            <span className="text">Level up Eggs</span>
          </div>
          <div className="snc-gameplay-point point-3">
            <img
              src={iconHatch}
              alt=""
              className="snc-gameplay-icon icon-egg-hatch"
            />
            <span className="text">Hatch Eggs</span>
          </div>
          <div className="snc-gameplay-point point-4">
            <img
              src={imgSNCChest}
              alt=""
              className="snc-gameplay-icon icon-snc-minted"
            />
            <span className="text">SNC Minted</span>
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default SNCFlowDiagram;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
// import { StickySNCRegisterButton } from '@/components/EnterWorldmapButton';
// import SNCRegistrationForm from '@/components/SNCRegistrationForm';
import SNCFlowDiagram from '@/components/SNCPageSubComponents/SNCFlowDiagram';
import SNCUtilities from '@/components/SNCPageSubComponents/SNCUtilities';
import SNCQASection from '@/components/SNCPageSubComponents/SNCQASection';
import SNCWhatIsSNC from '@/components/SNCPageSubComponents/SNCWhatIsSNC';
import SNCTokenomic from '@/components/SNCPageSubComponents/SNCTokenomic';
import logoCasper from '@/assets/images/icon--casper.svg';
import Decor from '@/components/GDecor';
import imgSNC from '@/assets/images/icon--snc.png';

const SNCHeader = () => {
  return (
    <React.Fragment>
      <img
        src={logoCasper}
        className="logo--casper-blockchain"
        alt="Casper Blockchain"
      />
      <div className="decor--header-snc">
        <img
          src={imgSNC}
          alt="WELCOME TO THE NEW ERA OF EGGFORCENFT"
          className="decor--snc"
        />
        <Decor.Top />
      </div>
    </React.Fragment>
  );
};

const SNCPage = () => {
  return (
    <MasterLayoutBody isRegularPage>
      <SNCHeader />
      <Container className="snc-gameplay--block storypage--block storypage--block-1">
        <Row>
          <Col md={12}>
            <SNCWhatIsSNC />
          </Col>
          <Col md={12}>
            <div className="snc-block--child">
              {/* <SNCRegistrationForm /> */}
            </div>
          </Col>
          <Col md={12}>
            <SNCFlowDiagram />
            <SNCUtilities />
            <SNCTokenomic />
          </Col>
          <Col md={12}>
            <SNCQASection />
          </Col>
        </Row>
      </Container>
      {/* <StickySNCRegisterButton /> */}
    </MasterLayoutBody>
  );
};

export default SNCPage;

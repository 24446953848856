import Big from 'big.js';
import CONFIG from '@/constants/settings';
import { moteToCspr, csprToMote } from '@/helpers/balance';
import type { TypeMote } from '@/types/balance';

export const getMintingFee = (amountEgg: number): TypeMote => {
  try {
    /**
     * Formula:
     * fee = (10 * eggs quantity ) + 20 CSPR network fee;
     */
    const networkFee_CSPR = moteToCspr(CONFIG.MINT_ONE_PAYMENT_AMOUNT);
    const amountEggCSPR = Big(amountEgg).times(10);
    const fee_CSPR = Big(amountEggCSPR).plus(networkFee_CSPR).toNumber();
    const amountFee = csprToMote(fee_CSPR);

    return amountFee;
  } catch (err: any) {
    console.error('Error: ', err);
    return 0;
  }
};

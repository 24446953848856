import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import { axios } from '@/services/axios';
import API from '@/constants/api';
import { AppDispatch, RootState } from '@/app/store';
import { IAuthState } from '@/modules/Auth/store';
import { isReadyForDeployment } from '@/modules/Auth/actions';
import { executeErrorMessage } from '@/services/errorsWrapper';
import {
  buildRefundInstallmentPaymentDeploy,
  buildPayInstallmentPaymentDeploy,
} from '@/services/casperServiceTS';
import { contractToByteArray } from '@/modules/EggPurchase/utils';
import type { UserType } from '@/modules/Auth/store';
import type {
  TypeRefundInstallmentPayment,
  TypePayInstallmentPayment,
} from '@/types/package';
import { fetchWASM } from '@/services/fetchServices';
import WASM_PATHS from '@/constants/wasmPath';
import { InstallmentsPaymentActions } from './store';
import { EggForceConfigurationEnum } from '@/types/generic';

// Pay Installment Payment method
export const payInstallmentProcess = createAsyncThunk<
  unknown,
  TypePayInstallmentPayment,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('installmentPayment/payProcess', async (data, { getState }) => {
  const state: { auth: IAuthState } = getState();
  const {
    auth: { currentUser, configuration },
  } = state;

  try {
    const isReady = await isReadyForDeployment(getState, [
      EggForceConfigurationEnum.INSTALLMENT_CONTRACT_HASH,
    ]);

    if (!isReady) {
      throw new Error('Check Casper connector');
    }

    const { activeKey } = currentUser as UserType;
    const { amount, id, paymentAmount } = data;
    const wasmDealer = await fetchWASM(WASM_PATHS.INSTALLMENT_PAYMENT_PAY);
    const installmentPaymentHash = configuration.INSTALLMENT_CONTRACT_HASH!;

    const result = buildPayInstallmentPaymentDeploy({
      key: activeKey,
      wasm: wasmDealer,
      installment_payment: contractToByteArray(installmentPaymentHash),
      paymentAmount: String(paymentAmount),
      amount,
      id,
    });

    return result;
  } catch (error: any) {
    executeErrorMessage(error);
    return;
  } finally {
  }
});

// Refund CSPR
export const refundInstallmentProcess = createAsyncThunk<
  unknown,
  TypeRefundInstallmentPayment,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('installmentPayment/refundProcess', async (data, { getState }) => {
  const state: { auth: IAuthState } = getState();
  const {
    auth: { currentUser, configuration },
  } = state;

  try {
    const isReady = await isReadyForDeployment(getState, [
      EggForceConfigurationEnum.INSTALLMENT_CONTRACT_HASH,
    ]);

    if (!isReady) {
      throw new Error('Check Casper connector');
    }

    const { activeKey } = currentUser as UserType;
    const { id, paymentAmount } = data;
    const installmentPaymentHash = configuration.INSTALLMENT_CONTRACT_HASH!;
    const result = buildRefundInstallmentPaymentDeploy({
      id,
      key: activeKey,
      paymentAmount: String(paymentAmount),
      contract: installmentPaymentHash,
    });

    return result;
  } catch (error: any) {
    executeErrorMessage(error);
    return;
  } finally {
  }
});

export const fetchInstallmentPayments = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'installmentPayment/fetchInstallmentPayments',
  async (_, { dispatch, getState, signal }) => {
    const {
      auth: { currentUser },
      installmentsPayment,
    }: RootState = getState();
    const { loaded } = installmentsPayment;
    try {
      const isReady = await isReadyForDeployment(getState);

      if (!isReady) {
        throw new Error('Check Casper connector');
      }

      dispatch(InstallmentsPaymentActions.setLoading(true));
      const { activeKey } = currentUser as UserType;
      const query = qs.stringify({
        publicKey: activeKey,
      });
      const { data } = await axios.get(API.installment(query), {
        signal,
      });
      const { result, installments } = data;

      if (!result) {
        throw new Error('Unable to load installments');
      }

      if (result) {
        dispatch(
          InstallmentsPaymentActions.updateInstallmentPaymentList(installments),
        );
      }
    } catch (error: any) {
      executeErrorMessage(error);
      return;
    } finally {
      dispatch(InstallmentsPaymentActions.setLoading(false));
      if (!loaded) {
        dispatch(
          InstallmentsPaymentActions.setInstallmentPaymentListLoaded(true),
        );
      }
    }
  },
);

export const getInstallmentPaymentDetail = createAsyncThunk<
  unknown,
  unknown,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'installmentPayment/getInstallmentPaymentDetail',
  async (idInstallment: any, { dispatch, getState, signal }) => {
    try {
      dispatch(
        InstallmentsPaymentActions.setInstallmentDetailModalLoading(true),
      );
      const isReady = await isReadyForDeployment(getState);

      if (!isReady) {
        throw new Error('Check Casper connector');
      }
      dispatch(InstallmentsPaymentActions.showInstallmentDetailModal());

      const { data } = await axios.get(API.installmentDetail(idInstallment), {
        signal,
      });
      const { result, installment } = data;

      if (!result) {
        throw new Error(`Unable to load installment: ${idInstallment}`);
      }

      if (result) {
        dispatch(
          InstallmentsPaymentActions.updateInstallmentDetail(installment),
        );
      }
    } catch (error: any) {
      executeErrorMessage(error);
      return;
    } finally {
      dispatch(
        InstallmentsPaymentActions.setInstallmentDetailModalLoading(false),
      );
    }
  },
);

export const previewInstallmentPayment = createAsyncThunk<
  unknown,
  unknown,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'installmentPayment/previewInstallmentPayment',
  async (installmentRequest: any, { getState, dispatch, signal }) => {
    try {
      const isReady = await isReadyForDeployment(getState);

      if (!isReady) {
        throw new Error('Check Casper connector');
      }

      dispatch(
        InstallmentsPaymentActions.setInstallmentDetailModalLoading(true),
      );

      const { numberOfEggs } = installmentRequest;
      const query = qs.stringify({
        numberOfEggs,
        duration: 3,
      });
      const { data } = await axios.get(API.installmentPreview(query), {
        signal,
      });
      const { result, preview } = data;

      if (!result) {
        throw new Error('Unable to load preview installment plan');
      }

      if (result) {
        dispatch(InstallmentsPaymentActions.updateInstallmentDetail(preview));
      }

      return true;
    } catch (error: any) {
      executeErrorMessage(error);
      return;
    } finally {
      dispatch(
        InstallmentsPaymentActions.setInstallmentDetailModalLoading(false),
      );
    }
  },
);

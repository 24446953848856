import { useCallback } from 'react';
import GModal from '@/components/GModal';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import EggMergingLayout from './MockEggMergingLayout';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import { DragonGuideStep7c } from './DragonGuideStep7';

const MockEggMergeModal = () => {
  const dispatch = useDispatch();
  const { nftMergeModal, currentGuideStep } = useSelector(
    selectEggForceJourneyModule,
  );

  const { open } = nftMergeModal;

  const onClose = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideNFTMergeModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      backdrop="static"
      keyboard={false}
      onHide={onClose}
      className="egg-merging-module--modal"
    >
      {currentGuideStep === GuideStep.step7c && <DragonGuideStep7c />}
      <EggMergingLayout />
    </GModal>
  );
};

export default MockEggMergeModal;

import { Heading } from '@/components/Typography';
import SocialSharingBlock from "@/components/SocialSharingBlock";
import imageMaze from '@/assets/short-terms/maze-game.webp';

const MazeGameChoiceModal = () => {
  return (
    <div className="luckyMoney--root mazeGame--root">
      <Heading h={4} className="mt-3 mb-3">
        Find your way through the EggForce Maze Minigame
      </Heading>
      <div className="minigame--body">
        <img className="maze-image" src={imageMaze} alt="Find your way EggForce" />
      </div>
      <div className="game-instruction--background">
        <div className="game-instruction">
          <div className="game-instruction--box">
            <Heading h={4}>Instruction</Heading>
            <ul>
              <li>Find the exit which can <strong>collect most eggs.</strong></li>
              <li><strong>Share your answer (A/B/C)</strong> via Twitter with button below</li>
              <li>Join Discord community, <strong>comment letter A/B/C</strong> in thread on Discord Minigame Post</li>
            </ul>
          </div>
        </div>
        <div className="game-social-sharing">
          <SocialSharingBlock
            twitterProps={{
              title: `📢 What an eggcited #EggForceMaze. My answer is ... , @EggForceNFT!
\nHi friend - come, join me and earn rewards 👉 #NFT #minigame #EggForceMaze @Casper_Network`,
              via: "",
              hashtags: ['EggForceLuckyMoney'],
              url: 'https://go.eggforce.io/link',
              label: 'Share My luck'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MazeGameChoiceModal;

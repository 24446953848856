import { useCallback } from 'react';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import CSPRExplorerButton from '@/components/CSPRExplorerButton';
import { getInstallmentPaymentDetail } from '@/modules/InstallmentsPayment/actions';
import Button from '@/components/GButton';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import configs from '@/constants/settings';

const TabBasicInfo = ({ data }: any) => {
  const dispatch = useDispatch();
  const {
    isDragonNFT,
    element = undefined,
    isInstallment,
    installmentId,
    tokenId,
    symbol,
    contractName,
    contractAddress,
    egg,
    dna,
    rarity,
    dob,
    edition,
    luckyPoinUnsynced = false,
  } = data;

  const onViewInstallmentPayment = useCallback(() => {
    if (isInstallment) {
      dispatch(getInstallmentPaymentDetail(installmentId));
    }
  }, [dispatch, installmentId, isInstallment]);

  return (
    <div className="nft-detail-basic-info--wrapper">
      <div className="metadata-properties is-row">
        <div className="metadata--row">
          <div className="metadata--column left">ID</div>
          <div className="metadata--column right">#{tokenId}</div>
        </div>
        {element && (
          <div className="metadata--row">
            <div className="metadata--column left">Element</div>
            <div className="metadata--column right">{element}</div>
          </div>
        )}
        {egg?.luck > 0 && (
          <div className="metadata--row">
            <div className="metadata--column left">Lucky Point</div>
            <div className="metadata--column right">
              {configs.MAXIMUM_LUCKY_POINT}%{/* {egg?.luck ?? 0}%  */}
              {luckyPoinUnsynced ? '(Unclaimed)' : ''}
            </div>
          </div>
        )}
        <div className="metadata--row">
          <div className="metadata--column left">Symbol</div>
          <div className="metadata--column right">{symbol}</div>
        </div>

        {dna && (
          <div className="metadata--row">
            <div className="metadata--column left">DNA</div>
            <div className="metadata--column right">{dna}</div>
          </div>
        )}

        {rarity && (
          <div className="metadata--row">
            <div className="metadata--column left">Rarity</div>
            <div className="metadata--column right">{rarity}</div>
          </div>
        )}

        {isDragonNFT && dob && (
          <div className="metadata--row">
            <div className="metadata--column left">D.O.B</div>
            <div className="metadata--column right">{dob}</div>
          </div>
        )}

        {edition && (
          <div className="metadata--row">
            <div className="metadata--column left">Edition</div>
            <div className="metadata--column right">{edition}</div>
          </div>
        )}

        <div className="metadata--row">
          <div className="metadata--column left">Contract Name</div>
          <div className="metadata--column right">{contractName}</div>
        </div>
        <div className="metadata--row">
          <div className="metadata--column left">Contract Address</div>
          <div className="metadata--column right">
            <CSPRExplorerButton
              explorerType="contract"
              className="contract-button"
              hash={contractAddress}
              label={false}
              size="small"
              isEmbedded
            />
          </div>
        </div>
        {MODULES_PERMISSION.USE_BNPL && isInstallment && (
          <div className="metadata--row">
            <div className="metadata--column left">Installment Payment ID</div>
            <div className="metadata--column right">
              <Button
                size="xsmall"
                btnStyle="1"
                onClick={onViewInstallmentPayment}
              >
                View Detail BNPL-{installmentId}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabBasicInfo;

import { animated, useSpring } from 'react-spring';
import iconSelvynn from '@/assets/images/icon--treeSelvyn.webp';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { GuideStep } from '@/modules/EggForceJourneyDemoModule/store';

const SelvnnTree = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;
  const isLastStep = currentGuideStep === GuideStep.step6;

  const wrapperStyles = useSpring({
    reverse: false,
    to: {
      transform: !isLastStep
        ? 'scale(0.75) translate(0px, 100px)'
        : 'scale(1) translate(0px, 0px)',
      opacity: !isLastStep ? 0 : 1,
    },
  });

  return (
    <animated.a
      style={wrapperStyles}
      href="/world"
      rel="nofollow noopener noreferrer"
      target="_blank"
      className="mock--enter-journey"
    >
      <img src={iconSelvynn} alt="Enter journey" />
    </animated.a>
  );
};

export default SelvnnTree;

import React from 'react';
import { Heading } from '@/components/Typography';
import iconChest from '@/assets/images/img--chest.png';
import Button from '@/components/GButton';
import DragonSectionWrapper from '../DragonSectionWrapper';
import DragonHeaderPrimary from '../DragonHeaderPrimary';
import iconGoal1 from '@/assets/images/goal--1.png';
import iconGoal2 from '@/assets/images/goal--2.png';
import iconGoal3 from '@/assets/images/goal--3.png';
import iconGoal4 from '@/assets/images/goal--4.png';
import iconGoal5 from '@/assets/images/goal--5.png';
import iconGoal6 from '@/assets/images/goal--6.png';

const DragonsBenefits = () => {
  const benefits = [
    {
      heading: 'Financial',
      value: 'Increase your CSPR earnings',
      icon: iconGoal1,
    },
    {
      heading: 'Engagement',
      value: 'Participate in exciting gameplay',
      icon: iconGoal2,
    },
    {
      heading: 'Artistry',
      value: 'Collect, trade or sell your NFTs for profit',
      icon: iconGoal3,
    },
    {
      heading: 'Collection',
      value: 'Get epic Dragons to enhance your collection',
      icon: iconGoal4,
    },
    {
      heading: 'Community',
      value: 'Join our passionate community for a long-term journey',
      icon: iconGoal5,
    },
    {
      heading: 'Growth',
      value: 'Raise your Dragons while earning limitless SNC tokens',
      icon: iconGoal6,
    },
  ];
  const onClickHandler = React.useCallback(() => {
    const element = document.querySelector(
      '#mock--eggforce-world',
    ) as HTMLElement;
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  return (
    <DragonSectionWrapper name="benefits">
      <DragonHeaderPrimary heading="6 goals when you embark on the EggForceNFT journey">
        {/* <Balancer className="body-content">
          6 goals when you embark on the EggForceNFT journey
        </Balancer> */}
      </DragonHeaderPrimary>
      <div className="body">
        <div className="dragon-benefits--wrapper">
          <div className="dragon-benefits">
            {benefits.map((benefit) => (
              <div key={benefit.heading} className="each-benefit">
                <img src={benefit.icon} className="icon" alt={benefit.value} />
                <Heading h="5">{benefit.heading}</Heading>
                <p className="text">{benefit.value}</p>
              </div>
            ))}
          </div>
          <Button onClick={onClickHandler} className="btn--explore-now">
            <span>Experience the EggForce journey</span>
          </Button>
        </div>
      </div>
      <img src={iconChest} className="image--chest" alt="Chest" />
    </DragonSectionWrapper>
  );
};

export default DragonsBenefits;

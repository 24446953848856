import { useMemo } from 'react';
import cn from 'classnames';
import {
  Boxes,
  CheckCircleFill,
  ExclamationDiamondFill,
} from 'react-bootstrap-icons';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import configs from '@/constants/settings';
import { DeployStatus } from '@/types/deploy';
import MiddleTruncatedText from '../MiddleTruncatedText';

const NotificationItem = (props: any) => {
  const { hash, className } = props;
  const deployTransactionUrl = useMemo(() => {
    return `${configs.CSPR_EXPLORER}/deploy/${hash.hash}`;
  }, [hash]);

  const { icon, message } = useMemo(() => {
    let statusMessage = '';
    let icon = <Boxes />;
    switch (hash.status) {
      case DeployStatus.COMPLETED: {
        statusMessage = 'is completed.';
        icon = <CheckCircleFill />;
        break;
      }
      case DeployStatus.PENDING: {
        statusMessage = ' is in progress. Please wait a few minutes.';
        break;
      }
      case DeployStatus.FAILED: {
        statusMessage = " couldn't complete. Please check CSPR deploy.";
        icon = <ExclamationDiamondFill />;
        break;
      }
      default:
        statusMessage = '';
    }

    return {
      icon,
      message: (
        <div className="content">
          Deploy{' '}
          <span className="hash-text">
            <MiddleTruncatedText>{hash.hash}</MiddleTruncatedText>
          </span>{' '}
          {statusMessage}
        </div>
      ),
    };
  }, [hash]);

  return (
    <div className="notification-item--root">
      <a
        href={deployTransactionUrl}
        rel="nofollow noopener noreferrer"
        target="_blank"
        title={`View ${hash.hash} on CSPR explorer`}
        className={cn('btn--view-hash-explorer--ignore', className)}
      >
        <div
          className={cn('notification-item--content', {
            'is--completed': hash.status === DeployStatus.COMPLETED,
            'is--pending': hash.status === DeployStatus.PENDING,
            'is--failed': hash.status === DeployStatus.FAILED,
          })}
        >
          <div className="notification-item--type">
            <span>{icon}</span>
          </div>
          <div className="notification-item--body">
            {message}
            <div className="last-updated">
              {formatDistanceToNow(new Date(hash.createdAt), {
                addSuffix: true,
              })}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default NotificationItem;

import React, { useState, useCallback, useMemo } from 'react';
import Big from 'big.js';
import cn from 'classnames';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { Heading } from '@/components/Typography';
import { getMintingFee } from '@/modules/WorldMint/utils';
import type { TypePurchasePackageParams } from '@/types/package';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import TablePackageSummary from '@/components/TablePackageSummary';
import AccountMiniHeader from '@/components/AccountMiniHeader';
import { useSelector } from 'react-redux';
import { selectInstallmentPaymentsDetailModal } from '@/modules/InstallmentsPayment/selectors';
import type { TypePreviewInstallmentPayment } from '@/types/package';
import useBlockMinting from '@/hooks/useBlockMinting';

interface IProps {
  packages: any[];
  formatter: string;
  open?: boolean;
  className?: string;
  selectedPackageIndex: number | null;
  onClose: () => void;
  onConfirm: (data: unknown) => void;
  isDeploying?: boolean;
}

const MockPurchaseConfirmPackageModal = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    isDeploying,
    onConfirm,
    onClose,
    open,
    packages,
    selectedPackageIndex,
  } = props;
  const { shouldDisable } = useBlockMinting({ isDeploying });
  const installmentDetailModal = useSelector(
    selectInstallmentPaymentsDetailModal,
  );
  const installmentDetail: TypePreviewInstallmentPayment =
    installmentDetailModal.data;
  const loadingBNPLPreview = installmentDetailModal.loading;
  const selectedPackage =
    selectedPackageIndex !== null
      ? packages.find(
          (packageItem: any) => packageItem.id === selectedPackageIndex,
        )
      : undefined;
  const [isInstallmentPayment, setInstallmentPayment] =
    useState<boolean>(false);

  const shouldDisablePurchase = useMemo(() => {
    if (shouldDisable) {
      return true;
    }

    if (isInstallmentPayment) {
      if (!installmentDetail || loadingBNPLPreview) {
        return true;
      }

      if (!installmentDetail?.isBuyable) {
        return true;
      }
    }

    return false;
  }, [
    shouldDisable,
    isInstallmentPayment,
    installmentDetail,
    loadingBNPLPreview,
  ]);
  const onSelectRegularPaymentType = useCallback(() => {
    setInstallmentPayment(false);
    dispatch(InstallmentsPaymentActions.updateInstallmentDetail(null));
  }, [dispatch]);
  const data = useMemo(() => {
    if (!selectedPackage) {
      return undefined;
    }
    const amountFee = getMintingFee(selectedPackage.amount);
    const hasDiscountPrice = Boolean(selectedPackage.percent > 0);
    const priceInUse = hasDiscountPrice
      ? Big(selectedPackage.priceSale)
      : Big(selectedPackage.priceDefault);

    const packageLabel = `${selectedPackage.name} (${selectedPackage.amount} ${
      selectedPackage.amount > 1 ? `eggs` : `egg`
    })`;
    const amountLocked = priceInUse.plus(amountFee);

    return {
      packagePrice: priceInUse.toNumber(),
      packageLabel,
      amountFee: amountFee,
      amountLocked: amountLocked.toNumber(),
    };
  }, [selectedPackage]);

  const onConfirmHandler = useCallback(() => {
    onConfirm({
      totalPackageValue: data!.packagePrice,
      amountEgg: selectedPackage.amount,
      buy_for: '',
      isBuyForChecked: false,
      isInstallmentPayment: false,
      affiliateCode: '',
    } as TypePurchasePackageParams);
  }, [data, onConfirm, selectedPackage]);

  if (!selectedPackage || !data) {
    return null;
  }

  return (
    <GModal show={open} className={'purchase-review'} onHide={onClose}>
      <div className="purchase-review--root">
        <Heading h={3} className="mb-3">
          Purchase Details
        </Heading>
        <AccountMiniHeader />
        <div className="purchase-review--wrapper">
          <Heading h={5}>Payment Method</Heading>
          <div
            className={cn('purchase-payment-method--wrapper', {
              'is-installment-payment': isInstallmentPayment,
            })}
          >
            <Button
              disabled={isDeploying}
              fullWidth
              size="small"
              onClick={onSelectRegularPaymentType}
              className={cn('payment--full', {
                'is-unselected': isInstallmentPayment,
              })}
              ignoreShadowStyle
              btnStyle={isInstallmentPayment ? '4' : '5'}
            >
              Full payment
            </Button>
            <Button
              disabled
              fullWidth
              size="small"
              className={cn('payment--bnpl', {
                'is-unselected': !isInstallmentPayment,
              })}
              ignoreShadowStyle
              btnStyle={isInstallmentPayment ? '5' : '4'}
            >
              <span>Buy Now, Pay Later</span>
              <div className="bnpl--text">
                <span style={{ marginRight: 6 }}>0%</span> Interest rate
              </div>
            </Button>
          </div>
          <div
            style={{ marginTop: 24 }}
            className={cn('no-minheight payment-details--summary')}
          >
            <TablePackageSummary data={data} />
          </div>
          <Button
            disabled={shouldDisablePurchase}
            onClick={onConfirmHandler}
            btnStyle="1"
            className="btn--confirm-purchase-package"
          >
            {isInstallmentPayment ? 'Start Installment Plan' : 'Purchase'}
          </Button>
          <div className="content-wrapper">
            <p className="helper">Please double check all info below</p>
            <ul>
              <li>
                Verify that you have selected the correct package and have a
                sufficient balance in your wallet account.
              </li>
              <li>Once signed, transaction cannot be cancelled.</li>
              {!isInstallmentPayment && (
                <li>
                  (*) Locked amount is reserved until minting process finished.
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </GModal>
  );
};

export default MockPurchaseConfirmPackageModal;

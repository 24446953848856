import { useMemo, useCallback } from 'react';
import GModal from '@/components/GModal';
import { Check2Circle, Boxes, InfoCircle } from 'react-bootstrap-icons';
import GNotificationModal from '@/components/GNotificationModal';
import { Heading } from '@/components/Typography';
import { selectHammerBurnStatusModal } from '@/modules/HammerBurn/selectors';
import { hammerBurnActions } from '@/modules/HammerBurn/store';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import CommonMessage from '@/components/CommonMessages';
import messages from '@/modules/HammerBurn/messages';

const HammerBurnStatusModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const { open, result } = useSelector(selectHammerBurnStatusModal);
  const onCloseHandler = useCallback(() => {
    dispatch(hammerBurnActions.hideBurnStatusModal());
    onClose();
  }, [dispatch, onClose]);
  const { title, icon, msg } = useMemo(() => {
    return {
      msg: !result ? CommonMessage.Failed : CommonMessage.Success,
      title: !result
        ? messages.infoBurnDragonIncomplete.defaultMessage
        : messages.infoBurnDragonCompleted.defaultMessage,
      icon: () => (!result ? <Boxes /> : <Check2Circle />),
    };
  }, [result]);

  return (
    <GModal
      closeButton={false}
      blurOverlay
      show={open}
      // show
      className={'hammer-burn-result'}
    >
      <GNotificationModal
        labelConfirm={'I understand'}
        className="egg-evolution--modal-status"
        onClose={onCloseHandler}
        icon={icon()}
      >
        <Heading h={4}>{title}</Heading>
        <p className="helper">
          <InfoCircle className="icon--helper" />
          {msg}
        </p>
        {/* {result && (
          <p className="helper">
            <InfoCircle className="icon--helper" />
            {messages.claimInstruction.defaultMessage}
          </p>
        )} */}
      </GNotificationModal>
    </GModal>
  );
};

export default HammerBurnStatusModal;

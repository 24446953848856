import cn from 'classnames';

const PackagePanel = ({
  onClick = undefined,
  packageIdx = 1,
  children,
  className,
}: any) => {
  return (
    <div
      className={cn(`package-item package-item--root`, className, {
        [`package-${packageIdx}`]: true,
      })}
      {...(onClick && { ...onClick })}
    >
      {children}
    </div>
  );
};

export default PackagePanel;

import { useCallback } from 'react';
import useCurrentUser from '@/hooks/useCurrentUser';
import useConfirmDeploy from '@/hooks/useConfirmDeploy';
import { BurnHammerDetailsSpec } from '@/types/EggActions';
import { getBurnHammerDeploy } from '@/services/burnNFTService';

const useHammerBurn = () => {
  const user = useCurrentUser();
  const { executeDeployWithoutPut, isDeploying, isError } = useConfirmDeploy();
  const buildBurnHammerDeployFn = useCallback(
    (details: BurnHammerDetailsSpec) => () => getBurnHammerDeploy(details),
    [],
  );

  const onBurnHammerHandler = useCallback(
    async (details: BurnHammerDetailsSpec) => {
      if (!user) {
        return undefined;
      }

      const result = await executeDeployWithoutPut(
        buildBurnHammerDeployFn(details),
        details.owner,
        details.owner,
      );
      // console.log(`🚀 ~ onBurnHammerHandler::result:`, result);

      return result;
    },
    [user, executeDeployWithoutPut, buildBurnHammerDeployFn],
  );

  return {
    burnHammer: onBurnHammerHandler,
    isDeploying,
    isError,
  };
};

export default useHammerBurn;

import QACasperSignerConnection from './QACasperSignerConnection';
import QACasperWallet from './QACasperWallet';
import QAProfileStatuses from './QAProfileStatuses';
import QAHatchingButtonsGroup from './QAHatchingButtonsGroup';

const mockData = [
  {
    question: "What's EggForce?",
    answer:
      'EggForce is a story and community-driven, high quality hand-drawn 10,000 Egg NFTs collection on Casper Network',
  },
  {
    question:
      'I am new with Casper Network. How could I create my Casper Wallet?',
    answer: () => <QACasperWallet />,
  },
  {
    question:
      "My NFT didn't show up in my NFT Collection. How long does it take to mint a NFT?",
    answer:
      'This progress usually takes around 3-5 minutes for a successful minting process. You can visit your NFT Collection after that duration.',
  },
  {
    question:
      "I can't connect to Casper Signer from EggForce website. What should I do?",
    answer: () => <QACasperSignerConnection />,
  },
  {
    question: 'What does the status dot on my profile indicate?',
    answer: () => <QAProfileStatuses />,
  },
  {
    question:
      'Tell me the differences between Incubate, Stop Incubate only and Stop Incubate & Undelegate?',
    answer: () => <QAHatchingButtonsGroup />,
  },
];

export default mockData;

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageDragon1 from '../../assets/images/dragon-doc 2.png';
import ImageDragon2 from '../../assets/images/dragon-kim 1.png';
import ImageDragon3 from '../../assets/images/dragon-kim-2 1.png';
import ImageDragon4 from '../../assets/images/dragon-loi 1.png';
import ImageDragon5 from '../../assets/images/dragon-lua 1.png';
import ImageDragon6 from '../../assets/images/dragon-ma 2.png';
import ImageDragon7 from '../../assets/images/dragon-moc 1.png';
import NFTEggs from '../../assets/images/NFTs-eggs.png';

const DragonIntroSwiper = () => {
  const swiperProps = {
    allowTouchMove: true,
    loop: true,
    freeMode: true,
    grabCursor: true,
    modules: [Autoplay],
    speed: 15000,
    spaceBetween: 20,
    slidesPerView: 3,
    autoplay: {
      delay: 1,
      disableOnInteraction: true,
    },
    breakpoints: {},
  };

  const dragonsUpperRow = [
    ImageDragon1,
    ImageDragon2,
    ImageDragon3,
    ImageDragon4,
    ImageDragon5,
    ImageDragon6,
    ImageDragon7,
  ];

  const dragonsLowerRow = [
    ImageDragon1,
    ImageDragon2,
    ImageDragon3,
    ImageDragon4,
    ImageDragon5,
    ImageDragon6,
    ImageDragon7,
  ];

  return (
    <div>
      <Swiper {...swiperProps} className="dragon-icon--swiper line-1">
        {dragonsUpperRow.map((imgSrc, index) => (
          <SwiperSlide
            key={`dragon-image--${index}`}
            className="dragon-icon--img-wrapper"
          >
            <img
              width={130}
              alt={`Dragon EggForce`}
              className="dragon-icon--img"
              src={imgSrc}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        {...swiperProps}
        className="dragon-icon--swiper line-2"
        initialSlide={2}
      >
        {dragonsLowerRow.map((imgSrc, index) => (
          <SwiperSlide
            key={`dragon-image--${index}`}
            className="dragon-icon--img-wrapper"
          >
            <img
              width={130}
              alt={`Dragon EggForce`}
              className="dragon-icon--img"
              src={imgSrc}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <img alt="Eggs" src={NFTEggs} className="mw-100" />
    </div>
  );
};

export default DragonIntroSwiper;

import type { RootState } from '@/app/store';

const selectHammerBurnModule = (state: RootState) => state.hammerBurnModule;
const selectHammerBurnStatusModal = (state: RootState) =>
  state.hammerBurnModule.burnStatusModal;
const selectHammerBurnInstructionModal = (state: RootState) =>
  state.hammerBurnModule.burnInstructionModal;

export {
  selectHammerBurnInstructionModal,
  selectHammerBurnStatusModal,
  selectHammerBurnModule,
};

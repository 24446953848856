import { useMemo, useCallback } from 'react';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { sharedToastProps, toast } from '@/services/toast';
import iconHatch from '@/assets/images/icon--hatch.png';
import type { NFTItem } from '@/types/NFTItem';
import { dragonManagementActions } from '@/modules/DragonManagementModule/store';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import messagesDetail from '@/components/NFTDetailViewModal/messages';
import MockDragonHatchStatusModal from './MockDragonHatchStatusModal';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import NetworkFeePanel from '@/components/NetworkFeePanel';
import commonMessages from '@/constants/commonMessages';
import LoadingBox from '@/components/LoadingBox';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';
import { mockDragonHatch } from '@/modules/EggForceJourneyDemoModule/actions';

const MockDragonHatchModal = () => {
  const MAKE_DRAGON_PAYMENT_AMOUNT = 65;
  const dispatch = useDispatch();
  const statusLoading = false;
  const { nftDetailModal, dragonHatchModal } = useSelector(
    selectEggForceJourneyModule,
  );
  const { data: dataNFTDetail } = nftDetailModal;
  const { open } = dragonHatchModal;

  const {
    token_uri: image,
    tokenId,
    egg,
    isInstallment,
  } = useMemo(() => {
    try {
      if (!dataNFTDetail) {
        throw new Error('NFTData is missing');
      }
      return getNFTDetails(dataNFTDetail as NFTItem);
    } catch (error: any) {
      return {
        token_uri: null,
        tokenId: null,
        egg: null,
        isInstallment: null,
      };
    }
  }, [dataNFTDetail]);
  const onCloseHandler = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideDragonHatchModal());
    dispatch(EggForceJourneyDemoModuleActions.setCurrentNFTDetail(null));
  }, [dispatch]);
  const shouldDisableDragronMakeButton = useMemo(() => {
    return Boolean(isInstallment || egg?.isProcessing);
  }, [egg?.isProcessing, isInstallment]);

  const onEvolveDragon = useCallback(async () => {
    try {
      if (true) {
        dispatch(
          mockDragonHatch({
            tokenId: String(tokenId),
          }),
        );
        dispatch(EggForceJourneyDemoModuleActions.showDragonHatchStatusModal());
        return {};
      }
    } catch (err: any) {
      toast.warn(messagesDetail.makeDragonFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
      dispatch(dragonManagementActions.setMakeStatusLoading(false));
    } finally {
      // onHideConfirmModalHandler();
    }
  }, [dispatch, tokenId]);

  if (!open || !dataNFTDetail) {
    return null;
  }

  return (
    <GModal
      blurOverlay
      show={open}
      className={'egg-evolve-dragon-confirm--modal'}
      onHide={onCloseHandler}
    >
      <div className="body-wrapper">
        <img src={image.value} alt="Egg" className="egg-evolve-dragon--egg" />
      </div>

      <NetworkFeePanel fee={MAKE_DRAGON_PAYMENT_AMOUNT} isMote={false} />
      <div className="actions">
        <Button
          disabled={shouldDisableDragronMakeButton}
          className="inside-nft-detail btn--nft-action btn--evolve-dragon"
          onClick={onEvolveDragon}
          btnStyle="5"
          size="xl"
        >
          {statusLoading ? (
            <>
              <LoadingBox
                isHorizontal
                label="MAKING DRAGON"
                className="loading--making-dragon"
                minHeight={32}
              />
            </>
          ) : (
            <>
              <img
                className="icon icon--claim-snc"
                src={iconHatch}
                alt={commonMessages.labelDragonHatch.defaultMessage}
              />
              {commonMessages.labelDragonHatch.defaultMessage}
            </>
          )}
        </Button>
      </div>
      <MockDragonHatchStatusModal onClose={onCloseHandler} />
    </GModal>
  );
};

export default MockDragonHatchModal;

import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import cn from 'classnames';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import iconHatch from '@/assets/images/icon--hatch.png';
import {
  selectDragonMakeStatusModal,
  selectDragonPreviewModal,
} from '@/modules/DragonManagementModule/selectors';
import { dragonManagementActions } from '@/modules/DragonManagementModule/store';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import commonMessages from '@/constants/commonMessages';
import LoadingBox from '@/components/LoadingBox';
import { useSpring, animated } from 'react-spring';
import useNFTBadgeIcon from '@/hooks/useNFTBadgeIcon';
import { DeployStatus, type DeployItem } from '@/types/deploy';
import useCurrentUser from '@/hooks/useCurrentUser';
import { selectEggPurchaseStore } from '@/modules/EggPurchase/selectors';
import isEqual from 'lodash/isEqual';
import { EggPurchaseStoreActions } from '@/modules/EggPurchase/store';
import { setDynamicLocalStorageItem } from '@/services/localStorage';
import { NFTTypeEnum } from '@/types/NFTItem';
import useLoadDragons from '@/hooks/useLoadDragons';

const EggEvolveToDragonViewOnlyModal = ({ deploy }: { deploy: DeployItem }) => {
  const { isValidating, isLoading, viewData, refetch } = useLoadDragons();
  // eslint-disable-next-line
  const { element, uri: image } = deploy.metadata! as any;
  const user = useCurrentUser();
  const cacheKey = user ? `deployHashesStore/${user.activeKey}` : undefined;
  const eggPurchasesStore = useSelector(selectEggPurchaseStore, isEqual);
  const { deployHash } = eggPurchasesStore;

  const refVideoDefault = useRef<HTMLVideoElement>(null);
  const refVideoAnimation = useRef<HTMLVideoElement>(null);

  const [animationStarted, setAnimationStarted] = useState(false);
  const [videoAVisible, setVideoAVisible] = useState(true);
  const [dragonBadgeVisible, setDragonBadgeVisible] = useState(false);

  const fadeIn = useSpring({
    opacity: videoAVisible && !animationStarted ? 1 : 0,
    from: { opacity: 1 },
    onRest: () => {
      if (animationStarted) {
        setVideoAVisible(false);
        refVideoAnimation.current?.play();
      }
    },
  });
  const dragonFadeIn = useSpring({
    opacity: dragonBadgeVisible ? 1 : 0,
    transform: dragonBadgeVisible
      ? `scale(1) translate(-50%, -50%)`
      : `scale(0.8) translate(-50%, -30%)`,
    from: { opacity: 0, transform: 'scale(0.8) translate(-50%, -30%)' },
    onRest: () => {},
  });

  const layerVideoNormal = `${process.env.PUBLIC_URL}/bg_effect_remake.webm`;
  const videoEffect = `${process.env.PUBLIC_URL}/EggEffect.webm`;
  const dispatch = useDispatch();
  const { open } = useSelector(selectDragonPreviewModal);

  const dataDragon = useMemo(() => {
    if (deploy.status === DeployStatus.COMPLETED) {
      const dragons = viewData.filter(
        (item) => item.name === NFTTypeEnum.DRAGON,
      );
      if (dragons.length) {
        const newDragon = dragons.filter((dragon) => {
          if (!dragon.metadata?.length) {
            return false;
          }

          const targetDragon = dragon.metadata.filter((data: any) => {
            if (
              data.key === 'origin' &&
              !!data.value &&
              data.value === deploy.metadata?.id
            ) {
              return true;
            }

            return false;
          });

          if (targetDragon.length) {
            return true;
          }

          return false;
        });

        return newDragon?.[0] ?? undefined;
      }
    }
  }, [deploy.metadata?.id, deploy.status, viewData]);
  const { loading: statusLoading } = useSelector(selectDragonMakeStatusModal);
  const imageElement = useNFTBadgeIcon({
    isBackground: true,
    element,
  });

  const onUpdateSeenReview = useCallback(() => {
    const newDeploys = deployHash.map((item) => {
      if (deploy.hash === item.hash) {
        return {
          ...item,
          metadata: {
            ...item.metadata,
            seen: true,
          },
        };
      }

      return item;
    });
    dispatch(EggPurchaseStoreActions.forceUpdateDeploys(newDeploys));
    setDynamicLocalStorageItem(cacheKey, newDeploys);
  }, [cacheKey, deploy, deployHash, dispatch]);
  const onCloseHandler = useCallback(() => {
    dispatch(dragonManagementActions.hideDragonPreviewModal());

    setAnimationStarted(false);
    setVideoAVisible(true);
    setDragonBadgeVisible(false);
  }, [dispatch]);
  const shouldDisableDragronMakeButton = useMemo(() => {
    return Boolean(
      statusLoading ||
        isValidating ||
        isLoading ||
        deploy.status === DeployStatus.PENDING ||
        !dataDragon?.imageUrl,
    );
  }, [
    dataDragon?.imageUrl,
    deploy.status,
    isLoading,
    isValidating,
    statusLoading,
  ]);

  const onStartPreview = useCallback(() => {
    setAnimationStarted(true);
    onUpdateSeenReview();
  }, [onUpdateSeenReview]);

  /**
   * Reload data when deploy COMPLETED
   */
  useEffect(() => {
    if (!dataDragon && deploy.status === DeployStatus.COMPLETED) {
      refetch();
    }
  }, [dataDragon, deploy.status, refetch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      blurOverlay
      show={open}
      className={'egg-evolve-dragon-confirm--modal'}
      onHide={onCloseHandler}
    >
      <div className="body-wrapper">
        <animated.video
          ref={refVideoDefault}
          onContextMenu={(evt) => evt.preventDefault()}
          className="video egg-evolve-dragon--effect-layer default"
          autoPlay
          playsInline
          muted
          loop
          style={{ ...fadeIn }}
        >
          <source src={layerVideoNormal} />
        </animated.video>
        <img src={image} alt="Egg" className="egg-evolve-dragon--egg" />
        {imageElement && (
          <animated.div
            style={{
              ...dragonFadeIn,
            }}
            className="egg-evolve-dragon--dragon-mock-wrapper"
          >
            {dataDragon?.imageUrl && (
              <img
                src={dataDragon.imageUrl}
                alt="Egg"
                className="egg-evolve-dragon--dragon-mock"
              />
            )}

            <img
              src={imageElement}
              alt="EggForce Unique Strength"
              className={cn(
                'block-unique-strength--bg-element',
                'egg-evolve-dragon--dragon-mock-background',
                element,
              )}
            />
          </animated.div>
        )}
        <animated.video
          ref={refVideoAnimation}
          onContextMenu={(evt) => evt.preventDefault()}
          className="video egg-evolve-dragon--effect-layer dragon-hatch"
          autoPlay={false}
          playsInline
          muted
          loop={false}
          onEnded={() => {
            if (animationStarted) {
              setDragonBadgeVisible(true);
            }
          }}
        >
          <source src={videoEffect} />
        </animated.video>
      </div>

      <div className="actions">
        <Button
          disabled={animationStarted || shouldDisableDragronMakeButton}
          className="inside-nft-detail btn--nft-action btn--evolve-dragon"
          onClick={onStartPreview}
          btnStyle="5"
          size="xl"
        >
          {statusLoading ? (
            <>
              <LoadingBox
                isHorizontal
                label="MAKING DRAGON"
                className="loading--making-dragon"
                minHeight={32}
              />
            </>
          ) : (
            <>
              <img
                className="icon icon--claim-snc"
                src={iconHatch}
                alt={commonMessages.labelDragonPreview.defaultMessage}
              />
              {commonMessages.labelDragonPreview.defaultMessage}
            </>
          )}
        </Button>
      </div>
    </GModal>
  );
};

export default EggEvolveToDragonViewOnlyModal;

import cn from 'classnames';

const LeaderboardRankNumber = ({ value }: { value: number }) => (
  <div
    className={cn(`rank-number rank-${value}`, {
      'ranks-top': value < 4,
    })}
  >
    {value}
  </div>
);

export default LeaderboardRankNumber;

import { useCallback } from 'react';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import Button from '@/components/GButton';
import GLeamEventFormModal from '@/components/GLeamEventFormModal';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
import { selectAdHocEventModal } from '@/modules/CustomEventModule/selectors';
import AdHocResultEventModal from './AdHocResultEventModal';

const AdHocEventModal = ({ hideTriggerButon = false }: any) => {
  const dispatch = useDispatch();
  const { open } = useSelector(selectAdHocEventModal);

  const onOpenAdHocEventModal = useCallback(() => {
    dispatch(CustomEventModalActions.showAdHocEventModal());
  }, [dispatch]);
  const onCloseAdHocEventModal = useCallback(() => {
    dispatch(CustomEventModalActions.hideAdHocEventModal());
  }, [dispatch]);
  const onConfirmAdHocEventModal = useCallback(() => {
    onCloseAdHocEventModal();
    dispatch(CustomEventModalActions.showAdHocResultEventModal());
  }, [dispatch, onCloseAdHocEventModal]);

  return (
    <div>
      {!hideTriggerButon && (
        <Button
          className="btn--open-mint-modal"
          onClick={onOpenAdHocEventModal}
        >
          <span>CLAIM WHITELIST NOW</span>
        </Button>
      )}
      <GLeamEventFormModal
        open={open}
        onClose={onCloseAdHocEventModal}
        onConfirm={onConfirmAdHocEventModal}
        skipToastOnConfirm
      />
      <AdHocResultEventModal />
    </div>
  );
};

export default AdHocEventModal;

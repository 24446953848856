import { useState, useCallback } from 'react';
import { Heading } from '@/components/Typography';
import imgSNC from '@/assets/images/icon--snc.png';
import { NFTBenefitMobileModal } from '@/components/NFTBenefit';
import Balancer from 'react-wrap-balancer';

const SNCUtilities = () => {
  const [modalShow, setModalShow] = useState(false);
  const [currPoint, setCurrentPoint] = useState(0);
  const onClickHandler = useCallback((index: number) => {
    setCurrentPoint(index);
    setModalShow(true);
  }, []);
  const data = [
    {
      copy: `Level up and transform your Egg NFTs into Dragon NFTs`,
    },
    {
      copy: `EggForce’s main currency for purchasing items and NFTs`,
    },
    {
      copy: `Essential resource for leveling up Eggs and Dragons`,
    },
  ];

  return (
    <div className="snc-block--child block--nft-utility snc-utilities">
      <Heading className="heading-primary text-gradient text-center mb-4">
        WHAT IS SNC TOKEN & ITS UTILITIES?
      </Heading>
      <div className="body">
        <Balancer className="body-content">
          <strong>Selvyn's Nodule Coin (SNC)</strong>, a CEP-18 token, is the
          most powerful resource in Callista World. Read more about the SNC
          utilities{' '}
          <a href="https://medium.com/@eggforcenft/all-about-snc-tokens-fe92abb4b7ec">
            here
          </a>
          .
        </Balancer>
      </div>
      <div className="snc-utilities-block--content">
        <div className="nft-utility--primary-wrapper snc-wrapper">
          <NFTBenefitMobileModal
            size="sm"
            show={modalShow}
            onHide={() => setModalShow(false)}
            copy={data[currPoint].copy}
          />
          <div className="nft-utility--primary-image snc-image">
            <img
              alt="EggForce SNC Benefit"
              className="nft-utility--snc-token"
              src={imgSNC}
            />
            {data.map(({ copy }, index) => (
              <div
                key={`benefit-${index}`}
                className={`nft-benefit--point point-${index + 1}`}
              >
                <span className="text">{copy}</span>
                <span className="pointer">
                  <div className="btn-pulse point-pulse desktop" />
                </span>
                <button
                  onClick={() => onClickHandler(index)}
                  className="btn-pulse point-pulse mobile"
                >
                  <span />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SNCUtilities;

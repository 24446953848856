import { useState } from 'react';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Heading } from '@/components/Typography';
import Button from '@/components/GButton';
import GSelect, { NewValueType } from '@/components/GSelect';
import GModal from '@/components/GModal';
import ExampleModal from '@/components/StyleguideExamples/ExampleModalAnnouncenents';
import GProgress from '@/components/GProgress';
import StyleguideBlock from '@/components/StyleguideExamples/StyleguideBlock';
import GCheckbox from '@/components/GCheckbox';
import GValidatorSelect from '@/components/GValidatorSelect';
import GInput from '@/components/GInput';
import GSearch from '@/components/GSearch/GSearch';
import CardValidator from '@/components/CardValidator';
import validatorCStake from '@/assets/chapter-images/chapter1--04.webp';
import validatorBackground from '@/assets/chapter-images/chapter1--03.webp';
import RewardRandomEggCracker from '@/components/RewardRandomEggCracker';
import NFTGridItem from '@/components/NFTGridItem';
import type { NFTItem } from '@/types/NFTItem';
import ASSETS_URL from '@/constants/assetsURL';
// import EggCracker from '@/components/EggCracker';
// import imgEgg1 from '@/assets/images/gameplay-icon-1.webp';
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const mock: NFTItem = {
  isInstallment: false,
  installmentId: 1,
  tokenId: '69',
  contractName: 'Dragon NFT',
  contractAddress:
    'hash-80a7c1e6cf552d69369ed5c351e0968ef00c42f355a6a9ba50f390d28a2609c1',
  creator: '010976375952ee6b58e8919db4a741274e6c584b6cf09c9fcbdf31f27fe2f61fd0',
  metadata: [
    {
      key: 'name',
      name: 'name',
      value: 'Eggforce Whitelist',
    },
    {
      key: 'description',
      name: 'description',
      value: 'Eggforce Whitelist',
    },
    {
      key: 'Material',
      name: 'Material',
      value: 'Selvyn’s nodules',
    },
    {
      key: 'Year of creation',
      name: 'Year of creation',
      value: '2022',
    },
    {
      key: 'Utilization',
      name: 'Utilization',
      value: 'Break whitelisted Genesis Egg',
    },
    {
      key: 'image',
      name: 'image',
      value: 'https://i.gifer.com/4P4W.gif',
    },
  ],
  action: 'Hatch',
  ownerAccountHash:
    'account-hash-1d7e8962e3b78b29a5bf430ebc0137f561482819d839655f06f17459a4198207',
  name: 'Dragon_NFT',
  total_supply: {
    type: 'BigNumber',
    hex: '0x3b9aca00',
  },
  symbol: 'CDRG',
  balances: 26,
};

const StyleguidePage = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  return (
    <MasterLayoutBody isRegularPage>
      {
        // Require for Confentti effect
      }
      <div
        className="js-container confe-container"
        style={{
          top: '0px !important',
        }}
      ></div>
      <Container className="">
        <Row>
          <Col md={12}>
            <Heading className="text-center mb-4">Styleguide</Heading>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Mint and Reward animation">
              {/* <EggCracker renderReward={<img src={imgEgg1} alt="Reward" />} /> */}
              <RewardRandomEggCracker
                resultType="string"
                winners={['John', 'Wick', 'Rock']}
              />
              <RewardRandomEggCracker resultType="number" max={888} />
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Typography Heading">
              <Heading h={1} className="mb-2 mt-2">
                Heading 1
              </Heading>
              <Heading h={2} className="mb-2 mt-2">
                Heading 2
              </Heading>
              <Heading h={3} className="mb-2 mt-2">
                Heading 3
              </Heading>
              <Heading h={4} className="mb-2 mt-2">
                Heading 4
              </Heading>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Laboriosam, a.
              </p>
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Button Styles">
              <Button>Button Default</Button>
              <Button btnStyle="1">Button Style 1</Button>
              <Button btnStyle="2">Button Style 2</Button>
              <Button btnStyle="3">Button Style 3</Button>
            </StyleguideBlock>
          </Col>
          <Col md={12}>
            <StyleguideBlock title="Button Sizes">
              <Button btnStyle="1" size="small">
                Size `small`
              </Button>
              <Button btnStyle="1" size="xl">
                Size `xl`
              </Button>
              <Button btnStyle="1" size="xxl">
                Size `xxl`
              </Button>
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Common Input">
              <GInput type="email" placeholder="Your email" />
              <GInput type="password" placeholder="Password" />
            </StyleguideBlock>
            <StyleguideBlock title="Special Input">
              <GSearch />
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Dropdown / Select">
              <GSelect
                options={options}
                onChange={(option: NewValueType) => console.log('>: ', option)}
              />
              <GSelect
                isMulti
                options={options}
                onChange={(option: NewValueType) => console.log('>: ', option)}
              />
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Modals">
              <Button size="small" onClick={() => setShowModal1(true)}>
                Size `small`
              </Button>
              <GModal
                size="sm"
                title="Title goes here"
                show={showModal1}
                onHide={() => setShowModal1(false)}
              >
                <div style={{ minHeight: 2000 }}>
                  <p>Hello world</p>
                </div>
              </GModal>

              <Button size="small" onClick={() => setShowModal2(true)}>
                Notification Modal
              </Button>
              <GModal
                show={showModal2}
                modalType="notification"
                backdrop="static"
                keyboard={false}
                onHide={() => setShowModal2(false)}
              >
                <Heading h={4} className="mb-3">
                  Connect Casper Signer to continue
                </Heading>
                <Button
                  className="mb-3"
                  style={{ minWidth: 200 }}
                  size="small"
                  onClick={() => setShowModal2(false)}
                >
                  Connect
                </Button>
              </GModal>

              <ExampleModal />
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Progress">
              <GProgress leftTopLabel="0" remaining="100" />
              <GProgress percent="68.6" leftTopLabel="68.6" remaining="33.63" />
              <GProgress percent={50} leftTopLabel="50" remaining="50" />
            </StyleguideBlock>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyleguideBlock title="Item">
              <GCheckbox />
            </StyleguideBlock>
            <StyleguideBlock title="Validator Card components">
              <CardValidator
                background={validatorBackground}
                validator={{
                  name: 'OriginStake',
                  imageSrc: ASSETS_URL.OriginStake,
                  address:
                    '01fa332dac2f307a5f3478278ca2ca65552b2e99de3c552698867b4a6a4235c50',
                  verified: true,
                }}
              />
              <CardValidator
                background={validatorCStake}
                validator={{
                  name: 'C-Stake',
                  imageSrc:
                    'https://avatars.githubusercontent.com/u/93722203?s=200&v=4',
                  address:
                    '01fa332dac2f307a5f3478278ca2ca65552b2e99de3c552698867b4a6a4235c50',
                  verified: false,
                }}
              />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: 12,
                }}
              >
                <NFTGridItem data={mock} />
              </div>
            </StyleguideBlock>
            <StyleguideBlock title="Validator Selection">
              <GValidatorSelect
                onChange={() => console.log('')}
                validator={{
                  name: 'OriginStake',
                  imageSrc: ASSETS_URL.OriginStake,
                  address:
                    '01fa332dac2f307a5f3478278ca2ca65552b2e99de3c552698867b4a6a4235c50',
                  verified: true,
                }}
              />
              <GValidatorSelect
                onChange={() => console.log('')}
                validator={{
                  name: 'C-Stake',
                  imageSrc:
                    'https://avatars.githubusercontent.com/u/93722203?s=200&v=4',
                  address:
                    '01fa332dac2f307a5f3478278ca2ca65552b2e99de3c552698867b4a6a4235c50',
                  verified: false,
                }}
              />
            </StyleguideBlock>
          </Col>
        </Row>
      </Container>
    </MasterLayoutBody>
  );
};

export default StyleguidePage;

import cn from 'classnames';
import { Heading } from '@/components/Typography';
import Balancer from 'react-wrap-balancer';

const JourneyStep = ({ icon, children, step, subtitle }: any) => {
  return (
    <div className={cn(`journey-step--wrapper step--${step}`)}>
      <div className="journey-step--item">
        <div className="section--icon">
          <img src={icon} alt={`Step ${step}`} />
        </div>
        <div className="section--content">
          <div className="heading--step">
            <Heading className="text-gradient">Step {step}</Heading>
            <div className="subtitle">{subtitle}</div>
          </div>
          <div className="content">
            <Balancer>{children}</Balancer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyStep;

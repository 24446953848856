import { Heading } from '@/components/Typography';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { useAppSelector as useSelector } from '@/app/hooks';
import { GuideStep } from '@/modules/EggForceJourneyDemoModule/store';
import iconStep4 from '@/assets/images/journey-step--4.png';

const DragonGuideStep5 = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step5) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-5">
      <div className="dragon-guide--step">
        <img src={iconStep4} alt="Welcome" className="guide-icon step-5" />
        <Heading h={5}>Are you a fan of time traveller?</Heading>
        <p>
          For demo purpose, we approximately calculate the reward you might get
          with incubated CSPR via EggForce. Click <strong>Next 7 days</strong>{' '}
          and see the accumulated SNC and CSPR changes. <br />
          Noting that the <strong>CSPR APY is 10.73%</strong>.
        </p>
      </div>
    </div>
  );
};

export default DragonGuideStep5;

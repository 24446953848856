import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const IGNORE_ERRORS: string[] = [
  'ResizeObserver loop completed with undelivered notifications.',
  "Failed to execute 'requestDevice' on 'USB': Must be handling a user gesture to show a permission request.",
];
const isTestnetEnv =
  Boolean(process.env.REACT_APP_DEBUG_SENTRY_TESTNET === 'true') ?? false;
const sentryTrackingURL = isTestnetEnv
  ? [
      'localhost',
      /^https:\/\/testnet-api\.eggforce\.io/,
      /^https:\/\/testnet\.eggforce\.io/,
    ]
  : ['https://api.eggforce.io', /^https:\/\/(www.)eggforce.io/];
Sentry.init({
  dsn: 'https://86f72014a90641bbb37d2b01ee976c4e@o4505474114715648.ingest.sentry.io/4505556371374080',
  environment: isTestnetEnv ? 'testnet' : 'mainnet',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  tracePropagationTargets: sentryTrackingURL,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
  beforeSend(event, hint) {
    const error: any = hint.originalException;
    if (
      error &&
      error.message &&
      error.message.match(new RegExp(IGNORE_ERRORS.join('|'), 'i'))
    ) {
      return null;
    }

    return event;
  },
});

console.log('>>> Sentry Triggered <<<');

import { useMemo, useCallback } from 'react';
import cn from 'classnames';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import MeLemLogo from '@/components/MeLemLogo';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import Button from '@/components/GButton';
import LoadingBox from '@/components/LoadingBox';
import { NFTEggStatus } from '@/types/NFTItem';
import StatusLabel from '@/components/StatusLabel';
import GProgress from '@/components/GProgress';
import type { NFTItem } from '@/types/NFTItem';
import {
  calculateXPAsPercent,
  formatXPValue,
  getNFTDetails,
} from '@/modules/NFTDetail/utils';
import BoxInfo from '@/components/BoxInfo';
import { generateCSSElementalClasses } from '@/helpers/nft';
import useNFTBadgeIcon from '@/hooks/useNFTBadgeIcon';
import { selectDragonMakeConfirmModal } from '@/modules/DragonManagementModule/selectors';
import commonMessages from '@/constants/commonMessages';
import NFTMedia from '@/components/NFTGridItem/NFTMedia';
import NFTCircle from '@/components/NFTGridItem/NFTCircle';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import { DragonGuideStep3b, DragonGuideStep3c } from './DragonGuideStep3';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { DragonGuideStep3e, DragonGuideStep3d } from './DragonGuideStep3';

const MockGridAsEgg = ({
  data,
  isTooltipContent,
  className = '',
  onClick,
  order = undefined,
}: any) => {
  const IS_HATCHING_MODULE_ENABLED = MODULES_PERMISSION.USE_NFT_INCUBATING;
  const dispatch = useDispatch();
  const { currentGuideStep } = useSelector(selectEggForceJourneyModule);
  const { open: makeDragonOpenModal } = useSelector(
    selectDragonMakeConfirmModal,
  );
  const {
    egg,
    classNFT,
    tokenId,
    nftType,
    token_uri,
    material,
    isInstallment,
    installmentId,
    level,
    brand,
    xp,
  } = getNFTDetails(data as NFTItem);
  const path = useNFTBadgeIcon({ element: classNFT?.value });
  const shouldShowHatchButton = useMemo(() => {
    // Top priority
    if (!IS_HATCHING_MODULE_ENABLED || isTooltipContent) {
      return false;
    }

    /**
     * Only allow hatching when Egg has never been hatched
     * or being stopped by User
     */
    if (
      !egg ||
      (egg &&
        egg?.status !== NFTEggStatus.minting &&
        egg?.status !== NFTEggStatus.failed)
    ) {
      return true;
    }

    return false;
  }, [IS_HATCHING_MODULE_ENABLED, egg, isTooltipContent]);
  const shouldDisableActionButtons = useMemo(() => {
    return Boolean(makeDragonOpenModal || egg?.isProcessing);
  }, [egg?.isProcessing, makeDragonOpenModal]);
  const shouldRenderActionsButton = useMemo(() => {
    return Boolean(!egg?.isProcessing && shouldShowHatchButton);
  }, [egg?.isProcessing, shouldShowHatchButton]);

  const { exp, nextLevelXp, percent } = useMemo(() => {
    const exp = xp?.value ?? 0;
    const levelValue = level?.value ?? '';
    const nextLevelXp = egg?.nextLevelXp ?? 0;

    return {
      exp,
      nextLevelXp,
      percent: calculateXPAsPercent({
        exp,
        level: levelValue,
        nextLevelXp,
      }),
    };
  }, [egg, level, xp]);

  const onClickNFTHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
    },
    [],
  );

  const onClickStakeHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(EggForceJourneyDemoModuleActions.setCurrentNFTDetail(data));
      dispatch(
        EggForceJourneyDemoModuleActions.selectNFTForHatching({
          tokenId,
          nftType,
        }),
      );
      dispatch(EggForceJourneyDemoModuleActions.showNFTHatchingModal());
      // onSelectHatchingEgg(String(tokenId));
    },
    [data, dispatch, nftType, tokenId],
  );
  const onConfirmEvolveToDragon = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(EggForceJourneyDemoModuleActions.setCurrentNFTDetail(data));
      dispatch(EggForceJourneyDemoModuleActions.showDragonHatchModal());
    },
    [data, dispatch],
  );

  return (
    <div
      className={cn('nft-grid-item--root-2', className, {
        selected: false,
        fullHeight: true,
        'is-tooltip-content': isTooltipContent,
      })}
      onClick={onClickNFTHandler}
    >
      <div className="nft-grid-item--heading">
        <div className="custom-layout">
          <div className="validator-and-progress">
            <NFTCircle
              material={material}
              shouldShowHatchButton={shouldShowHatchButton}
              eggValidator={egg?.validator}
            />
            <div className="nft-grid-item--hatching-progress">
              {order === 1 && currentGuideStep === GuideStep.step3d && (
                <DragonGuideStep3d />
              )}
              <GProgress
                leftTopLabel={<strong>{formatXPValue(exp)} XP</strong>}
                leftLabel={
                  <strong>
                    ID: <span>#{tokenId}</span>
                  </strong>
                }
                rightLabel={
                  egg?.status && (
                    <StatusLabel
                      className="compact"
                      value={egg?.status}
                      booster={egg?.booster?.rate ?? null}
                    />
                  )
                }
                percent={percent}
                tooltipLabel={`${exp}/${nextLevelXp} XP`}
              />
            </div>
          </div>
        </div>
      </div>
      <NFTMedia nftType={nftType} media={token_uri} description={''} />

      <div className="box--metadata visible">
        {classNFT?.value && path && (
          <BoxInfo
            label="Element"
            value={
              <span className={cn(generateCSSElementalClasses(classNFT.value))}>
                <img
                  loading="lazy"
                  src={path}
                  className="icon"
                  alt={classNFT.value}
                />
                {classNFT?.value}
              </span>
            }
            valueClassName="element"
            isHorizontal={false}
          />
        )}
        {level?.value && (
          <BoxInfo
            label="Level"
            value={`${level?.value}`}
            isHorizontal={false}
          />
        )}
        {brand?.value && (
          <BoxInfo
            label="Brand"
            value={`${brand?.value}`}
            isHorizontal={false}
          />
        )}
        {MODULES_PERMISSION.USE_BNPL && isInstallment && (
          <BoxInfo
            label="Installment Payment"
            value={String(installmentId)}
            isHorizontal={false}
          />
        )}
      </div>
      {MODULES_PERMISSION.USE_BNPL && isInstallment && <MeLemLogo />}
      <div className="nft-grid-item--body">
        {egg?.isProcessing && (
          <LoadingBox
            className="for--nft-grid-item"
            label="Processing..."
            minHeight={100}
          />
        )}
        {shouldRenderActionsButton && (
          <>
            <Button
              disabled={
                shouldDisableActionButtons ||
                (currentGuideStep === GuideStep.step3b && order !== 1)
              }
              className="btn--nft-action btn--start-hatching"
              onClick={onClickStakeHandler}
              btnStyle="5"
              size="small"
            >
              {commonMessages.labelDelegate.defaultMessage}
            </Button>
            {order === 1 && currentGuideStep === GuideStep.step3b && (
              <DragonGuideStep3b />
            )}
          </>
        )}
        {shouldRenderActionsButton && (
          <>
            <Button
              disabled={
                shouldDisableActionButtons ||
                currentGuideStep === GuideStep.step3b
              }
              className="btn--nft-action btn--start-dragon-hatch"
              onClick={onConfirmEvolveToDragon}
              btnStyle="5"
              size="small"
            >
              {commonMessages.labelDragonHatch.defaultMessage}
            </Button>
            {order === 1 && currentGuideStep === GuideStep.step3c && (
              <DragonGuideStep3c />
            )}
            {order === 2 && currentGuideStep === GuideStep.step3c && (
              <DragonGuideStep3e />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MockGridAsEgg;

import { Heading } from '@/components/Typography';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import Button from '@/components/GButton';
import iconStep6 from '@/assets/images/journey-step--6.png';

const DragonGuideStep4 = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step4) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-4">
      <div className="dragon-guide--step">
        <img src={iconStep6} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Wonderful!!!</Heading>
        <p>
          You've just successfully hatched a dragon. Now continue the same with
          the others and recieve special Dragon NFTs with Selvyn.
        </p>
        <p style={{ fontStyle: 'italic' }}>
          Pro tip: for actual gameplay, you'll need to stop incubating first,
          before hatching a dragon.
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step4b),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export const DragonGuideStep4b = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step4b) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-4b">
      <div className="dragon-guide--step">
        <img src={iconStep6} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Well done!!</Heading>
        <p>
          That's one part of the journey. Now <strong>Turn off</strong> the NFT
          Collection and continue with the next guide.
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step7),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default DragonGuideStep4;

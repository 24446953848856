import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Heading } from '../Typography';
import ASSETS_URL from '@/constants/assetsURL';

const PartnersBlock = () => {
  const partners = [
    {
      url: 'https://casperdash.io/',
      name: 'CasperDash',
      logo: ASSETS_URL.Casperdash,
      imageStyles: {},
    },
    {
      url: 'https://originstake.com/',
      name: 'OriginStake',
      logo: ASSETS_URL.OriginStake,
    },
    {
      url: '',
      name: 'TOD Consulting',
      logo: ASSETS_URL.TODConsulting,
    },
    {
      url: '',
      name: 'C-Stake',
      logo: ASSETS_URL.CStake,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://www.bitcat365.com',
      name: 'Bit Cat🐱',
      logo: ASSETS_URL.BitCat,
    },
    {
      url: 'https://speedystaking.io/',
      name: 'Speedy Staking',
      logo: ASSETS_URL.SpeedyStaking,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: '',
      name: 'Casper Community',
      logo: ASSETS_URL.CasperCommunity,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: '',
      name: 'Casper Delegation',
      logo: ASSETS_URL.CasperDelegation,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://caspercommunity.io',
      name: 'CasperCommunity.io',
      logo: ASSETS_URL.CasperCommunityIO,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://makestake.io/',
      name: 'Make',
      logo: ASSETS_URL.Make,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://www.directcsprstaking.xyz/',
      name: 'Direct CSPR Staking (DCS)',
      logo: ASSETS_URL.DirectCSPRStaking,
    },
    {
      url: 'https://casperlads.io/',
      name: 'CasperLads',
      logo: ASSETS_URL.CasperLads,
    },
    {
      url: 'https://coincu.com/',
      name: 'Coincu',
      logo: ASSETS_URL.Coincu,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://goldsquad.io',
      name: 'Gold Squad',
      logo: ASSETS_URL.GoldSquad,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://blockbit.es',
      name: 'Blockbit.es',
      logo: ASSETS_URL.Blockbit,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://nftcalendar.io',
      name: 'NFT Calendar',
      logo: ASSETS_URL.NFTCalendar,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://cspr.market',
      name: 'CSPR Market',
      logo: ASSETS_URL.CSPR_MARKET,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://cryptoviet.ventures/',
      name: 'CryptoViet.Venture',
      logo: ASSETS_URL.CryptoVietVenture,
      imageStyles: {
        maxWidth: '90%',
      },
    },
    {
      url: 'https://nexfi.app/',
      name: 'NEXFI Launchpad',
      logo: ASSETS_URL.Nexfi,
      imageStyles: {
        maxWidth: '90%',
      },
    },
  ];
  const renderPartner = (partner: any) => {
    const Image = () => (
      <img
        {...(partner?.imageStyles && {
          style: partner.imageStyles,
        })}
        src={partner.logo}
        alt={partner.name}
      />
    );

    if (partner.url) {
      return (
        <a
          target="_blank"
          rel="nofollow noopener noreferrer"
          href={partner.url}
          className="grid-item--link"
        >
          <Image />
        </a>
      );
    }

    return (
      <span className="grid-item--link">
        <Image />
      </span>
    );
  };
  return (
    <Container className="homepage--block">
      <Row>
        <Col>
          <div className="block--partners">
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInDown"
            >
              <Heading className="text-center">Partners</Heading>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div className={`partner-grid logo-total--${partners.length}`}>
                {partners.map((partner) => {
                  return (
                    <div className="grid-item" key={partner.name}>
                      <div className="grid-item--logo">
                        {renderPartner(partner)}
                      </div>
                      <div className="grid-item--name">
                        <h3>{partner.name}</h3>
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimationOnScroll>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PartnersBlock;

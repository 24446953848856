import Balancer from 'react-wrap-balancer';
import DragonDemoGameplay from './DragonDemoGameplay';
import frameLeft from '@/assets/images/frame--left.png';
import frameRight from '@/assets/images/frame--right.png';

const EggForceMockGameplay = () => {
  return (
    <div className="mock--virtual-info">
      <div className="frame-wrapper">
        <img src={frameLeft} className="frame-left" alt="Frame left" />
        <img src={frameRight} className="frame-right" alt="Frame right" />
        <DragonDemoGameplay />
      </div>
      <Balancer className="helper">
        The sandbox gameplay is for education purposes only. No real wallet
        connection or balance changes are made on The Casper Network.
      </Balancer>
    </div>
  );
};

export default EggForceMockGameplay;

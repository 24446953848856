import { useCallback } from 'react';
import GModal from '@/components/GModal';
import { Heading } from '@/components/Typography';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import SocialDiscordButton from '@/components/SocialDiscordButton';
import { selectHammerBurnInstructionModal } from '@/modules/HammerBurn/selectors';
import { hammerBurnActions } from '@/modules/HammerBurn/store';

const HammerBurnInstructrionModal = () => {
  const dispatch = useDispatch();
  const { open } = useSelector(selectHammerBurnInstructionModal);
  const onCloseHandler = useCallback(() => {
    dispatch(hammerBurnActions.hideBurnInstructionModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      onHide={onCloseHandler}
      blurOverlay
      className={'egg-evolving--introduction'}
    >
      <div>
        <div className="game-instruction">
          <div className="game-instruction--box">
            <Heading h={4}>Instruction</Heading>
            <ul>
              <li>
                Selected hammer will be burned and you will receive extra Lucky
                Point (up to 31% in total) to hatch your egg into a
                Rare/Legendary Dragon
              </li>
              <li>
                Explore more about EggForce in our{' '}
                <SocialDiscordButton skipConfused compact />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </GModal>
  );
};

export default HammerBurnInstructrionModal;

import React, { useMemo, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useWindowSize } from 'react-use';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import {
  ParallaxBannerLayer,
  ParallaxBanner,
  Parallax,
} from 'react-scroll-parallax';
import { Heading, HeadingSuperb } from '@/components/Typography';
import NFTBenefit from '@/components/NFTBenefit';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import DragonIntroSwiper from '@/components/DragonIntroSwiper';
import DragonHeroBanner from '@/components/DragonHeroBanner';
import NFTUniqueStrength from '@/components/NFTUniqueStrength';
import PartnersBlock from '@/components/PartnersBlock';
import dragonAnatto from '@/assets/images/dragon-lua 1.png';
import dragonZephyr from '@/assets/images/dragon-loi 1.png';
import TeamMembersBlock from '@/components/TeamMembersBlock';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { Links } from '@/constants/publicURL';
import Button from '@/components/GButton';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Homepage = () => {
  const { width: windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  const shouldParallaxDisabled = useMemo(
    () => windowWidth <= 600,
    [windowWidth],
  );

  const onShowAdHocEventModal = useCallback(() => {
    dispatch(CustomEventModalActions.setAdHocResultEventModalSrc('wl-form'));
    dispatch(CustomEventModalActions.showAdHocEventModal());
  }, [dispatch]);

  const renderPrimaryButton = useMemo(() => {
    if (MODULES_PERMISSION.USE_ADHOC_FORM) {
      return (
        <Button
          onClick={onShowAdHocEventModal}
          className="hero-block--whitelist-register"
        >
          <span>CLAIM WHITELIST NOW</span>
        </Button>
      );
    }

    return (
      <React.Fragment>
        <a
          // href="https://discord.com/invite/vCURDNzjYc"
          href="/world"
          rel="nofollow noopener noreferrer"
          target="_blank"
          className="medium btn--style-1 hero-block--whitelist-register"
        >
          Hatch Dragons now
        </a>
        {/* <a
          href="https://zealy.io/c/eggforcenft-6595/questboard"
          rel="nofollow noopener noreferrer"
          target="_blank"
          className="medium btn--style-1 hero-block--whitelist-register hachathon"
        >
          Join Hatchathon
        </a> */}
      </React.Fragment>
    );
  }, [onShowAdHocEventModal]);

  return (
    <MasterLayoutBody>
      <div className="homepage--block-1 homepage--hero-block">
        <ParallaxBanner className="homepage--hero-block-wrapper">
          <ParallaxBannerLayer disabled>
            <DragonHeroBanner />
          </ParallaxBannerLayer>
          <Parallax
            disabled={shouldParallaxDisabled}
            speed={-10}
            className="hero-block--background-gradient"
          ></Parallax>
          <Parallax
            disabled={shouldParallaxDisabled}
            speed={7}
            shouldAlwaysCompleteAnimation
            scale={[1, 1.1]}
            className="hero-block--text-wrapper"
          >
            <h2 className="animate__animated animate__slideInDown hero-block--title medium">
              Upgradable NFTs with utilities
            </h2>
            <p className="animate__animated animate__slideInUp hero-block--month medium">
              <span className="color-yellow">
                Stake CSPR and level up your eggs to Legendary Dragons
              </span>
            </p>
            <div className="animate__animated animate__slideInUp hero-block--primary-actions">
              {renderPrimaryButton}
            </div>
          </Parallax>
        </ParallaxBanner>
      </div>
      <div className="homepage--block-2 fullwidth">
        <Parallax
          disabled
          startScroll={0}
          endScroll={450}
          speed={-10}
          shouldAlwaysCompleteAnimation
          scale={[0.88, 1]}
          translateY={[-25, 0]}
          className="container homepage--block block--what-is-eggforce"
        >
          <Row>
            <Col
              className="col-image"
              sm={{ order: 1, span: 12 }}
              md={{ span: 6, order: 2 }}
            >
              <DragonIntroSwiper />
            </Col>
            <Col
              className="col-wie-content"
              sm={{ order: 2, span: 12 }}
              md={{ span: 6, order: 1 }}
            >
              <HeadingSuperb>What is EggForce?</HeadingSuperb>
              <Heading className="text-gradient" h={3}>
                EggForce NFT Collection
              </Heading>
              <p>
                EggForce is a story and{' '}
                <b>community-driven, high quality hand-drawn</b> 10,000 Egg NFTs
                collection on <b>Casper Network</b>. Based on the{' '}
                <b>
                  special hatching leveling system, mystery dragon drop, merging
                </b>
                , a bond will be formed between artists, builders, web3
                enthusiasts and validators, brands.
              </p>
              <a
                href={Links.discord}
                rel="nofollow noopener noreferrer"
                target="_blank"
                className="btn--learn-more btn--style-1"
              >
                Learn More
                <i className="icon--arrow-right" />
              </a>
            </Col>
          </Row>
        </Parallax>
        <Container className="homepage--block block--unique-strength">
          <Row>
            <Col md={12}>
              {/* <button onClick={() => methodDoesNotExist()}>
                Break the world
              </button> */}
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInDown"
              >
                <Heading className="heading-primary text-center mb-2 mt-4">
                  EggForce Unique Strength
                </Heading>
              </AnimationOnScroll>
            </Col>
            <Col md={12}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInUp"
              >
                <NFTUniqueStrength />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
        <Container className="homepage--block block--the-battle">
          <Row>
            <Col md={12}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInDown"
              >
                <Heading className="heading-primary text-center mb-4">
                  Dragon Civil War
                </Heading>
              </AnimationOnScroll>
            </Col>
            <Col sm={12} md={6}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInLeft"
                className="homepage-block--dragon left reverse"
              >
                <div className="block-dragon--image">
                  <img src={dragonAnatto} alt="Dragon Osirase" />
                  <div className="vertical-line">
                    <span className="circle top"></span>
                    <span className="circle bottom"></span>
                  </div>
                </div>
                <div className="block-dragon--info">
                  <Heading h={2}>Osirase</Heading>
                  <div className="short-content">
                    <p>
                      The remain supreme purebred dragons living under the
                      embrace of Selvyn tree. After the momentous purge,{' '}
                      <b>
                        these proved themselves to possess the ancestors' purest
                        strength and power
                      </b>
                      . The long-lasting life of Selvyn and the Woodland's peace
                      lie on these dragons' hands.
                    </p>
                  </div>
                  <a
                    href={Links.discord}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="btn--explore-more btn--style-1 sz-small"
                  >
                    Recruit Now
                  </a>
                </div>
              </AnimationOnScroll>
            </Col>
            <Col sm={12} md={6}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInRight"
                className="homepage-block--dragon right"
              >
                <div className="block-dragon--image">
                  <img alt="Dragon Athetic" src={dragonZephyr} />
                </div>
                <div className="block-dragon--info">
                  <Heading h={2}>Athetic</Heading>
                  <div className="short-content">
                    <p>
                      The pacifist dragons chose death over bloodshed of their
                      tribes and flee to the Zephyr cursed land.{' '}
                      <b>
                        The harshness of the West has transformed these
                        creatures into more powerful ones.
                      </b>{' '}
                      The only thing that remains is a deep-rooted annihilation
                      hatred against the Osirases.
                    </p>
                  </div>
                  <a
                    href={Links.discord}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="btn--explore-more btn--style-1 sz-small"
                  >
                    Recruit Now
                  </a>
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
        <Container className="homepage--block block--nft-utility">
          <Col md={12}>
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInDown"
            >
              <Heading className="heading-primary text-center mb-4">
                NFT Utilities
              </Heading>
            </AnimationOnScroll>
          </Col>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <NFTBenefit />
          </AnimationOnScroll>
        </Container>
      </div>
      <div className="homepage--block-3">
        <Container className="homepage--block">
          <Row>
            <Col>
              <div className="block--be-the-legend">
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn="animate__fadeInDown"
                >
                  <Heading className="text-center mb-4">Be the legend!</Heading>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn="animate__fadeInUp"
                >
                  <div className="content">
                    <p>
                      Join us now to choose your favorite side and become the
                      legend!
                    </p>
                    <a
                      href={Links.discord}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="btn--start-now btn--style-3 sz-xxl"
                    >
                      Now or Never
                    </a>
                  </div>
                </AnimationOnScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="homepage--block-4 fullwidth">
        <Container className="homepage--block block--roadmap-wrapper">
          <Row>
            <Col>
              <div className="block--roadmad block--timeline-root">
                <AnimationOnScroll
                  animateOnce={true}
                  animateIn="animate__fadeInDown"
                >
                  <Heading className="text-center">Roadmap</Heading>
                </AnimationOnScroll>
                <div className="content">
                  <div className="block--timeline-item phase-1">
                    <AnimationOnScroll
                      animateOnce={true}
                      animateIn="animate__fadeInLeft"
                    >
                      <span className="block--timeline--border-line"></span>
                      <span className="block--timeline--background"></span>
                      <h4 className="subtitle">Q4 2022</h4>
                      <div className="roadmap-list">
                        <ul>
                          <li>Release Egg NFT</li>
                          <li>Release chapter 1</li>
                          <li>Seed Round</li>
                          <li>Private Round</li>
                        </ul>
                      </div>
                    </AnimationOnScroll>
                  </div>
                  <div className="block--timeline-item phase-2">
                    <AnimationOnScroll
                      animateOnce={true}
                      animateIn="animate__fadeInLeft"
                    >
                      <span className="block--timeline--border-line"></span>
                      <span className="block--timeline--background"></span>
                      <h4 className="subtitle">Q1 2023</h4>
                      <div className="roadmap-list">
                        <ul>
                          <li>Closed Beta Round</li>
                          <li>Open Beta Round</li>
                          <li>Release Egg Hatching Mechanism</li>
                        </ul>
                      </div>
                    </AnimationOnScroll>
                  </div>
                  <div className="block--timeline-item phase-3">
                    <AnimationOnScroll
                      animateOnce={true}
                      animateIn="animate__fadeInLeft"
                    >
                      <span className="block--timeline--border-line"></span>
                      <span className="block--timeline--background"></span>
                      <h4 className="subtitle">Q2 2023</h4>
                      <div className="roadmap-list">
                        <ul>
                          <li>Public Round</li>
                          <li>Release Egg Merging</li>
                          <li>Release Dragon Drop Feature</li>
                          <li>Support Mobile Devices</li>
                          <li>Integrate with Buy Now Pay Later service</li>
                        </ul>
                      </div>
                    </AnimationOnScroll>
                  </div>
                  <div className="block--timeline-item phase-4">
                    <AnimationOnScroll
                      animateOnce={true}
                      animateIn="animate__fadeInLeft"
                    >
                      <span className="block--timeline--border-line"></span>
                      <span className="block--timeline--background"></span>
                      <h4 className="subtitle">Q3 2023</h4>
                      <div className="roadmap-list">
                        <ul>
                          <li>Release Chapter 2</li>
                          <li>Release EggForce’s goods</li>
                          <li>Release Dragon Slide Selection Mechanism</li>
                          <li>Release Dragon leveling system</li>
                          <li>Release Legendary Wind Dragon</li>
                        </ul>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="homepage--block-5">
        <PartnersBlock />
      </div>
      <div className="homepage--block-6 homepage-block--with-grave-background">
        <TeamMembersBlock />
      </div>
    </MasterLayoutBody>
  );
};

export default Homepage;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Balancer from 'react-wrap-balancer';
import DragonSectionWrapper from '../DragonSectionWrapper';
// import EggForceMockGameplay from '../EggForceMockGameplay';
import DragonHeaderPrimary from '../DragonHeaderPrimary';

const DragonIntroduction = () => {
  return (
    <DragonSectionWrapper name="introduction">
      <Container className="">
        <Row id="mock--eggforce-world">
          <Col md={12}>
            <DragonHeaderPrimary heading="FROM EGGS TO DRAGONS: HOW EGG NFTS BECOME DRAGON NFTS?">
              <Balancer className="body-content">
                Press the <strong>Get Started</strong> button to start exploring
                the exciting journey in the EggForce Callista World with the
                below gameplay demo. Follow our instructions, brave souls!
              </Balancer>
            </DragonHeaderPrimary>
          </Col>
        </Row>
      </Container>
      <div className="">{/* <EggForceMockGameplay /> */}</div>
    </DragonSectionWrapper>
  );
};

export default DragonIntroduction;

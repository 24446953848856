import Balancer from 'react-wrap-balancer';
import ASSETS_URL from '@/constants/assetsURL';

const HappyHourEvent = () => {
  return (
    <div className="event--happy-hour--root">
      <p className='event--happy-hour--message'>
        <Balancer>Use your <strong>#Hammer</strong> to <strong>DOUBLE YOUR PROFITS</strong>, <strong>EARN $CSPR and EggNFTs</strong> with our Partners</Balancer>
      </p>
      <div className="event--happy-hour--partners">
        <div className="each-partner">
          <h3 className="partner">
            <a
              href="https://go.eggforce.io/CIig"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className="link-wrapper"
              title="Casperdash - 9999 CSPR Airdrop"
            >
              <div className="logo-wrapper">
                <img
                  src={ASSETS_URL.Casperdash}
                  alt=""
                  className="logo"
                />
              </div>
              <span className="text"><Balancer>9999 CSPR Airdrop</Balancer></span>
            </a>
          </h3>
        </div>
        <div className="each-partner">
          <h3 className="partner">
            <a
              href="https://go.eggforce.io/CIig"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className="link-wrapper"
              title="OriginStake - Stake up to 100K+ - Earn x2 rewards + EggNFTs"
            >
              <div className="logo-wrapper">
                <img
                  src={ASSETS_URL.OriginStake}
                  alt=""
                  className="logo"
                />
              </div>
              <span className="text">
                <Balancer>
                  Stake up to 100K+ <br />
                  Earn x2 rewards & EggNFTs
                </Balancer>
              </span>
            </a>
          </h3>
        </div>
        <div className="each-partner">
          <h3 className="partner">
            <a
              href="https://go.eggforce.io/CIiq"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className="link-wrapper"
              title="Speedy Staking - 7500 CSPR + EggNFT Giveaway"
            >
              <div className="logo-wrapper">
                <img
                  src={ASSETS_URL.SpeedyStaking}
                  alt=""
                  className="logo"
                />
              </div>
              <span className="text">
                <Balancer>
                  7500 CSPR <br />
                  EggNFT Giveaway
                </Balancer>
              </span>
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default HappyHourEvent;

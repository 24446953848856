import { useMemo, useCallback } from 'react';
import cn from 'classnames';
import Big from 'big.js';
import isBefore from 'date-fns/isBefore';
import { Heading } from '@/components/Typography';
import {
  XCircle,
  CheckCircle,
  CalendarCheck,
  Calendar3,
} from 'react-bootstrap-icons';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import GProgress from '@/components/GProgress';
import { getInstallmentPaymentDetail } from '@/modules/InstallmentsPayment/actions';
import { formatCSPRFull } from '@/helpers/balance';
import { InstallmentPaymentStatus } from '@/types/package';
import { formateDate } from '@/helpers/datetime';
import type { TypeInstallmentPayment } from '@/types/package';

const InstallmentPaymentItem = ({ details, last, className = '' }: any) => {
  const dispatch = useDispatch();
  const { installmentId, status } = details;

  const {
    isOverdue,
    leftLabel,
    paidAmount,
    totalAmount,
    percent,
    purchaseDate,
    nextPaymentDate,
    remainingAmount,
  } = useMemo(() => {
    const data: TypeInstallmentPayment = details;
    const { planLength, status, nextPaymentSchedule } = data;
    const paidAmount = Big(data.paidAmount ?? 0);
    const planPayment = Big(data.planPayment ?? 0);
    const remainingAmount = Big(data.remainingAmount ?? 0);

    const totalAmount = paidAmount.add(remainingAmount);
    const percent =
      paidAmount.gt(0) && totalAmount.gt(0)
        ? paidAmount.div(totalAmount).mul(100)
        : Big(0);
    const purchaseDate = data.at ? formateDate(data.at) : '';
    const nextPaymentDate = nextPaymentSchedule
      ? formateDate(nextPaymentSchedule)
      : '';
    const isOverdue = nextPaymentSchedule
      ? isBefore(new Date(nextPaymentSchedule), new Date())
      : false;
    let leftLabel = '';

    switch (status) {
      case InstallmentPaymentStatus.ACTIVE:
        leftLabel = `Next payment: ${nextPaymentDate}`;
        break;
      case InstallmentPaymentStatus.COMPLETED:
        leftLabel = 'Completed';
        break;
      case InstallmentPaymentStatus.CANCEL:
        leftLabel = 'Cancelled';
        break;
      default:
        break;
    }

    return {
      id: data.installmentId,
      planLength,
      purchaseDate: `Purchase Date: ${purchaseDate}`,
      nextPaymentDate: `Next Payment: ${nextPaymentDate}`,
      paidAmount: formatCSPRFull(paidAmount.toNumber()),
      totalAmount: formatCSPRFull(totalAmount.toNumber()),
      percent: percent.toNumber(),
      remainingAmount:
        status !== InstallmentPaymentStatus.COMPLETED ? (
          <span>
            Remaining:{' '}
            <strong>{formatCSPRFull(remainingAmount.toNumber())} CSPR</strong>
          </span>
        ) : (
          ''
        ),
      planPayment: planPayment.toNumber(),
      leftLabel,
      isOverdue,
    };
  }, [details]);
  const renderDate = useCallback((date: any, useCheck = false) => {
    const Icon = useCheck ? CalendarCheck : Calendar3;
    return (
      <span className="info--date">
        <Icon style={{ marginRight: 6 }} />
        <span>{date}</span>
      </span>
    );
  }, []);

  const renderStatus = useCallback((status: any, Icon: any) => {
    return (
      <span>
        <Icon style={{ marginRight: 6 }} />
        <span>{status}</span>
      </span>
    );
  }, []);

  const renderStatusLabel = useCallback(() => {
    switch (status) {
      case InstallmentPaymentStatus.ACTIVE:
        return renderDate(nextPaymentDate, true);
      case InstallmentPaymentStatus.COMPLETED:
        return renderStatus(leftLabel, CheckCircle);
      case InstallmentPaymentStatus.CANCEL:
        return renderStatus(leftLabel, XCircle);
      default:
        return null;
    }
  }, [leftLabel, nextPaymentDate, renderDate, renderStatus, status]);

  const onViewInstallmentPayment = useCallback(() => {
    dispatch(getInstallmentPaymentDetail(installmentId));
  }, [dispatch, installmentId]);

  return (
    <div
      onClick={onViewInstallmentPayment}
      className={cn('installment-payment-item--root', className, {
        'is-overdue': isOverdue,
        'is-cancelled': status === InstallmentPaymentStatus.CANCEL,
      })}
    >
      <Heading h={5} className="text-gradient2 mb-2">
        {`Installment Payment ID: #${installmentId}`}
      </Heading>
      <GProgress
        leftLabel={renderDate(purchaseDate)}
        rightLabel={renderStatusLabel()}
        rightTopLabel={
          <div className="btn--actions-wrapper">{remainingAmount}</div>
        }
        tooltipLabel={`${paidAmount} / ${totalAmount} CSPR`}
        percent={percent}
      />
    </div>
  );
};

export default InstallmentPaymentItem;

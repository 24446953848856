import React from 'react';
import './fireCircle.scss';

const FireCircle = () => {
  return (
    <div className="fire-circle--root">
      <div className="circle"></div>
      <svg>
        <filter id="wavy">
          <feTurbulence
            x="0"
            y="0"
            baseFrequency="0.015"
            numOctaves="10"
            speed="2"
          >
            <animate
              attributeName="baseFrequency"
              dur="60s"
              values="0.02; 0.005; 0.02"
              repeatCount="indefinite"
            />
          </feTurbulence>
          <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
        </filter>
      </svg>
    </div>
  );
};

export default FireCircle;

import cn from 'classnames';

const DragonSectionWrapper = ({
  id = undefined,
  children,
  name,
  className,
}: any) => {
  return (
    <div
      id={id}
      className={cn(`dragon-block--child dragon-block--${name}`, className)}
    >
      {children}
    </div>
  );
};

export default DragonSectionWrapper;

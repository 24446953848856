import { useCallback, useEffect } from 'react';
import Big from 'big.js';
import useSWR from 'swr';
import { fetcher } from '@/services/axios';
import API from '@/constants/api';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import { packagesDetailActions } from '@/modules/WorldMint/store';
import { selectPackagesDetailStore } from '@/modules/WorldMint/selectors';
import type {
  DiscountPackageItemType,
  PackageClientItemType,
} from '@/components/QuickBuyModal/PackageItems';
import { convertBalanceFromHex } from '@/helpers/balance';

const generateDiscountPackages = (
  discountMap: DiscountPackageItemType[],
  pricePerEgg: number,
): PackageClientItemType[] => {
  return discountMap.map(
    (packageItem: DiscountPackageItemType, index: number) => {
      const amount = Big(packageItem.amount);
      const discount = Big(packageItem.discount);
      const priceDefault = Big(pricePerEgg).times(amount);
      const discountPrice = priceDefault.times(discount).div(100);
      // console.log(
      //   `🚀 ~ discountPrice:`,
      //   discountPrice.toNumber(),
      //   Math.floor(discountPrice.toNumber()),
      // );
      const priceSale = priceDefault.sub(discountPrice);
      return {
        id: index,
        name: `${packageItem.name} Pack`,
        amount: amount.toNumber(),
        percent: discount.toNumber(),
        priceDefault: priceDefault.toNumber(),
        priceSale: Math.floor(priceSale.toNumber()),
      };
    },
  );
};

const useFetchEggPackages = () => {
  const dispatch = useDispatch();
  const storeData = useSelector(selectPackagesDetailStore);
  const { data, error } = useSWR(API.fetchPackagesDetail, fetcher);

  const updatePackages = useCallback(
    (serverPackages: any, serverPrice: any) => {
      const pricePerEgg = convertBalanceFromHex(serverPrice.hex);

      const packages = generateDiscountPackages(
        serverPackages as DiscountPackageItemType[],
        pricePerEgg,
      );

      dispatch(
        packagesDetailActions.bindData({
          packagesMap: packages,
          pricePerEgg,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (data && storeData.packages?.length === 0) {
      const { priceInfo, sold, total } = data;
      updatePackages(priceInfo.discountMap, priceInfo.price);

      if (total) {
        dispatch(
          packagesDetailActions.updateSalesStats({
            sold: sold ?? 0,
            total,
          }),
        );
      }
    }
  }, [data, dispatch, storeData, updatePackages]);

  return {
    isLoading: !error && !data,
    isError: error,
  };
};

export default useFetchEggPackages;

import { useLayoutEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import isString from 'lodash/isString';
import { accountActions } from '@/modules/Auth/store';
import { useAppDispatch } from '@/app/hooks';

export const COOKIE_NAME = 'eggforceAffCode';

const useAffiliateCode = () => {
  const DAYS = 30;
  const dispatch = useAppDispatch();
  // const COOKIE_MAXAGE_SHORT = 60; // second
  const COOKIE_MAXAGE = 60 * 60 * 24 * DAYS; // 30 days in second

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const currCookie = cookies?.[COOKIE_NAME];
  const [params] = useSearchParams();
  const codeAffiliate = params.get('code');

  // Set new aff. code when current User doesn't have one
  // or current code expires
  // Store new aff. code for user
  const setAffiliateCode = useCallback(
    (value: string) => {
      console.log(`>> SAVING[${COOKIE_NAME}]: `, value, COOKIE_MAXAGE);
      setCookie(COOKIE_NAME, value, {
        maxAge: COOKIE_MAXAGE,
      });
      dispatch(accountActions.updateAccountAffiliateCode(value));
    },
    [COOKIE_MAXAGE, dispatch, setCookie],
  );

  // Get aff. code from user
  const getAffiliateCode = useCallback(() => {
    return currCookie;
  }, [currCookie]);

  const checkCurrentUserWithAffiliateCode = useCallback(() => {
    // Skip when code is invalid
    if (!isString(codeAffiliate) || codeAffiliate.trim() === '') {
      return;
    }

    // Only store first time affiliate code when available
    // and wait until it expires. No override here
    if (currCookie === undefined) {
      setAffiliateCode(codeAffiliate);
    }
  }, [codeAffiliate, currCookie, setAffiliateCode]);

  useLayoutEffect(() => {
    checkCurrentUserWithAffiliateCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (currCookie) {
      dispatch(accountActions.updateAccountAffiliateCode(currCookie));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getAffiliateCode,
  };
};

export default useAffiliateCode;

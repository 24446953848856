import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Big from 'big.js';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'lodash/isEmpty';
import useCurrentUser from '@/hooks/useCurrentUser';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { Heading } from '@/components/Typography';
import { getMintingFee } from '@/modules/WorldMint/utils';
import InputCheckbox from '@/components/GInputCheckbox';
import GInput from '@/components/GInput';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';
import type { PackageClientItemType } from './PackageItems';
import type {
  TypePurchasePackageBNPLParams,
  TypePurchasePackageParams,
} from '@/types/package';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import imagePresent from '@/assets/images/image--present.webp';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import TablePackageSummary from '@/components/TablePackageSummary';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import AccountMiniHeader from '@/components/AccountMiniHeader';
import { useSelector } from 'react-redux';
import { selectInstallmentPaymentsDetailModal } from '@/modules/InstallmentsPayment/selectors';
import { previewInstallmentPayment } from '@/modules/InstallmentsPayment/actions';
import type { TypePreviewInstallmentPayment } from '@/types/package';
import useBlockMinting from '@/hooks/useBlockMinting';
import { selectAuth } from '@/modules/Auth/selectors';
import InstallmentReviewData from './InstallmentReviewData';
interface IProps {
  packages: PackageClientItemType[];
  formatter: string;
  open?: boolean;
  className?: string;
  selectedPackageIndex: number | null;
  onClose: () => void;
  onConfirm: (data: unknown) => void;
  isDeploying?: boolean;
}

const PurchaseConfirmPackageModal = (props: IProps) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const {
    isDeploying,
    onConfirm,
    onClose,
    open,
    packages,
    selectedPackageIndex,
  } = props;
  const { shouldDisable } = useBlockMinting({ isDeploying });
  const storeAuth = useSelector(selectAuth, isEqual);
  const installmentDetailModal = useSelector(
    selectInstallmentPaymentsDetailModal,
  );
  const installmentDetail: TypePreviewInstallmentPayment =
    installmentDetailModal.data;
  const loadingBNPLPreview = installmentDetailModal.loading;
  const selectedPackage =
    selectedPackageIndex !== null ? packages[selectedPackageIndex] : undefined;
  const [purchaseAsGift, setPurchaseAsGift] = useState<boolean>(false);
  const [isInstallmentPayment, setInstallmentPayment] =
    useState<boolean>(false);
  const [fieldEmailValue, setFieldEmail] = useState<string>('');
  const [formErrors, setFormErrors] = useState<undefined | any>(undefined);
  const [recipient, setRecipient] = useState<string>('');
  const [fieldAffiliateCode, setAffiliateCode] = useState<string>(
    storeAuth.affiliateCode ?? '',
  );

  const shouldDisablePurchase = useMemo(() => {
    if (shouldDisable) {
      return true;
    }

    if (isInstallmentPayment) {
      if (!installmentDetail || loadingBNPLPreview) {
        return true;
      }

      if (!installmentDetail?.isBuyable) {
        return true;
      }
    }

    return false;
  }, [
    shouldDisable,
    isInstallmentPayment,
    installmentDetail,
    loadingBNPLPreview,
  ]);

  const onFieldChangeHandler = useCallback(
    (fieldName: string, value: string, setter: (value: string) => void) => {
      setter(value);

      if (formErrors && formErrors[fieldName]) {
        setFormErrors((prev: any) => ({
          ...prev,
          [fieldName]: '',
        }));
      }
    },
    [formErrors],
  );

  const validate = useCallback(() => {
    let error: any = {};

    if (!isInstallmentPayment) {
      if (purchaseAsGift) {
        if (recipient.trim() === '') {
          error['recipient'] = 'This field cannot be empty';
        }

        if (recipient) {
          // Prevent gifting himself
          if (recipient === user.activeKey && isValidPublicKey(recipient)) {
            error['recipient'] =
              "Your friend's public address should be different from yours.";
          }

          if (recipient !== user.activeKey && !isValidPublicKey(recipient)) {
            error['recipient'] =
              'Look like the address is not valid. Please help to check again.';
          }
        }
      }

      if (fieldAffiliateCode) {
        if (
          !isValidPublicKey(fieldAffiliateCode) ||
          fieldAffiliateCode === user.activeKey
        ) {
          error['fieldAffiliateCode'] = 'Invalid refferal code';
        }
      }
    }

    if (isInstallmentPayment) {
      if (!isEmail(fieldEmailValue)) {
        error['email'] = 'Please enter valid email address';
      }
    }

    return error;
  }, [
    fieldAffiliateCode,
    fieldEmailValue,
    isInstallmentPayment,
    purchaseAsGift,
    recipient,
    user.activeKey,
  ]);
  const onShowInstallmentReviewModal = useCallback(() => {
    dispatch(InstallmentsPaymentActions.showQASupportModal());
  }, [dispatch]);
  const onClearGiftPurchase = useCallback(() => {
    setPurchaseAsGift(false);
    setRecipient('');
    setFormErrors(undefined);
  }, []);
  const onSelectRegularPaymentType = useCallback(() => {
    setInstallmentPayment(false);
    dispatch(InstallmentsPaymentActions.updateInstallmentDetail(null));
  }, [dispatch]);
  const onSelectInstallmentPaymentType = useCallback(async () => {
    if (!selectedPackage) {
      return;
    }
    onClearGiftPurchase();
    dispatch(
      previewInstallmentPayment({
        numberOfEggs: selectedPackage.amount,
      }),
    );
    setInstallmentPayment(true);
  }, [dispatch, onClearGiftPurchase, selectedPackage]);
  const data = useMemo(() => {
    if (!selectedPackage) {
      return undefined;
    }
    const amountFee = getMintingFee(selectedPackage.amount);
    const hasDiscountPrice = Boolean(selectedPackage.percent > 0);
    const priceInUse = hasDiscountPrice
      ? Big(selectedPackage.priceSale)
      : Big(selectedPackage.priceDefault);

    const packageLabel = `${selectedPackage.name} (${selectedPackage.amount} ${
      selectedPackage.amount > 1 ? `eggs` : `egg`
    })`;
    const amountLocked = priceInUse.plus(amountFee);

    return {
      packagePrice: priceInUse.toNumber(),
      packageLabel,
      amountFee: amountFee,
      amountLocked: amountLocked.toNumber(),
    };
  }, [selectedPackage]);

  const onConfirmHandler = useCallback(() => {
    const result = validate();

    if (!data || !selectedPackage || !isEmpty(result)) {
      if (!isEmpty(result)) {
        setFormErrors(result);
      }
      return;
    }

    if (isInstallmentPayment) {
      onConfirm({
        downPaymentValue: installmentDetail.downPayment,
        paymentAmount: installmentDetail.paymentAmount,
        amountEgg: installmentDetail.numberOfEggs,
        plan_len: installmentDetail.duration,
        contact: fieldEmailValue,
        isInstallmentPayment: true,
      } as TypePurchasePackageBNPLParams);
    }

    if (!isInstallmentPayment) {
      onConfirm({
        totalPackageValue: data.packagePrice,
        amountEgg: selectedPackage.amount,
        buy_for: purchaseAsGift ? recipient : '',
        isBuyForChecked: purchaseAsGift,
        isInstallmentPayment: false,
        affiliateCode: fieldAffiliateCode,
      } as TypePurchasePackageParams);
    }
  }, [
    fieldAffiliateCode,
    data,
    fieldEmailValue,
    installmentDetail,
    isInstallmentPayment,
    onConfirm,
    purchaseAsGift,
    recipient,
    selectedPackage,
    validate,
  ]);

  // Reset Purchase as gift
  useEffect(() => {
    if (!open) {
      onClearGiftPurchase();
      setInstallmentPayment(false);
      setFieldEmail('');
      setAffiliateCode(storeAuth.affiliateCode ?? '');
    }
  }, [
    open,
    isInstallmentPayment,
    onClearGiftPurchase,
    storeAuth.affiliateCode,
  ]);

  if (!user || !selectedPackage || !data) {
    return null;
  }

  return (
    <GModal show={open} className={'purchase-review'} onHide={onClose}>
      <div className="purchase-review--root">
        <Heading h={3} className="mb-3">
          Purchase Details
        </Heading>
        <AccountMiniHeader />
        <div className="purchase-review--wrapper">
          {MODULES_PERMISSION.USE_BNPL && (
            <>
              <Heading h={5}>Payment Method</Heading>
              <div
                className={cn('purchase-payment-method--wrapper', {
                  'is-installment-payment': isInstallmentPayment,
                })}
              >
                <Button
                  disabled={isDeploying}
                  fullWidth
                  size="small"
                  onClick={onSelectRegularPaymentType}
                  className={cn('payment--full', {
                    'is-unselected': isInstallmentPayment,
                  })}
                  ignoreShadowStyle
                  btnStyle={isInstallmentPayment ? '4' : '5'}
                >
                  Full payment
                </Button>
                <Button
                  disabled={isDeploying}
                  fullWidth
                  size="small"
                  onClick={onSelectInstallmentPaymentType}
                  className={cn('payment--bnpl', {
                    'is-unselected': !isInstallmentPayment,
                  })}
                  ignoreShadowStyle
                  btnStyle={isInstallmentPayment ? '5' : '4'}
                >
                  <span>Buy Now, Pay Later</span>
                  <div className="bnpl--text">
                    <span style={{ marginRight: 6 }}>0%</span> Interest rate
                  </div>
                </Button>
              </div>
              <div className="purchase-installment-helper">
                <Button
                  btnStyle="4"
                  size="small"
                  onClick={onShowInstallmentReviewModal}
                  className="btn--what-is-purchase-installment"
                >
                  Tell me more about this?
                </Button>
              </div>
            </>
          )}
          <div
            className={cn('payment-details--summary', {
              'is--out-of-stock': Boolean(
                isInstallmentPayment && !installmentDetail?.isBuyable,
              ),
            })}
          >
            {isInstallmentPayment && (
              <InstallmentReviewData
                packageData={data}
                selectedIndex={
                  selectedPackageIndex ? selectedPackageIndex + 1 : 1
                }
                renderInput={
                  <Row>
                    <Form.Group as={Col} xs={12} className="ginput-group mb-3">
                      <Form.Label>Email</Form.Label>
                      <GInput
                        value={fieldEmailValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onFieldChangeHandler(
                            'email',
                            e.target.value,
                            setFieldEmail,
                          )
                        }
                        type="text"
                        placeholder="Your email address"
                        className={cn({
                          'is-invalid': formErrors?.email,
                        })}
                        required
                      />
                      {formErrors?.email && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                }
              />
            )}
            {!isInstallmentPayment && (
              <React.Fragment>
                <Heading h={5}>Optional</Heading>
                <div className="form-control--block">
                  <label className="input-label">Referral Code</label>
                  <GInput
                    className={cn({
                      'is-invalid': formErrors?.fieldAffiliateCode ?? false,
                    })}
                    value={fieldAffiliateCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onFieldChangeHandler(
                        'fieldAffiliateCode',
                        e.target.value,
                        setAffiliateCode,
                      )
                    }
                    placeholder={`Enter affiliate code`}
                  />
                  {formErrors?.fieldAffiliateCode && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors?.fieldAffiliateCode}
                    </Form.Control.Feedback>
                  )}
                </div>
                <div className="purchase-as-gift--wrapper">
                  <InputCheckbox
                    label={
                      <>
                        <span>Purchase as a gift to my friend</span>
                        <img
                          className="checkbox--present"
                          alt="Present as gift"
                          src={imagePresent}
                        />
                      </>
                    }
                    disabled={isInstallmentPayment}
                    isControlled
                    value={purchaseAsGift}
                    onChange={(newValue) => {
                      setPurchaseAsGift(newValue);
                    }}
                  />
                  {purchaseAsGift && (
                    <GInput
                      required
                      disabled={!purchaseAsGift}
                      className={cn({
                        'is-invalid': purchaseAsGift && formErrors?.recipient,
                      })}
                      value={recipient}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onFieldChangeHandler(
                          'recipient',
                          e.target.value,
                          setRecipient,
                        )
                      }
                      placeholder={`Enter your friend's Casper Network wallet address`}
                    />
                  )}
                  {purchaseAsGift && formErrors?.recipient && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors?.recipient}
                    </Form.Control.Feedback>
                  )}
                </div>
                <TablePackageSummary data={data} />
              </React.Fragment>
            )}
          </div>
          <Button
            disabled={shouldDisablePurchase}
            onClick={onConfirmHandler}
            btnStyle="1"
            className="btn--confirm-purchase-package"
          >
            {isInstallmentPayment ? 'Start Installment Plan' : 'Purchase'}
          </Button>
          <div className="content-wrapper">
            <p className="helper">Please double check all info below</p>
            <ul>
              <li>
                Verify that you have selected the correct package and have a
                sufficient balance in your wallet account.
              </li>
              <li>Once signed, transaction cannot be cancelled.</li>
              {!isInstallmentPayment && (
                <li>
                  (*) Locked amount is reserved until minting process finished.
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </GModal>
  );
};

export default PurchaseConfirmPackageModal;

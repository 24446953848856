import { Stopwatch } from 'react-bootstrap-icons';
import Button from '@/components/GButton';
import { Heading } from '@/components/Typography';
import CompactModal from '@/components/CompactModal';

interface IProps {
  onClose: () => void;
  open: boolean;
  className?: string;
}

const CardLimitEntry = (props: IProps) => {
  const { onClose, open } = props;
  return (
    <CompactModal open={open}>
      <Heading h={4}>Limit reached!</Heading>
      <p className="helper">
        <Stopwatch className="icon--helper" />
        You can only flip 1 card per day. Please come back tomorrow
      </p>
      <Button
        className="btn--action"
        size="small"
        onClick={onClose}
        btnStyle="1"
      >
        I Understand
      </Button>
    </CompactModal>
  );
};

export default CardLimitEntry;

import React from 'react';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import {
  DragonJourney,
  DragonRarityCollections,
  DragonsLevelUp,
  EggForceTokenomic,
  DragonHeroBanner,
} from '@/components/DragonsLandingSubComponents';

const DragonsPage = () => {
  return (
    <MasterLayoutBody isJourneyPage isRegularPage>
      <DragonHeroBanner />
      <DragonJourney />
      <EggForceTokenomic />
      <DragonsLevelUp />
      <DragonRarityCollections />
    </MasterLayoutBody>
  );
};

export default DragonsPage;

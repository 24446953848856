import { useMemo, useCallback } from 'react';
import { selectConnectionType } from '@/modules/Auth/selectors';
import isEqual from 'lodash/isEqual';
import { ToastContentProps, toast } from 'react-toastify';
import { useAppSelector } from '@/app/hooks';
import { CONNECTION_TYPES } from '@/constants/settings';
import WalletConnectors from '@/services/WalletConnectors';
import type { WalletConnectorType } from '@/services/WalletConnectors';
import ToastErrorWallet from '@/modules/CasperSigner/ConnectWallet/ToastErrorWallet';

const useLedgerErrorsHandler = () => {
  const currentWalletConnection = useAppSelector(selectConnectionType, isEqual);
  const isLedgerConnected = useMemo(() => {
    return currentWalletConnection === CONNECTION_TYPES.ledger;
  }, [currentWalletConnection]);
  const walletInstance = useMemo(() => {
    if (!currentWalletConnection) {
      return undefined;
    }
    return WalletConnectors.from(
      currentWalletConnection,
    ) as WalletConnectorType;
  }, [currentWalletConnection]);

  const renderToastErrorWallet = useCallback(
    (connector: WalletConnectorType, error: any) => {
      return (props: ToastContentProps) => (
        <ToastErrorWallet
          {...props}
          connector={connector}
          message={error.message}
        />
      );
    },
    [],
  );

  const toastError = useCallback(
    (error: any) => {
      if (!walletInstance) {
        return;
      }

      toast(renderToastErrorWallet(walletInstance, error), {
        toastId: 'error-ledger',
      });
    },
    [renderToastErrorWallet, walletInstance],
  );

  return { toastError, isLedgerConnected };
};

export default useLedgerErrorsHandler;

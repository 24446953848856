import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import EggCracker from "../EggCracker";

const NumberReward = ({value} : any) => (<div className="number-reward">{value}</div>);
const StringReward = ({value} : any) => (<div className={`string-reward ${value?.length >= 16 && 'long-text'}`}>{value}</div>); 

const RewardRandomEggCracker = ({ max = 1000, winners = [], resultType } : any) => {
  const isNumberType = Boolean(resultType === 'number');
  const MAX_NUMBER = isNumberType ? max : winners.length;
  const [random, setRandom] = useState<number | string | undefined>(undefined);

  const onRandom = () =>  {
    const pickedId = Math.floor(Math.random() * MAX_NUMBER) + 1; 
    
    switch(resultType) {
      case 'number':
        setRandom(pickedId);
        break;
      case 'string':
        setRandom(winners[pickedId]);
        break;
      default:
        break;
    }
  };
  const onReset = () => {
    setTimeout(() => {
      onRandom();
    }, 3200);
  }
  useEffect(() => {
    if (isNumberType && !max) {
      console.warn("Must provide a maximum value when using resultType as Number")
    }

    if (!isNumberType && !winners?.length) {
      console.warn("Must provide winner list when using resultType as String")
    }

    onRandom();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRewardComp = isNumberType ? <NumberReward value={random}/> : <StringReward value={random}/>;
  return (<EggCracker onReset={onReset} renderReward={renderRewardComp} />)
};

RewardRandomEggCracker.propTypes = {
  resultType: PropTypes.string.isRequired,
  winners: PropTypes.array,
  max: PropTypes.number,
}

export default RewardRandomEggCracker;

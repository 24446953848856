import React from "react";
import { Heading } from '@/components/Typography';
import SocialSharingBlock from "@/components/SocialSharingBlock";

const QAContact = () => {
  return (
    <div className="qa-contact--wrapper">
      <Heading className="text-center" h={5}>
        Not found what you're looking for? Try asking Selvyn
      </Heading>
      <SocialSharingBlock
        parentClassnames="qa-contact--social-wrapper"
        twitterProps={{
          title: "🤗 Hi Selvyn, I have a question for you. 👉 ",
          via: "",
          hashtags: ['NFT', 'CSPR', 'EggForce', 'Callista'],
          url: 'https://go.eggforce.io/link'
        }}
      />
    </div>
  );
};

export default React.memo(QAContact);

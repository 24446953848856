import { useEffect, useState, useMemo, useCallback } from 'react';
import cn from "classnames";
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import GModal from '@/components/GModal';
import { selectMinigamesModal } from '@/modules/Minigames/selectors';
import { MinigamesModalActions } from '@/modules/Minigames/store';
import FlashGameModal from './FlashGameModal';
import MazeGameChoiceModal from './MazeGameChoiceModal';
import CardFlippingGame from "./CardFlippingGame";

interface IProps {
  className?: string;
}

const MiniGamesModal = ({ className }: IProps) => {
  const dispatch = useDispatch();
  const { currentGame, open: modalStateOpen } = useSelector(selectMinigamesModal());
  const [customClass, setCustomClass] = useState<string>("");
  const onCloseHandler = useCallback(() => {
    dispatch(MinigamesModalActions.hideModal());
    dispatch(MinigamesModalActions.deselectGame());
  }, [dispatch]);

  const renderGame = useMemo(() => {
    switch(currentGame) {
      case "game-lixi": return <FlashGameModal />;
      case "game-maze": return <MazeGameChoiceModal />;
      case "game-card-flipping": return <CardFlippingGame onClose={onCloseHandler} change={modalStateOpen} setCustomClass={setCustomClass} />;
      default: return null;
    }
  }, [currentGame, modalStateOpen, onCloseHandler]);

  useEffect(() => {
    if (!modalStateOpen && customClass !== "") {
      setCustomClass("");
    }
  }, [customClass, modalStateOpen]);

  return (
    <GModal
      closeButton={false}
      show={modalStateOpen}
      keyboard={false}
      onHide={onCloseHandler}
      className={cn(`newyear ${currentGame} ${customClass}`, className)}
    >
      <div>
        {renderGame}
      </div>
    </GModal>
  );
};

export default MiniGamesModal;

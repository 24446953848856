import Balancer from 'react-wrap-balancer';

const EggMintingEventBlock = () => {
  return (
    <Balancer className="event-message--root">
      <br />
      <p>A campaign helps you to get rewards with just a simple "weapon".</p>
      <p>
        An opportunity for all users to approach Casper Network, which is
        evaluated as a super potential project in the Blockchain industry.
      </p>

      <p>
        Casper Network casts an enchantment over it because it was hiding a
        secret in the depth of its heart. Join now and devote your heart to
        hunting the treasure, will you?
      </p>

      <p>
        All you need is a simple weapon: <b>a Casper wallet</b>
      </p>

      <p>
        EggForceNFT and CasperDash hid the treasure of 10,000 CSPR & WL Hammer
        NFT slots for 400 lucky users in the wallets. They are waiting to be
        found
      </p>

      <p>Start the Treasure Hunt NOW!</p>

      <p>Make sure you follow all guidelines too: </p>

      <p>
        - Create{' '}
        <a
          href="https://casperdash.io/?utm_source=eggforce&utm_medium=banner&utm_campaign=treasure-hunt&utm_id=001"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          CasperDash Wallet
        </a>
      </p>
      <p>
        - RT this{' '}
        <a
          href="https://twitter.com/EggForceNFT/status/1638148656166756353"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          post
        </a>
      </p>
      <p>- Tag 3 friends</p>
      <p>
        - Follow{' '}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href="https://twitter.com/EggForceNFT"
        >
          EggForce
        </a>{' '}
        &{' '}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href="https://twitter.com/casperdash_io"
        >
          CasperDash
        </a>
      </p>
      <p>
        - Show your proof by taking the screenshot of your Wallet under the
        comment
      </p>

      <p>Rise and wild!</p>
    </Balancer>
  );
};

export default EggMintingEventBlock;

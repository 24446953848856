const commonMessages = {
  errNotEnoughBalance: {
    id: 'CommonMessage.errNotEnoughBalance',
    defaultMessage:
      'Not enough CSPR to proceed. Please check your account balance.',
  },
  errNotEnoughBalanceWithValue: (value: string) => ({
    id: 'CommonMessage.errNotEnoughBalanceWithValue',
    defaultMessage: `Not enough CSPR to proceed. Required ${value} CSPR, please check your account balance.`,
  }),
  labelDelegate: {
    id: 'CommonMessage.labelDelegate',
    defaultMessage: 'INCUBATE',
  },
  labelUndelegate: {
    id: 'CommonMessage.labelUndelegate',
    defaultMessage: 'STOP',
  },
  labelClaimSNC: {
    id: 'CommonMessage.labelClaimSNC',
    defaultMessage: 'LEVEL UP',
  },
  labelDragonHatch: {
    id: 'CommonMessage.labelDragonHatch',
    defaultMessage: 'HATCH DRAGON',
  },
  labelDragonPreview: {
    id: 'CommonMessage.labelDragonPreview',
    defaultMessage: 'PREVIEW',
  },
  labelTransferEgg: {
    id: 'CommonMessage.labelTransferEgg',
    defaultMessage: 'TRANSFER',
  },
  labelHammerBurn: {
    id: 'CommonMessage.labelHammerBurn',
    defaultMessage: 'BURN',
  },
  labelHammerEnchant: {
    id: 'CommonMessage.labelHammerEnchant',
    defaultMessage: 'Burn Hammer',
  },
};

export default commonMessages;

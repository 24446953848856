import { useCallback } from 'react';
import cn from 'classnames';
import NFTMedia from './NFTMedia';
import CSPRExplorerButton from '@/components/CSPRExplorerButton';
import NFTTitle from './NFTTitle';
import NFTCreationYear from './NFTCreationYear';
import type { NFTItem } from '@/types/NFTItem';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import NFTCircle from './NFTCircle';

const GridAsHammer = ({
  data,
  isTooltipContent,
  className = '',
  onClick,
}: any) => {
  const {
    contractAddress,
    creator,
    name,
    contractName,
    yearOfCreation,
    tokenId,
    nftType,
    description,
    image,
    material,
  } = getNFTDetails(data as NFTItem);
  const onClickNFTHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onClick();
    },
    [onClick],
  );

  return (
    <div
      className={cn('nft-grid-item--root', className, {
        selected: false,
        fullHeight: true,
        'is-tooltip-content': isTooltipContent,
      })}
      onClick={onClickNFTHandler}
    >
      <CSPRExplorerButton
        explorerType="contract"
        className="contract-button"
        hash={contractAddress}
        label={false}
        size="small"
        isEmbedded
      />
      <div className="nft-grid-item--heading">
        <NFTCircle material={material} shouldShowHatchButton={false} />
        <NFTTitle
          nftType={nftType}
          contractAddress={contractAddress}
          contractName={contractName}
          name={name}
          tokenId={String(tokenId)}
          creator={creator}
        />
      </div>
      <NFTMedia
        nftType={nftType}
        media={image}
        description={description.value}
      />
      <div className="nft-grid-item--body">
        <NFTCreationYear yearOfCreation={yearOfCreation} />
        {/* {egg?.isProcessing && <LoadingBox minHeight={100} />} */}
      </div>
    </div>
  );

  // return (
  //   <div
  //     className={cn('nft-grid-item--root-2', className, {
  //       selected: false,
  //       fullHeight: true,
  //       'is-tooltip-content': isTooltipContent,
  //     })}
  //     onClick={onClickNFTHandler}
  //   >
  //     <div className="nft-grid-item--heading">
  //       <div className="custom-layout">
  //         <div className="validator-and-progress">
  //           <NFTCircle material={material} shouldShowHatchButton={false} />
  //           <div className="nft-grid-item--hatching-progress">
  //             <GProgress
  //               leftTopLabel={<strong>{formatXPValue(exp)} XP</strong>}
  //               leftLabel={
  //                 <strong>
  //                   ID: <span>#{tokenId}</span>
  //                 </strong>
  //               }
  //               rightLabel={
  //                 egg?.status && (
  //                   <StatusLabel
  //                     className="compact"
  //                     value={egg?.status}
  //                     booster={egg?.booster?.rate ?? null}
  //                   />
  //                 )
  //               }
  //               percent={percent}
  //               tooltipLabel={`${exp}/${nextLevelXp} XP`}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <NFTMedia
  //       nftType={nftType}
  //       media={{
  //         key: image?.value?.toLowerCase(),
  //         name: image?.value?.toLowerCase(),
  //         value: image?.value?.toLowerCase() ?? image,
  //       }}
  //       description={description.value}
  //     />

  //     <div className="box--metadata visible">
  //       {classNFT?.value && path && (
  //         <BoxInfo
  //           label="Element"
  //           value={
  //             <span className={cn(generateCSSElementalClasses(classNFT.value))}>
  //               <img
  //                 loading="lazy"
  //                 src={path}
  //                 className="icon"
  //                 alt={classNFT.value}
  //               />
  //               {classNFT?.value}
  //             </span>
  //           }
  //           valueClassName="element"
  //           isHorizontal={false}
  //         />
  //       )}
  //       {level?.value && (
  //         <BoxInfo
  //           label="Level"
  //           value={`${level?.value}`}
  //           isHorizontal={false}
  //         />
  //       )}
  //       {brand?.value && (
  //         <BoxInfo
  //           label="Brand"
  //           value={`${brand?.value}`}
  //           isHorizontal={false}
  //         />
  //       )}
  //       {MODULES_PERMISSION.USE_BNPL && isInstallment && (
  //         <BoxInfo
  //           label="Installment Payment"
  //           value={String(installmentId)}
  //           isHorizontal={false}
  //         />
  //       )}
  //     </div>
  //     {MODULES_PERMISSION.USE_BNPL && isInstallment && <MeLemLogo />}
  //     <div className="nft-grid-item--body">
  //       {egg?.isProcessing && (
  //         <LoadingBox
  //           className="for--nft-grid-item"
  //           label="Processing..."
  //           minHeight={100}
  //         />
  //       )}
  //     </div>
  //   </div>
  // );
};

export default GridAsHammer;

import { useEffect, useCallback, useState, useMemo } from 'react';
import Big from 'big.js';
import cn from 'classnames';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'lodash/isEmpty';
import useDevice from '@/hooks/useDevice';
import useCurrentUser from '@/hooks/useCurrentUser';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import { Heading } from '@/components/Typography';
import { getMintingFee } from '@/modules/WorldMint/utils';
import type { PackageClientItemType } from './PackageItems';
import GInput from '@/components/GInput';
import GSelect from '@/components/GSelect';
import { purchasePackage } from '@/modules/WorldMint/actions';
import { formatCSPRFull } from '@/helpers/balance';

interface IProps {
  packages: PackageClientItemType[];
  formatter: string;
  open?: boolean;
  className?: string;
  onClose: () => void;
  onConfirm: () => void;
  onSetPurchaseStatus: (value: boolean) => void;
}

const PurchaseCustomPackModal = (props: IProps) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const isDevice = useDevice();
  const { onSetPurchaseStatus, onConfirm, onClose, open, packages } = props;
  const [selectedPackageIndex, setSelectedPackage] = useState<number>(0);

  const [fieldWalletValue, setFieldWallet] = useState<string>('');
  const [fieldEmailValue, setFieldEmail] = useState<string>('');
  const [fieldDiscordIdValue, setFieldDiscord] = useState<string>('');
  const [fieldTelegramValue, setFieldTelegram] = useState<string>('');
  const [formErrors, setFormErrors] = useState<undefined | any>(undefined);

  const isCustomPackSelected = useMemo(() => {
    return Boolean(selectedPackageIndex > packages.length);
  }, [packages.length, selectedPackageIndex]);
  const options = useMemo(() => {
    const mappedOptions = packages.map((packageItem) => ({
      value: String(packageItem.id),
      label: `${packageItem.name} (${packageItem.amount} ${
        // eslint-disable-next-line
        packageItem.amount == 1 ? 'Egg' : 'Eggs'
      }) ${
        packageItem.amount > 1 ? `- Discount: ${packageItem.percent}%` : ''
      }`,
    }));
    const customPackOption = {
      value: String(packages?.length + 1),
      label: 'Custom Pack',
    };

    return [...mappedOptions, customPackOption];
  }, [packages]);
  const data = useMemo(() => {
    if (!packages?.length || isCustomPackSelected) {
      return;
    }

    const selectedPackage = packages[selectedPackageIndex];
    const amountFee = getMintingFee(selectedPackage.amount);
    const hasDiscountPrice = Boolean(selectedPackage.percent > 0);
    const priceInUse = hasDiscountPrice
      ? Big(selectedPackage.priceSale)
      : Big(selectedPackage.priceDefault);

    const packageLabel = `${selectedPackage.name} (${selectedPackage.amount} ${
      selectedPackage.amount > 1 ? `eggs` : `egg`
    })`;
    /**
     * Convert to Mote
     */
    const amountLocked = priceInUse.plus(amountFee);

    return {
      packagePrice: priceInUse.toNumber(),
      packageLabel,
      amountFee: amountFee,
      amountLocked: amountLocked.toNumber(),
    };
  }, [isCustomPackSelected, packages, selectedPackageIndex]);

  const onUseCurrentUserPublicKey = useCallback(() => {
    if (!user) {
      return;
    }

    // Need to store as separate variable to prevent value reference
    const key = user.activeKey;
    setFieldWallet(key);
    setFormErrors((prev: any) => ({
      ...prev,
      publicKey: '',
    }));
  }, [user]);
  const validate = useCallback(() => {
    let error: any = {};

    if (!isEmail(fieldEmailValue)) {
      error['email'] = 'Please enter valid email address';
    }

    if (fieldDiscordIdValue.trim() === '') {
      error['idDiscord'] = 'Please enter your discord username';
    }

    return error;
  }, [fieldDiscordIdValue, fieldEmailValue]);
  const onConfirmHandler = useCallback(async () => {
    try {
      const selectedPackage = packages[selectedPackageIndex];
      let packageName = 'custom';
      if (isDevice) {
        packageName = isCustomPackSelected
          ? 'custom'
          : selectedPackage.name.split(' ')[0].toLowerCase();
      }
      const postData = {
        publicKey: fieldWalletValue,
        email: fieldEmailValue,
        idDiscord: fieldDiscordIdValue,
        idTelegram: fieldTelegramValue,
        selectedPackage: packageName,
      };
      const result = validate();

      if (isEmpty(result)) {
        const serverResult = await dispatch(purchasePackage(postData)).unwrap();
        if (serverResult?.result === false) {
          throw new Error('Something went wrong');
        }
        if (serverResult?.result === true) {
          onConfirm();
          onSetPurchaseStatus(true);
        }
      } else {
        setFormErrors(result);
      }
    } catch (error: any) {
      console.log(`🚀 ~ onConfirmHandler ~ error`, error.message);
      onSetPurchaseStatus(false);
      onConfirm();
    }
  }, [
    packages,
    selectedPackageIndex,
    isDevice,
    fieldWalletValue,
    fieldEmailValue,
    fieldDiscordIdValue,
    fieldTelegramValue,
    validate,
    isCustomPackSelected,
    dispatch,
    onConfirm,
    onSetPurchaseStatus,
  ]);
  const onFieldChangeHandler = useCallback(
    (fieldName: string, value: string, setter: (value: string) => void) => {
      setter(value);

      if (formErrors && formErrors[fieldName]) {
        setFormErrors((prev: any) => ({
          ...prev,
          [fieldName]: '',
        }));
      }
    },
    [formErrors],
  );

  useEffect(() => {
    if (open === false) {
      setSelectedPackage(0);
      setFieldWallet('');
      setFieldEmail('');
      setFieldDiscord('');
      setFieldTelegram('');
      setFormErrors(undefined);
    }
  }, [open]);

  return (
    <GModal
      show={open}
      className={cn('purchase-custom-pack', {
        'is-mobile': isDevice,
      })}
      onHide={onClose}
    >
      <div className="purchase-review--root purchase-custom-pack--root">
        <Heading h={3} className="mb-3 primary-heading">
          {isDevice ? 'Egg Package Purchase' : 'Custom Pack Purchase'}
        </Heading>
        <Heading h={5} className="secondary-heading">
          Account Detail
        </Heading>
        <div className="buyer--detail">
          <Form>
            <Container className="p-0">
              <Row>
                <Form.Group as={Col} xs={12} className="ginput-group mb-3">
                  <Form.Label>Casper wallet Address</Form.Label>
                  <GInput
                    value={fieldWalletValue}
                    onChange={(e) =>
                      onFieldChangeHandler(
                        'publicKey',
                        e.target.value,
                        setFieldWallet,
                      )
                    }
                    type="text"
                    placeholder="Your Casper wallet address"
                    className={cn({
                      'is-invalid': formErrors?.publicKey,
                    })}
                    required
                  />
                  {formErrors?.publicKey && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.publicKey}
                    </Form.Control.Feedback>
                  )}
                  {!isDevice && (
                    <div className="use-current-key--wrapper">
                      <Button
                        type="button"
                        ignoreShadowStyle
                        size="small"
                        onClick={onUseCurrentUserPublicKey}
                        className="use-current-key--button"
                      >
                        Use current key
                      </Button>
                    </div>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={12} className="ginput-group mb-3">
                  <Form.Label>Email</Form.Label>
                  <GInput
                    value={fieldEmailValue}
                    onChange={(e) =>
                      onFieldChangeHandler(
                        'email',
                        e.target.value,
                        setFieldEmail,
                      )
                    }
                    type="text"
                    placeholder="Your email address"
                    className={cn({
                      'is-invalid': formErrors?.email,
                    })}
                    required
                  />
                  {formErrors?.email && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.email}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  xs={12}
                  md="6"
                  className="ginput-group mb-3"
                >
                  <Form.Label>Discord Username</Form.Label>
                  <GInput
                    value={fieldDiscordIdValue}
                    onChange={(e) =>
                      onFieldChangeHandler(
                        'idDiscord',
                        e.target.value,
                        setFieldDiscord,
                      )
                    }
                    type="text"
                    placeholder="Discord Username"
                    className={cn({
                      'is-invalid': formErrors?.idDiscord,
                    })}
                  />
                  {formErrors?.idDiscord && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.idDiscord}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  md="6"
                  className="ginput-group mb-3"
                >
                  <Form.Label>Telegram Id (optional)</Form.Label>
                  <GInput
                    value={fieldTelegramValue}
                    onChange={(e) =>
                      onFieldChangeHandler(
                        'idTelegram',
                        e.target.value,
                        setFieldTelegram,
                      )
                    }
                    type="text"
                    placeholder="Telegram Id"
                  />
                  {formErrors?.idTelegram && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.idTelegram}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              {isDevice && (
                <Row>
                  <Form.Group as={Col} xs={12} className="ginput-group mb-3">
                    <Form.Label>Package</Form.Label>
                    <GSelect
                      defaultValue={options[0]}
                      options={options}
                      onChange={({ value }: any) => {
                        setSelectedPackage(parseInt(value, 10));
                      }}
                    />
                  </Form.Group>
                </Row>
              )}
            </Container>
          </Form>
        </div>
        {isDevice && (
          <div className="purchase-review--wrapper">
            {data && (
              <>
                <Heading h={5} className="secondary-heading">
                  Package Summary
                </Heading>
                <Table className="table-result" striped hover variant="dark">
                  <tbody>
                    <tr>
                      <td>Package</td>
                      <td>{data.packageLabel}</td>
                    </tr>
                    <tr>
                      <td>Package value</td>
                      <td>{formatCSPRFull(data.packagePrice)} CSPR</td>
                    </tr>
                    <tr>
                      <td>Minting Fee</td>
                      <td>{formatCSPRFull(data.amountFee)} CSPR</td>
                    </tr>
                    {!isDevice && (
                      <tr>
                        <td>Locked amount (*)</td>
                        <td>{formatCSPRFull(data.amountLocked)} CSPR</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            )}
            <div className="content-wrapper">
              <p className="helper">Please double check all info below</p>
              <ul>
                <li>
                  Verify that you selected the correct package, and have
                  sufficient balance in your wallet account.
                </li>
                <li>
                  <strong>
                    Once you've registered purchasement with us, keep a close
                    look at your social account as we'll contact you directly
                    for further confirmation.
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer--bottom">
        <Button fullWidth size="small" onClick={onConfirmHandler} btnStyle="1">
          {isDevice ? 'I understand & Register Purchase' : 'Purchase'}
        </Button>
      </div>
    </GModal>
  );
};

export default PurchaseCustomPackModal;

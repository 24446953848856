import Balancer from 'react-wrap-balancer';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { selectPackagesDetailStore } from '@/modules/WorldMint/selectors';
import { Heading } from '@/components/Typography';
import ClaimingProgressBar from '@/components/ClaimProgressBar';
import { useAppSelector } from '@/app/hooks';

const ClaimedSalesStats = () => {
  const packageModuleStore = useAppSelector(selectPackagesDetailStore);
  const { salesStats } = packageModuleStore;

  if (!MODULES_PERMISSION.USE_HAPPY_HOUR) {
    return null;
  }
  return (
    <div className="quickbuy-package--heading">
      <Balancer>
        <Heading className="text-center text-gradient" h={3}>
          PUBLIC ROUND
        </Heading>
      </Balancer>
      {salesStats.total > 0 && (
        <div className="claim-progress--wrapper">
          <ClaimingProgressBar
            data={{
              element: 'Earth',
              sold: salesStats.sold?.toString() ?? '0',
              total: salesStats.total?.toString(),
              level: '',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ClaimedSalesStats;

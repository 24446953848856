import { Heading } from '@/components/Typography';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import iconTreeSelvyn from '@/assets/images/icon--treeSelvyn.webp';
import iconMerge from '@/assets/images/icon--merge.png';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import Button from '@/components/GButton';

const DragonGuideStep7 = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step7) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-7">
      <div className="dragon-guide--step">
        <img src={iconTreeSelvyn} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Merge your Eggs!</Heading>
        <p>
          Merging eggs can increase your chance when hatching a legendary dragon
          <br />
          Click <strong>Merging Hub</strong> to begin the merging guide.
        </p>
      </div>
    </div>
  );
};

export const DragonGuideStep7a = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step7a) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-7a">
      <div className="dragon-guide--step">
        <img src={iconTreeSelvyn} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Egg Merging!</Heading>
        <p>
          Place the Egg you want to preserve{' '}
          <strong>as the main element in Slot 1</strong>. Any other selected
          eggs will be merged into this primary slot. They{' '}
          <strong>will be burned</strong> after the process has been completed.
          <br />
          When ready, click <strong>Start Merging</strong>
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.wait),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export const DragonGuideStep7b = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step7b) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-7b">
      <div className="dragon-guide--step">
        <img src={iconTreeSelvyn} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Egg Merged!</Heading>
        <p>
          The egg has been successfully merged. You can then either stake or
          <br />
          <br />
          <i>
            Protip: There's a slightly additional SNC on the primary egg after
            merging, value varies by number of burned eggs.
          </i>
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step7c),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export const DragonGuideStep7c = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step7c) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-7c">
      <div className="dragon-guide--step">
        <img src={iconMerge} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Succesfully merged!</Heading>
        <p>
          You've just completed the demo gameplay. <strong>Turn off</strong> the
          Merging Hub screen and <strong>find the Selvyn tree</strong> on the
          map.
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step6),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default DragonGuideStep7;

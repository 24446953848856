import { useCallback } from 'react';
import GModal from '@/components/GModal';
import { Check2Circle, Boxes, InfoCircle } from 'react-bootstrap-icons';
import GNotificationModal from '@/components/GNotificationModal';
import { Heading } from '@/components/Typography';
import { useMemo } from 'react';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import { selectSNCModule } from '@/modules/SNCModule/selectors';
import { SNCModuleActions } from '@/modules/SNCModule/store';
import isEqual from 'lodash/isEqual';
import SocialDiscordButton from '@/components/SocialDiscordButton';
import SocialTelegramButton from '@/components/SocialTelegramButton';
import CloseModalButton from '@/components/GCloseModalButton';

const SNCRegisterStatusModal = () => {
  const dispatch = useDispatch();
  const SNCModule = useSelector(selectSNCModule, isEqual);
  const {
    statusModal: { message, open, isSuccess, code },
  } = SNCModule;
  const onCloseStatusModal = useCallback(() => {
    dispatch(SNCModuleActions.hideStatusModal());
  }, [dispatch]);

  const { title, icon, msg } = useMemo(() => {
    const successMessage = (
      <span>
        To confirm the CSPR, please DM the below code our dedicated Community
        Managers via <a href="https://t.me/EggforceNFT_Community">Telegram</a>{' '}
        or <a href="https://discord.com/invite/vCURDNzjYc">Discord</a>.
      </span>
    );
    const failedMessage = (
      <span>
        No worries, it's on us and we've been notified. Truly sorry for this
        inconvenience.
      </span>
    );
    return {
      msg: isSuccess ? successMessage : message ? message : failedMessage,
      title: isSuccess
        ? `You're now ready to receive CSPR`
        : `Failed to register SNC Airdrop!`,
      icon: () => (!isSuccess ? <Boxes /> : <Check2Circle />),
    };
  }, [isSuccess, message]);

  return (
    <GModal
      closeButton={false}
      show={open}
      className={'snc-registration-result'}
      onHide={onCloseStatusModal}
    >
      <CloseModalButton onClick={onCloseStatusModal} />
      <GNotificationModal
        labelConfirm={''}
        className="snc-registration--status"
        onClose={onCloseStatusModal}
        icon={icon()}
        fullWidth={false}
        closeButtonStyle="3"
      >
        <Heading h={4}>{title}</Heading>
        {isSuccess ? (
          <p className="helper success">
            {msg}
            <span className="confirm-code">{code}</span>
          </p>
        ) : (
          <p className="helper">
            <InfoCircle className="icon--helper" />
            {msg}
          </p>
        )}
        {!isSuccess && (
          <div className="snc-actions-button--wrapper">
            <SocialDiscordButton
              skipConfused
              compact
              label="Confirm on Discord"
              onClick={onCloseStatusModal}
            />
            <SocialTelegramButton
              skipConfused
              compact
              label="Confirm on Telegram"
              onClick={onCloseStatusModal}
            />
          </div>
        )}
      </GNotificationModal>
    </GModal>
  );
};

export default SNCRegisterStatusModal;

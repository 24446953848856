import Balancer from 'react-wrap-balancer';

const TurkishDonationCampaign = () => {
  return (
    <Balancer className="event-message--root">
      <br />
      <p>Dear our lovely #Hatchers, dear everyone</p>
      <p>Together with our 1st round of NFT sales - Closed Beta Round, EggForceNFT is running a donation campaign to Turkish citizens after the disasters</p>

      <p>We will donate 1% of our TOTAL 4 ROUNDS sales revenue to Turkey. This 1% will be automatically transferred to <b>EggForce Donation Address (01fc24a2dcb743280334e46ab48f55ba674b7f2cefb87b686262bbccaf3be3567a)</b> when users purchase egg NFTs</p>

      <p>The donated money is in CSPR. We want it to be more convenient for Casper Communities</p>

      <p>You can check our website here for the Wallet Address link: eggforce.io/world</p>

      <p>At 1:00 PM CET every Friday, we will update weekly information about the donation (ex: how much we have raised, etc.)</p>

      <p>Everyone,</p>

      <p>This donation was born for EggForceNFT's Turkish partners and Turkish community's sake.</p>
      <p>You don't have to be rich to be kind! The key is: be kind and you'll be rich.</p>
      <p>Let's support them, share love and pray all the best for them together!</p>

      <p>Dear Turkish community,</p>

      <p>In the midst of darkness, light persists.</p>
      <p>We'll be here for you.</p>
    </Balancer>
  );
};

export default TurkishDonationCampaign;

import React from 'react';

const AnswerComponent = () => {
  return (
    <div>
      <p>These are reasons why you should own one!:</p>
      <p>
        <img
          src="https://assets.eggforce.io/egg-utilities.png"
          alt="What are Egg NFT utilities?"
        />
      </p>
    </div>
  );
};

export default React.memo(AnswerComponent);

import { useEffect } from 'react';
import { useDebounce } from 'react-use';
import useSWR from 'swr';
import API from '@/constants/api';
import { DeployStatus } from '@/types/deploy';
import { fetcher } from '@/services/axios';
import configs from '@/constants/settings';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import { selectEggPurchaseStore } from '@/modules/EggPurchase/selectors';
import isEqual from 'lodash/isEqual';
import { EggPurchaseStoreActions } from '@/modules/EggPurchase/store';
import { isDeployTooOld } from '@/helpers/deploy';

const useNotifications = () => {
  const dispatch = useDispatch();
  const eggPurchasesStore = useSelector(selectEggPurchaseStore, isEqual);
  const { deployHash } = eggPurchasesStore;
  let params = '';
  deployHash.forEach((hash) => {
    if (hash.status !== DeployStatus.COMPLETED) {
      /**
       * If current hash is too old,
       * e.g One was created longer than 24 hours, then skip
       */
      if (!isDeployTooOld(hash.createdAt)) {
        params += `deployHash=${hash.hash}&`;
      }
    }
  });

  const { data, isValidating, mutate } = useSWR(
    params ? API.deployStatuses(params) : null,
    fetcher,
  );

  useDebounce(
    () => {
      if (!isValidating) {
        mutate();
      }
    },
    configs.INTERVAL_DEBOUNCE_NOTIFICATIONS_API,
    [isValidating, data],
  );

  useEffect(() => {
    if (!data || !data.length) {
      return;
    }

    dispatch(EggPurchaseStoreActions.batchUpdateStatuses(data));
  }, [dispatch, data]);
};

export default useNotifications;

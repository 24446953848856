import { useMemo, useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import Navbar from 'react-bootstrap/Navbar';
import WorldMap from '@/components/WorldMap';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import WorldMint from '@/modules/WorldMint';
import WorldSidebar from '@/modules/WorldSidebar';
import useCurrentUser from '@/hooks/useCurrentUser';
import WorldProfileMenu from '@/modules/WorldProfileMenu';
import ConnectSignerModal from '@/components/ConnectSignerModal';
import { NFTCollectionModalActions } from '@/modules/EggCollection/store';
import settings, { ConnectionTypes } from '@/constants/settings';
import MiniGamesModal from '@/components/MiniGamesModal';
import NotificationSidebar from '@/components/NotificationSidebar';
import { EggPurchaseStoreActions } from '@/modules/EggPurchase/store';
import { selectAuth } from '@/modules/Auth/selectors';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import useMintPermission from '@/hooks/useMintPermission';
import WorldMintMobile from '@/modules/WorldMint/WorldMintMobile';
import useDevice from '@/hooks/useDevice';
import WalletConnectors from '@/components/WalletConnectors';
import InstallmentsListModal from '@/components/InstallmentsListModal';
import InstallmentPaymentQASupportModal from '@/components/InstallmentsListModal/InstallmentPaymentQASupportModal';
import InstallmentPaymentStatusModal from '@/components/InstallmentsListModal/InstallmentPaymentStatusModal';
import InstallmentPaymentDetailModal from '@/components/InstallmentsListModal/InstallmentPaymentDetailModal';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { fetchInstallmentPayments } from '@/modules/InstallmentsPayment/actions';
import { selectInstallmentPaymentsListStore } from '@/modules/InstallmentsPayment/selectors';
import { selectConnectionType } from '@/modules/Auth/selectors';
import EggMergingModuleModal from '@/modules/EggMerging';
import LeaderboardModuleModal from '@/modules/Leaderboards';
import SNCRegistrationModal from '@/components/SNCRegistrationModal';
import EggCollectionFilters from '@/components/EggCollectionFilters';
import WorldSecondaryMenu from '@/components/WorldSecondaryMenu';
import LedgerAccountsSelector from '@/components/LedgerAccountsSelector';
import useAccountWallet from '@/hooks/useAccountWallet';

const WorldmapScreen = () => {
  useMintPermission();
  const { open: openInstallmentPaymentList } = useSelector(
    selectInstallmentPaymentsListStore,
  );
  const currentWalletConnection = useSelector(selectConnectionType, isEqual);

  const hideWorldmapControllers = settings.HIDE_EGGFORCE_MAP_CONTROLLERS;
  const dispatch = useDispatch();
  const { publicKey: publicKeyUseWallet } = useAccountWallet({
    skipOnChange: true,
  });
  const storeAuth = useSelector(selectAuth, isEqual);
  const { loading } = storeAuth;
  const isDevice = useDevice();
  const user = useCurrentUser();
  const refreshNFTCollection = useCallback(() => {
    if (!user) {
      return;
    }

    dispatch(NFTCollectionModalActions.resetData());
    dispatch(EggPurchaseStoreActions.reset());

    if (MODULES_PERMISSION.USE_BNPL) {
      dispatch(fetchInstallmentPayments());
    }
  }, [dispatch, user]);

  const renderPrimaryModules = useMemo(() => {
    /**
     * Enable AdHoc Form in Map only when meeting these conditions:
     * - USE_ADHOC_FORM: true
     * - USE_NFT_MINTING: false
     */
    // if (
    //   !MODULES_PERMISSION.USE_NFT_MINTING &&
    //   MODULES_PERMISSION.USE_ADHOC_FORM
    // ) {
    //   return <AdHocEventModal />;
    // }
    if (MODULES_PERMISSION.USE_MOBILE_SUPPORT) {
      return <WorldMint />;
    }

    return isDevice ? <WorldMintMobile /> : <WorldMint />;
  }, [isDevice]);

  const renderSignInSection = useMemo(() => {
    const DOM = (
      <div className="world-element world-element--connect-button">
        {user?.activeKey ? (
          <WorldProfileMenu user={user} />
        ) : (
          <WalletConnectors />
        )}
      </div>
    );
    if (MODULES_PERMISSION.USE_MOBILE_SUPPORT) {
      return DOM;
    }

    return !isDevice && DOM;
  }, [isDevice, user]);

  /**
   * Automatically reload user data when changing account (change key)
   */
  useEffect(() => {
    if (user && publicKeyUseWallet && user.activeKey !== publicKeyUseWallet) {
      console.log('>>> Account changed, reload NFT Collection');
      refreshNFTCollection();
    }
  }, [publicKeyUseWallet, refreshNFTCollection, user]);

  return (
    <MasterLayoutBody>
      <div className="header--worldmap">
        <Navbar.Brand as={NavLink} to="/home" className="header--worldmap-logo">
          EggForce
        </Navbar.Brand>
      </div>
      {!hideWorldmapControllers && (
        <>
          <ConnectSignerModal />
          {renderSignInSection}
          <div className="world-element world-element--secondary-menu has-menu">
            <WorldSecondaryMenu />
          </div>
          <div className="world-element world-element--sidebar has-menu">
            <WorldSidebar />
          </div>
          <div className="world-element world-element--mint-wrapper">
            {renderPrimaryModules}
          </div>
        </>
      )}
      {/**
       * TODO: Remove after event
       */}
      {hideWorldmapControllers && (
        <div className="world-element world-element--mint-wrapper">
          <div className="social--sharing--bottom">
            <TwitterShareButton
              className="tw sharing-button"
              title="📢 Calling for @EggForceNFT. Check out my result for #Callista_World puzzle!
Forming your own kingdom with @C_Staking @BitCat365 @WeAreTeamMAKE @OriginStake @speedystaking
Hey strangers, come and join me 👉 "
              via=""
              hashtags={['NFT', 'CSPR', 'EggForce', 'Callista']}
              url={'https://go.eggforce.io/link'}
            >
              <TwitterIcon size={46} round={true} />
              <span className="label">Share map</span>
            </TwitterShareButton>
          </div>
        </div>
      )}
      <WorldMap />
      <MiniGamesModal />
      <NotificationSidebar />
      {loading && <div className="global--loading" />}
      {openInstallmentPaymentList && <InstallmentsListModal />}
      <InstallmentPaymentQASupportModal />
      <InstallmentPaymentStatusModal />
      <InstallmentPaymentDetailModal />
      <EggCollectionFilters />
      <EggMergingModuleModal />
      <LeaderboardModuleModal />
      <SNCRegistrationModal />
      {currentWalletConnection === ConnectionTypes.ledger && (
        <LedgerAccountsSelector />
      )}
    </MasterLayoutBody>
  );
};

export default WorldmapScreen;

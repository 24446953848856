import { createSlice } from '@reduxjs/toolkit';
import { IModal } from '@/types/modal';

interface ICustomEventModal extends IModal {
  adHocEventModal: IModal;
  adHocResultEventModal: IModal & {
    src: string;
  };
}

const initialState: ICustomEventModal = {
  loading: false,
  open: false,
  adHocEventModal: {
    loading: false,
    open: false,
  },
  adHocResultEventModal: {
    loading: false,
    open: false,
    src: 'wl-form',
  },
};

const CustomEventModal = createSlice({
  name: 'CustomEventModal',
  initialState,
  reducers: {
    setLoading: (state: ICustomEventModal, action) => {
      state.loading = action.payload;
    },
    showModal: (state: ICustomEventModal) => {
      state.open = true;
    },
    hideModal: (state: ICustomEventModal) => {
      state.open = false;
    },
    setAdHocEventModalLoading: (state: ICustomEventModal, action) => {
      state.adHocEventModal.loading = action.payload;
    },
    showAdHocEventModal: (state: ICustomEventModal) => {
      state.adHocEventModal.open = true;
    },
    hideAdHocEventModal: (state: ICustomEventModal) => {
      state.adHocEventModal.open = false;
    },
    setAdHocResultEventModalLoading: (state: ICustomEventModal, action) => {
      state.adHocResultEventModal.loading = action.payload;
    },
    setAdHocResultEventModalSrc: (state: ICustomEventModal, action) => {
      state.adHocResultEventModal.src = action.payload;
    },
    showAdHocResultEventModal: (state: ICustomEventModal) => {
      state.adHocResultEventModal.open = true;
    },
    hideAdHocResultEventModal: (state: ICustomEventModal) => {
      state.adHocResultEventModal.open = false;
    },
  },
});

const CustomEventModalActions = {
  ...CustomEventModal.actions,
};

export { CustomEventModalActions };
export default CustomEventModal.reducer;

import { useState, useMemo, useCallback } from 'react';
import {
  useAccount,
  LedgerAccount,
  useFetchLedgerAccounts,
  useSetLedgerAccountIndex,
} from '@casperdash/usewallet';
import cn from 'classnames';
import GModal from '@/components/GModal';
import OptionItem from '@/components/OptionItem';
import MiddleTruncatedText from '@/components/MiddleTruncatedText';
import { Heading } from '@/components/Typography';
import { selectLedgerAccountSelectorModal } from '@/modules/CasperSigner/selectors';
import { connectWalletActions } from '@/modules/CasperSigner/store';
import imgHatch from '@/assets/images/icon--unhatch.png';
import Button from '@/components/GButton';
import CustomScrollbar from '@/components/CustomScrollbar';
import useLedgerErrorsHandler from '@/hooks/useLedgerErrorsHandler';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';

type LedgerIndex = number;

const LedgerAccountsSelector = () => {
  const dispatch = useDispatch();
  const { publicKey } = useAccount();
  const { toastError } = useLedgerErrorsHandler();
  const { setLedgerAccountIndexAsync } = useSetLedgerAccountIndex({
    onSuccess: ({ index }: { index: string }) => {
      dispatch(connectWalletActions.saveSelectedLedgerAccount(index));
    },
    onError: (error, variables) => {
      toastError(error);
    },
  });
  const { data } = useFetchLedgerAccounts(
    {
      total: 10,
    },
    {
      enabled: !!publicKey,
    },
  );
  const [selectedIndex, setAccountIndex] = useState<LedgerIndex>(0);
  const ledgerAccounts = useMemo(() => {
    return data?.filter((account: LedgerAccount) => account.publicKey);
  }, [data]);
  const { open } = useSelector(selectLedgerAccountSelectorModal);
  const onHideHandler = useCallback(() => {
    dispatch(connectWalletActions.hideLedgerAccountsModal());
  }, [dispatch]);
  const onSelectHandler = useCallback((value: LedgerIndex) => {
    setAccountIndex(value);
  }, []);
  const onConnectSelectedAccount = useCallback(async () => {
    try {
      if (selectedIndex !== 0 && !selectedIndex) {
        return;
      }
      dispatch(connectWalletActions.setLedgerAccountsLoading(true));
      await setLedgerAccountIndexAsync({
        index: String(selectedIndex),
      });
      onHideHandler();
      dispatch(connectWalletActions.setLedgerAccountsLoading(false));
    } catch (err: any) {
      toastError(err);
    }
  }, [
    dispatch,
    onHideHandler,
    selectedIndex,
    setLedgerAccountIndexAsync,
    toastError,
  ]);

  if (!ledgerAccounts?.length) {
    return null;
  }

  return (
    <GModal
      blurOverlay
      onHide={onHideHandler}
      show={open}
      // show
      backdrop="static"
      className={'compact-modal ledger-accounts-selector--modal'}
    >
      <div className={cn('ledger-accounts-selector--wrapper')}>
        <div className="compact-modal--box success">
          <div className="body">
            <div className="section--title">
              <Heading h={3}>Account Selection</Heading>
              <p>Please select account from your Ledger</p>
            </div>
            <div className="ledger-accounts--list">
              <CustomScrollbar>
                {ledgerAccounts?.map((account: any) => {
                  const isSelected = selectedIndex === account.index;
                  return (
                    <div
                      role="button"
                      key={`account-${account.publicKey}`}
                      onClick={() =>
                        onSelectHandler(account.index as LedgerIndex)
                      }
                      className={cn('ledger-accounts--item', {
                        'is-selected': isSelected,
                      })}
                    >
                      <span className="ledger-accounts--count">
                        {account.index + 1}
                      </span>
                      <OptionItem
                        className="is-ledger-account-item"
                        text={account.publicKey}
                        textNode={
                          <MiddleTruncatedText>
                            {account.publicKey as string}
                          </MiddleTruncatedText>
                        }
                      />
                      <span
                        className={cn({
                          'ledger-accounts--selected': true,
                          'is-selected': isSelected,
                        })}
                      >
                        {isSelected && (
                          <img
                            loading="lazy"
                            src={imgHatch}
                            alt="Selected"
                            className="icon-selected"
                          />
                        )}
                      </span>
                    </div>
                  );
                })}
              </CustomScrollbar>
            </div>
            {/* <Heading className="text-gradient2" h={4}>
              {title}
            </Heading>
            <p className="helper">{msg}</p>
            <SocialDiscordButton skipConfused /> */}
          </div>
          <div className="actions">
            <Button
              onClick={onConnectSelectedAccount}
              className="btn--connect-via-ledger"
              size="small"
              btnStyle="5"
            >
              <span>Connect</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="modal-gradient--bottom"></div>
    </GModal>
  );
};

export default LedgerAccountsSelector;

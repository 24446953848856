import { useCallback } from 'react';
import Big from 'big.js';
import useCurrentUser from '@/hooks/useCurrentUser';
import isNumber from 'lodash/isNumber';
import type { TypeMote } from '@/types/balance';

const useCheckBalance = () => {
  const user = useCurrentUser();
  const checkBalanceAgainstAmount = useCallback(
    (amount: TypeMote) => {
      if (!user || !user?.balance) {
        return false;
      }
      const bigAmount = Big(amount);
      const userBalance = isNumber(user.balance) ? Big(user.balance) : Big(0);

      if (userBalance.eq(0) || bigAmount.gt(userBalance)) {
        return false;
      }

      return true;
    },
    [user],
  );

  return {
    checkBalanceAgainstAmount,
  };
};

export default useCheckBalance;

import {
  CasperDashConnector,
  CasperSignerConnector,
  CasperWalletConnector,
} from '@casperdash/usewallet';
import { CasperLedgerConnector } from '@casperdash/usewallet-ledger';
import ASSETS_URL from '@/constants/assetsURL';
import { WalletLinks } from '@/constants/publicURL';

class WalletProviders {
  _instance: any;
  _name: string = '';
  iconPath: string = '';
  connectFunction: any;

  setConnector(fnc: any) {
    this.connectFunction = fnc;
  }

  connect() {
    this.connectFunction();
  }

  get iconURL() {
    return this.iconPath;
  }

  get name() {
    return this._name;
  }
}

export class CasperSignerWallet extends WalletProviders {
  static id = 'casperSigner';
  _name = 'CasperSigner';
  iconPath = ASSETS_URL.CasperSigner;

  get instance() {
    return new CasperSignerConnector();
  }

  get connectorId() {
    return CasperSignerWallet.id;
  }

  get walletLink() {
    return WalletLinks.casperSigner;
  }
}

export class CasperWallet extends WalletProviders {
  static id = 'casperWallet';
  _name = 'CasperWallet';
  iconPath = ASSETS_URL.CasperWallet;

  get instance() {
    return new CasperWalletConnector();
  }

  get connectorId() {
    return CasperWallet.id;
  }

  get walletLink() {
    return WalletLinks.casperWallet;
  }
}

export class CasperDashWallet extends WalletProviders {
  static id = 'casperDash';
  _name = 'CasperDash';
  iconPath = ASSETS_URL.Casperdash;

  get instance() {
    return new CasperDashConnector();
  }

  get connectorId() {
    return CasperDashWallet.id;
  }

  get walletLink() {
    return WalletLinks.casperDash;
  }
}

export class CasperLedgerWallet extends WalletProviders {
  static id = 'ledger';
  _name = 'Ledger';
  iconPath = ASSETS_URL.Ledger2;

  get instance() {
    return new CasperLedgerConnector();
  }

  get connectorId() {
    return CasperLedgerWallet.id;
  }

  get walletLink() {
    return WalletLinks.ledger;
  }
}

export type WalletConnectorType =
  | CasperSignerWallet
  | CasperWallet
  | CasperDashWallet
  | CasperLedgerWallet;

export const casperDashConnector: WalletConnectorType = new CasperDashWallet();
export const casperWalletConnector: WalletConnectorType = new CasperWallet();
export const casperSignerConnector: WalletConnectorType =
  new CasperSignerWallet();
export const casperLedgerConnector: WalletConnectorType =
  new CasperLedgerWallet();

const WalletConnectors = {
  [CasperDashWallet.id]: casperDashConnector,
  [CasperWallet.id]: casperWalletConnector,
  [CasperSignerWallet.id]: casperSignerConnector,
  [CasperLedgerWallet.id]: casperLedgerConnector,
  from: (id: string): any | undefined => {
    return WalletConnectors[id];
  },
};

export default WalletConnectors;

import { Heading } from '@/components/Typography';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import Button from '@/components/GButton';
import iconStep3 from '@/assets/images/journey-step--3.png';
import iconStep3d from '@/assets/images/journey-step--5.png';

const DragonGuideStep3 = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step3) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-3">
      <div className="dragon-guide--step">
        <img src={iconStep3} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Wonderful!!!</Heading>
        <p>
          Now click on <strong>NFTs</strong> button and see your purchased NFTs.
          <br />
        </p>
      </div>
    </div>
  );
};

export const DragonGuideStep3b = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step3b) {
    return null;
  }
  return (
    <div className="dragon-guide--content step-3b">
      <div className="dragon-guide--step">
        <img src={iconStep3} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Incubate your first egg</Heading>
        <p>
          Click <strong>Incubate</strong> to stake CSPR. This is also used for
          leveling up your egg NFT. Let's try to incubate 5,000 CSPR.
        </p>
      </div>
    </div>
  );
};

export const DragonGuideStep3c = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step3c) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-3c">
      <div className="dragon-guide--step">
        <img src={iconStep3d} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Hatch dragon</Heading>
        <p>
          You've just leveled up a <strong>Platinum Egg</strong>. Now click{' '}
          <strong>Dragon Hatch</strong> to hatch a dragon. The higher level the
          egg is, the more chance you'd successfully hatch{' '}
          <strong>a legendary dragon.</strong>
        </p>
      </div>
    </div>
  );
};

export const DragonGuideStep3e = () => {
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step3c) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-3c">
      <div className="dragon-guide--step">
        <img src={iconStep3d} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Hatch dragon</Heading>
        <p>
          You can also hatch your <strong>Rock Egg</strong> into a Dragon NFT
          and see the difference.
        </p>
      </div>
    </div>
  );
};

export const DragonGuideStep3d = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep } = store;

  if (currentGuideStep !== GuideStep.step3d) {
    return null;
  }

  return (
    <div className="dragon-guide--content step-3d">
      <div className="dragon-guide--step">
        <img src={iconStep3} alt="Welcome" className="guide-icon" />
        <Heading h={5}>Good job!!!</Heading>
        <p>
          The seleted egg status has been changed to <strong>Incubating</strong>
          .
        </p>
        <p style={{ fontStyle: 'italic' }}>
          Pro tip: for actual gameplay, you can opt to incubate more CSPR to
          fasten the leveling up progress.
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step5),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default DragonGuideStep3;

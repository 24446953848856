import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEqual from 'lodash/isEqual';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { Heading } from '@/components/Typography';
import { GInputField } from '@/components/GInput';
import Button from '@/components/GButton';
import { FormRenderProps } from 'react-final-form';
import useCurrentUser from '@/hooks/useCurrentUser';

import { registerSNCAirdrop } from '@/modules/SNCModule/actions';
import { FormApi } from 'final-form';
import { SNCModuleActions } from '@/modules/SNCModule/store';
import SNCRegisterStatusModal from './SNCRegisterStatusModal';
import { selectSNCModule } from '@/modules/SNCModule/selectors';
import { Links } from '@/constants/publicURL';
import {
  mustBeEmail,
  mustBeCasperWalletAddress,
  composeValidators,
  required,
} from '@/services/reactFinalFormServices';

interface SNCRegisterFormSpec {
  walletAddress?: string;
  email?: string;
  idDiscord?: string;
  idTelegram?: string;
}

const SNCRegistratioFormComponent = (props: FormRenderProps) => {
  const { form } = props;
  const SNCModule = useSelector(selectSNCModule, isEqual);
  const user = useCurrentUser();
  const { loading } = SNCModule;
  const onUseCurrentUserPublicKey = useCallback(() => {
    if (!user) {
      return;
    }

    // Need to store as separate variable to prevent value reference
    const key = user.activeKey;
    form.change('walletAddress', key);
  }, [form, user]);

  return (
    <form onSubmit={props.handleSubmit}>
      <Container className="p-0">
        <Row>
          <Form.Group
            as={Col}
            xs={12}
            className="ginput-group mb-3 field--wallet-address"
          >
            <Field
              label="Casper Wallet Address (*)"
              name="walletAddress"
              type="text"
              component={GInputField}
              validate={composeValidators(required, mustBeCasperWalletAddress)}
            />
            {user && (
              <Button
                type="button"
                ignoreShadowStyle
                size="small"
                onClick={onUseCurrentUserPublicKey}
                className="use-current-key--button"
              >
                Use current key
              </Button>
            )}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={12} className="ginput-group mb-3">
            <Field
              label="Email (*)"
              name="email"
              type="text"
              component={GInputField}
              validate={composeValidators(required, mustBeEmail)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={12} md="6" className="ginput-group mb-3">
            <Field
              label="Discord Username (optional)"
              name="idDiscord"
              type="text"
              component={GInputField}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md="6" className="ginput-group mb-3">
            <Field
              label="Telegram Username (optional)"
              name="idTelegram"
              type="text"
              component={GInputField}
            />
          </Form.Group>
        </Row>
      </Container>

      <Button
        disabled={loading}
        size="small"
        className="btn--snc-register"
        type="submit"
      >
        {loading ? `Registering...` : `Register`}
      </Button>
    </form>
  );
};

const SNCRegistrationForm = ({ isModal = false }: any) => {
  const dispatch = useDispatch();
  const initValues: SNCRegisterFormSpec = useMemo(
    () => ({
      walletAddress: '',
      email: '',
      idDiscord: '',
      idTelegram: '',
    }),
    [],
  );
  const onSubmit = useCallback(
    async (values: SNCRegisterFormSpec, form: FormApi) => {
      try {
        dispatch(SNCModuleActions.setLoading(true));
        dispatch(SNCModuleActions.updateResultStatusMessage(''));
        const result: any = await dispatch(registerSNCAirdrop(values)).unwrap();

        if (result?.result === false) {
          dispatch(SNCModuleActions.updateResultStatusModal(false));
          dispatch(SNCModuleActions.showStatusModal());
          return;
        }

        // @ts-ignore: TODO twq is injected from tag manager.
        twq('event', 'tw-oeheh-ofrm3', {
          value: values.walletAddress,
          status: 'subscribed',
          email_address: values.email,
        });

        setTimeout(() => {
          dispatch(
            SNCModuleActions.updateRegisterCode(result?.lead.airdropCode),
          );
          dispatch(SNCModuleActions.updateResultStatusModal(true));
          dispatch(SNCModuleActions.showStatusModal());
          // Reset form
          // https://dev.to/jetrockets/reset-values-in-react-final-form-w-keepdirtyonreinitialize-26cf
          form.reset(initValues);
        }, 500);
      } catch (err: any) {
        dispatch(
          SNCModuleActions.updateResultStatusMessage(
            'Email or Casper wallet address was already registered.',
          ),
        );
        dispatch(SNCModuleActions.updateResultStatusModal(false));
        dispatch(SNCModuleActions.showStatusModal());
      } finally {
        dispatch(SNCModuleActions.setLoading(false));
      }
    },
    [dispatch, initValues],
  );

  return (
    <div
      id={isModal ? `snc-registration-form--map` : `snc-registration-form`}
      className={cn('snc-registration-form--root', {
        'is-modal': isModal,
      })}
    >
      <Heading
        h={3}
        className={cn('mb-3 primary-heading', {
          'text-center': isModal,
        })}
      >
        SNC Airdrop
      </Heading>
      <div>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initValues}
          render={SNCRegistratioFormComponent}
        />
        <div className="game-instruction snc-airdrop">
          <div className="game-instruction--box">
            <ul>
              <li>
                Participants can claim their 50 CSPR tokens by confirming their
                unique code through our dedicated Community Managers via{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={Links.telegramCommunity}
                >
                  Telegram
                </a>{' '}
                or{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={Links.discord}
                >
                  Discord
                </a>
                .
              </li>
              <li>
                Participants can claim the remaining if they mint their Eggs on{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={Links.eggforceWorld}
                >
                  eggforce.io/world
                </a>
                , receiving an exciting reward of 740 - 1200 SNC Tokens.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SNCRegisterStatusModal />
    </div>
  );
};

export default SNCRegistrationForm;

import { useCallback, useState } from 'react';
import Button from '@/components/GButton';
import { selectPackagesDetailStore } from '@/modules/WorldMint/selectors';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import GenericErrorModal from '@/components/GenericErrorModal';
import useFetchEggPackages from '@/hooks/useFetchEggPackages';
import PurchaseCustomPackModal from '@/components/QuickBuyModal/PurchaseCustomPackModal';
import { selectCutomPackModalStore } from '@/modules/WorldMint/selectors';
import { packagesDetailActions } from '@/modules/WorldMint/store';
import PurchaseStatusModal from '@/components/QuickBuyModal/PurchaseStatusModal';

const WorldMintMobile = () => {
  const dispatch = useDispatch();
  useFetchEggPackages();
  const formatter = '0,0[.]00';
  const customPackModal = useSelector(selectCutomPackModalStore);
  const { packages } = useSelector(selectPackagesDetailStore);
  const [onPurchaseResultOpen, setPurchaseResultOpen] =
    useState<boolean>(false);
  const [isPurchaseSuccess, setPurchaseStatus] = useState<boolean | undefined>(
    undefined,
  );

  const onClickMintHandler = useCallback(() => {
    dispatch(packagesDetailActions.showCustomPackModal());
  }, [dispatch]);
  const onClosePurchaseCustomModal = useCallback(() => {
    dispatch(packagesDetailActions.hideCustomPackModal());
  }, [dispatch]);
  const onConfirmPurchasePackage = useCallback(async () => {
    setPurchaseResultOpen(true);
  }, []);
  const onClosePurchaseStatusModal = useCallback(() => {
    setPurchaseResultOpen(false);

    if (customPackModal.open) {
      onClosePurchaseCustomModal();
    }
  }, [customPackModal, onClosePurchaseCustomModal]);

  return (
    <div>
      <Button className="btn--open-mint-modal" onClick={onClickMintHandler}>
        <span>MINT EGG</span>
      </Button>
      <PurchaseCustomPackModal
        formatter={formatter}
        packages={packages}
        open={customPackModal.open}
        onClose={onClosePurchaseCustomModal}
        onConfirm={onConfirmPurchasePackage}
        onSetPurchaseStatus={setPurchaseStatus}
      />
      <PurchaseStatusModal
        open={onPurchaseResultOpen}
        onClose={onClosePurchaseStatusModal}
        isSuccess={isPurchaseSuccess}
        isCustomPack
      />
      <GenericErrorModal />
    </div>
  );
};

export default WorldMintMobile;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Heading } from '@/components/Typography';
import MasterLayoutBody from '@/components/Layout/MasterLayoutBody';
import dragonAnatto from '@/assets/images/dragon-lua 1.png';
import dragonZephyr from '@/assets/images/dragon-loi 1.png';
import iconSword from '@/assets/images/icon--sword.svg';
import Chapters from '@/components/Chapters';
import imagePartOfTheStory from '@/assets/images/story--story-part.webp';
import imageWhatHappensNext from '@/assets/images/story--next.webp';

const StoryPage = () => {
  return (
    <MasterLayoutBody isRegularPage>
      <Container className="storypage--block storypage--block-1">
        <Row>
          <Col md={12}>
            <Chapters />
          </Col>
        </Row>
      </Container>
      <Container className="storypage--block storypage--block-2">
        <span className="icon-square pos--top-left" />
        <span className="icon-square pos--bottom-right" />
        <Row>
          <Col md={12}>
            <div className="story--part-of-the-story">
              <div className="story-block--heading">
                <Heading h={2}>Be part of the story</Heading>
              </div>
              <div className="story-block--body">
                <div className="story-block--image-column">
                  <img src={imagePartOfTheStory} alt="WHAT HAPPENS NEXT?" />
                </div>
                <div className="story-block--content">
                  <div>
                    <p>
                      Be a part of Woodland by choosing your favorite side now!{' '}
                    </p>
                    <p>Osirase or Athetic, which side you want to be?</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="story--choose-your-side">
              <div className="story-block--heading">
                <Heading h={2}>CHOOSE YOUR SIDE!</Heading>
              </div>
              <div className="story--dragon-boxes">
                <div className="dragon-card--box box-1">
                  <div className="dragon-card--image-wrapper">
                    <div className="dragon-card--image">
                      <img
                        src={dragonAnatto}
                        alt="Dragon Osirase"
                        className="dragon-card--img"
                      />
                    </div>
                    <Heading h={2}>Osirase</Heading>
                  </div>
                  <div className="dragon-card--content">
                    <p>
                      The remain supreme purebred dragons living under the
                      embrace of Selvyn tree. After the momentous purge, these
                      proved themselves to possess the ancestors' purest
                      strength and power. The long-lasting life of Selvyn and
                      the Woodland's peace lie on these dragons' hands.
                    </p>
                  </div>
                </div>
                <div className="dragon-card--box box-2">
                  <div className="dragon-card--sword-icon">
                    <img src={iconSword} alt="CHOOSE YOUR SIDE!" />
                  </div>
                </div>
                <div className="dragon-card--box box-3 reverse">
                  <div className="dragon-card--image-wrapper">
                    <div className="dragon-card--image">
                      <img
                        src={dragonZephyr}
                        alt="Dragon Athetic"
                        className="dragon-card--img"
                      />
                    </div>
                    <Heading h={2}>Athetic</Heading>
                  </div>
                  <div className="dragon-card--content">
                    <p>
                      Rulers of the West (Zephyr), the pacifist dragons chose
                      death over bloodshed of their tribes and flee to the
                      Zephyr cursed land.The harshness of the West has
                      transformed these creatures into more powerful ones. The
                      only thing that remains is a deep-rooted annihilation
                      hatred against the Osirases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="storypage--block storypage--block-3">
        <Row>
          <Col md={12}>
            <div className="story--what-happens-next">
              <div className="story-block--heading d-block d-sm-none">
                <Heading h={2}>WHAT HAPPENS NEXT?</Heading>
              </div>
              <div className="story-block--body">
                <div className="story-block--image-column">
                  <img src={imageWhatHappensNext} alt="WHAT HAPPENS NEXT?" />
                </div>
                <div className="story-block--content">
                  <div>
                    <Heading className="d-none d-sm-block" h={2}>
                      WHAT HAPPENS NEXT?
                    </Heading>
                    <p>
                      The secret behind the decline of Selvyn has yet to be
                      revealed. Who is the mastermind behind it? Is there any
                      terrible truth that we do not know? What coming up next?
                      This mystery will be revealed in the next chapter of
                      EggForce.
                    </p>
                    <p>
                      Chapter 2 of EggForce is expected to be released in
                      Q1/2024. Follow our social channel for the next update!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </MasterLayoutBody>
  );
};

export default StoryPage;

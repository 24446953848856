import { CLKey, CLPublicKey, RuntimeArgs } from 'casper-js-sdk';
import { BurnHammerDetailsSpec } from '@/types/EggActions';
import { getTokenIDsCLValue } from './casperServiceTS';
import contracService from './contractServices';
import CONFIG from '@/constants/settings';

const buildBurnDeploy = (
  // baseAccount: CLPublicKey,
  args: {
    owner: CLPublicKey;
    token_ids: string[];
  },
  paymentAmount: number,
  contract: string,
) => {
  if (!args?.token_ids?.length) {
    return;
  }

  const runtimeArgs = RuntimeArgs.fromMap({
    owner: new CLKey(args.owner),
    token_ids: getTokenIDsCLValue(args.token_ids),
  });

  // Set contract before calling callEntryPoint
  contracService.contract.setContractHash(`hash-${contract}`);

  // Build Transfer deploy
  const deploy = contracService.contract.callEntrypoint(
    'burn',
    runtimeArgs,
    args.owner,
    CONFIG.CHAIN_NAME,
    String(paymentAmount),
  );

  return deploy;
};

export const getBurnHammerDeploy = ({
  tokenIds,
  owner,
  fee,
  contract,
}: BurnHammerDetailsSpec) => {
  try {
    return buildBurnDeploy(
      {
        owner: CLPublicKey.fromHex(owner),
        token_ids: tokenIds,
      },
      fee,
      contract,
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get Burn Hammer deploy.`);
  }
};

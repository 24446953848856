import { useCallback } from 'react';
import Balancer from 'react-wrap-balancer';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import Container from 'react-bootstrap/Container';
import Button from '@/components/GButton';
import DragonSectionWrapper from '../DragonSectionWrapper';
import DragonHeaderPrimary from '../DragonHeaderPrimary';
import DragonsBenefits from '../DragonsBenefits';
import iconStep1 from '@/assets/images/journey-step--1.png';
import iconStep2 from '@/assets/images/journey-step--2.png';
import iconStep3 from '@/assets/images/journey-step--3.png';
import iconStep4 from '@/assets/images/journey-step--4.png';
import iconStep5 from '@/assets/images/journey-step--5.png';
import iconStep6 from '@/assets/images/journey-step--6.png';
import DragonIntroduction from '../DragonIntroduction';
import MockCSPRCalculatorModal from '../DragonDemoGameplay/MockCSPRCalculatorModal';
import JourneyStep from './JourneyStep';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';
import { Heading } from '@/components/Typography';
import EggForceMockGameplay from '../EggForceMockGameplay';

const DragonJourney = () => {
  const dispatch = useDispatch();
  const onClickHandler = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.showCSPRCalculatorModal());
    //   const element = document.querySelector('#cspr-calculator') as HTMLElement;
    //   if (element) {
    //     element.scrollIntoView({
    //       behavior: 'smooth',
    //     });
  }, [dispatch]);
  return (
    <DragonSectionWrapper name="journey">
      <div className="gradient--top"></div>
      <div className="big-heading">
        <DragonHeaderPrimary heading="">
          <>
            <Heading className="heading-primary text-gradient text-center mb-2">
              WELCOME TO THE EGGFORCE JOURNEY
            </Heading>
            <Heading
              h={2}
              className="heading-primary text-gradient text-center mb-2"
            >
              FROM EGG NFTS TO DRAGON NFTS
            </Heading>
            <Balancer className="body-content">
              Follow us to explore how to start your journey in the mythical
              Callista World
            </Balancer>
          </>
        </DragonHeaderPrimary>
      </div>
      <Container>
        <div className="journey-steps--wrapper">
          <JourneyStep
            icon={iconStep1}
            step="1"
            subtitle="Hold CSPR in your Casper Network wallet"
          >
            <p>
              Earn interest on your CSPR <strong>(11% APY)</strong>
            </p>
            <Button size="small" onClick={onClickHandler}>
              Calculate earning
            </Button>
          </JourneyStep>
          <JourneyStep icon={iconStep2} step="2" subtitle="Join EggForceNFT">
            <p>Mint egg(s) via our website, CSPR.Market or affiliate program</p>
          </JourneyStep>
          <JourneyStep icon={iconStep3} step="3" subtitle="Hatch your egg(s)">
            <p>
              Hatch your egg(s) by staking your CSPR with your preferred
              Landlord Validators
            </p>
          </JourneyStep>
          <JourneyStep
            icon={iconStep4}
            step="4"
            subtitle="Earn double rewards every 2 hours"
          >
            <ul>
              <li>Earn CSPR staking rewards</li>
              <li>Earn SNC - EggForceNFT's currency</li>
            </ul>
          </JourneyStep>
          <JourneyStep icon={iconStep5} step="5" subtitle="Use SNC">
            <ul>
              <li>Swap to XP to level up your egg(s)</li>
              <li>Buy utility items and cosmetics</li>
              <li>Swap SNC to CSPR and other tokens</li>
            </ul>
          </JourneyStep>
          <JourneyStep
            icon={iconStep6}
            step="6"
            subtitle="Access to Dragon Combat"
          >
            <p>Play with your NFTs in our Trading Card Game</p>
          </JourneyStep>
        </div>
        <div className="body">
          <DragonsBenefits />
          <DragonIntroduction />
        </div>
      </Container>
      <div className="mock--virtual-wrapper">
        <EggForceMockGameplay />
      </div>
      <MockCSPRCalculatorModal />
    </DragonSectionWrapper>
  );
};

export default DragonJourney;

import React, { useMemo } from 'react';
import Balancer from 'react-wrap-balancer';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ConfirmModal from '@/components/ConfirmModal';
import InputCheckbox from '@/components/GInputCheckbox';
import {
  TextUnhatch,
  TextUndelegate,
} from '@/components/QASupportModal/QAHatchingButtonsGroup';
import useSiteConfigurations from '@/hooks/useSiteConfigurations';
import NetworkFeePanel from '@/components/NetworkFeePanel';

const ConfirmUnhatchModal = ({
  shouldUndelegate,
  open,
  onHide,
  onConfirm,
  onCheckboxChange,
  disableConfirm = false,
}: any) => {
  const siteConfigs = useSiteConfigurations();
  const confirmMessage = useMemo(() => {
    return shouldUndelegate ? (
      <span>
        Are you sure you want to <strong>stop incubating current egg</strong>{' '}
        and <strong>undelegate CSPR staking amount</strong>?
      </span>
    ) : (
      <span>
        Are you sure you want to <strong>stop incubating current egg</strong>?
      </span>
    );
  }, [shouldUndelegate]);

  if (!open) {
    return null;
  }

  return (
    <ConfirmModal
      backdrop="static"
      keyboard={false}
      modalClassname="confirm-unhatch-undelegate-modal"
      open={open}
      showIcon={false}
      onHide={onHide}
      onConfirm={onConfirm}
      disableConfirm={disableConfirm}
      disableCancel={disableConfirm}
    >
      <Balancer ratio={0}>{confirmMessage}</Balancer>
      <div className="content">
        <OverlayTrigger
          trigger={['hover', 'click', 'focus']}
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip--confirm-unhatch-undelegate`}>
              <React.Fragment>
                <TextUnhatch />
                <TextUndelegate />
              </React.Fragment>
            </Tooltip>
          }
        >
          <div>
            <InputCheckbox
              label="Undelegate my CSPR"
              isControlled
              value={shouldUndelegate}
              className="checkbox--undelegate"
              onChange={onCheckboxChange}
            />
          </div>
        </OverlayTrigger>
        {shouldUndelegate && (
          <div className="compact-modal--network-fee-wrapper">
            <NetworkFeePanel fee={siteConfigs.STOP_ONLY_FEE!} />
          </div>
        )}
      </div>
    </ConfirmModal>
  );
};

export default ConfirmUnhatchModal;

import React, { useCallback, useState, useMemo } from 'react';
import { ArrowClockwise } from 'react-bootstrap-icons';
import CrackManual from './CrackManual';
// import CrackAuto from './CrackAuto';
import Hammer from './Hammer';
import Reward from './Reward';
import Egg from './Egg';
import ButtonMint from './ButtonMint';
import Button from '@/components/GButton';
import CongratsOverlay from "./CongratsOverlay";

type TEggCrackerProps = {
  onReset: () => void,
  renderReward: React.ReactNode,
  rewardProps?: {
    className?: string,
    animationType?: string
  }
}

const EggCracker = ({ onReset, renderReward, rewardProps }: TEggCrackerProps) => {
  const DURATION_HAMMER_HIT = 750;
  const DURATION_HAMMER_AUTO = 6500;

  const [currentStep, updateStep] = useState(0);
  const [isRewardInview, setRewardInview] = useState(false);
  const [hit, setAction] = useState(false);
  const shouldMoveNext = useMemo(() => currentStep < 2, [currentStep]);

  const onNextStep = useCallback(() => {
    updateStep(currentStep + 1);
  }, [currentStep]);
  const onMintHandler = useCallback(() => {
    if (!shouldMoveNext) {
      return;
    }

    setAction(!hit);
    onNextStep();
  }, [hit, onNextStep, shouldMoveNext]);

  return (
    <div>
      <div className="egg-cracker--root">
        <CongratsOverlay hit={hit} shouldMoveNext={shouldMoveNext} />
        <CrackManual
          isRewardInview={isRewardInview}
          currentStep={currentStep}
        />
        {/* <CrackAuto hit={hit} /> */}
        <Egg hit={hit} isRewardInview={isRewardInview} />
        <Hammer
          isRewardInview={isRewardInview}
          hit={hit}
          currentStep={currentStep}
          durationAuto={DURATION_HAMMER_AUTO}
          durationManual={DURATION_HAMMER_HIT}
          onNextStep={onNextStep}
        />
        <Reward
          animationType={rewardProps?.animationType}
          onReset={onReset}
          hit={hit}
          delay={DURATION_HAMMER_HIT * 1.5}
          setRewardInview={setRewardInview}
          isRewardInview={isRewardInview}
          isVisible={!shouldMoveNext}
          className={rewardProps?.className}
        >
          {renderReward}
        </Reward>
        {/* <img src={imgEggCracked} /> */}
        <ButtonMint hit={hit} onMint={onMintHandler} />
      </div>
      {currentStep === 2 && (
        <Button
          size="small"
          className="btn-mint--reset"
          onClick={() => {
            const p = new Promise((res) => {
              setAction(false);
              updateStep(0);
              setRewardInview(false);
              res(true);
            });

            p.then(() => {
              onReset();
            });
          }}
        >
          <ArrowClockwise />
        </Button>
      )}
    </div>
  );
};

export default EggCracker;

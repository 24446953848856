import { Heading } from '@/components/Typography';
import {
  useAppDispatch as useDispatch,
  // useAppSelector as useSelector,
} from '@/app/hooks';
import iconTreeSelvyn from '@/assets/images/icon--treeSelvyn.webp';

import {
  EggForceJourneyDemoModuleActions,
  // GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import Button from '@/components/GButton';

const DragonGuideStep6 = () => {
  const dispatch = useDispatch();
  // const store = useSelector(selectEggForceJourneyModule);
  return (
    <div className="dragon-guide--content step-6">
      <div className="dragon-guide--step">
        <img src={iconTreeSelvyn} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Congratulations!</Heading>
        <p>
          Hopefully the journey enlightend the Callista world experience,
          there's still much more to it. Click <strong>the Selvyn icon</strong>{' '}
          and it'll guide you there. <br />
          Happy incubating,
        </p>
        <Button
          btnStyle="5"
          size="small"
          className="btn--accept"
          onClick={(e) => {
            e.preventDefault();
            dispatch(EggForceJourneyDemoModuleActions.enableFinishedState());
            dispatch(
              EggForceJourneyDemoModuleActions.showLuckyNumberFormModal(),
            );
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default DragonGuideStep6;

import {
  RuntimeArgs,
  CLValue,
  CLValueBuilder,
  CLPublicKey,
  CLAccountHash,
} from 'casper-js-sdk';
import CONFIG from '@/constants/settings';
import {
  getOptionalKey,
  getCLOptionalAccountHash,
} from '@/modules/CasperSigner/helpers';
import contracService from './contractServices';
import type { TokenID } from '@/types/generic';
/**
 *
 * @param {*} args
 * @returns
 */
interface DeployArgs {
  paymentAmount: string;
  key: string;
  // key: Keys.AsymmetricKey;
}

export interface DealerBuyArgs extends DeployArgs {
  wasm: Uint8Array;
  token: Uint8Array;
  broker: Uint8Array;
  amount: number;
  count: number;
  buy_for: any;
  ref_by?: string;
}

export interface InstallmentPaymentPayPaymentArgs extends DeployArgs {
  wasm: Uint8Array;
  installment_payment: Uint8Array;
  id: number;
  amount: number;
}

export interface InstallmentPaymentinstallmentPaymentArgs extends DeployArgs {
  wasm: Uint8Array;
  installment_payment: Uint8Array;
  token: Uint8Array;
  count: number;
  plan_len: number;
  amount: number;
  contact: string;
}

export const buildDealerBuyDeploy = (args: DealerBuyArgs) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    broker: CLValueBuilder.byteArray(args.broker),
    token: CLValueBuilder.byteArray(args.token),
    amount: CLValueBuilder.u512(args.amount),
    count: CLValueBuilder.u64(args.count),
    buy_for: getOptionalKey(args.buy_for),
    ref_by: getCLOptionalAccountHash(
      args.ref_by
        ? new CLAccountHash(CLPublicKey.fromHex(args.ref_by).toAccountHash())
        : null,
    ),
  });

  return contracService.contract.install(
    args.wasm,
    runtimeArgs,
    args.paymentAmount,
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
    // [args.key],
  );
};

// Purchase BNPL
export const buildInstallmentPaymentDeploy = (
  args: InstallmentPaymentinstallmentPaymentArgs,
) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    installment_payment: CLValueBuilder.byteArray(args.installment_payment),
    token: CLValueBuilder.byteArray(args.token),
    count: CLValueBuilder.u32(args.count),
    plan_len: CLValueBuilder.u32(args.plan_len),
    amount: CLValueBuilder.u512(args.amount),
    contact: CLValueBuilder.string(args.contact),
  });

  return contracService.contract.install(
    args.wasm,
    runtimeArgs,
    args.paymentAmount,
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
    // [args.key],
  );
};

// Pay BNPL
export const buildPayInstallmentPaymentDeploy = (
  args: InstallmentPaymentPayPaymentArgs,
) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    installment_payment: CLValueBuilder.byteArray(args.installment_payment),
    id: CLValueBuilder.u256(args.id),
    amount: CLValueBuilder.u512(args.amount),
  });

  const deploy = contracService.contract.install(
    args.wasm,
    runtimeArgs,
    String(args.paymentAmount),
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
    // [args.key]
  );

  return deploy;
};

// Refund BNPL
export const buildRefundInstallmentPaymentDeploy = (args: any) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    id: CLValueBuilder.u256(args.id),
  });

  // Set contract before calling callEntryPoint
  contracService.contract.setContractHash(`hash-${args.contract}`);

  // Build Cancel deploy
  const deploy = contracService.contract.callEntrypoint(
    'cancel',
    runtimeArgs,
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
    String(args.paymentAmount),
    // [args.key],
  );

  return deploy;
};

export function getTokenIDsCLValue(ids: TokenID[]): CLValue {
  let token_ids = null;
  const first_id = String(ids[0]);

  // eslint-disable-next-line
  if (String(parseInt(first_id)) == first_id) {
    token_ids = CLValueBuilder.list(ids.map((x) => CLValueBuilder.u256(x)));
  } else {
    token_ids = CLValueBuilder.list(
      ids.map((x) => CLValueBuilder.string(String(x))),
    );
  }

  return token_ids;
}

// Merge Deploy
export const buildMergeTokensDeploy = (args: any) => {
  if (!args?.mergedTokenIds?.length) {
    return;
  }

  const runtimeArgs = RuntimeArgs.fromMap({
    token_ids: getTokenIDsCLValue(args.mergedTokenIds),
  });

  // Set contract before calling callEntryPoint
  contracService.contract.setContractHash(`hash-${args.contract}`);

  // Build Merge deploy
  const deploy = contracService.contract.callEntrypoint(
    'merge',
    runtimeArgs,
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
    String(args.paymentAmount),
  );

  return deploy;
};

interface DragonDropArgs extends DeployArgs {
  tokenId: string;
  wasm: Uint8Array;
  token: Uint8Array;
  broker: Uint8Array;
}

// Drop Dragon deploy
export const buildDragonDropDeploy = (args: DragonDropArgs) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    token_id: CLValueBuilder.u256(args.tokenId),
    egg_token: CLValueBuilder.byteArray(args.token),
    dragon_broker: CLValueBuilder.byteArray(args.broker),
  });

  return contracService.contract.install(
    args.wasm,
    runtimeArgs,
    args.paymentAmount,
    CLPublicKey.fromHex(args.key),
    CONFIG.CHAIN_NAME,
  );
};

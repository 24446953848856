import { useMemo, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import { Heading } from '@/components/Typography';
import GModal from '@/components/GModal';
import { NFTEggStatus, NFTTypeEnum, type NFTItem } from '@/types/NFTItem';
import MockGridAsEgg from './MockGridAsEgg';
import Button from '@/components/GButton';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import { EGG_LEVEL_NAME, getNFTDetails } from '@/modules/NFTDetail/utils';
import {
  updateFutureDate,
  updateDelegateRewards,
} from '@/modules/EggForceJourneyDemoModule/actions';
import MockGridAsDragon from './MockGridAsDragon';
import MockProfileMenu from './MockProfileMenu';
import DragonGuideStep5 from './DragonGuideStep5';
import { DragonGuideStep4b } from './DragonGuideStep4';

const MockNFTList = ({ dataTimeline }: any) => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { snc, hasDragon, shouldAccumulateSNC, nftsModal, currentGuideStep } =
    store;
  const { data, open } = nftsModal;

  const hasOnePlatinumEgg = useMemo(() => {
    if (currentGuideStep === GuideStep.wait) {
      let result = false;
      const eggs = data.filter(
        (one) => one?.egg?.status === NFTEggStatus.incubating,
      );
      eggs.forEach((egg) => {
        const { level } = getNFTDetails(egg);
        if (level.value === EGG_LEVEL_NAME.PLATINUM) {
          result = true;
        }
      });
      return result;
    }
    return false;
  }, [currentGuideStep, data]);

  const onClose = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideNFTListModal());
  }, [dispatch]);

  const onClickNextDays = async () => {
    if (currentGuideStep === GuideStep.step5) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.wait));
    }
    await dispatch(
      updateFutureDate({
        period: 7,
      }),
    );
    dispatch(
      updateDelegateRewards({
        period: dataTimeline.diff,
      }),
    );
  };

  useEffect(() => {
    if (currentGuideStep === GuideStep.wait && hasOnePlatinumEgg) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step3c));
    }
  }, [currentGuideStep, dispatch, hasOnePlatinumEgg]);

  useEffect(() => {
    if (hasDragon && !shouldAccumulateSNC) {
      const incubatingEggs = data.filter(
        (item) => item?.egg?.status === NFTEggStatus.incubating,
      );
      if (snc > 0 || incubatingEggs.length) {
        dispatch(
          EggForceJourneyDemoModuleActions.updateShouldAccumulateSNC(true),
        );
      }
    }
  }, [data, dispatch, hasDragon, shouldAccumulateSNC, snc]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      backdrop="static"
      keyboard={false}
      onHide={onClose}
      className="modal--egg-collections modal--height-90h-x"
    >
      {currentGuideStep === GuideStep.step4b && <DragonGuideStep4b />}
      <Heading h={2} className="modal--heading text-center">
        NFT Collection
      </Heading>
      <div className="mock--header-section">
        <span className="mock--timeline">
          {dataTimeline.futureDate
            ? dataTimeline.combineDate
            : dataTimeline.currentDate}
        </span>
        <MockProfileMenu />
      </div>
      <Container fluid className="nft-grid--container">
        <div className="nft-grid--wrapper">
          {data.map((item: NFTItem, index: number) => (
            <div key={item.tokenId} className="column">
              {item.name === NFTTypeEnum.DRAGON ? (
                <MockGridAsDragon
                  className={`grid--${index}`}
                  data={item}
                  order={index}
                />
              ) : (
                <MockGridAsEgg
                  className={`grid--${index}`}
                  data={item}
                  order={index}
                />
              )}
            </div>
          ))}
        </div>
      </Container>
      <div className="mock--guide-next-days">
        <div className="wrapper">
          <DragonGuideStep5 />
          <Button
            className="btn--next-7days"
            // size="small"
            // ignoreShadowStyle
            onClick={onClickNextDays}
          >
            Next 7 days
          </Button>
        </div>
      </div>
    </GModal>
  );
};

export default MockNFTList;

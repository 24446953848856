import { Heading } from '@/components/Typography';

interface IProps {
  title: string;
  children: React.ReactNode,
}

const AnnoncementBlock = ({ title, children } : IProps) => {
  return (
    <div className="announcenents-block">
      <Heading h={3} className="text-gradient mb-1">
        {title}
      </Heading>
      <div className="modal-announcenents-content">
        {children}
      </div>
    </div>
  )
};

export default AnnoncementBlock;

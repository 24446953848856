import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children?: ReactNode;
  className?: string | Object;
  src: string;
  alt?: string;
  pos?: 'left' | 'right' | 'center' | 'full';
}

const StoryImage = ({ src, alt, pos = 'center' }: Props) => {
  return (
    <img
      className={cn('story-thumb--image', {
        left: pos === 'left',
        right: pos === 'right',
        center: pos === 'center',
        full: pos === 'full',
      })}
      src={src}
      alt={alt}
    />
  );
};

export default StoryImage;

import qs from 'qs';
import { IAuthState } from '@/modules/Auth/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@/app/store';
import { axios } from '@/services/axios';
import { executeErrorMessage } from '@/services/errorsWrapper';
import API from '@/constants/api';
import { LeaderboardTabEnum, LeaderboardSpec } from '@/types/leaderboard';
import configs from '@/constants/settings';
import { leaderboardActions } from './store';

export const loadTopValidatorsLeaderboard = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch;
  }
>('leaderboard/loadTopValidatorsLeaderboard', async (_, { dispatch }) => {
  const response = await axios.get(API.statsTopValidators);
  if (response?.status === 200) {
    return response.data;
  }

  return undefined;
});

export const loadTopEggsLeaderboard = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch;
  }
>('leaderboard/loadTopEggsLeaderboard', async (_, { dispatch }) => {
  const query = qs.stringify({
    by: 'stakedAmount',
    top: String(configs.LEADERBOARD_TOP_EGGS),
  });
  const response = await axios.get(API.statsTopEggs(query));

  if (response?.status === 200) {
    return response.data;
  }

  return undefined;
});

export const loadTopIncubatorsLeaderboard = createAsyncThunk<
  unknown,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>('leaderboard/loadTopIncubatorsLeaderboard', async (_, { getState }) => {
  const state: { auth: IAuthState } = getState();
  const {
    auth: { currentUser },
  } = state;
  const query = qs.stringify({
    ...(currentUser && { publicKey: currentUser.activeKey }),
    top: String(configs.LEADERBOARD_TOP_HATCHERS),
  });
  const response = await axios.get(API.statsTopIncubators(query));
  if (response?.status === 200) {
    return response.data;
  }

  return undefined;
});

const clearLeaderboardData = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch;
  }
>('leaderboard/clearLeaderboardData', async (_, { dispatch }) => {
  dispatch(leaderboardActions.setData([]));
});

export const loadLeaderboardData = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('leaderboard/loadLeaderboardData', async (_, { getState, dispatch }) => {
  try {
    dispatch(clearLeaderboardData());
    dispatch(leaderboardActions.setLoading(true));
    const state: { leaderboardModule: LeaderboardSpec } = getState();
    const {
      leaderboardModule: { currentTab },
    } = state;

    let data;
    switch (currentTab) {
      case LeaderboardTabEnum.EGGS: {
        const result: any = await dispatch(loadTopEggsLeaderboard()).unwrap();
        data = result?.eggs ?? [];
        break;
      }
      case LeaderboardTabEnum.INCUBATORS: {
        const result: any = await dispatch(
          loadTopIncubatorsLeaderboard(),
        ).unwrap();

        data =
          [
            result?.incubators,
            result?.rank,
            result?.total,
            result?.reportDate,
          ] ?? [];
        break;
      }
      case LeaderboardTabEnum.VALIDATORS: {
        const result: any = await dispatch(
          loadTopValidatorsLeaderboard(),
        ).unwrap();
        data = result?.validators ?? [];
        break;
      }
    }

    dispatch(leaderboardActions.setData(data));
    dispatch(leaderboardActions.setLoading(false));
  } catch (error: any) {
    executeErrorMessage(error);
    dispatch(leaderboardActions.setLoading(false));
    return;
  }
});

import { useMemo, useCallback } from 'react';
import GModal from '@/components/GModal';
import { Check2Circle, Boxes, InfoCircle } from 'react-bootstrap-icons';
import GNotificationModal from '@/components/GNotificationModal';
import { Heading } from '@/components/Typography';
import { selectInstallmentPaymentsStatusModal } from '@/modules/InstallmentsPayment/selectors';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import CommonMessage from '@/components/CommonMessages';

const InstallmentPaymentStatusModal = () => {
  const dispatch = useDispatch();
  const paymentReviewModal = useSelector(selectInstallmentPaymentsStatusModal);
  const { open } = paymentReviewModal;
  const isSuccess = false;
  const onCloseHandler = useCallback(() => {
    dispatch(InstallmentsPaymentActions.hideStatusModal());
  }, [dispatch]);
  const { title, icon, msg } = useMemo(() => {
    return {
      msg: !isSuccess ? CommonMessage.Failed : CommonMessage.Success,
      title: !isSuccess
        ? `Purchase wasn't completed`
        : `Successfully purchased!`,
      icon: () => (!isSuccess ? <Boxes /> : <Check2Circle />),
    };
  }, [isSuccess]);

  return (
    <GModal
      closeButton={false}
      blurOverlay
      show={open}
      className={'purchase-result'}
    >
      <GNotificationModal
        labelConfirm={'I understand'}
        className="quickbuy-package--purchase-status"
        onClose={onCloseHandler}
        icon={icon()}
      >
        <Heading h={4}>{title}</Heading>
        <p className="helper">
          <InfoCircle className="icon--helper" />
          {msg}
        </p>
      </GNotificationModal>
    </GModal>
  );
};

export default InstallmentPaymentStatusModal;

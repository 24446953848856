import { InfoCircle } from 'react-bootstrap-icons';
import Button from '@/components/GButton';
import { Heading } from '@/components/Typography';
import CompactModal from '@/components/CompactModal';

interface IProps {
  onClose: () => void;
  open: boolean;
  result: string;
  className?: string;
}

const CardRewardModal = (props: IProps) => {
  const { onClose, open, result } = props;
  return (
    <CompactModal
      open={open}
      onHide={onClose}
      showIcon={true}
      closeButton={true}
    >
      <Heading h={4}>Your Lucky Prize</Heading>
      <h3>{result}</h3>
      <p className="helper">
        <InfoCircle className="icon--helper" />
        Share your Lucky Prize on Twitter and join our Discord to claim your
        luck
      </p>
      <Button
        asLink
        className="btn--action"
        size="small"
        href="https://discord.com/invite/vCURDNzjYc"
        onClick={onClose}
      >
        Visit Discord Community
      </Button>
    </CompactModal>
  );
};

export default CardRewardModal;

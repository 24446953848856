import {
  DeployUtil,
  RuntimeArgs,
  CLPublicKey,
  CLValueBuilder,
} from 'casper-js-sdk';
import { NETWORK_NAME, ENTRY_POINT_DELEGATE } from '@/constants/key';
import { csprToMote } from '@/helpers/balance';
import { StakeDetailsSpec, ClaimDetailsSpec } from '@/types/EggActions';

/**
 * It creates a deploy that deploys the auction contract.
 * @param baseAccount - The account that will pay for the deploy.
 * @param entryPoint - The name of the function to be called.
 * @param args - The arguments to pass to the entry point.
 * @param paymentAmount - The amount of tokens to send to the contract.
 * @returns The deploy object.
 */
const buildStakeDeploy = (
  baseAccount: CLPublicKey,
  entryPoint: string,
  args: any,
  paymentAmount: number,
  auctionHash: string,
) => {
  const deployParams = new DeployUtil.DeployParams(baseAccount, NETWORK_NAME);
  const runTimeArgs = RuntimeArgs.fromMap(args);
  const AUCTION_HASH = Uint8Array.from(Buffer.from(auctionHash, 'hex'));
  const session = DeployUtil.ExecutableDeployItem.newStoredContractByHash(
    AUCTION_HASH,
    entryPoint,
    runTimeArgs,
  );
  const payment = DeployUtil.standardPayment(paymentAmount);
  return DeployUtil.makeDeploy(deployParams, session, payment);
};

/**
 * It builds a StakeDeploy transaction with the given parameters
 * @returns The `StakeDeploy` transaction.
 */
export const getStakeDeploy = ({
  fromAddress,
  validator,
  fee,
  amount,
  entryPoint = ENTRY_POINT_DELEGATE,
  auctionHash,
}: StakeDetailsSpec) => {
  try {
    const fromAccPk = CLPublicKey.fromHex(fromAddress);
    const validatorPk = CLPublicKey.fromHex(validator);
    return buildStakeDeploy(
      fromAccPk,
      entryPoint,
      {
        delegator: fromAccPk,
        validator: validatorPk,
        amount: CLValueBuilder.u512(csprToMote(amount)),
      },
      csprToMote(fee),
      auctionHash,
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get stake deploy.`);
  }
};

const buildClaimDeploy = (
  baseAccount: CLPublicKey,
  args: any,
  paymentAmount: number,
) => {
  const deployParams = new DeployUtil.DeployParams(baseAccount, NETWORK_NAME);
  const transferId = 0;
  const session = DeployUtil.ExecutableDeployItem.newTransfer(
    args.amount,
    args.toAccount,
    null,
    transferId,
  );
  const payment = DeployUtil.standardPayment(paymentAmount);
  return DeployUtil.makeDeploy(deployParams, session, payment);
};

export const getClaimDeploy = ({
  amount,
  fromAddress,
  toAddress,
  fee,
}: ClaimDetailsSpec) => {
  try {
    const fromAccPk = CLPublicKey.fromHex(fromAddress);
    const toAccPk = CLPublicKey.fromHex(toAddress);

    return buildClaimDeploy(
      fromAccPk,
      {
        toAccount: toAccPk,
        amount,
      },
      fee,
    );
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to get Claim/Transfer deploy.`);
  }
};

import { useMemo } from 'react';
import { selectEggPurchaseStore } from '@/modules/EggPurchase/selectors';
import { DeployStatus } from '@/types/deploy';
import { useAppSelector as useSelector } from '@/app/hooks';
import { isDeployTooOld } from '@/helpers/deploy';

const useBlockMinting = ({
  isDeploying = false,
}: {
  isDeploying?: boolean;
}) => {
  const eggPurchasesStore = useSelector(selectEggPurchaseStore);
  const { deployHash } = eggPurchasesStore;
  const latestDeploy = deployHash.at(0) ?? undefined;

  const shouldDisable = useMemo(() => {
    if (
      isDeploying ||
      (latestDeploy &&
        !isDeployTooOld(latestDeploy.createdAt, 1) &&
        latestDeploy.status === DeployStatus.PENDING &&
        latestDeploy?.metadata?.action === 'mint')
    ) {
      return true;
    }

    return false;
  }, [isDeploying, latestDeploy]);

  return {
    shouldDisable,
  };
};

export default useBlockMinting;

import { useMemo, useCallback } from 'react';
import GModal from '@/components/GModal';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import SocialSharingBlock from '@/components/SocialSharingBlock';
import configs from '@/constants/settings';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { Heading } from '@/components/Typography';
import Button from '@/components/GButton';
import { selectCustomEventModal } from '@/modules/CustomEventModule/selectors';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
import { selectAuthPermissions } from '@/modules/Auth/selectors';
import { packagesDetailActions } from '@/modules/WorldMint/store';
import SocialDiscordButton from '@/components/SocialDiscordButton';
import Balancer from 'react-wrap-balancer';
import addSeconds from 'date-fns/addSeconds';

interface IProps {
  onClickPrimaryAction?: () => void;
  className?: string;
}

const CountdownSample = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  return (
    <div className="happy-hour-countdown">
      <ul id="countdown" className="happy-hour-countdown--wrapper">
        <li id="days" className="block">
          <div className="number">
            <div className="value">{completed ? 0 : days}</div>
          </div>
          <div className="label">Days</div>
        </li>
        <li id="hours" className="block">
          <div className="number">
            <div className="value">{completed ? 0 : hours}</div>
          </div>
          <div className="label">Hours</div>
        </li>
        <li id="minutes" className="block">
          <div className="number">
            <div className="value">{completed ? 0 : minutes}</div>
          </div>
          <div className="label">Minutes</div>
        </li>
        <li id="seconds" className="block">
          <div className="number">
            <div className="value">{completed ? 0 : seconds}</div>
          </div>
          <div className="label">Seconds</div>
        </li>
      </ul>
      <span className="time-note">(Time is set as UTC Timezone)</span>
    </div>
  );
};

const WLRound = ({ onClickHandler, time }: any) => {
  const rendererMintButton = ({ completed }: CountdownRenderProps) => {
    if (completed) {
      return (
        <Button className="btn--buy-labled-egg" onClick={onClickHandler}>
          <span>MINT NOW</span>
        </Button>
      );
    }
    return (
      <div className="game-social-sharing">
        <SocialSharingBlock
          hideDiscord
          twitterProps={{
            title: `Hi #CSPR fam👋,\nI won 1 of 3000 Whitelist Tickets😎!\nWith this ticket I can get a sweet discount if I buy an @EggForceNFT during the whitelist round which starts the 17th of July.
      \nHurry, before they run out of tickets. Get yours too now`,
            via: '',
            hashtags: ['CSPR', 'NFT'],
            url: 'eggforce.io/world',
            label: 'Share My luck',
          }}
        />
      </div>
    );
  };

  return (
    <div className="content">
      <Balancer>
        <Heading className="text-gradient" h={2}>
          Congratulations!
        </Heading>
        <Heading h={3}>You are 1 of 3000 luckiest Whitelist Winners!</Heading>
        <p>
          💼 Total Supply: <strong>789 Eggs</strong>
        </p>
        <p>
          💥 Mint Price: <strong>1,895 CSPR</strong>
        </p>
        <p>
          🔒 Mint Per Wallet: <strong>5 Eggs</strong>
        </p>
        <p>
          ⏰ Mint Date: <strong>3PM (UTC) | July 17th, 2023</strong>
        </p>
        <br />
        <small>
          During the Whitelisted round, a total of <strong>789 Eggs</strong>{' '}
          will be available for purchase. After the Whitelisted round, an
          additional <strong>1,199 Eggs</strong> will be sold on the EggForce
          landing page and cspr.market. In this round, there will only ever be{' '}
          <strong>2,500</strong> Eggs in existence.
        </small>
        <br />
        <br />
        <Countdown
          renderer={rendererMintButton}
          date={configs.DEBUG_ENV ? addSeconds(new Date(), 5) : time}
        />
      </Balancer>
    </div>
  );
};

const OpenRoundInformation = () => {
  return (
    <div className="content">
      <Balancer>
        <Heading className="text-gradient" h={2}>
          Oops! You're not on the Whitelist.
        </Heading>
        <Heading h={3}>
          Don't worry, though! You can still join our EggForce Open Round.
        </Heading>
        <p>
          💼 Total Supply: <strong>600 Eggs</strong>
        </p>
        <p>
          💥 Mint Price: <strong>2,158 CSPR</strong>
        </p>
        <p>
          ⏰ Mint Date: <strong>3PM (UTC) | July 22nd, 2023</strong>
        </p>
        <br />
        <small>
          During the Whitelisted round, a total of <strong>789 Eggs</strong>{' '}
          will be available for purchase. After the Whitelisted round, an
          additional <strong>1,199 Eggs</strong> will be sold on the EggForce
          landing page and cspr.market. In this round, there will only ever be{' '}
          <strong>2,500</strong> Eggs in existence.
        </small>
        <br />
        <br />
        <div>
          <p>Meanwhile, please join our Discord to follow the latest updates</p>
          <SocialDiscordButton skipConfused compact={false} />
        </div>
      </Balancer>
    </div>
  );
};

const HappyHourModal = ({ onClickPrimaryAction }: IProps) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectAuthPermissions);
  const { open } = useSelector(selectCustomEventModal);
  const onCloseHandler = useCallback(() => {
    dispatch(CustomEventModalActions.hideModal());
  }, [dispatch]);

  const onClickHandler = useCallback(() => {
    // Claim hammer when User doesn't have one
    // if (onClickPrimaryAction && !permissions?.hasHammer) {
    //   onClickPrimaryAction();
    //   return;
    // }

    // Show Package modal as usual
    dispatch(packagesDetailActions.showQuickbuykModal());
    onCloseHandler();
  }, [dispatch, onCloseHandler]);

  const time = useMemo(() => {
    // 3PM 17/7/23
    // const times = configs.PUBLIC_WHITELISTED_TIME.split("-");
    const [year, monthFromIndex0, date, hour] =
      configs.PUBLIC_WHITELISTED_TIME.split('-').map((str) =>
        parseInt(str, 10),
      );
    return new Date(Date.UTC(year, monthFromIndex0, date, hour));
  }, []);

  // Renderer callback with condition
  const renderer = (props: CountdownRenderProps) => {
    return (
      <div>
        <CountdownSample {...props} />
      </div>
    );
  };

  return (
    <GModal
      show={open}
      backdrop="static"
      onHide={onCloseHandler}
      className={'happy-hour-modal--root'}
    >
      <div className="body">
        <Heading className="text-center text-gradient" h={3}>
          EggForce Public Round - Your Eggs Your Army
        </Heading>
        <Countdown date={time} renderer={renderer} />
        {permissions?.isWhitelistAccount ? (
          <WLRound onClickHandler={onClickHandler} time={time} />
        ) : (
          <OpenRoundInformation />
        )}
      </div>
      <div className="happy-hour--bottom-gradient"></div>
    </GModal>
  );
};

export default HappyHourModal;

import { Heading } from '@/components/Typography';
import iconTreeSelvyn from '@/assets/images/icon--treeSelvyn.webp';

const DragonGuideStep1 = () => {
  return (
    <div className="dragon-guide--content step-1">
      <div className="dragon-guide--step">
        <img src={iconTreeSelvyn} alt="Welcome" className="guide-icon step-1" />
        <Heading h={5}>Welcome</Heading>
        <p>
          Click <strong>Get Started</strong> to begin a short sample journey in
          Callista world.
        </p>
        <p>
          No worries, using our demo is free and only for educational purposes.
        </p>
      </div>
    </div>
  );
};

export default DragonGuideStep1;

import { useCallback } from 'react';
import { CLPublicKey } from 'casper-js-sdk';
import { axios, signal } from '@/services/axios';
import useCurrentUser from '@/hooks/useCurrentUser';
import useConfirmDeploy from '@/hooks/useConfirmDeploy';
import {
  buildInstallmentPaymentDeploy,
  buildDealerBuyDeploy,
} from '@/services/casperServiceTS';
import { contractToByteArray } from '@/modules/EggPurchase/utils';
import { getMintingFee } from '@/modules/WorldMint/utils';
import { log } from '@/helpers/utils';
import { DealerBuyArgs } from '@/services/casperServiceTS';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';
import { fetchWASM } from '@/services/fetchServices';
import WASM_PATHS from '@/constants/wasmPath';
import type {
  TypePurchasePackageBNPLParams,
  TypePurchasePackageParams,
} from '@/types/package';
import API from '@/constants/api';
import useSiteConfigurations from '@/hooks/useSiteConfigurations';

type DealerBuyArgsWithoutBuyFor = Omit<DealerBuyArgs, 'buy_for'>;
interface IBuildDealerBuyClient extends Partial<DealerBuyArgsWithoutBuyFor> {
  buy_for: string;
  isBuyForChecked: boolean;
  isInstallmentPayment: boolean;
  affiliateCode: string;
}

const useEggMinting = () => {
  const user = useCurrentUser();
  const fileDealerWASM = WASM_PATHS.DEALER;
  const fileInstallmentPaymentWASM = WASM_PATHS.INSTALLMENT_PAYMENT_INSTALL;
  const siteConfig = useSiteConfigurations();

  const { executeDeploy, isDeploying, isError } = useConfirmDeploy();
  const getAffiliateCodeBeforeBuying = useCallback(
    (cookieValue: string) => {
      try {
        // Skip when not found any cookie
        // or found cookie was equal to current User key
        if (
          !cookieValue ||
          !isValidPublicKey(cookieValue) ||
          cookieValue === user.activeKey
        ) {
          return undefined;
        }

        return cookieValue;
      } catch (err) {
        return undefined;
      }
    },
    [user],
  );
  const onBuildDealerBuyDeploy = useCallback(
    (data: IBuildDealerBuyClient) => async () => {
      const { amount, count, buy_for, isBuyForChecked, affiliateCode } = data;
      log(`🚀 ~ Buying ${count} with total MOTE: `, amount);

      const isInvalidRecipient = Boolean(
        isBuyForChecked &&
          typeof buy_for === 'string' &&
          buy_for.length > 0 &&
          !isValidPublicKey(buy_for),
      );

      if (
        !user ||
        !amount ||
        !count ||
        isInvalidRecipient ||
        !siteConfig?.BROKER_CONTRACT_HASH ||
        !siteConfig?.TOKEN_CONTRACT_HASH
      ) {
        return;
      }

      try {
        const brokerContractHash = siteConfig.BROKER_CONTRACT_HASH;
        const tokenContractHash = siteConfig.TOKEN_CONTRACT_HASH;
        const wasmDealer = await fetchWASM(fileDealerWASM);
        const paymentAmount = getMintingFee(count).toString();
        const ref_by = getAffiliateCodeBeforeBuying(affiliateCode);

        // Build and request to sign deploy with signer
        return buildDealerBuyDeploy({
          key: user.activeKey,
          wasm: wasmDealer,
          paymentAmount,
          broker: contractToByteArray(brokerContractHash),
          token: contractToByteArray(tokenContractHash),
          buy_for: isBuyForChecked ? CLPublicKey.fromHex(buy_for) : null,
          ref_by,
          amount: amount,
          count,
        });
      } catch (error: any) {
        console.log(`🚀 ~ onBuildDealerBuyDeploy ~ error`, error);
        throw new Error(`Failed to create dealer buy deploy.`);
      }
    },
    [
      fileDealerWASM,
      getAffiliateCodeBeforeBuying,
      siteConfig.BROKER_CONTRACT_HASH,
      siteConfig.TOKEN_CONTRACT_HASH,
      user,
    ],
  );

  const onBuildInstallmentPaymentDeploy = useCallback(
    (data: any) => async () => {
      const { amount, paymentAmount, count, plan_len, contact } = data;
      log(`🚀 ~ BNPL Buying ${count} with total MOTE: `, amount);

      if (
        !user ||
        !amount ||
        !count ||
        !siteConfig?.TOKEN_CONTRACT_HASH ||
        !siteConfig?.INSTALLMENT_CONTRACT_HASH
      ) {
        return;
      }

      try {
        const tokenContractHash = siteConfig.TOKEN_CONTRACT_HASH;
        const installmentPaymentHash = siteConfig.INSTALLMENT_CONTRACT_HASH;
        const wasmDealer = await fetchWASM(fileInstallmentPaymentWASM);

        // Build and request to sign deploy with signer
        return buildInstallmentPaymentDeploy({
          key: user.activeKey,
          wasm: wasmDealer,
          installment_payment: contractToByteArray(installmentPaymentHash),
          token: contractToByteArray(tokenContractHash),
          contact,
          plan_len,
          paymentAmount: String(paymentAmount),
          amount,
          count,
        });
      } catch (error: any) {
        console.log(`🚀 ~ onBuildInstallmentPaymentDeploy ~ error`, error);
        throw new Error(`Failed to create dealer buy deploy.`);
      }
    },
    [
      fileInstallmentPaymentWASM,
      siteConfig.INSTALLMENT_CONTRACT_HASH,
      siteConfig.TOKEN_CONTRACT_HASH,
      user,
    ],
  );

  const onBuy = useCallback(
    async (data: TypePurchasePackageParams) => {
      if (!user || !data.totalPackageValue) {
        return;
      }

      const { activeKey } = user;
      const result = await executeDeploy(
        onBuildDealerBuyDeploy({
          amount: data.totalPackageValue,
          count: data.amountEgg,
          buy_for: data.buy_for ?? null,
          isBuyForChecked: data.isBuyForChecked,
          isInstallmentPayment: data.isInstallmentPayment,
          affiliateCode: data.affiliateCode,
        }),
        activeKey,
        activeKey,
      );
      return result;
    },
    [executeDeploy, onBuildDealerBuyDeploy, user],
  );

  const encryptEmail = useCallback(
    async (email: string) =>
      await axios.post(
        API.encrypt,
        {
          email,
        },
        {
          signal,
        },
      ),
    [],
  );
  const onBuyBNPL = useCallback(
    async (data: TypePurchasePackageBNPLParams) => {
      if (!user || !data.downPaymentValue) {
        return;
      }

      // Encrypt email data
      const { data: dataEncrypted } = await encryptEmail(data.contact);
      const { result: resultEncrypted, encrypted: encrypedContact } =
        dataEncrypted;

      if (!resultEncrypted) {
        throw new Error(dataEncrypted?.message);
      }

      // Build and execute deploy
      const { activeKey } = user;
      const result = await executeDeploy(
        onBuildInstallmentPaymentDeploy({
          amount: data.downPaymentValue,
          paymentAmount: data.paymentAmount,
          count: data.amountEgg,
          plan_len: data.plan_len,
          contact: encrypedContact,
        }),
        activeKey,
        activeKey,
      );
      console.log(`🚀 ~ onBuyBNPL::result:`, result);
      return result;
    },
    [encryptEmail, executeDeploy, onBuildInstallmentPaymentDeploy, user],
  );

  return {
    buy: onBuy,
    buyBNPL: onBuyBNPL,
    isDeploying,
    isError,
  };
};

export default useEggMinting;

import React from "react";

const AnswerComponent = () => {
  return (
    <div>
      <p>Make sure Casper Signer is working properly by checking one of the following:</p>
      <ul>
        <li>Casper Signer is not being stucked by another connection request, or a Signing request being made.</li>
        <li>Casper Signer wasn't disabled by your browser. There might be a chance Casper Signer was disabled temporarily due to new permission in new release. Make sure it was enabled by checking your browser extension page.</li>
      </ul>
      <p>As a last resort, you can always try to Lock your Casper Signer and try re-login again.</p>
    </div>
  );
};

export default React.memo(AnswerComponent);

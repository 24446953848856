// import * as fs from "fs";
import { CasperClient, CLPublicKey, CasperServiceByJsonRPC } from "casper-js-sdk";
import { hexToBytes } from "@noble/hashes/utils";

export const parseTokenMeta = (str: string): Array<[string, string]> =>
  str.split(",").map((s) => {
    const map = s.split(" ");
    return [map[0], map[1]];
  });

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getDeploy = async (NODE_URL: string, deployHash: string, logErrors: boolean = true) => {
  const client = new CasperClient(NODE_URL);
  let i = 300;
  while (i !== 0) {
    const [deploy, raw] = await client.getDeploy(deployHash);
    if (raw.execution_results.length !== 0) {
      // @ts-ignore
      if (raw.execution_results[0].result.Success) {
        return deploy;
      } else {
        if (logErrors) {
          console.log(JSON.stringify(raw, (key, value) => {
            if (key === "module_bytes") {
              value = "__DATA__";
            }
            return value;
          }, 2));
        }
        // @ts-ignore
        throw Error(
          "Contract execution: " +
            // @ts-ignore
            raw.execution_results[0].result.Failure.error_message
        );
      }
    } else {
      i--;
      await sleep(1000);
      continue;
    }
  }
  throw Error("Timeout after " + i + "s. Something's wrong");
};

export const getAccountInfo: any = async (
  nodeAddress: string,
  publicKey: CLPublicKey
) => {
  const client = new CasperServiceByJsonRPC(nodeAddress);
  const stateRootHash = await client.getStateRootHash();
  const accountHash = publicKey.toAccountHashStr();
  const blockState = await client.getBlockState(stateRootHash, accountHash, []);
  return blockState.Account;
};

export const getContractPackageInfo: any = async (
  nodeAddress: string,
  packageHash: string
) => {
  const client = new CasperServiceByJsonRPC(nodeAddress);
  const stateRootHash = await client.getStateRootHash();
  const blockState = await client.getBlockState(stateRootHash, packageHash, []);
  return blockState.ContractPackage;
};

export const getStateRootHash: any = async (nodeAddress: string) => {
  const client = new CasperServiceByJsonRPC(nodeAddress);
  return await client.getStateRootHash();
};

/**
 * Returns a value under an on-chain account's storage.
 * @param info - On-chain information (AccountInfo, ContractMetadata)
 * @param namedKey - A named key associated with an on-chain account.
 */
export const getNamedKeyValue = (info: any, namedKey: string) => {
  const found = info.namedKeys.find((i: any) => i.name === namedKey);
  if (found) {
    return found.key;
  }
  return undefined;
};

export const getBinary = (pathToBinary: string) => {
  console.log("... WASM path " + pathToBinary)
  return undefined;
  // return new Uint8Array(fs.readFileSync(pathToBinary, null).buffer);
};

export const contractToByteArray = (hash: string) => {
  let hashOnly = hash.replace("hash-", "").replace("contract-", "");
  return hexToBytes(hashOnly);
}
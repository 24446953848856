import { useSpring, animated } from 'react-spring';
import Button from '@/components/GButton';

const ButtonMint = ({ hit, onMint }: any) => {

  const { opacity, y } = useSpring({
    reset: hit,
    reverse: false,
    to: {
      y: hit ? 10 : 0,
      opacity: hit ? 0 : 1
    }
  })
  return (
    <animated.div style={{
      opacity,
      transform: y.to(y => `translateY(${y}px)`)
    }} className="btn-mint--wrapper">
      <Button size="large" className="btn-mint--action" onClick={onMint}>
        BANGGG!
      </Button>
    </animated.div>
  );
};

export default ButtonMint;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Nav from 'react-bootstrap/Nav';
import { Heading } from '@/components/Typography';
import memberGp from '@/assets/images/members/img--member-gaupoit.webp';
import memberKien from '@/assets/images/members/img--member-kiennt.webp';
import memberRosalyn from '@/assets/images/members/img--member-rosalyn.webp';
import memberDuy from '@/assets/images/members/img--member-duy.webp';
import LogoTw from '@/assets/images/twitter.svg';
import LogoLinkedIn from '@/assets/images/logo--linkedin.svg';
import LogoTwitch from '@/assets/images/logo--twitch.svg';
import LogoTiktok from '@/assets/images/logo--tiktok.svg';
import LogoYoutube from '@/assets/images/logo--youtube.svg';
import LogoFacebook from '@/assets/images/fb.svg';
import LogoInsta from '@/assets/images/insta.svg';
import LogoTelegram from '@/assets/images/logo--telegram.svg';

interface ISocialMapping {
  twitter?: string;
  linkedin?: string;
  twitch?: string;
  tiktok?: string;
  youtube?: string;
  facebookPage?: string;
  insta?: string;
  telegram?: string;
}
interface IMemberProps {
  name: string;
  image: any;
  role?: string;
  imageStyles?: object | undefined;
  socialLinks: ISocialMapping;
}

const socialMapping: ISocialMapping = {
  twitter: LogoTw,
  linkedin: LogoLinkedIn,
  twitch: LogoTwitch,
  tiktok: LogoTiktok,
  youtube: LogoYoutube,
  facebookPage: LogoFacebook,
  insta: LogoInsta,
  telegram: LogoTelegram,
};

const TeamMembersBlock = () => {
  const members: IMemberProps[] = [
    {
      name: 'Duy Nguyen',
      image: memberDuy,
      role: 'Art Director',
      imageStyles: undefined,
      socialLinks: {
        telegram: 'https://t.me/@DuyNg206',
      },
    },
    {
      name: 'Rosalyn',
      image: memberRosalyn,
      role: 'Head of Business Development',
      imageStyles: undefined,
      socialLinks: {
        twitter: 'https://twitter.com/Rosalyn_EF',
        telegram: 'https://t.me/Salyn_EggForce',
      },
    },
    {
      name: 'Gaupoit | CasperDash',
      image: memberGp,
      role: 'Project Management',
      imageStyles: undefined,
      socialLinks: {
        twitter: 'https://twitter.com/gaupoit',
        telegram: 'https://t.me/gaupoit',
      },
    },
    {
      name: 'Kenny | CasperDash',
      image: memberKien,
      role: 'Head of Development',
      imageStyles: undefined,
      socialLinks: {
        telegram: 'https://t.me/@kennykkk',
      },
    },
  ];

  return (
    <Container className="homepage--block">
      <Row>
        <Col>
          <div className="block--members">
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInDown"
            >
              <Heading className="text-center">Members</Heading>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div className={`members-grid logo-total--${members.length}`}>
                {members.map((partner: IMemberProps) => {
                  const socials = partner.socialLinks
                    ? Object.keys(partner.socialLinks)
                    : [];
                  return (
                    <div className="grid-item" key={partner.name}>
                      <div className="grid-item--logo">
                        <div className="grid-item--square">
                          <img
                            {...(partner?.imageStyles && {
                              style: partner.imageStyles,
                            })}
                            className="grid-item--image"
                            src={partner.image}
                            alt={partner.name}
                          />
                        </div>
                      </div>
                      <div className="grid-item--name">
                        <h3 className="name">{partner.name}</h3>
                        <p className="role">{partner?.role}</p>
                      </div>
                      <div className="grid-item--social-links">
                        {socials.map((social) => {
                          const socialLink =
                            partner.socialLinks[social as keyof ISocialMapping];

                          if (!social || !socialLink) {
                            return null;
                          }

                          return (
                            <div
                              key={`${partner.name}-${social}`}
                              className="social-link--item"
                            >
                              <Nav.Link
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                href={socialLink}
                                className={`social-link ${social}`}
                              >
                                <img
                                  src={
                                    socialMapping[
                                      social as keyof ISocialMapping
                                    ]
                                  }
                                  className="social-link--logo"
                                  alt="EggForce Social Link"
                                />
                              </Nav.Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimationOnScroll>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMembersBlock;

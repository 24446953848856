import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@/services/axios';
import API from '@/constants/api';
import { AppDispatch, RootState } from '@/app/store';
import { IAuthState } from '@/modules/Auth/store';
import { INFTDetail } from '@/modules/NFTDetail/store';
import { isReadyForDeployment } from '@/modules/Auth/actions';
import type { UserType } from '@/modules/Auth/store';
import { executeErrorMessage } from '@/services/errorsWrapper';
import { isValidDeployResult } from '@/helpers/deploy';
import type { SignedDeployResult } from '@/types/deploy';
import { TypeMote } from '@/types/balance';
import { csprToMote } from '@/helpers/balance';
import { BurnHammerDetailsSpec } from '@/types/EggActions';
import { EggForceConfigurationEnum } from '@/types/generic';
// import { hammerBurnActions } from './store';

export const burnHammerProcess = createAsyncThunk<
  unknown,
  unknown,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'burnHammer/burnHammerProcess',
  async (
    { burnHammer, eggTokenId, hammerId, paymentAmount }: any,
    { dispatch, getState },
  ) => {
    const state: { auth: IAuthState; NFTDetail: INFTDetail } = getState();
    const {
      auth: { currentUser, configuration },
    } = state;
    const { activeKey } = currentUser as UserType;
    try {
      const isReady = await isReadyForDeployment(getState, [
        EggForceConfigurationEnum.WHITELIST_CONTRACT_HASH,
      ]);

      if (!isReady) {
        throw new Error('Check Casper connector');
      }

      const burnHammerDetails: BurnHammerDetailsSpec = {
        contract: configuration.WHITELIST_CONTRACT_HASH!,
        tokenIds: [hammerId],
        owner: activeKey,
        fee: csprToMote(paymentAmount) as TypeMote,
      };

      const signResponse: SignedDeployResult = await burnHammer(
        burnHammerDetails,
      );

      if (isValidDeployResult(signResponse)) {
        const response = await axios.post(
          API.burnHammer(eggTokenId),
          signResponse.signedDeploy,
        );

        return {
          apiResponse: response?.data ?? undefined,
          deployResult: signResponse,
        };
      }

      return undefined;
    } catch (error: any) {
      executeErrorMessage(error);
      return;
    } finally {
    }
  },
);

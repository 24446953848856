import { useCallback } from 'react';
import { buildDragonDropDeploy } from '@/services/casperServiceTS';
import { executeErrorMessage } from '@/services/errorsWrapper';
import useConfirmDeploy from './useConfirmDeploy';
import useCurrentUser from './useCurrentUser';
import WASM_PATHS from '@/constants/wasmPath';
import { fetchWASM } from '@/services/fetchServices';
import type { SignedDeployResult } from '@/types/deploy';
import { contractToByteArray } from '@/modules/EggPurchase/utils';
import useSiteConfigurations from './useSiteConfigurations';
import { MakeDragonDetailsSpec } from '@/types/EggActions';

const useDragonDrop = () => {
  const user = useCurrentUser();
  const { executeDeployWithoutPut, isDeploying, isError } = useConfirmDeploy();
  const siteConfig = useSiteConfigurations();

  const buildDragonDropDeployFn = useCallback(
    (data: any) => async () => {
      try {
        return buildDragonDropDeploy(data);
      } catch (error: any) {
        console.log(`🚀 ~ onBuildDragonDropDeploy ~ error`, error);
        throw new Error(`Failed to create Dragon drop deploy.`);
      }
    },
    [],
  );
  const onMakeDragon = useCallback(
    async ({ tokenId, paymentAmount }: MakeDragonDetailsSpec) => {
      try {
        if (!user) {
          throw new Error('Missing public key');
        }

        if (
          !siteConfig ||
          !siteConfig?.DRAGON_BROKER_CONTRACT_HASH ||
          !siteConfig?.TOKEN_CONTRACT_HASH
        ) {
          throw new Error('Invalid configurations');
        }

        const fileDragonDropWASM = WASM_PATHS.DRAGON_DROP;
        const { activeKey } = user;
        const brokerContractHash = siteConfig?.DRAGON_BROKER_CONTRACT_HASH;
        const tokenContractHash = siteConfig?.TOKEN_CONTRACT_HASH;
        const wasmDealer = await fetchWASM(fileDragonDropWASM);

        const data = {
          key: activeKey,
          tokenId: tokenId,
          wasm: wasmDealer,
          broker: contractToByteArray(brokerContractHash),
          token: contractToByteArray(tokenContractHash),
          paymentAmount: String(paymentAmount),
        };

        const deployResult = (await executeDeployWithoutPut(
          buildDragonDropDeployFn(data),
          activeKey,
          activeKey,
        )) as SignedDeployResult;
        console.log(`🚀 ~ onDropDragon ~ deployResult:`, deployResult);

        return deployResult;
      } catch (error: any) {
        executeErrorMessage(error);
        return undefined;
      }
    },
    [buildDragonDropDeployFn, executeDeployWithoutPut, siteConfig, user],
  );

  return { isDeploying, isError, makeDragon: onMakeDragon };
};

export default useDragonDrop;

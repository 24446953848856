import { useCallback } from 'react';
import cn from 'classnames';
import GModal from '@/components/GModal';
import { Heading } from '@/components/Typography';
import { useMemo } from 'react';
import CommonMessage from '@/components/CommonMessages';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
import { selectAdHocResultEventModal } from '@/modules/CustomEventModule/selectors';
import iconTreeSelvyn from '@/assets/images/icon--treeSelvyn.webp';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import SocialDiscordButton from '@/components/SocialDiscordButton';

const AdHocResultEventModal = () => {
  const dispatch = useDispatch();
  const { open, src } = useSelector(selectAdHocResultEventModal);
  const { title, msg } = useMemo(() => {
    const isFromBtn = src === 'btn--enter-callista';
    const messageBtn = (
      <span>
        Good day from Selvyn, new to Callista World? How about claim your
        Whitelist slot and meet our community?
      </span>
    );
    return {
      msg: isFromBtn ? messageBtn : CommonMessage.SentEmailSuccess,
      title: 'Whitelist request registered',
    };
  }, [src]);

  const onClickOKHandler = useCallback(() => {
    dispatch(CustomEventModalActions.hideAdHocResultEventModal());
  }, [dispatch]);

  return (
    <GModal
      blurOverlay
      onHide={onClickOKHandler}
      show={open}
      className={'adhoc-result-event--modal'}
    >
      <div className={cn('notification-modal--box-wrapper')}>
        <div className="notification-modal--box success">
          <div className="header">
            <div className="icon">
              <img className="tree-selvyn" src={iconTreeSelvyn} alt="Tip" />
            </div>
          </div>
          <div className="body">
            <Heading className="text-gradient2" h={4}>
              {title}
            </Heading>
            <p className="helper">{msg}</p>
            <SocialDiscordButton skipConfused />
          </div>
        </div>
      </div>
      <div className="modal-gradient--bottom"></div>
    </GModal>
  );
};

export default AdHocResultEventModal;

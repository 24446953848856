import { useCallback } from 'react';
import GModal from '@/components/GModal';
import { leaderboardActions } from './store';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import LeaderboardLayout from '@/components/LeaderboardLayout';
import { selectLeaderboardModule } from './selectors';

const LeaderboardModuleModal = () => {
  const dispatch = useDispatch();
  const leaderboardModule = useSelector(selectLeaderboardModule);

  const { open } = leaderboardModule;

  const onClose = useCallback(() => {
    dispatch(leaderboardActions.hideModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      backdrop="static"
      keyboard={false}
      onHide={onClose}
      className="leaderboard-module--modal"
    >
      <LeaderboardLayout />
    </GModal>
  );
};

export default LeaderboardModuleModal;

import { useCallback, useEffect, useState, useMemo } from 'react';
import Big from 'big.js';
import cn from 'classnames';

import Form from 'react-bootstrap/Form';
import LoadingBox from '@/components/LoadingBox';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import type { NFTItem } from '@/types/NFTItem';
import { NFTEggStatus, NFTTypeEnum } from '@/types/NFTItem';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { eggHatchingSelectActions } from '@/modules/EggHatching/store';
import GTokenInput from '@/components/GTokenInput';
import {
  CSPR_AUCTION_DELEGATE_FEE,
  MIN_CSPR_DELEGATE_TO_NEW_VALIDATOR,
} from '@/constants/key';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import NFTMedia from '@/components/NFTGridItem/NFTMedia';
import BoxInfo from '@/components/BoxInfo';
import { Heading } from '@/components/Typography';
import { toast, sharedToastProps } from '@/services/toast';
import NFTUniqueStrength from '@/components/NFTUniqueStrength';
import HelperHatch from '@/components/NFTDetailViewModal/HelperHatch';
import AccountMiniHeader from '@/components/AccountMiniHeader';
import configs from '@/constants/settings';
import EggHatchStatusModal from '@/components/EggHatchSelectingModal/EggHatchStatusModal';
import messages from '@/components/EggHatchSelectingModal/messages';
import commonMessages from '@/constants/commonMessages';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import { mockIncubateEgg } from '@/modules/EggForceJourneyDemoModule/actions';
import { mockValidators } from './mockData';
import MockGValidatorSelect from '@/components/GValidatorSelect/MockGValidatorSelect';
import { moteToCspr } from '@/helpers/balance';

const MockEggIncubateSelector = () => {
  const fee = CSPR_AUCTION_DELEGATE_FEE;
  const minAmount = configs.DEBUG_ENV ? 10 : MIN_CSPR_DELEGATE_TO_NEW_VALIDATOR;
  const dispatch = useDispatch();
  const {
    balance,
    currentGuideStep,
    nftDetailModal,
    nftsModal,
    nftHatchingModal: eggHatchSelectModal,
  } = useSelector(selectEggForceJourneyModule);
  const { data: nfts } = nftsModal;
  const [isHatchLoading, setHatchLoading] = useState<boolean>(false);
  const { data: dataDetail, loading } = nftDetailModal;

  const {
    open,
    selectedNFT: selectedNFT_ID,
    selectedValidator,
  } = eggHatchSelectModal;
  const defaultValidatorValue = useMemo(() => {
    if (
      dataDetail?.egg?.validator &&
      typeof dataDetail?.egg?.validator === 'string'
    ) {
      return dataDetail?.egg?.validator;
    }

    return selectedValidator;
  }, [dataDetail, selectedValidator]);
  const {
    description,
    image,
    token_uri,
    name: nftType = '',
    level,
    classNFT,
  } = useMemo(() => {
    if (!selectedNFT_ID) {
      return {
        metadata: [],
        name: '',
      };
    }

    const nft = nfts.find(({ tokenId, name }) => {
      return (
        tokenId === selectedNFT_ID.tokenId && selectedNFT_ID.nftType === name
      );
    });
    const eggData = getNFTDetails(nft);

    return {
      description: eggData.description,
      image: eggData.image,
      token_uri: eggData.token_uri,
      name: eggData.nftType,
      level: eggData.level,
      classNFT: eggData.classNFT,
    };
  }, [nfts, selectedNFT_ID]);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [onHatchingResultOpen, setHatchingResultOpen] =
    useState<boolean>(false);
  const [isHatchingSuccess, setHatchingStatus] = useState<boolean | undefined>(
    undefined,
  );
  const [formErrors, setFormErrors] = useState<undefined | any>(undefined);

  const [currValidator, setValidator] = useState<any>(undefined);

  const shouldDisableValidatorSelect = useMemo(() => {
    if (!dataDetail?.egg) {
      return false;
    }

    const { egg: eggStatusDetail } = dataDetail as Partial<NFTItem>;
    if (eggStatusDetail.status === NFTEggStatus.incubating) {
      return true;
    }

    return false;
  }, [dataDetail]);
  const shouldDisableHatchButton = useMemo(() => {
    if (!amount || dataDetail?.egg?.isProcessing || isHatchLoading) {
      return true;
    }

    const bigAmount = amount ? Big(amount) : Big(0);
    const bigMinAmount = Big(minAmount);
    const accountBalance = Big(moteToCspr(balance)).minus(Big(fee));

    return bigAmount.lt(bigMinAmount) || bigAmount.gt(accountBalance);
  }, [
    amount,
    balance,
    dataDetail?.egg?.isProcessing,
    fee,
    isHatchLoading,
    minAmount,
  ]);

  const onClose = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideNFTHatchingModal());
  }, [dispatch]);
  const onSelectValidator = useCallback((data: any) => {
    setValidator(data);
  }, []);
  const onChangeTokenAmount = useCallback(
    (value: number) => {
      setAmount(value);

      if (formErrors) {
        setFormErrors(undefined);
      }
    },
    [formErrors],
  );
  const onCloseStatusModal = useCallback(() => {
    setHatchingResultOpen(false);
    dispatch(EggForceJourneyDemoModuleActions.selectNFTForHatching(null));
    onClose();
  }, [dispatch, onClose]);

  const onStakeHandler = useCallback(async () => {
    try {
      if (!currValidator) {
        throw new Error('Invalid validator selected');
      }

      if (!selectedNFT_ID) {
        throw new Error('Invalid NFT selected');
      }

      setHatchLoading(true);
      dispatch(
        mockIncubateEgg({
          tokenId: selectedNFT_ID.tokenId,
          validator: currValidator.publicKey,
          amount: Big(amount ?? minAmount).toNumber(),
        }),
      );
      setHatchingStatus(true);
      setHatchingResultOpen(true);

      if (currentGuideStep === GuideStep.step3b) {
        dispatch(
          EggForceJourneyDemoModuleActions.updateHasOneIncubatingEgg(true),
        );
        dispatch(
          EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step3d),
        );
      }
    } catch (err: any) {
      toast.warn(messages.hatchFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
      console.error(`onStakeHandler::error `, err.message);
    } finally {
      setHatchLoading(false);
    }
  }, [
    amount,
    currValidator,
    currentGuideStep,
    dispatch,
    minAmount,
    selectedNFT_ID,
  ]);

  useEffect(() => {
    if (!open && selectedNFT_ID) {
      dispatch(eggHatchingSelectActions.selectNFT(null));
    }
  }, [dispatch, open, selectedNFT_ID]);

  useEffect(() => {
    if (!open) {
      setAmount(undefined);
      formErrors && setFormErrors(undefined);
    }
  }, [formErrors, open]);

  useEffect(() => {
    if (defaultValidatorValue) {
      const result = mockValidators.find(
        (option: any) => option.publicKey === defaultValidatorValue,
      );
      if (result) {
        onSelectValidator({
          ...result,
          label: result.name,
          value: result.publicKey,
        });
      }
    }
  }, [defaultValidatorValue, onSelectValidator]);

  if (!open || !selectedNFT_ID) {
    return null;
  }

  return (
    <GModal show={open} className={'egg-hatching--modal'} onHide={onClose}>
      {loading && (
        <div className="egg-hatching--overlay">
          <LoadingBox />
        </div>
      )}
      <AccountMiniHeader />
      <div>
        <NFTUniqueStrength
          skipText
          forHatching
          backgroundElement={classNFT?.value ?? ''}
          renderMedia={
            <NFTMedia
              isDetailView
              className="for--hatching"
              nftType={nftType}
              media={nftType === NFTTypeEnum.EGG ? token_uri : image}
              description={nftType === NFTTypeEnum.EGG ? '' : description.value}
            />
          }
        />
      </div>
      <Container className="p-0">
        <Row className="row--validator">
          <Form.Group as={Col} xs={12} className="ginput-group mb-3">
            <MockGValidatorSelect
              initOptions={mockValidators}
              isDisabled={shouldDisableValidatorSelect}
              defaultValue={defaultValidatorValue}
              onChange={onSelectValidator}
            />
          </Form.Group>
          {shouldDisableValidatorSelect && <HelperHatch />}
        </Row>
        <Row className="row--amount">
          <Form.Group as={Col} xs={12} className="ginput-group mb-3">
            <Form.Label>Staking amount</Form.Label>
            <GTokenInput
              className={cn({
                'is-invalid': formErrors?.amount || formErrors?.balance,
              })}
              value={amount}
              onInputChange={onChangeTokenAmount}
              formErrors={formErrors}
              placeholder={`Minimum amount is ${minAmount} CSPR`}
            />
          </Form.Group>
        </Row>
      </Container>
      <div className="panel inside egg-hatching-panel">
        {shouldDisableValidatorSelect && (
          <div className="egg-hatching-panel--box">
            <Heading h={4} className="fullwidth">
              Incubation Information
            </Heading>
            <BoxInfo
              label="Status"
              value={dataDetail?.egg.status}
              isHorizontal={false}
            />
            <BoxInfo
              label="Incubating amount"
              value={`${dataDetail?.egg?.stakedAmount} CSPR`}
              isHorizontal={false}
            />
            <BoxInfo
              label="Accumulated SNC"
              value={`${dataDetail?.egg?.accumulatedSnc} SNC`}
              isHorizontal={false}
            />
            <BoxInfo
              label="Egg Level"
              value={`${level?.value ?? ''}`}
              isHorizontal={false}
            />
          </div>
        )}
        <div className="egg-hatching-panel--box">
          <Heading h={4} className="fullwidth">
            Transaction Information
          </Heading>
          <BoxInfo label="Network" value={`Casper`} isHorizontal={false} />
          <BoxInfo
            label="Network Fee"
            value={`${fee} CSPR`}
            isHorizontal={false}
          />
          <BoxInfo
            label="Minimum amount"
            value={`${minAmount} CSPR`}
            isHorizontal={false}
          />
        </div>
      </div>
      <Button
        disabled={shouldDisableHatchButton}
        className="btn--hatch-selecting-modal"
        type="button"
        size="small"
        onClick={onStakeHandler}
        btnStyle="5"
      >
        {commonMessages.labelDelegate.defaultMessage}
      </Button>
      <EggHatchStatusModal
        open={onHatchingResultOpen}
        onClose={onCloseStatusModal}
        isSuccess={isHatchingSuccess}
      />
    </GModal>
  );
};

export default MockEggIncubateSelector;

import { createAsyncThunk } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';
import { AppDispatch, RootState } from '@/app/store';
import {
  mockEgg,
  mockDragon,
} from '@/components/DragonsLandingSubComponents/DragonDemoGameplay/mockData';
import {
  EggForceJourneyDemoModuleSpec,
  EggForceJourneyDemoModuleActions,
} from './store';
import Big from 'big.js';
import { csprToMote } from '@/helpers/balance';
import { addDays } from '@/helpers/datetime';
import { NFTEggStatus, NFTTypeEnum } from '@/types/NFTItem';
import { MOTE_RATE } from '@/constants/key';
import { axios } from '@/services/axios';
import API from '@/constants/api';
import {
  calculateReward,
  convertCSPRToSNC,
  countDiffDays,
  getImagePath,
  upgradeEggImage,
  upgradeEggLevel,
  upgradeEggNextLvl,
} from './utils';
import {
  DRAGON_LEVEL_NAME,
  ELEMENTAL_NAME,
  EGG_LEVEL_NAME,
  getNFTDetails,
} from '../NFTDetail/utils';

const mockNFTElementals = [
  ELEMENTAL_NAME.Water,
  ELEMENTAL_NAME.Fire,
  ELEMENTAL_NAME.Earth,
  ELEMENTAL_NAME.Metal,
  ELEMENTAL_NAME.Wood,
];

export const addEggs = createAsyncThunk<
  unknown,
  unknown,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/addEggs',
  async ({ amount }: any, { getState, dispatch, signal }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const {
      nftsModal: { data },
    } = EggForceJourneyDemo;

    const eggs = new Array(amount).fill(1).map(() => {
      const pickedId = Math.floor(Math.random() * mockNFTElementals.length);
      return {
        ...mockEgg,
        tokenId: uniqueId(),
        metadata: [
          {
            key: 'Class',
            value: mockNFTElementals[pickedId],
          },
          {
            key: 'Level',
            value: EGG_LEVEL_NAME.ROCK,
          },
          {
            key: 'Year of creation',
            value: '2023',
          },
          {
            key: 'token_uri',
            value: `https://assets.eggforce.io/egg/${mockNFTElementals[
              pickedId
            ]?.toLowerCase()}-rock.png`,
          },
        ],
      };
    });
    dispatch(EggForceJourneyDemoModuleActions.updateEggs([...data, ...eggs]));
  },
);

export const updateMockBalance = createAsyncThunk<
  unknown,
  unknown,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/updateMockBalance',
  async ({ amount }: any, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { balance } = EggForceJourneyDemo;

    const bigBalance = Big(balance);
    const bigChanges = Big(amount);
    const newBalance = bigBalance.minus(bigChanges).toNumber();
    dispatch(EggForceJourneyDemoModuleActions.updateBalance(newBalance));
  },
);

export const mockIncubateEgg = createAsyncThunk<
  unknown,
  unknown,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/mockIncubateEgg',
  async ({ amount, tokenId, validator }: any, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { nftsModal, timelineController } = EggForceJourneyDemo;
    const { data } = nftsModal;

    const newData = data.map((item: any) => {
      if (item.tokenId === tokenId) {
        return {
          ...item,
          startIncubateDate: timelineController.futureDate
            ? timelineController.futureDate
            : timelineController.startDate,
          egg: {
            ...item.egg,
            validator,
            status: 'incubating',
            stakedAmount: (item?.egg?.stakedAmount ?? 0) + amount,
          },
        };
      }

      return item;
    });

    dispatch(EggForceJourneyDemoModuleActions.updateEggs(newData));
    dispatch(
      updateMockBalance({
        amount: csprToMote(amount),
      }),
    );
  },
);

export const mockDragonHatch = createAsyncThunk<
  unknown,
  unknown,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/mockDragonHatch',
  async ({ tokenId }: any, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { nftsModal } = EggForceJourneyDemo;
    const { data } = nftsModal;
    const rarities = [
      DRAGON_LEVEL_NAME.ELEMENTAL,
      DRAGON_LEVEL_NAME.RARE,
      DRAGON_LEVEL_NAME.LEGENDARY,
    ];

    const newData = data.map((item: any) => {
      if (item.tokenId === tokenId) {
        const rarity = rarities[Math.floor(Math.random() * rarities.length)];
        return {
          ...mockDragon,
          tokenId: uniqueId(),
          metadata: mockDragon.metadata.map((meta) => {
            if (meta.key === 'edition') {
              return {
                key: meta.key,
                value: uniqueId(),
              };
            }

            if (meta.key === 'name') {
              return {
                key: meta.key,
                value: `Dragon ${rarity} #${uniqueId()}`,
              };
            }

            if (meta.key === 'image') {
              const { classNFT } = getNFTDetails(item);
              return {
                key: meta.key,
                value: getImagePath(NFTTypeEnum.DRAGON, classNFT.value, rarity),
              };
            }

            if (meta.key === 'rarity') {
              return {
                key: 'rarity',
                value: rarity,
              };
            }

            if (meta.key === 'class') {
              const { classNFT } = getNFTDetails(item);
              return {
                key: 'class',
                value: classNFT.value,
              };
            }

            return meta;
          }),
        };
      }

      return item;
    });

    dispatch(EggForceJourneyDemoModuleActions.updateEggs(newData));
    dispatch(
      updateMockBalance({
        amount: csprToMote(30),
      }),
    );
  },
);

export const updateFutureDate = createAsyncThunk<
  unknown,
  {
    period: number;
  },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/updateFutureDate',
  async ({ period }, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { timelineController } = EggForceJourneyDemo;
    const { startDate, futureDate } = timelineController;

    const future = addDays(futureDate ? futureDate : startDate, period);
    dispatch(
      EggForceJourneyDemoModuleActions.updateFutureDate(future.toISOString()),
    );
  },
);

export const updateDelegateRewards = createAsyncThunk<
  unknown,
  { period: number },
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/updateDelegateRewards',
  async (_, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { balance, shouldAccumulateSNC } = EggForceJourneyDemo;
    const totalReward: any = await dispatch(updateEggLevel()).unwrap();

    // New CSPR balance
    const newBalance = Big(balance).plus(totalReward);

    dispatch(
      EggForceJourneyDemoModuleActions.updateBalance(newBalance.toNumber()),
    );
    // 1cspr = 10 snc rate
    // convert reward to CSPR then * 10
    const cspr = Big(totalReward).div(MOTE_RATE);
    dispatch(
      EggForceJourneyDemoModuleActions.updateSNCBalance({
        value: cspr.mul(10).toNumber(),
        shouldAccumulate: shouldAccumulateSNC,
      }),
    );
  },
);

export const updateEggLevel = createAsyncThunk<
  unknown,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/updateEggLevel',
  async (_, { getState, dispatch }) => {
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { nftsModal, timelineController } = EggForceJourneyDemo;
    let accReward = 0;
    const result = nftsModal.data.map((nft) => {
      if (nft?.egg?.status === NFTEggStatus.incubating) {
        const period = countDiffDays({
          futureDate: timelineController.futureDate,
          startDate: nft.startIncubateDate,
        });
        const reward = calculateReward(nft?.egg?.stakedAmount, period);

        accReward = Big(accReward).plus(reward).toNumber();

        const snc = convertCSPRToSNC(reward.toNumber());
        const xp = Big(snc).div(4).toNumber();
        const { classNFT } = getNFTDetails(nft);
        const newMetadata = nft.metadata
          .map((item: any) => {
            if (item.key === 'XP') {
              return null;
            }
            if (item.key === 'Level') {
              return upgradeEggLevel(xp);
            }

            if (item.key === 'token_uri') {
              return upgradeEggImage(xp, classNFT);
            }

            return item;
          })
          .filter(Boolean);

        return {
          ...nft,
          metadata: [
            ...newMetadata,
            {
              key: 'XP',
              value: xp,
            },
          ],
          egg: {
            ...nft.egg,
            nextLevelXp: upgradeEggNextLvl(xp),
          },
        };
      }

      return nft;
    });

    dispatch(EggForceJourneyDemoModuleActions.updateEggs(result));

    return accReward;
  },
);

export const registerLuckyNumber = createAsyncThunk(
  'EggForceJourneyDemoModule/registerLuckyNumber',
  async (formValues: any) => {
    const apiParams = {
      email: formValues.email.trim(),
      publicKey: formValues?.walletAddress?.trim() ?? '',
      idDiscord: formValues?.idDiscord?.trim(),
      idTelegram: formValues?.idTelegram?.trim(),
      selectedPackage: 'custom',
    };
    const { data } = await axios.post(API.registerLuckyNumber, {
      ...apiParams,
    });

    if (!data) {
      return;
    }

    return data;
    // return {
    //   result: true,
    //   code: 'DUC-123',
    // };
  },
);

export const mockMergeEgg = createAsyncThunk<
  unknown,
  unknown,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'EggForceJourneyDemoModule/mockMergeEgg',
  async (
    { tokenId, mergedTokenIds, paymentAmount }: any,
    { getState, dispatch },
  ) => {
    const BONUS_SNC = 50;
    const state: { EggForceJourneyDemo: EggForceJourneyDemoModuleSpec } =
      getState();
    const { EggForceJourneyDemo } = state;
    const { nftsModal } = EggForceJourneyDemo;
    const { data } = nftsModal;

    const filteredEggs = data
      .filter((item) => {
        if (mergedTokenIds.includes(item.tokenId)) {
          return false;
        }

        return true;
      })
      .map((item) => {
        // Primary egg
        if (item.tokenId === tokenId) {
          const bonus = item.egg.snc + mergedTokenIds.length * BONUS_SNC;
          return {
            ...item,
            egg: {
              ...item.egg,
              snc: bonus,
            },
          };
        }

        return item;
      });
    dispatch(EggForceJourneyDemoModuleActions.updateEggs(filteredEggs));
    dispatch(EggForceJourneyDemoModuleActions.setLastMergedEgg(tokenId));
    dispatch(
      updateMockBalance({
        amount: paymentAmount,
      }),
    );
  },
);

import Big from 'big.js';
import { differenceInCalendarDays } from 'date-fns';
import { formateDate } from '@/helpers/datetime';
import { csprToMote, moteToCspr } from '@/helpers/balance';
import type { TypeMote } from '@/types/balance';
import { NFTTypeEnum } from '@/types/NFTItem';
import {
  EGG_LEVEL_NAME,
  ELEMENTAL_NAME,
  DRAGON_LEVEL_NAME,
  EGG_MAX_XP_LEVEL,
} from '../NFTDetail/utils';

import eggEarthRock from '@/assets/short-terms/earth-rock.png';
import eggEarthSilver from '@/assets/short-terms/earth-silver.png';
import eggEarthGold from '@/assets/short-terms/earth-gold.png';
import eggEarthPlatinum from '@/assets/short-terms/earth-platinum.png';
import dragonEarthElemental from '@/assets/short-terms/earth-dragon-elemental.gif';
import dragonEarthRare from '@/assets/short-terms/earth-dragon-rare.gif';
import dragonEarthLegendary from '@/assets/short-terms/earth-dragon-legendary.gif';

import eggFireRock from '@/assets/short-terms/fire-rock.png';
import eggFireSilver from '@/assets/short-terms/fire-silver.png';
import eggFireGold from '@/assets/short-terms/fire-gold.png';
import eggFirePlatinum from '@/assets/short-terms/fire-platinum.png';
import dragonFireElemental from '@/assets/short-terms/fire-dragon-elemental.gif';
import dragonFireRare from '@/assets/short-terms/fire-dragon-rare.gif';
import dragonFireLegendary from '@/assets/short-terms/fire-dragon-legendary.gif';

import eggWaterRock from '@/assets/short-terms/water-rock.png';
import eggWaterSilver from '@/assets/short-terms/water-silver.png';
import eggWaterGold from '@/assets/short-terms/water-gold.png';
import eggWaterPlatinum from '@/assets/short-terms/water-platinum.png';
import dragonWaterElemental from '@/assets/short-terms/water-dragon-elemental.gif';
import dragonWaterRare from '@/assets/short-terms/water-dragon-rare.gif';
import dragonWaterLegendary from '@/assets/short-terms/water-dragon-legendary.gif';

import eggMetalRock from '@/assets/short-terms/metal-rock.png';
import eggMetalSilver from '@/assets/short-terms/metal-silver.png';
import eggMetalGold from '@/assets/short-terms/metal-gold.png';
import eggMetalPlatinum from '@/assets/short-terms/metal-platinum.png';
import dragonMetalElemental from '@/assets/short-terms/metal-dragon-elemental.gif';
import dragonMetalRare from '@/assets/short-terms/metal-dragon-rare.gif';
import dragonMetalLegendary from '@/assets/short-terms/metal-dragon-legendary.gif';

import eggWoodRock from '@/assets/short-terms/wood-rock.png';
import eggWoodSilver from '@/assets/short-terms/wood-silver.png';
import eggWoodGold from '@/assets/short-terms/wood-gold.png';
import eggWoodPlatinum from '@/assets/short-terms/wood-platinum.png';
import dragonWoodElemental from '@/assets/short-terms/wood-dragon-elemental.gif';
import dragonWoodRare from '@/assets/short-terms/wood-dragon-rare.gif';
import dragonWoodLegendary from '@/assets/short-terms/wood-dragon-legendary.gif';

const imageMapper = {
  [NFTTypeEnum.EGG]: {
    [ELEMENTAL_NAME.Earth]: {
      [EGG_LEVEL_NAME.ROCK]: eggEarthRock,
      [EGG_LEVEL_NAME.SILVER]: eggEarthSilver,
      [EGG_LEVEL_NAME.GOLD]: eggEarthGold,
      [EGG_LEVEL_NAME.PLATINUM]: eggEarthPlatinum,
    },
    [ELEMENTAL_NAME.Water]: {
      [EGG_LEVEL_NAME.ROCK]: eggWaterRock,
      [EGG_LEVEL_NAME.SILVER]: eggWaterSilver,
      [EGG_LEVEL_NAME.GOLD]: eggWaterGold,
      [EGG_LEVEL_NAME.PLATINUM]: eggWaterPlatinum,
    },
    [ELEMENTAL_NAME.Fire]: {
      [EGG_LEVEL_NAME.ROCK]: eggFireRock,
      [EGG_LEVEL_NAME.SILVER]: eggFireSilver,
      [EGG_LEVEL_NAME.GOLD]: eggFireGold,
      [EGG_LEVEL_NAME.PLATINUM]: eggFirePlatinum,
    },
    [ELEMENTAL_NAME.Wood]: {
      [EGG_LEVEL_NAME.ROCK]: eggWoodRock,
      [EGG_LEVEL_NAME.SILVER]: eggWoodSilver,
      [EGG_LEVEL_NAME.GOLD]: eggWoodGold,
      [EGG_LEVEL_NAME.PLATINUM]: eggWoodPlatinum,
    },
    [ELEMENTAL_NAME.Metal]: {
      [EGG_LEVEL_NAME.ROCK]: eggMetalRock,
      [EGG_LEVEL_NAME.SILVER]: eggMetalSilver,
      [EGG_LEVEL_NAME.GOLD]: eggMetalGold,
      [EGG_LEVEL_NAME.PLATINUM]: eggMetalPlatinum,
    },
  },
  [NFTTypeEnum.DRAGON]: {
    [ELEMENTAL_NAME.Earth]: {
      [DRAGON_LEVEL_NAME.ELEMENTAL]: dragonEarthElemental,
      [DRAGON_LEVEL_NAME.RARE]: dragonEarthRare,
      [DRAGON_LEVEL_NAME.LEGENDARY]: dragonEarthLegendary,
    },
    [ELEMENTAL_NAME.Water]: {
      [DRAGON_LEVEL_NAME.ELEMENTAL]: dragonWaterElemental,
      [DRAGON_LEVEL_NAME.RARE]: dragonWaterRare,
      [DRAGON_LEVEL_NAME.LEGENDARY]: dragonWaterLegendary,
    },
    [ELEMENTAL_NAME.Fire]: {
      [DRAGON_LEVEL_NAME.ELEMENTAL]: dragonFireElemental,
      [DRAGON_LEVEL_NAME.RARE]: dragonFireRare,
      [DRAGON_LEVEL_NAME.LEGENDARY]: dragonFireLegendary,
    },
    [ELEMENTAL_NAME.Wood]: {
      [DRAGON_LEVEL_NAME.ELEMENTAL]: dragonWoodElemental,
      [DRAGON_LEVEL_NAME.RARE]: dragonWoodRare,
      [DRAGON_LEVEL_NAME.LEGENDARY]: dragonWoodLegendary,
    },
    [ELEMENTAL_NAME.Metal]: {
      [DRAGON_LEVEL_NAME.ELEMENTAL]: dragonMetalElemental,
      [DRAGON_LEVEL_NAME.RARE]: dragonMetalRare,
      [DRAGON_LEVEL_NAME.LEGENDARY]: dragonMetalLegendary,
    },
  },
};

const getImagePath = (type: string, elemental: string, level: string) => {
  // @ts-ignore
  return imageMapper[type][elemental][level];
};

const calculateReward = (stakeAmount: number, period: number) => {
  const apy = 10.73;
  // Yearly APY
  const yearly = Big(csprToMote(stakeAmount)).mul(apy).div(100);
  // Total reward during the timespan
  const totalReward = yearly.div(365).mul(period);

  return totalReward;
};

const convertCSPRToSNC = (cspr: TypeMote) => {
  // Yearly APY
  return moteToCspr(Big(cspr).mul(10).toNumber());
};

const countDiffDays = (timelineController: any) => {
  const currDate = formateDate(timelineController.startDate);
  const futureDate = timelineController.futureDate
    ? formateDate(timelineController.futureDate)
    : '';
  const diff = futureDate
    ? differenceInCalendarDays(new Date(futureDate), new Date(currDate))
    : 0;

  return diff;
};

const upgradeEggLevel = (xp: number) => {
  if (xp >= EGG_MAX_XP_LEVEL.Gold) {
    return {
      key: 'Level',
      value: EGG_LEVEL_NAME.PLATINUM,
    };
  }

  if (xp >= EGG_MAX_XP_LEVEL.Silver) {
    return {
      key: 'Level',
      value: EGG_LEVEL_NAME.GOLD,
    };
  }

  if (xp >= EGG_MAX_XP_LEVEL.Rock) {
    return {
      key: 'Level',
      value: EGG_LEVEL_NAME.SILVER,
    };
  }

  return {
    key: 'Level',
    value: EGG_LEVEL_NAME.ROCK,
  };
};

const upgradeEggImage = (xp: number, classNFT: any) => {
  if (xp >= EGG_MAX_XP_LEVEL.Gold) {
    const path = getImagePath(
      NFTTypeEnum.EGG,
      classNFT.value,
      EGG_LEVEL_NAME.PLATINUM,
    );
    return {
      key: 'token_uri',
      value: path,
    };
  }

  if (xp >= EGG_MAX_XP_LEVEL.Silver) {
    return {
      key: 'token_uri',
      value: getImagePath(NFTTypeEnum.EGG, classNFT.value, EGG_LEVEL_NAME.GOLD),
    };
  }

  if (xp >= EGG_MAX_XP_LEVEL.Rock) {
    return {
      key: 'token_uri',
      value: getImagePath(
        NFTTypeEnum.EGG,
        classNFT.value,
        EGG_LEVEL_NAME.SILVER,
      ),
    };
  }

  return {
    key: 'token_uri',
    value: getImagePath(NFTTypeEnum.EGG, classNFT.value, EGG_LEVEL_NAME.ROCK),
  };
};

const upgradeEggNextLvl = (xp: number) => {
  if (xp >= EGG_MAX_XP_LEVEL.Gold) {
    return EGG_MAX_XP_LEVEL.Platinum;
  }

  if (xp >= EGG_MAX_XP_LEVEL.Silver) {
    return EGG_MAX_XP_LEVEL.Gold;
  }

  if (xp >= EGG_MAX_XP_LEVEL.Rock) {
    return EGG_MAX_XP_LEVEL.Silver;
  }

  return EGG_MAX_XP_LEVEL.Rock;
};

export {
  upgradeEggNextLvl,
  upgradeEggImage,
  upgradeEggLevel,
  EGG_MAX_XP_LEVEL,
  calculateReward,
  convertCSPRToSNC,
  countDiffDays,
  getImagePath,
};

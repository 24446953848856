// import heroImageDefault from '@/assets/images/hero-image--desktop-large.webp';
// import heroImageDesktopMedium from '@/assets/images/hero-image--desktop-medium.webp';
// import heroImageMobile from '@/assets/images/hero-image--mobile.webp';
// import heroImageDefault from '@/assets/images/mint-hero-image--desktop-large.webp';
// import heroImageDesktopMedium from '@/assets/images/mint-hero-image--desktop-medium.webp';
// import heroImageMobile from '@/assets/images/mint-hero-image--mobile.webp';
// import heroImageDefault from '@/assets/images/easter-hero-image--desktop-medium.png';
// import heroImageDesktopMedium from '@/assets/images/easter-hero-image--desktop-medium.png';
// import heroImageMobile from '@/assets/images/easter-hero-image--mobile.png';
import heroImageDefault from '@/assets/short-terms/hatch-dragon--full.jpg';
import heroImageDesktopMedium from '@/assets/short-terms/hatch-dragon--1920.jpg';
import heroImageMobile from '@/assets/short-terms/hatch-dragon--full.jpg';

const DragonHeroBanner = () => {
  return (
    <picture>
      <source
        media="(min-width: 2056px)"
        srcSet={`${heroImageDefault} 1x`}
        sizes="100vw"
      />
      <source
        media="(min-width: 1280px)"
        srcSet={`${heroImageDesktopMedium} 1x`}
        sizes="100vw"
      />
      <source
        media="(min-width: 769px)"
        srcSet={`${heroImageMobile} 1x`}
        sizes="100vw"
      />

      <source
        media="(max-width: 768px)"
        srcSet={`${heroImageMobile} 1x`}
        sizes="100vw"
      />
      <img loading="lazy" src={heroImageDefault} alt="Dragon EggForce" />
    </picture>
  );
};

export default DragonHeroBanner;

import PropTypes from 'prop-types';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Heading } from '@/components/Typography';
import EggCracker from '@/components/EggCracker';

const RewardItem = ({ value, isNumber = false }: any) => {
  const arrValue = value?.split(',');
  const firstBlockValues = arrValue?.slice(0, 9) ?? [];
  // const secondBlockValues = arrValue?.slice(10, 15) ?? [];
  return (
    <div className="multi-items-reward">
      <Heading h={3}>Lucky Hatchers (Casper Egg)</Heading>
      <div className="parent first">
        {firstBlockValues.map((reward: any) => (
          <span
            key={reward}
            className={cn('each-reward-item', {
              number: isNumber,
            })}
          >
            {reward}
          </span>
        ))}
      </div>

      {/* <Heading h={3}></Heading>
      <div className="parent">
        {secondBlockValues.map((reward: any) => (
          <span
            key={reward}
            className={cn('each-reward-item style-2', {
              number: isNumber,
            })}
          >
            {reward}
          </span>
        ))}
      </div> */}
    </div>
  );
};

const MultiRewardEggCracker = ({
  max = 1000,
  winners = Array,
  noOfPicker = 1,
  resultType,
}: any) => {
  const isNumberType = Boolean(resultType === 'number');
  const [random, setRandom] = useState<number | string | undefined>(undefined);
  const [options, setOptions] = useState<Array<any>>(winners);

  const onRandom = () => {
    if (options.length === 0) {
      setRandom('End game');
      return;
    }

    let tempOptions = options;

    if (tempOptions.length <= noOfPicker) {
      setRandom(tempOptions.join(','));
      setOptions([]);
      return;
    }

    let picked = [];
    for (let i = 0; i < noOfPicker; i++) {
      const pickedId =
        tempOptions.length === 1
          ? 0
          : Math.floor(Math.random() * tempOptions.length);

      const pickedOption = tempOptions[pickedId];
      picked.push(pickedOption);
      tempOptions = tempOptions.filter((item) => item !== pickedOption);
    }

    setRandom(picked.join(', '));
    setOptions(tempOptions);
  };

  const onReset = () => {
    setTimeout(() => {
      onRandom();
    }, 3200);
  };
  useEffect(() => {
    if (isNumberType && !max) {
      console.warn(
        'Must provide a maximum value when using resultType as Number',
      );
    }

    if (!isNumberType && !winners?.length) {
      console.warn('Must provide winner list when using resultType as String');
    }

    if (typeof random === 'undefined') {
      onRandom();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EggCracker
      rewardProps={{ className: 'oval', animationType: 'jump-only' }}
      onReset={onReset}
      renderReward={<RewardItem value={random} isNumber={isNumberType} />}
    />
  );
};

MultiRewardEggCracker.propTypes = {
  resultType: PropTypes.string.isRequired,
  winners: PropTypes.array,
  max: PropTypes.number,
  noOfPicker: PropTypes.number,
};

export default MultiRewardEggCracker;

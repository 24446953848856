import Balancer from 'react-wrap-balancer';

const EggMintingEventBlock = () => {
  return (
    <Balancer className="event-message--root">
      <br />
      <p>Good day, good hatcher.</p>
      <p>== Mint Details ==</p>

      <p>Why do we need EggForce Closed Beta round?</p>

      <p>
        1️⃣ Hammer holders are the hatchers who are interested in / understand /
        actively participate in EggForce's past/present events.
      </p>

      <p>
        2️⃣ When Hammer Holders mint Eggs and early access EggForce
        utilities/mechanisms such as incubating and egg merging, together with
        the community feedback/ideas we build the community needs / oriented
        utilities before the Open Beta / Public round (where everyone can join).{' '}
      </p>

      <p>
        3️⃣ The benefits for Hammer Holders are: they can mint Eggs with
        preferential prices; join exclusive events from EggForce and partners.
      </p>

      <p>What you can know now is: </p>

      <p>
        1️⃣ It will be a whitelist wallet mint. Only wallet having Hammer NFT
      </p>
      <p>
        2️⃣ Supply will be <strong>1000</strong>{' '}
      </p>
      <p>
        3️⃣ The mint price will be <strong>1471,04 CSPR</strong>.{' '}
        <strong>Min buy 5 NFTs.</strong>
      </p>

      <p>
        Mint page:{' '}
        <a href="https://eggforce.io/world">https://eggforce.io/world</a>
      </p>

      <p>
        The next two weeks will be crazy, so 🐉 with us, hatch with us, spread
        the word, good vibes, and eggciting 🥚
      </p>
    </Balancer>
  );
};

export default EggMintingEventBlock;

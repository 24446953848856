import { useCallback } from 'react';
import GModal from '@/components/GModal';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import isEqual from 'lodash/isEqual';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';
import LuckyNumberRegistration from './LuckyNumberRegistration';

const LuckyNumberRegistrationModal = () => {
  const dispatch = useDispatch();
  const { luckyNumberFormModal } = useSelector(
    selectEggForceJourneyModule,
    isEqual,
  );

  const { open } = luckyNumberFormModal;

  const onClose = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideLuckyNumberFormModal());
  }, [dispatch]);

  if (!open) {
    return null;
  }

  return (
    <GModal
      show={open}
      backdrop="static"
      keyboard={false}
      onHide={onClose}
      className="SNC-module--registration-modal lucky-number-modal"
    >
      <LuckyNumberRegistration isModal />
    </GModal>
  );
};

export default LuckyNumberRegistrationModal;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IModal } from '@/types/modal';
import type { TypeSelectedNFT } from '@/types/NFTItem';

export enum GuideStep {
  step1 = 'step1',
  step2 = 'step2',
  step3 = 'step3',
  step3b = 'step3b',
  step3c = 'step3c',
  step3d = 'step3d',
  step3e = 'step3e',
  step4 = 'step4',
  step4b = 'step4b',
  step5 = 'step5',
  step6 = 'step6',
  step7 = 'step7',
  step7a = 'step7a',
  step7b = 'step7b',
  step7c = 'step7c',
  wait = 'wait',
}
export interface EggForceJourneyDemoModuleSpec {
  shouldAccumulateSNC: boolean;
  balance: number;
  snc: number;
  step: number;
  isStarted: boolean;
  isFinished: boolean;
  packagesModal: IModal;
  nftsModal: IModal & {
    data: Array<any>;
  };
  nftDetailModal: IModal & {
    data: any;
  };
  nftHatchingModal: IModal & {
    selectedNFT: TypeSelectedNFT | null;
    selectedValidator?: string;
  };
  dragonHatchModal: IModal & {
    statusModal: IModal & {
      isSuccess: boolean;
    };
  };
  nftMergeModal: IModal & {
    lastMergedEggId: any;
    statusModal: IModal & {
      result: boolean;
    };
  };
  csprCalculatorModal: IModal;
  timelineController: {
    startDate: string;
    futureDate: string;
  };
  calculatorModal: IModal;
  showGuide: boolean;
  currentGuideStep: GuideStep;
  hasDragon: boolean;
  hasOneIncubatingEgg: boolean;
  hasFirstLeveledUpEgg: boolean;
  luckyNumberFormModal: IModal & {
    statusModal: IModal & {
      code: string;
      isSuccess: boolean;
      message: string;
    };
  };
}

export const initialState: EggForceJourneyDemoModuleSpec = {
  shouldAccumulateSNC: false,
  step: 1,
  snc: 0,
  balance: 1_000_000_000_000_000,
  isStarted: false,
  isFinished: false,
  packagesModal: {
    open: false,
    loading: false,
  },
  nftsModal: {
    open: false,
    loading: false,
    data: [],
  },
  nftDetailModal: {
    open: false,
    loading: false,
    data: undefined,
  },
  nftMergeModal: {
    open: false,
    loading: false,
    lastMergedEggId: undefined,
    statusModal: {
      result: false,
      open: false,
      loading: false,
    },
  },
  nftHatchingModal: {
    selectedNFT: null,
    selectedValidator: undefined,
    loading: false,
    open: false,
  },
  dragonHatchModal: {
    loading: false,
    open: false,
    statusModal: {
      loading: false,
      open: false,
      isSuccess: true,
    },
  },
  timelineController: {
    startDate: new Date().toISOString(),
    futureDate: '',
  },
  calculatorModal: {
    open: false,
    loading: false,
  },
  csprCalculatorModal: {
    open: false,
    loading: false,
  },
  showGuide: false,
  currentGuideStep: GuideStep.step1,
  hasDragon: false,
  hasOneIncubatingEgg: false,
  hasFirstLeveledUpEgg: false,
  luckyNumberFormModal: {
    loading: false,
    open: false,
    statusModal: {
      isSuccess: false,
      loading: false,
      open: false,
      code: '',
      message: '',
    },
  },
};

const EggForceJourneyDemoModule = createSlice({
  name: 'EggForceJourneyDemoModule',
  initialState,
  reducers: {
    start: (state: EggForceJourneyDemoModuleSpec) => {
      state.isStarted = true;
    },
    showGuide: (state: EggForceJourneyDemoModuleSpec) => {
      state.showGuide = true;
    },
    hideGuide: (state: EggForceJourneyDemoModuleSpec) => {
      state.showGuide = false;
    },
    showNFTListModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftsModal.open = true;
    },
    hideNFTListModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftsModal.open = false;
    },
    showNFTDetailModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftDetailModal.open = true;
    },
    hideNFTDetailModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftDetailModal.open = false;
    },
    setCurrentNFTDetail: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftDetailModal.data = action.payload;
    },
    showNFTMergeModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftMergeModal.open = true;
    },
    hideNFTMergeModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftMergeModal.open = false;
    },
    setLastMergedEgg: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftMergeModal.lastMergedEggId = action.payload;
    },
    showNFTMergeStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftMergeModal.statusModal.open = true;
    },
    hideNFTMergeStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftMergeModal.statusModal.open = false;
    },
    setNFTMergeStatusModal: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftMergeModal.statusModal.result = action.payload;
    },
    updateEggs: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftsModal.data = action.payload;
    },
    setPackagesListModal: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.packagesModal.open = action.payload;
    },
    updateBalance: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<number>,
    ) => {
      state.balance = action.payload;
    },
    updateSNCBalance: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<{
        value: number;
        shouldAccumulate: boolean;
      }>,
    ) => {
      if (action.payload.shouldAccumulate) {
        state.snc += action.payload.value;
      } else {
        state.snc = action.payload.value;
      }
    },
    showNFTHatchingModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftHatchingModal.open = true;
    },
    hideNFTHatchingModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftHatchingModal.open = false;
    },
    selectNFTForHatching: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftHatchingModal.selectedNFT = action.payload ?? null;
    },
    selectValidator: (state: EggForceJourneyDemoModuleSpec, action) => {
      state.nftHatchingModal.selectedValidator = action.payload ?? undefined;
    },
    clearValidator: (state: EggForceJourneyDemoModuleSpec) => {
      state.nftHatchingModal.selectedValidator = undefined;
    },
    showDragonHatchModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.dragonHatchModal.open = true;
    },
    hideDragonHatchModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.dragonHatchModal.open = false;
    },
    showDragonHatchStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.dragonHatchModal.statusModal.open = true;
    },
    hideDragonHatchStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.dragonHatchModal.statusModal.open = false;
    },
    updateFutureDate: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<string>,
    ) => {
      state.timelineController.futureDate = action.payload;
    },
    showCalculatorModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.calculatorModal.open = true;
    },
    hideCalculatorModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.calculatorModal.open = false;
    },
    enableFinishedState: (state: EggForceJourneyDemoModuleSpec) => {
      state.isFinished = true;
    },
    disableFinishedState: (state: EggForceJourneyDemoModuleSpec) => {
      state.isFinished = false;
    },
    showCSPRCalculatorModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.csprCalculatorModal.open = true;
    },
    hideCSPRCalculatorModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.csprCalculatorModal.open = false;
    },
    updateGuide: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<GuideStep>,
    ) => {
      state.currentGuideStep = action.payload;
    },
    updateHasDragon: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.hasDragon = action.payload;
    },
    updateHasOneIncubatingEgg: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.hasOneIncubatingEgg = action.payload;
    },
    updateHasFirstLeveledUpEgg: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.hasFirstLeveledUpEgg = action.payload;
    },
    updateShouldAccumulateSNC: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldAccumulateSNC = action.payload;
    },
    showLuckyNumberFormModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.luckyNumberFormModal.open = true;
    },
    hideLuckyNumberFormModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.luckyNumberFormModal.open = false;
    },
    setLuckyNumberFormLoading: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.luckyNumberFormModal.loading = action.payload;
    },
    showStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.luckyNumberFormModal.statusModal.open = true;
    },
    hideStatusModal: (state: EggForceJourneyDemoModuleSpec) => {
      state.luckyNumberFormModal.statusModal.open = false;
    },
    updateResultStatusMessage: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<string>,
    ) => {
      state.luckyNumberFormModal.statusModal.message = action.payload;
    },
    updateResultStatusModal: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<boolean>,
    ) => {
      state.luckyNumberFormModal.statusModal.isSuccess = action.payload;
    },
    updateLuckyNumberCode: (
      state: EggForceJourneyDemoModuleSpec,
      action: PayloadAction<string>,
    ) => {
      state.luckyNumberFormModal.statusModal.code = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

const EggForceJourneyDemoModuleActions = {
  ...EggForceJourneyDemoModule.actions,
};

export { EggForceJourneyDemoModuleActions };
export default EggForceJourneyDemoModule.reducer;

import cn from 'classnames';
import { useState, useMemo, useCallback, useRef } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { sharedToastProps, toast } from '@/services/toast';
import isEmpty from 'lodash/isEmpty';
import iconHatch from '@/assets/images/icon--hatch.png';
import type { NFTItem } from '@/types/NFTItem';
import {
  selectDragonMakeStatusModal,
  selectDragonMakeConfirmModal,
} from '@/modules/DragonManagementModule/selectors';
import { selectNFTDetailModal } from '@/modules/NFTDetail/selectors';
import { dragonManagementActions } from '@/modules/DragonManagementModule/store';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import messagesDetail from '@/components/NFTDetailViewModal/messages';
import EggEvolveInstructrionModal from './EggEvolveInstructrionModal';
import useCheckBalance from '@/hooks/useCheckBalance';
import { csprToMote } from '@/helpers/balance';
import { evoluteEggToDragonProcess } from '@/modules/EggHatching/actions';
import useLedgerErrorsHandler from '@/hooks/useLedgerErrorsHandler';
import { handleDeployResult } from '@/modules/EggPurchase/actions';
import { lockAndReloadEggDetail } from '@/modules/NFTDetail/actions';
import EggEvolveToDragonStatusModal from '@/components/EggEvolveToDragonStatusModal';
import useDragonDrop from '@/hooks/useDragonDrop';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import NetworkFeePanel from '@/components/NetworkFeePanel';
import commonMessages from '@/constants/commonMessages';
import LoadingBox from '@/components/LoadingBox';
import { NFTEggStatus } from '@/types/NFTItem';
import { useSpring, animated } from 'react-spring';
import SETTINGS from '@/constants/settings';
import InfoProcessingPanel from '@/components/InfoProcessingPanel';
import NFTMetadataBox from '../NFTDetailViewModal/NFTMetadataBox';
import configs from '@/constants/settings';

const EggEvolveDragonConfirmModal = () => {
  const MAKE_DRAGON_PAYMENT_AMOUNT = SETTINGS.DRAGON_HATCH_PAYMENT_AMOUNT; //CSPR
  const refVideoDefault = useRef<HTMLVideoElement>(null);
  const refVideoAnimation = useRef<HTMLVideoElement>(null);

  const [animationStarted, setAnimationStarted] = useState(false);
  const [videoAVisible, setVideoAVisible] = useState(true);

  const fadeIn = useSpring({
    opacity: videoAVisible && !animationStarted ? 1 : 0,
    from: { opacity: 1 },
    onRest: () => {
      if (animationStarted) {
        setVideoAVisible(false);
        refVideoAnimation.current?.play();
      }
    },
  });

  const layerVideoNormal = `${process.env.PUBLIC_URL}/bg_effect_remake.webm`;
  const videoEffect = `${process.env.PUBLIC_URL}/EggEffect.webm`;
  const { makeDragon, isDeploying } = useDragonDrop();
  const { checkBalanceAgainstAmount } = useCheckBalance();
  const dispatch = useDispatch();
  const { open } = useSelector(selectDragonMakeConfirmModal);
  const { loading: statusLoading } = useSelector(selectDragonMakeStatusModal);
  const { isLedgerConnected, toastError } = useLedgerErrorsHandler();
  const NFTDetailStore = useSelector(selectNFTDetailModal);
  const { data: dataNFTDetail } = NFTDetailStore;
  const {
    token_uri: image,
    tokenId,
    egg,
    isInstallment,
    classNFT,
    luckPointOnChain,
  } = useMemo(() => {
    try {
      if (!dataNFTDetail) {
        throw new Error('NFTData is missing');
      }
      return getNFTDetails(dataNFTDetail as NFTItem);
    } catch (error: any) {
      return {
        token_uri: null,
        tokenId: null,
        egg: null,
        isInstallment: null,
        classNFT: null,
        luckPointOnChain: null,
      };
    }
  }, [dataNFTDetail]);

  const luckyPoint = egg?.luck;
  const hasLuckyPointOffchain = Boolean(luckyPoint > 0);
  const hasClaimedLuckyPoint = useMemo(() => {
    if (isEmpty(luckPointOnChain)) {
      return false;
    }

    const pointOnChain = parseInt(luckPointOnChain?.value ?? '0', 10);
    return egg?.luck === pointOnChain;
  }, [egg, luckPointOnChain]);
  const onCloseHandler = useCallback(() => {
    dispatch(dragonManagementActions.hideMakeConfirmModal());

    setAnimationStarted(false);
    setVideoAVisible(true);
  }, [dispatch]);
  const shouldDisableDragronMakeButton = useMemo(() => {
    return Boolean(
      isInstallment ||
        isDeploying ||
        statusLoading ||
        egg?.status === NFTEggStatus.incubating ||
        egg?.isProcessing,
    );
  }, [
    egg?.isProcessing,
    egg?.status,
    isDeploying,
    isInstallment,
    statusLoading,
  ]);

  const onStartAnimation = useCallback(() => {
    setAnimationStarted(true);
  }, []);

  const onEvolveDragon = useCallback(async () => {
    try {
      // Prevent making Dragon when not enough balance
      if (!checkBalanceAgainstAmount(csprToMote(MAKE_DRAGON_PAYMENT_AMOUNT))) {
        toast.warn(messagesDetail.makeDragonNotEnoughCSPR.defaultMessage, {
          ...sharedToastProps,
          autoClose: true,
        });
        return;
      }

      const result: any = await dispatch(
        evoluteEggToDragonProcess({
          makeDragon,
          tokenId: String(tokenId),
          paymentAmount: csprToMote(MAKE_DRAGON_PAYMENT_AMOUNT),
        }),
      ).unwrap();

      if (result) {
        onStartAnimation();

        setTimeout(async () => {
          dispatch(dragonManagementActions.setMakeStatusModal(true));
          dispatch(dragonManagementActions.showMakeStatusModal());

          await dispatch(
            handleDeployResult({
              result: result?.deployResult,
              message: `Egg evolution process for token ${tokenId} is sent`,
              toastId: `egg-evolution-${tokenId}`,
              metadata: {
                from: 'nftDetail',
                id: tokenId,
                action: 'dragon making',
                uri: image.value,
                element: classNFT.value,
                seen: false,
              },
              configs: {
                skipStoringDeploy: isEmpty(result?.deployResult),
              },
              onCompleted: () => {
                dispatch(lockAndReloadEggDetail({ tokenId }));
                dispatch(dragonManagementActions.setMakeStatusLoading(false));
              },
            }),
          );
        }, 5000);

        return result;
      }
    } catch (err: any) {
      if (isLedgerConnected) {
        toastError(err);
      }
      console.log(`🚀 ~ onEvolveToDragon ~ err:`, err.message);
      toast.warn(messagesDetail.makeDragonFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
      dispatch(dragonManagementActions.setMakeStatusLoading(false));
    } finally {
      // onHideConfirmModalHandler();
    }
  }, [
    MAKE_DRAGON_PAYMENT_AMOUNT,
    checkBalanceAgainstAmount,
    classNFT,
    dispatch,
    image,
    isLedgerConnected,
    makeDragon,
    onStartAnimation,
    toastError,
    tokenId,
  ]);

  const onClickMakeDragonInstructionModal = useCallback(() => {
    dispatch(dragonManagementActions.showMakeInstructionModal());
  }, [dispatch]);

  if (!open || !dataNFTDetail) {
    return null;
  }

  return (
    <GModal
      blurOverlay
      show={open}
      className={'egg-evolve-dragon-confirm--modal'}
      onHide={onCloseHandler}
      disabledClose={isDeploying}
    >
      {hasLuckyPointOffchain && !hasClaimedLuckyPoint && (
        <InfoProcessingPanel
          message={messagesDetail.unclaimedLuckypoint.defaultMessage}
        />
      )}

      <div className="body-wrapper">
        <animated.video
          ref={refVideoDefault}
          onContextMenu={(evt) => evt.preventDefault()}
          className="video egg-evolve-dragon--effect-layer default"
          autoPlay
          playsInline
          muted
          loop
          style={{ ...fadeIn }}
        >
          <source src={layerVideoNormal} />
        </animated.video>
        <img src={image.value} alt="Egg" className="egg-evolve-dragon--egg" />
        <animated.video
          ref={refVideoAnimation}
          onContextMenu={(evt) => evt.preventDefault()}
          className="video egg-evolve-dragon--effect-layer dragon-hatch"
          autoPlay={false}
          playsInline
          muted
          loop={false}
          // style={{ ...playVideoB }}
          // onPlay={() => setVideoBVisible(false)}
          onEnded={() => {}}
        >
          <source src={videoEffect} />
        </animated.video>
      </div>
      {hasLuckyPointOffchain && (
        <div
          className={cn('lucky-point-star--wrapper', {
            unclaimed: !hasClaimedLuckyPoint,
          })}
        >
          <div className="star-block slot-1" />
          <div className="star-block" />
          <NFTMetadataBox
            className={`item-1 item--lucky-point ${
              hasClaimedLuckyPoint ? '' : 'unclaimed'
            }`}
            valueClassname="lucky-point"
            label="Lucky Point"
          >
            <div className="point">
              <span className="point-value">
                {configs.MAXIMUM_LUCKY_POINT}
                {/* {luckyPoint} */}
              </span>
              <span className="percent">%</span>
            </div>
          </NFTMetadataBox>
          <div className="star-block" />
          <div className="star-block slot-5" />
        </div>
      )}

      <NetworkFeePanel fee={MAKE_DRAGON_PAYMENT_AMOUNT} isMote={false} />
      <div className="actions">
        <Button
          disabled={animationStarted || shouldDisableDragronMakeButton}
          className="inside-nft-detail btn--nft-action btn--evolve-dragon"
          onClick={onEvolveDragon}
          // onClick={onStartAnimation}
          btnStyle="5"
          size="xl"
        >
          {statusLoading ? (
            <>
              <LoadingBox
                isHorizontal
                label="MAKING DRAGON"
                className="loading--making-dragon"
                minHeight={32}
              />
            </>
          ) : (
            <>
              <img
                className="icon icon--claim-snc"
                src={iconHatch}
                alt={commonMessages.labelDragonHatch.defaultMessage}
              />
              {commonMessages.labelDragonHatch.defaultMessage}
            </>
          )}
        </Button>
        <button
          onClick={onClickMakeDragonInstructionModal}
          className={'icon--how-to-merge'}
        >
          <QuestionCircleFill />
        </button>
      </div>
      <EggEvolveInstructrionModal />
      <EggEvolveToDragonStatusModal onClose={onCloseHandler} />
    </GModal>
  );
};

export default EggEvolveDragonConfirmModal;

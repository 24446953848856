import { useCallback, useState, useEffect } from 'react';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Heading } from '@/components/Typography';
import Button from '@/components/GButton';
import LoadingBox from '@/components/LoadingBox';
import CardFlippingGame from './CardFlippingGame';
import useFlippingCard from './useFlippingCard';
import CardLimitEntry from './CardLimitEntry';
import { getSafeHTML } from '@/helpers/html';

const ErrorBox = ({ onClose, error }: any) => (
  <div className="error-box--inline">
    <Heading h={4}>
      {error?.type === '3'
        ? 'Limit reached!'
        : 'No worries, Selvyn is having a look.'}
    </Heading>
    <div className="icon">
      <InfoCircleFill />
    </div>
    {error?.type === '2' ? (
      <div dangerouslySetInnerHTML={getSafeHTML(error?.reason)} />
    ) : (
      <p>{error?.reason}</p>
    )}

    <Button className="btn-error--close" size="small" onClick={onClose}>
      I Understand
    </Button>
  </div>
);

interface IProps {
  setCustomClass: (classes: string) => void;
  onClose?: () => void;
  change: boolean;
}

const CardFlippingGameContainer = ({
  onClose,
  change,
  setCustomClass,
}: IProps) => {
  const { loading, error } = useFlippingCard();

  const [isLimited, setLimited] = useState<boolean>(false);
  const shouldRenderError = Boolean(!loading && error);
  const shouldRenderGame = Boolean(!loading && !error);
  const onShowLimitedModal = useCallback(() => setLimited(true), []);
  const onCloseLimitedModal = useCallback(() => {
    if (shouldRenderError && onClose) {
      onClose();
    }
    setLimited(false);
  }, [shouldRenderError, onClose]);

  useEffect(() => {
    if (shouldRenderError) {
      setCustomClass('error');
    }
  }, [setCustomClass, shouldRenderError]);

  useEffect(() => {
    if (error && error.type === '3') {
      onShowLimitedModal();
    }
  }, [error, onShowLimitedModal]);

  return (
    <div className="luckyMoney--root mazeGame--root">
      {!shouldRenderError && (
        <Heading h={3} className="mt-3 mb-3">
          Win 789 CSPR and boosters to level up your Egg NFTs faster!
        </Heading>
      )}
      <div className="minigame--body">
        {loading && !error && <LoadingBox />}
        {shouldRenderError && error?.type !== '3' && (
          <ErrorBox error={error} onClose={onClose} />
        )}
        {shouldRenderGame && (
          <CardFlippingGame
            onShowLimitedModal={onShowLimitedModal}
            change={change}
          />
        )}
      </div>
      <CardLimitEntry open={isLimited} onClose={onCloseLimitedModal} />
      {!shouldRenderError && (
        <div className="game-instruction--background">
          <div className="game-instruction">
            <div className="game-instruction--box">
              <Heading h={4}>
                3 Easy steps to win! You can repeat these steps every day.
              </Heading>
              <ul>
                <li>Choose and flip 1 card to see your lucky prize</li>
                <li>
                  Share your lucky prize on Discord in our{' '}
                  <strong>#🎰・lottery</strong> channel
                </li>
                <li>Tweet about our lottery</li>
              </ul>
              <small>
                We will announce the winners every day on our Discord
              </small>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardFlippingGameContainer;

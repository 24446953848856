import { useEffect } from 'react';
import { useTransition, useSpringRef, animated } from 'react-spring';
import imgEgg from '@/assets/images/egg-02.webp';

const Egg = ({ isRewardInview }: any) => {
  const transApi = useSpringRef();
  const transition = useTransition(isRewardInview, {
    config: {
      tension: 120,
      friction: 14,
    },
    ref: transApi,
    from: { opacity: 0, transform: [0, 0, 0, 0.85] },
    enter: { opacity: 1, transform: [0, 0, 0, 1] },
    leave: { opacity: 0, transform: [0, 45, 0, 0.45] },
  });

  useEffect(() => {
    // Show egg
    if (!isRewardInview) {
      transApi.start();
    }

    // Hide the egg on reward shown
    if (isRewardInview) {
      transApi.set({ opacity: 0, transform: [0, 80, 0, 0.25] });
    }
  }, [isRewardInview, transApi]);

  return transition(({ opacity, transform }) => {
    return (
      <animated.img
        style={{
          opacity,
          transform: transform.to(
            (x, y, z, scale) => `translateY(${y}%) scale(${scale})`,
          ),
        }}
        src={imgEgg}
        className="egg-cracker--egg"
        alt="Egg"
      />
    );
  });
};

export default Egg;

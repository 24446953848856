import React, { useState, useCallback } from 'react';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import Button from '@/components/GButton';
import EggCollectionModal from '@/modules/EggCollection';
import { NFTCollectionModalActions } from '@/modules/EggCollection/store';
import { selectNFTCollectionModal } from '@/modules/EggCollection/selectors';
import { showConnectCSModal } from '@/modules/CasperSigner/store';
import useCurrentUser from '@/hooks/useCurrentUser';
import QASupportModal from '@/components/QASupportModal';
import NFTDetailViewModal from '@/components/NFTDetailViewModal';
import { selectNFTDetailModal } from '@/modules/NFTDetail/selectors';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import AnnoucementsModal from '@/components/Announcements/AnnoucementsModal';
import useDevice from '@/hooks/useDevice';
import iconLeaderboard from '@/assets/images/icon--leaderboard.png';
import iconHatch from '@/assets/images/icon--hatch.png';
import { leaderboardActions } from '@/modules/Leaderboards/store';
import { eggMergingActions } from '@/modules/EggMerging/store';
import iconMerge from '@/assets/images/icon--merge.png';
import EggHatchSelectingModal from '@/components/EggHatchSelectingModal';
import EggEvolveDragonConfirmModal from '@/components/EggEvolveToDragonModal';

const WorldSidebar = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const isDevice = useDevice();
  const isMobileSupport = MODULES_PERMISSION.USE_MOBILE_SUPPORT;
  const [QAModalOpen, setQAModalOpen] = useState<boolean>(false);
  const [annonucementsModalOpen, setAnnonucementsModalOpen] =
    useState<boolean>(false);
  const nftCollectionModalState = useSelector(selectNFTCollectionModal);
  const NFTDetailStore = useSelector(selectNFTDetailModal);
  const { open: showModal } = nftCollectionModalState;
  const { data: NFTDetail, open: showNFTDetailModal } = NFTDetailStore;
  const onViewLeaderboard = useCallback(() => {
    dispatch(leaderboardActions.showModal());
  }, [dispatch]);
  const onViewNFTCollection = useCallback(() => {
    if (!user?.activeKey) {
      dispatch(showConnectCSModal());
      return;
    }

    dispatch(NFTCollectionModalActions.showModal());
  }, [dispatch, user]);

  const onOpenMergingModal = useCallback(() => {
    if (!user?.activeKey) {
      dispatch(showConnectCSModal());
      return;
    }

    dispatch(eggMergingActions.showModal());
  }, [dispatch, user]);

  return (
    <div className="map-sidebar--root">
      <div className="buttons">
        {((!isMobileSupport && !isDevice) || isMobileSupport) && (
          <React.Fragment>
            <Button
              ignoreShadowStyle
              tooltip="View NFT Collection"
              className="btn--icon large use--elemental-background btn--view-collection"
              onClick={onViewNFTCollection}
            >
              <img
                style={{ width: 56 }}
                src={iconHatch}
                alt="View NFT Collection"
              />
              <span className="label">NFT Collection</span>
            </Button>
            <Button
              ignoreShadowStyle
              tooltip="Merging Hub"
              className="btn--icon large use--elemental-background btn--merging-hub"
              onClick={onOpenMergingModal}
            >
              <img style={{ width: 54 }} src={iconMerge} alt="Merging Hub" />
              <span className="label">Merging Hub</span>
            </Button>
          </React.Fragment>
        )}
        <Button
          ignoreShadowStyle
          tooltip="Q & A"
          className="btn--icon large use--elemental-background"
          onClick={() => setQAModalOpen(true)}
        >
          <QuestionCircleFill style={{ width: 26 }} />
          <span className="label">Q&A</span>
        </Button>
        {isDevice && isMobileSupport && (
          <Button
            ignoreShadowStyle
            tooltip="Leaderboard"
            className="btn--icon large use--elemental-background"
            onClick={onViewLeaderboard}
          >
            <img
              style={{ width: 50 }}
              src={iconLeaderboard}
              alt="Leaderboard"
            />
            <span className="label">Leaderboard</span>
          </Button>
        )}
        {/* <Button
          className="btn--icon use--elemental-background"
          onClick={() => setAnnonucementsModalOpen(true)}
        >
          <MegaphoneFill style={{ width: 26 }} />
        </Button> */}
      </div>
      <EggCollectionModal open={showModal} />
      {showNFTDetailModal && NFTDetail && <NFTDetailViewModal />}
      <QASupportModal
        open={QAModalOpen}
        onClose={() => setQAModalOpen(false)}
      />
      <AnnoucementsModal
        open={annonucementsModalOpen}
        onClose={() => setAnnonucementsModalOpen(false)}
      />
      <EggHatchSelectingModal />
      <EggEvolveDragonConfirmModal />
    </div>
  );
};

export default WorldSidebar;

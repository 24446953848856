import { AppThunk } from '@/app/store';
import { axios } from '@/services/axios';
import { IAuthState } from '@/modules/Auth/store';
import API from '@/constants/api';
import { signal } from '@/services/axios';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const hasFlippedCard = (): AppThunk => async (dispatch, getState) => {
  try {
    const gameName = "YourEggsYourPower";
    const state: { auth: IAuthState } = getState();
    const {
      auth: { currentUser },
    } = state;

    if (!currentUser?.activeKey) {
      throw new Error("Require connect CS");
    }
    const { activeKey } = currentUser;
    const { data } = await axios.get(API.canPlayGame(activeKey, gameName), {
      signal
    });

    await sleep(500);

    if (data && data?.result === false) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(`🚀 ~ hasFlippedCard::error `, error)
    return undefined;
  }
}

const startFlipCard = (signedMessage: string): AppThunk => async (dispatch, getState) => {
  try {
    const gameName = "YourEggsYourPower";
    const state: { auth: IAuthState } = getState();
    const {
      auth: { currentUser },
    } = state;

    if (!currentUser?.activeKey) {
      throw new Error("Require connect CS");
    }
    const { activeKey } = currentUser;
    const { data } = await axios.post(API.playGame(gameName), {
      signature: signedMessage,
      publicKey: activeKey,
    });

    await sleep(500);

    if (data) {
      // Allowed to play and get result
      if (data.result?.status) {
        // status: money | wishes
        return data.result.status;
      }

      // Already played
      if (data?.status === "failed") {
        return data.status;
      }
    }

    return false;
  } catch (error: any) {
    console.error(`🚀 ~ startFlipCard::error `, error.message)
    return undefined;
  }
}

export {
  startFlipCard,
  hasFlippedCard
}
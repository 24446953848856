const messages = {
  mergeFailed: {
    id: 'HammerBurnModule.burnHammerFailed',
    defaultMessage:
      "Something didn't look right. Selvyn has been noticed and having a look. You can safely close this",
  },
  mergeSuccess: {
    id: 'HammerBurnModule.burnHammerSuccess',
    defaultMessage:
      'Successfully and started burning process. You can safely close this',
  },
  infoBurnDragonIncomplete: {
    id: 'HammerBurnModule.infoBurnIncomplete',
    defaultMessage:
      "Hammber burn process wasn't completed! Please try again later.",
  },
  infoBurnDragonCompleted: {
    id: 'HammerBurnModule.infoBurnIncomplete',
    defaultMessage: 'Burning hammer started!',
  },
  intro: {
    id: 'HammerBurnModule.intro',
    defaultMessage:
      'Burning hammer for better chance of hatching Legendary dragon.',
  },
  claimInstruction: {
    id: 'HammerBurnModule.claimInstruction',
    defaultMessage:
      'Make sure you claim the Lucky Point (by swapping existing SNC, with any amount) before hatching dragon in order for the point effectively applied.',
  },
};

export default messages;

import { useMemo, useEffect, useCallback } from 'react';
import GModal from '@/components/GModal';
import isEqual from 'lodash/isEqual';
import InstallmentPaymentItem from '@/components/InstallmentPaymentItem';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import useCurrentUser from '@/hooks/useCurrentUser';
import LoadingBox from '@/components/LoadingBox';
import { selectInstallmentPaymentsListStore } from '@/modules/InstallmentsPayment/selectors';
import { fetchInstallmentPayments } from '@/modules/InstallmentsPayment/actions';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import { Heading } from '@/components/Typography';
import EmptyDataPlaceholder from '@/components/EmptyDataPlaceholder';

const InstallmentsListModal = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const { payments, open, loading, loaded } = useSelector(
    selectInstallmentPaymentsListStore,
    isEqual,
  );
  const onClose = useCallback(() => {
    dispatch(InstallmentsPaymentActions.hideModal());
  }, [dispatch]);

  const onFetchInstallmentPayments = useCallback(() => {
    dispatch(fetchInstallmentPayments());
  }, [dispatch]);

  const renderPaymentsList = useMemo(() => {
    if (!loaded) {
      return null;
    }

    if (!loading && !payments?.length) {
      return <EmptyDataPlaceholder className="empty-installment-payments" />;
    }

    return (
      <div className="installment-payments--list">
        {payments.map((each, index) => (
          <InstallmentPaymentItem
            details={each}
            key={`installment-payment-${each?.installmentId ?? index}`}
          />
        ))}
      </div>
    );
  }, [loaded, loading, payments]);

  // Clean up installment detail after closing
  useEffect(() => {
    if (!open) {
      dispatch(InstallmentsPaymentActions.updateInstallmentDetail(null));
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (user && !loading) {
      onFetchInstallmentPayments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GModal
      show={open}
      className={'installments-payment--modal'}
      onHide={onClose}
      blurOverlay
    >
      <div className="installments-payment--root">
        <Heading h={3} className="text-center">
          Installment Purchases
        </Heading>
        {!loaded && loading && <LoadingBox />}
        {renderPaymentsList}
      </div>
    </GModal>
  );
};

export default InstallmentsListModal;

import HappyHourEvent from './HappyHourEvent';
import Balancer from 'react-wrap-balancer';

const HappyHourEventBlock = () => {
  return (
    <Balancer className="event-message--root">
      <p>Hey everybody,</p>
      <p>
        EggForceNFT with our Partners: <strong>OriginStake, SpeedyStaking and CasperDash</strong> are delivering HUGE event for our #Hammer_Hodlers - 9999 CSPR, x2 reward
        profits and more, ONLY in Happy Hour Time 🥳
      </p>
      <p>So BREAKING NEWS - We will extend it as follow:</p>
      <p>⏰ Time: 1PM - 11PM UTC</p>
      <p>📅 Date: 15th - 28th Feb 2023</p>

      <p>1 #HammerNFT - 1 FORTUNE & 1 Time Only 💪</p>
      <p>Remember #Hatchers, get the WL Hammer NFT first! </p>
      <p>
        It is the condition to join this event and x2 your reward profit! 💪
      </p>
      <p>Claim WL Hammer NFT and GET READY now!🔥</p>
      <HappyHourEvent />
    </Balancer>
  );
};

export default HappyHourEventBlock;

import { createSlice } from '@reduxjs/toolkit';
import { IModal } from '@/types/modal';

export interface IInstallmentsPayment extends IModal {
  payments: Array<any>;
  detailModal: IModal & {
    data: any;
    tokens: Array<any>;
  };
  loaded: Boolean;
  statusModal: IModal;
  supportQAModal: IModal;
  refundReviewModal: IModal;
}

const initialState: IInstallmentsPayment = {
  payments: [],
  loading: false,
  open: false,
  loaded: false,
  detailModal: {
    loading: false,
    open: false,
    data: null,
    tokens: [],
  },
  statusModal: {
    loading: false,
    open: false,
  },
  supportQAModal: {
    loading: false,
    open: false,
  },
  refundReviewModal: {
    loading: false,
    open: false,
  },
};

const InstallmentsPayment = createSlice({
  name: 'InstallmentsPayment',
  initialState,
  reducers: {
    setLoading: (state: IInstallmentsPayment, action) => {
      state.loading = action.payload;
    },
    showModal: (state: IInstallmentsPayment) => {
      state.open = true;
    },
    hideModal: (state: IInstallmentsPayment) => {
      state.open = false;
    },
    setStatusLoading: (state: IInstallmentsPayment, action) => {
      state.statusModal.loading = action.payload;
    },
    showStatusModal: (state: IInstallmentsPayment) => {
      state.statusModal.open = true;
    },
    hideStatusModal: (state: IInstallmentsPayment) => {
      state.statusModal.open = false;
    },
    showQASupportModal: (state: IInstallmentsPayment) => {
      state.supportQAModal.open = true;
    },
    hideQASupportModal: (state: IInstallmentsPayment) => {
      state.supportQAModal.open = false;
    },
    showRefundReviewModal: (state: IInstallmentsPayment) => {
      state.refundReviewModal.open = true;
    },
    hideRefundReviewModal: (state: IInstallmentsPayment) => {
      state.refundReviewModal.open = false;
    },
    updateInstallmentPaymentList: (state: IInstallmentsPayment, action) => {
      state.payments = action.payload;
    },
    setInstallmentPaymentListLoaded: (state: IInstallmentsPayment, action) => {
      state.loaded = action.payload;
    },
    setInstallmentDetailModalLoading: (state: IInstallmentsPayment, action) => {
      state.detailModal.loading = action.payload;
    },
    showInstallmentDetailModal: (state: IInstallmentsPayment) => {
      state.detailModal.open = true;
    },
    hideInstallmentDetailModal: (state: IInstallmentsPayment) => {
      state.detailModal.open = false;
    },
    updateInstallmentDetail: (state: IInstallmentsPayment, action) => {
      state.detailModal.data = action.payload;
    },
    updateInstallmentDetailTokens: (state: IInstallmentsPayment, action) => {
      state.detailModal.tokens = action.payload;
    },
    clearInstallmentDetail: (state: IInstallmentsPayment) => {
      state.detailModal.data = null;
    },
    clearInstallmentPaymentData: (state: IInstallmentsPayment) => {
      state.payments = [];
      state.detailModal.data = null;
    },
  },
});

const InstallmentsPaymentActions = {
  ...InstallmentsPayment.actions,
};

export { InstallmentsPaymentActions };
export default InstallmentsPayment.reducer;

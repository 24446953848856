import { useEffect, useCallback, useState, useMemo } from 'react';
import shuffle from 'lodash/shuffle';
import useCurrentUser from '@/hooks/useCurrentUser';
import { useAppSelector, useAppDispatch as useDispatch } from '@/app/hooks';
import isEqual from 'lodash/isEqual';
import { startFlipCard } from './actions';
import Card from './Card';
import { genericModalActions } from '@/modules/GenericErrorModal/store';
import { selectConnectionType } from '@/modules/Auth/selectors';
import imageCard1Front from '@/assets/short-terms/card-1--front.jpg';
import imageCard1Back from '@/assets/short-terms/card-1--back.jpg';
import imageCard2Front from '@/assets/short-terms/card-2--front.jpg';
import imageCard2Back from '@/assets/short-terms/card-2--back.jpg';
import imageCard3Front from '@/assets/short-terms/card-3--front.jpg';
import imageCard3Back from '@/assets/short-terms/card-3--back.jpg';
import imageCard4Front from '@/assets/short-terms/card-4--front.jpg';
import imageCard4Back from '@/assets/short-terms/card-4--back.jpg';
import useSigners from '@/hooks/useSigners';

interface IProps {
  change: boolean;
  onShowLimitedModal: () => void;
}

const CardFlippingGame = ({ onShowLimitedModal, change }: IProps) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const currentWalletConnection = useAppSelector(selectConnectionType, isEqual);
  const [selectedCard, setSelectedCard] = useState<undefined | number>(
    undefined,
  );
  const [flipped, setFlippingCard] = useState<boolean>(false);
  const { signMessageAsync } = useSigners();

  const { randomCards } = useMemo(() => {
    const original = [
      {
        index: 1,
        front: imageCard1Front,
        back: imageCard1Back,
      },
      {
        index: 2,
        front: imageCard2Front,
        back: imageCard2Back,
      },
      {
        index: 3,
        front: imageCard3Front,
        back: imageCard3Back,
      },
      {
        index: 4,
        front: imageCard4Front,
        back: imageCard4Back,
      },
    ];

    return {
      randomCards: shuffle(original),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);
  const onFlipCardClient = useCallback(
    () => setFlippingCard((prev) => !prev),
    [],
  );
  const onFlipCardHandler = useCallback(
    async (order: number) => {
      try {
        const { activeKey } = user;
        const message =
          currentWalletConnection === 'ledger'
            ? `Casper:\nPlay-YourEggsYourPower-${activeKey}`
            : `Play-YourEggsYourPower-${activeKey}`;
        console.log(`🚀 ~ message:`, message);

        setSelectedCard(order);
        const signedMessage = await signMessageAsync({
          message,
          signingPublicKeyHex: activeKey,
        });
        console.log(`🚀 ~ onFlipCardHandler ~ signedMessage:`, signedMessage);
        if (!signedMessage) {
          throw new Error('Process has been cancelled');
        }

        if (signedMessage) {
          const result: any = await dispatch(startFlipCard(signedMessage));

          result && onFlipCardClient();
          return result;
        }

        return undefined;
      } catch (error: any) {
        console.error(`🚀 ~ onFlipCardHandler::`, error.message);
        /**
         * TODO:
         * Make a thunk calling:
         * - Set modal content
         * - Open modal
         */
        setSelectedCard(undefined);
        dispatch(genericModalActions.setTitle('Process has been cancelled'));
        dispatch(genericModalActions.setModalState(true));
      }
    },
    [
      currentWalletConnection,
      dispatch,
      onFlipCardClient,
      signMessageAsync,
      user,
    ],
  );

  useEffect(() => {
    return () => {
      setSelectedCard(undefined);
    };
  }, []);
  return (
    <div className="flipping-card--cards">
      {randomCards.map((card, index) => {
        return (
          <Card
            isSelected={Boolean(selectedCard === card.index)}
            flipped={flipped}
            onFlip={onFlipCardHandler}
            key={`card--${index}-${card.index}`}
            front={card.front}
            back={card.back}
            order={card.index}
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
            onShowLimitedModal={onShowLimitedModal}
          />
        );
      })}
    </div>
  );
};

export default CardFlippingGame;

import React from 'react';
import { WalletLinks } from '@/constants/publicURL';

const AnswerComponent = () => {
  return (
    <div>
      <p>
        To explore the Callista World, Hatchers need to connect their wallet on
        Casper Network. EggForce is supporting these below wallets on Casper
        Network:
      </p>
      <ul>
        <li>
          CasperDash Wallet Installation Guide:{' '}
          <a
            style={{ color: '#FFB356' }}
            href="https://docs.casperdash.io/user-guides/casperdash-extension-wallet/how-to-install-the-casperdash-extension"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Link
          </a>
        </li>
        <li>
          Casper Signer Installation Link:{' '}
          <a
            style={{ color: '#FFB356' }}
            href={WalletLinks.casperSigner}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Link
          </a>
        </li>
        <li>
          Casper Wallet Installation Link:{' '}
          <a
            style={{ color: '#FFB356' }}
            href={WalletLinks.casperWallet}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            Link
          </a>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(AnswerComponent);

import { useCallback } from 'react';
import GModal from '@/components/GModal';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';

// Dragons Mock
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';

import CSPRStakingCalculator from '@/components/CSPRStakingCalculator';

const MockCSPRCalculatorModal = ({ className }: any) => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { csprCalculatorModal } = store;

  const onCloseHandler = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideCSPRCalculatorModal());
  }, [dispatch]);

  return (
    <GModal
      show={csprCalculatorModal.open}
      backdrop="static"
      keyboard={false}
      onHide={onCloseHandler}
      className={'cspr-caluculator-modal'}
    >
      <CSPRStakingCalculator />
    </GModal>
  );
};

export default MockCSPRCalculatorModal;

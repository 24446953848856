import React, { useCallback } from 'react';
import cn from 'classnames';
import add from 'date-fns/add';
import Balancer from 'react-wrap-balancer';
import BoxInfo from '@/components/BoxInfo';
import { Heading } from '@/components/Typography';
import logoCSPR from '@/assets/images/logo--cspr.svg';
import { formatCSPRFull } from '@/helpers/balance';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectInstallmentPaymentsDetailModal } from '@/modules/InstallmentsPayment/selectors';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfoProcessingPanel from '@/components/InfoProcessingPanel';
import type { TypePreviewInstallmentPayment } from '@/types/package';
import PackagePanel from '@/components/PackagePanel';
import MeLemLogo from '@/components/MeLemLogo';
import { formateDate } from '@/helpers/datetime';
import LoadingBox from '@/components/LoadingBox';
import TableInstallmentPackageSummary from '@/components/TableInstallmentPackageSummary';
import messages from '@/modules/InstallmentsPayment/messages';

const InstallmentPreviewPoint = (props: any) => {
  const { paymentSchedule, payment, scheduleOrder } = props;
  return (
    <div className={`payment-point point--${scheduleOrder}`}>
      <div className="payment-point--preview">
        {scheduleOrder === '1' && (
          <span className="payment-point--urh">
            <div className="btn-pulse point-pulse desktop is--completed" />
            <span className="text">You are here</span>
          </span>
        )}
        <div className="info">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`plan-payment--preview-${scheduleOrder}`}>
                Payment {scheduleOrder}
              </Tooltip>
            }
          >
            <span className="payment-point--value">
              <img className="icon" src={logoCSPR} alt="CSPR" />
              <span className="text">{formatCSPRFull(payment)} CSPR</span>
            </span>
          </OverlayTrigger>
        </div>
        <div className="datetime">{paymentSchedule}</div>
      </div>
    </div>
  );
};

const InstallmentReviewData = ({
  packageData,
  selectedIndex,
  renderInput,
}: any) => {
  const detailData = useSelector(selectInstallmentPaymentsDetailModal);
  const { loading } = detailData;
  const data: TypePreviewInstallmentPayment = detailData.data;

  const getPaymentSchedule = useCallback((order: string) => {
    switch (order) {
      case '1':
        return 'Today';
      case '2': {
        return formateDate(
          add(new Date(), {
            days: 31,
          }),
        );
      }
      case '3':
        return formateDate(
          add(new Date(), {
            days: 31 * 2,
          }),
        );
      default:
        return '';
    }
  }, []);

  if (loading) {
    return <LoadingBox />;
  }

  if (!loading && !data) {
    return (
      <div className="table-package--wrapper empty-data">
        <Balancer>{messages.errorLoadingBNPL.defaultMessage}</Balancer>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="purchase-installment--details">
        {!data.isBuyable && (
          <InfoProcessingPanel
            className="installment-plan--out-of-stock-plan"
            message={messages.errorOutOfStockBNPL.defaultMessage}
          />
        )}
        <div className={cn('purchase-installment--info')}>
          <div className="header">
            <Heading h={5}>Installment Plan Details</Heading>
            <MeLemLogo />
          </div>
          <div className={cn('installment-plan--review-wrapper')}>
            <div className="installment-plan--progress-wrapper">
              <PackagePanel
                className="installment-plan--package no-cursor"
                packageIdx={selectedIndex}
              >
                {['1', '2', '3'].map((item) => (
                  <InstallmentPreviewPoint
                    key={`package-preview--${item}`}
                    scheduleOrder={item}
                    payment={item === '1' ? data.downPayment : data.planPayment}
                    paymentSchedule={getPaymentSchedule(item)}
                  />
                ))}
              </PackagePanel>
            </div>
            <div className="installment-plan--details">
              <BoxInfo
                label="Interest Rate"
                value={`${data.interest}%`}
                isHorizontal={false}
              />
              <BoxInfo
                label="Installment plan"
                value={`${data.duration} Months`}
                isHorizontal={false}
              />
              <div className="monthly-payment--summary">
                <div className="text">
                  <span className="top">Monthly Payment</span>
                  <span className="bottom">(every 31 days)</span>
                </div>
                <div className="value">
                  <img className="icon" src={logoCSPR} alt="CSPR" />
                  <span className="amount">
                    {formatCSPRFull(data.planPayment)} CSPR
                  </span>
                </div>
              </div>
            </div>
            {renderInput}
          </div>
        </div>
      </div>
      <TableInstallmentPackageSummary packageData={packageData} />
    </React.Fragment>
  );
};

export default InstallmentReviewData;

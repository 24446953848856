import { useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Heading } from '../Typography';
import CustomScrollbar from "../CustomScrollbar";
import ChapterOne from '../StoryChapters/ChaperOne';
import ChapterFullscreenModal from "../ChapterFullscreenModal";
import "swiper/css/pagination";

const Chapters = () => {
  const [show, setShow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    {
      heading: "Chapter I",
      component: ChapterOne
    }
  ]
  const swiperProps = {
    allowTouchMove: true,
    grabCursor: false,
    speed: 800,
    spaceBetween: 20,
    slidesPerView: 1,
    autoplay: false,
    breakpoints: {}, 
    modules: [Pagination],
    pagination: {
      clickable: true,
      el: ".chapters-pagination--root"
    }
  };
  const CurrentChapter = data[currentSlide].component;
  return (
    <div className="story-chapters--wrapper">
      <div className="story-chapters--container">
        <button onClick={() => setShow(!show)} className="btn--modal-maximize" />
        <div className="story-chapter--heading">
          <Heading h={2}>{data[currentSlide].heading}</Heading>
        </div>
        <div className="story-chapter--body">
          <Swiper {...swiperProps} 
            onSlideChange={sw => {
              setCurrentSlide(sw.activeIndex);
            }}
            className="chapters-swiper--root">
            {data.map(eachChapter => (
              <SwiperSlide key={`slide--${eachChapter.heading}`} className="chapters-swiper--item">
                <div className="story-chapter--content">
                  <CustomScrollbar isAlwaysVisible>
                    <eachChapter.component />
                  </CustomScrollbar>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="chapters-pagination--root"></div>
      </div>
      <ChapterFullscreenModal heading={data[currentSlide].heading} show={show} onHide={() => setShow(false)}>
        <CurrentChapter />
      </ChapterFullscreenModal>
    </div>
  )
};

export default Chapters;

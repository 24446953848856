import { createSlice } from '@reduxjs/toolkit';
import { LeaderboardTabEnum, LeaderboardSpec } from '@/types/leaderboard';

const initialState: LeaderboardSpec = {
  currentTab: LeaderboardTabEnum.INCUBATORS,
  data: [],
  loading: false,
  open: false,
};

const Leaderboard = createSlice({
  name: 'Leaderboard',
  initialState,
  reducers: {
    setLoading: (state: LeaderboardSpec, action) => {
      state.loading = action.payload;
    },
    showModal: (state: LeaderboardSpec) => {
      state.open = true;
    },
    hideModal: (state: LeaderboardSpec) => {
      state.open = false;
    },
    selectTab: (state: LeaderboardSpec, action) => {
      state.currentTab = action.payload;
    },
    setData: (state: LeaderboardSpec, action) => {
      state.data = action.payload;
    },
  },
});
const leaderboardActions = {
  ...Leaderboard.actions,
};

export { leaderboardActions };
export default Leaderboard.reducer;

import { useCallback } from 'react';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import useCurrentUser from '@/hooks/useCurrentUser';
import useConfirmDeploy from '@/hooks/useConfirmDeploy';
import {
  refundInstallmentProcess,
  payInstallmentProcess,
} from '@/modules/InstallmentsPayment/actions';
import type {
  TypeRefundInstallmentPayment,
  TypePayInstallmentPayment,
} from '@/types/package';

const useInstallmentPayment = () => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const { executeDeploy, isDeploying, isError } = useConfirmDeploy();

  const buildPayInstallmentPaymentDeployFn = useCallback(
    (data: TypePayInstallmentPayment) => async () => {
      return await dispatch(payInstallmentProcess(data)).unwrap();
    },
    [dispatch],
  );

  const buildRefundInstallmentPaymentDeployFn = useCallback(
    (data: TypeRefundInstallmentPayment) => async () => {
      return await dispatch(refundInstallmentProcess(data)).unwrap();
    },
    [dispatch],
  );

  const onPay = useCallback(
    async (data: TypePayInstallmentPayment) => {
      if (!user) {
        return;
      }

      const { activeKey } = user;
      const result = await executeDeploy(
        buildPayInstallmentPaymentDeployFn(data),
        activeKey,
        activeKey,
      );

      return result;
    },
    [buildPayInstallmentPaymentDeployFn, executeDeploy, user],
  );

  const onRefund = useCallback(
    async (data: TypeRefundInstallmentPayment) => {
      if (!user) {
        return;
      }

      const { activeKey } = user;
      const result = await executeDeploy(
        buildRefundInstallmentPaymentDeployFn(data),
        activeKey,
        activeKey,
      );

      return result;
    },
    [buildRefundInstallmentPaymentDeployFn, executeDeploy, user],
  );

  return {
    pay: onPay,
    refund: onRefund,
    isDeploying,
    isError,
  };
};

export default useInstallmentPayment;

import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import isEqual from 'lodash/isEqual';
import {
  useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';
import { Heading } from '@/components/Typography';
import { GInputField } from '@/components/GInput';
import Button from '@/components/GButton';
import { FormRenderProps } from 'react-final-form';
import isEmail from 'validator/lib/isEmail';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';
import { FormApi } from 'final-form';
import { registerLuckyNumber } from '@/modules/EggForceJourneyDemoModule/actions';
import LuckyNumberRegisterStatusModal from './LuckyNumberRegisterStatusModal';
import { Links } from '@/constants/publicURL';
import { composeValidators, required } from '@/services/reactFinalFormServices';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';
import Balancer from 'react-wrap-balancer';

const mustBeEmail = (value: any) =>
  isEmail(value) ? undefined : 'Please enter valid email address';
const mustBeCasperWalletAddressOptional = (value: any) => {
  if (!value || (typeof value === 'string' && value.trim() === '')) {
    return undefined;
  }

  return isValidPublicKey(value)
    ? undefined
    : 'Please use a Casper wallet address';
};

interface LuckyNumberRegisterFormSpec {
  walletAddress?: string;
  email?: string;
  idDiscord?: string;
  idTelegram?: string;
}

const LuckyNumberRegistratioFormComponent = (props: FormRenderProps) => {
  const { luckyNumberFormModal } = useSelector(
    selectEggForceJourneyModule,
    isEqual,
  );
  const { loading } = luckyNumberFormModal;

  return (
    <form onSubmit={props.handleSubmit}>
      <Container className="p-0">
        <Row>
          <Form.Group as={Col} xs={12} className="ginput-group mb-3">
            <Field
              label="Email (*)"
              name="email"
              type="text"
              component={GInputField}
              validate={composeValidators(required, mustBeEmail)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            as={Col}
            xs={12}
            className="ginput-group mb-3 field--wallet-address"
          >
            <Field
              label="CSPR Wallet Address (*)"
              name="walletAddress"
              type="text"
              component={GInputField}
              validate={composeValidators(
                required,
                mustBeCasperWalletAddressOptional,
              )}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={12} md="6" className="ginput-group mb-3">
            <Field
              label="Discord Username (optional)"
              name="idDiscord"
              type="text"
              component={GInputField}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md="6" className="ginput-group mb-3">
            <Field
              label="Telegram Username (optional)"
              name="idTelegram"
              type="text"
              component={GInputField}
            />
          </Form.Group>
        </Row>
      </Container>

      <Button
        disabled={loading}
        size="small"
        className="btn--snc-register"
        type="submit"
      >
        {loading ? `Registering...` : `Register`}
      </Button>
    </form>
  );
};

const LuckyNumberRegistration = ({ isModal = false }: any) => {
  const dispatch = useDispatch();
  const initValues: LuckyNumberRegisterFormSpec = useMemo(
    () => ({
      walletAddress: '',
      email: '',
      idDiscord: '',
      idTelegram: '',
    }),
    [],
  );
  const onSubmit = useCallback(
    async (values: LuckyNumberRegisterFormSpec, form: FormApi) => {
      try {
        dispatch(
          EggForceJourneyDemoModuleActions.setLuckyNumberFormLoading(true),
        );
        dispatch(
          EggForceJourneyDemoModuleActions.updateResultStatusMessage(''),
        );
        const result: any = await dispatch(
          registerLuckyNumber(values),
        ).unwrap();
        console.log(`🚀 ~ result:`, result);

        if (result?.result === false) {
          dispatch(
            EggForceJourneyDemoModuleActions.updateResultStatusModal(false),
          );
          dispatch(EggForceJourneyDemoModuleActions.showStatusModal());
          return;
        }

        // @ts-ignore: TODO twq is injected from tag manager.
        // twq('event', 'tw-oeheh-ofrm3', {
        //   value: values.walletAddress,
        //   status: 'subscribed',
        //   email_address: values.email,
        // });

        setTimeout(() => {
          dispatch(
            EggForceJourneyDemoModuleActions.updateLuckyNumberCode(
              result?.lead.airdropCode,
            ),
          );
          dispatch(
            EggForceJourneyDemoModuleActions.updateResultStatusModal(true),
          );
          dispatch(EggForceJourneyDemoModuleActions.showStatusModal());
          // Reset form
          // https://dev.to/jetrockets/reset-values-in-react-final-form-w-keepdirtyonreinitialize-26cf
          form.reset(initValues);
        }, 500);
      } catch (err: any) {
        dispatch(
          EggForceJourneyDemoModuleActions.updateResultStatusMessage(
            'Email or Casper wallet address was already registered.',
          ),
        );
        dispatch(
          EggForceJourneyDemoModuleActions.updateResultStatusModal(false),
        );
        dispatch(EggForceJourneyDemoModuleActions.showStatusModal());
      } finally {
        dispatch(
          EggForceJourneyDemoModuleActions.setLuckyNumberFormLoading(false),
        );
      }
    },
    [dispatch, initValues],
  );

  return (
    <div
      id={isModal ? `snc-registration-form--map` : `snc-registration-form`}
      className={cn('snc-registration-form--root', {
        'is-modal': isModal,
      })}
    >
      <Heading
        h={3}
        className={cn('mb-3 primary-heading', {
          'text-center': isModal,
        })}
      >
        Thank you for trying our demo!
      </Heading>
      <div className="luckynumber--text">
        <Balancer>
          To show our gratitude we’re giving away X free Egg NFTs. Just fill in
          the form below and you’ll receive a Lucky Number. On{' '}
          <strong>December 18th</strong> we’ll let you know if your number won.
          Good luck.
        </Balancer>
      </div>
      <div>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initValues}
          render={LuckyNumberRegistratioFormComponent}
        />
        <div className="game-instruction snc-airdrop">
          <div className="game-instruction--box">
            <ul>
              <li>
                If you have any more questions or you need support, then feel
                free to reach out via{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={Links.telegramCommunity}
                >
                  Telegram
                </a>{' '}
                or{' '}
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href={Links.discord}
                >
                  Discord
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
      <LuckyNumberRegisterStatusModal />
    </div>
  );
};

export default LuckyNumberRegistration;

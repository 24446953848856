import { useCallback } from 'react';
import { useAppSelector as useSelector } from '@/app/hooks';
import isEqual from 'lodash/isEqual';
import LeaderboardTabs from './LeaderboardTabs';
import LeaderboardUsers from './LeaderboardUsers';
import LeaderboardValidators from './LeaderboardValidators';
import LeaderboardTopEggs from './LeaderboardTopEggs';
import { selectLeaderboardModule } from '@/modules/Leaderboards/selectors';
import { LeaderboardTabEnum } from '@/types/leaderboard';
import EmptyDataPlaceholder from '@/components/EmptyDataPlaceholder';
import LoadingBox from '@/components/LoadingBox';

const LeaderboardLayout = () => {
  const { currentTab, loading, data } = useSelector(
    selectLeaderboardModule,
    isEqual,
  );
  const renderLeaderboard = useCallback(() => {
    if (loading) {
      return <LoadingBox />;
    }

    if (!loading && !data?.length) {
      return <EmptyDataPlaceholder />;
    }

    switch (currentTab) {
      case LeaderboardTabEnum.EGGS:
        return <LeaderboardTopEggs />;
      case LeaderboardTabEnum.VALIDATORS:
        return <LeaderboardValidators />;
      default:
        return <LeaderboardUsers />;
    }
  }, [currentTab, data?.length, loading]);

  return (
    <div className="leaderboard-layout--root">
      <LeaderboardTabs />
      <div className="leaderboard-layout--body">{renderLeaderboard()}</div>
    </div>
  );
};

export default LeaderboardLayout;

import OptionItem from '@/components/OptionItem';
import imgXP from '@/assets/images/icon--xp.png';
import { formatXPValue } from '@/modules/NFTDetail/utils';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectLeaderboardModule } from '@/modules/Leaderboards/selectors';
import CustomScrollbar from '@/components/CustomScrollbar';
import CSPRValue from '@/components/CSPRValue';
import LeaderboardRankNumber from './LeaderboardRankNumber';

const LeaderboardTopEggs = () => {
  const { data } = useSelector(selectLeaderboardModule);

  return (
    <div>
      <div className="table-leaderboard--wrapper for-top-eggs">
        <CustomScrollbar>
          {data.map((item: any, index) => {
            return (
              <div className="item--row" key={`leaderboard-row--${item._id}`}>
                <div className="column column-1">
                  <LeaderboardRankNumber value={index + 1} />
                </div>
                <div className="column column-2">
                  <OptionItem
                    className="is-leaderboard-item leaderboard-item--egg"
                    text={`Egg NFT #${item.tokenId}`}
                    src={item.metadata?.token_uri}
                  />
                </div>
                <div className="column column-3">
                  {item.metadata?.['Level']}
                </div>
                <div className="column column-4">
                  <div className="leaderboard-item--text-inline">
                    <span className="value">
                      {formatXPValue(item.metadata?.XP ?? 0)} XP
                    </span>
                    <img
                      loading="lazy"
                      className="icon icon-xp"
                      src={imgXP}
                      alt="XP"
                    />
                  </div>
                </div>
                <div className="column column-5 last">
                  <div className="leaderboard-item--text-inline">
                    <CSPRValue
                      reverse
                      shouldFormat={false}
                      value={formatXPValue(item.stakedAmount)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default LeaderboardTopEggs;

import imgGameplayImg1 from '@/assets/images/gameplay--img-1.webp';
import imgHammer from '@/assets/images/hammer-sample.png';

const EmptyGridItems = ({ useHammer = false, total = 9 }) => (
  <div className="empty-slot--wrapper">
    {new Array(total).fill(1).map((_, index) => (
      <div key={`empty-item--${index}`} className="empty-slot--item">
        <img
          loading="lazy"
          src={useHammer ? imgHammer : imgGameplayImg1}
          alt="Sample NFT"
        />
      </div>
    ))}
  </div>
);

export default EmptyGridItems;

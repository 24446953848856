import { useAccount } from '@casperdash/usewallet';
import type { Account } from '@casperdash/usewallet';
import { accountActions } from '@/modules/Auth/store';
import { useAppDispatch } from '@/app/hooks';
import { ConnectionTypes } from '@/constants/settings';
import WalletConnectors from '@/services/WalletConnectors';
interface useAccountWalletSpec {
  skipOnChange?: boolean;
  skipOnConnect?: boolean;
}

const useAccountWallet = (
  { skipOnConnect, skipOnChange }: useAccountWalletSpec = {
    skipOnConnect: false,
    skipOnChange: false,
  },
) => {
  const dispatch = useAppDispatch();
  const { publicKey } = useAccount({
    onConnect: (account: Account) => {
      if (skipOnConnect) {
        return;
      }
      if (account.connector?.id) {
        dispatch(
          accountActions.setWalletConnected(
            account.connector?.id as ConnectionTypes,
          ),
        );
      }
    },
    onChange: (account: Account) => {
      if (skipOnChange) {
        return;
      }

      const { isConnected, connector } = account;
      const isLedgerConnector = connector?.id === ConnectionTypes.ledger;
      /**
       * Ledger doesn't support `open` `close` popup like Extension
       * so it should not trigger any `connect`
       */
      if (!isConnected && !isLedgerConnector && connector) {
        const walletInstance = WalletConnectors.from(connector?.id);
        if (walletInstance) {
          walletInstance.connect();
        }
      }
    },
  });

  return {
    publicKey,
  };
};

export default useAccountWallet;

import { useMemo, useCallback, useState } from 'react';
import Big from 'big.js';
import { InfoCircle } from 'react-bootstrap-icons';
import { Heading } from '@/components/Typography';
import CSPRValue, { SNCValue } from '@/components/CSPRValue';
import GTokenInput from '../GTokenInput';
import { csprToMote } from '@/helpers/balance';

type StakeReward = {
  cspr: number;
  snc: number;
};
interface DataAPR {
  yearly: StakeReward;
  monthly: StakeReward;
  weekly: StakeReward;
  daily: StakeReward;
}

type Period = keyof DataAPR;

const CSPRStakingCalculator = () => {
  const APR = 10.73;
  const [value, setValue] = useState<number>(1000);
  const onChange = useCallback((value: number) => {
    setValue(value);
  }, []);

  const data: DataAPR | undefined = useMemo(() => {
    if (!value) {
      return;
    }

    const valueMote = Big(csprToMote(value));
    const yearly = Big(valueMote).mul(APR).div(100);
    // const yearlyReward = Big(valueMote).plus(yearly);
    const yearlyReward = Big(0).plus(yearly);

    return {
      yearly: {
        cspr: yearlyReward.toNumber(),
        snc: yearlyReward.mul(10).toNumber(),
      },
      monthly: {
        cspr: yearlyReward.div(12).toNumber(),
        snc: yearlyReward.div(12).mul(10).toNumber(),
      },
      weekly: {
        cspr: yearlyReward.div(52).toNumber(),
        snc: yearlyReward.div(52).mul(10).toNumber(),
      },
      daily: {
        cspr: yearlyReward.div(365).toNumber(),
        snc: yearlyReward.div(365).mul(10).toNumber(),
      },
    };
  }, [value]);

  return (
    <div className="cspr-calculator--root">
      <div className="column--input">
        <div className="heading">
          <Heading h={5}>CALCULATE YOUR PROFIT</Heading>
        </div>
        <div className="body">
          <div className="title">Enter CSPR Amount</div>
          <GTokenInput
            min={1}
            value={value}
            onInputChange={onChange}
            hideMaxButton
            useCompactLogo
          />
          <div className="text--apy">
            APY <span>10.73%</span>
          </div>
        </div>
        <div className="footer">
          <span>
            <InfoCircle className="icon--helper" /> The calculations only give
            an approximate amount, as the price fluctuates.
          </span>
        </div>
      </div>
      <div className="column--stats">
        <div className="earnings-table--wrapper">
          <div className="header">
            <Heading h={5}>REWARD EARNINGS</Heading>
            <span className="cspr-snc--rate">1 CSPR = 10 SNC</span>
          </div>

          <div className="earnings--wrapper">
            {['daily', 'weekly', 'monthly', 'yearly'].map((period) => (
              <div className="earning--row" key={period}>
                <div className="earning--period">{period}</div>
                <div className="earning--value">
                  <CSPRValue value={data ? data[period as Period].cspr : 0} />
                </div>
                <div className="earning--value snc">
                  <SNCValue value={data ? data[period as Period].snc : 0} />
                </div>
              </div>
            ))}
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </div>
  );
};

export default CSPRStakingCalculator;

import React, { useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import GModal from '@/components/GModal';
import { Heading } from '@/components/Typography';
import QAContact from './QAContact';
import mockData from "./mockData";

interface IProps {
  onClose?: () => void;
  open?: boolean;
  className?: string;
}

const QASupportModal = ({ open = false, onClose }: IProps) => {
  const onCloseHandler = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <GModal
      show={open}
      backdrop="static"
      onHide={onCloseHandler}
      className={"qa-support-modal--root"}
    >
      <div>
        <Heading className="text-center text-gradient" h={3}>
          EggForce Q & A
        </Heading>
        <Accordion
          defaultActiveKey="0"
          flush
          className="eggForce-accordion--root"
        >
          {mockData.map((question, index) => {
            const isFC = Boolean(typeof question.answer === "function");
            const item = (isFC ? <question.answer /> : question.answer) as React.ReactNode;
            return (
              <Accordion.Item
                eventKey={String(index)}
                key={`question--${index}`}
              >
                <Accordion.Header className="eggForce-accordion--header">
                  {question.question}
                </Accordion.Header>
                <Accordion.Body className="eggForce-accordion--body">
                  {item}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
        <QAContact />
      </div>
    </GModal>
  );
};

export default QASupportModal;

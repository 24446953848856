import cn from 'classnames';
import logoCSPR from '@/assets/images/logo--cspr.svg';
import imgSNC from '@/assets/images/icon--snc.png';
import { formatCSPRFull } from '@/helpers/balance';

const CSPRValue = ({
  value,
  reverse = false,
  className = '',
  shouldFormat = true, // by default this value is in MOTE
  hasCSPRLogo = true,
}: any) => {
  return (
    <span
      className={cn('cspr-value--root', className, {
        reverse,
      })}
    >
      <span className="text">
        {hasCSPRLogo && <img className="icon" src={logoCSPR} alt="CSPR" />}
        {shouldFormat ? formatCSPRFull(value) : value}{' '}
        <span className="cspr-label">CSPR</span>
      </span>
    </span>
  );
};

export const SNCValue = ({
  value,
  reverse = false,
  className = '',
  shouldFormat = true, // by default this value is in MOTE
  hasCSPRLogo = true,
}: any) => {
  return (
    <span
      className={cn('cspr-value--root', className, {
        reverse,
      })}
    >
      <span className="text">
        {hasCSPRLogo && <img className="icon" src={imgSNC} alt="SNC" />}
        {shouldFormat ? formatCSPRFull(value) : value}{' '}
        <span className="cspr-label">SNC</span>
      </span>
    </span>
  );
};

export default CSPRValue;

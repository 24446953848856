import { Heading } from '@/components/Typography';
// import { useState, useCallback } from 'react';
// import imgSNC from '@/assets/images/icon--snc.png';
// import { NFTBenefitMobileModal } from '@/components/NFTBenefit';

const SNCTokenomic = () => {
  return (
    <div className="snc-block--child block--nft-tokenomic">
      <Heading className="heading-primary text-gradient text-center mb-4">
        SNC Tokenomic
      </Heading>
      <div className="snc-tokenomic--content">
        <div className="coming-soon">
          <div className="overlay"></div>
          <div className="text">Coming Soon</div>
        </div>
      </div>
    </div>
  );
};

export default SNCTokenomic;

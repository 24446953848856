import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import Balancer from 'react-wrap-balancer';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import useDevice from '@/hooks/useDevice';
import { ItemTypes } from '@/types/EggMerge';
import Button from '@/components/GButton';
import EmptyEggCollection from '@/modules/EggCollection/EmptyEggCollection';
import { eggMergingActions } from '@/modules/EggMerging/store';
import { useAppDispatch } from '@/app/hooks';
import CustomScrollbar from '@/components/CustomScrollbar';
import { Box } from '@/components/EggMergingLayout/Box';
import EggMergingInstructionModal from '@/components/EggMergingLayout/EggMergingInstructionModal';
import EggPlaceholderWrapper from '@/components/EggMergingLayout/EggPlaceholderWrapper';
import EggMergingStatusModal from '@/components/EggMergingLayout/EggMergingStatusModal';

import { Heading } from '@/components/Typography';
import messages from '@/modules/EggMerging/messages';
import iconMerge from '@/assets/images/icon--merge.png';
import InfiniteLoader from '@/components/InfiniteLoader';
import NetworkFeePanel from '@/components/NetworkFeePanel';

import {
  EggForceJourneyDemoModuleActions,
  GuideStep,
} from '@/modules/EggForceJourneyDemoModule/store';
import {
  selectEggForceJourneyMergeStatus,
  selectEggForceJourneyModule,
} from '@/modules/EggForceJourneyDemoModule/selectors';
import { getNFTDetails } from '@/modules/NFTDetail/utils';
import { NFTTypeEnum } from '@/types/NFTItem';
import useMockEggMerging from './useMockEggMerging';
import noop from 'lodash/noop';
import { DragonGuideStep7b } from './DragonGuideStep7';

const EggMergingMain = ({
  isValidating,
  refetch,
  eggs,
  isLoading,
  hasMore,
  fetchMore,
}: any) => {
  const dispatch = useAppDispatch();
  const {
    paymentAmount,
    eggPlaceholders,
    getDroppedEgg,
    validate,
    shouldDisableMergeButton,
    merge,
    isDropped,
    handleDrop,
    handleRemove,
  } = useMockEggMerging({ eggs, refetch });
  const store = useSelector(selectEggForceJourneyModule);
  const { currentGuideStep, isStarted, nftMergeModal } = store;
  const renderGuide = useMemo(() => {
    if (!isStarted || !nftMergeModal.lastMergedEggId) {
      return null;
    }

    if (currentGuideStep === GuideStep.step7b) {
      return <DragonGuideStep7b />;
    }

    return null;
  }, [currentGuideStep, isStarted, nftMergeModal.lastMergedEggId]);
  const onStartMergingHandler = useCallback(async () => {
    const resultValidate = validate();
    if (!resultValidate) {
      return;
    }

    await merge(eggPlaceholders);
  }, [eggPlaceholders, merge, validate]);

  const onClickMergeInstructionModal = useCallback(() => {
    dispatch(eggMergingActions.showMergeInstructionModal());
  }, [dispatch]);

  const renderBoxes = useCallback(() => {
    if (!isLoading && !eggs?.length) {
      // If deployHash appears as a list, this means a minting process in in progress
      return (
        <EmptyEggCollection
          isValidating={isValidating}
          isLoading={isLoading}
          reload={refetch}
        />
      );
    }

    return (
      <div style={{ height: 610, position: 'relative' }}>
        {renderGuide}
        <CustomScrollbar isAlwaysVisible>
          <div className="nft-grid-compact--container">
            {eggs?.map((item: any) => (
              <Box
                key={`egg-box--${item.tokenId}`}
                item={item}
                type={ItemTypes.EGG}
                isDropped={isDropped(item.tokenId)}
                isDeploying={false}
                primaryElement={
                  eggPlaceholders?.[0]?.lastDroppedItem ?? undefined
                }
              />
            ))}
          </div>
          <InfiniteLoader
            hasMore={hasMore}
            fetchMore={fetchMore}
            loading={isLoading}
          />
        </CustomScrollbar>
      </div>
    );
  }, [
    isLoading,
    eggs,
    hasMore,
    fetchMore,
    isValidating,
    refetch,
    isDropped,
    eggPlaceholders,
    renderGuide,
  ]);

  return (
    <div className="egg-merge--root">
      <div className="egg-merge--left-column">
        <EggPlaceholderWrapper
          onRemove={handleRemove}
          onDrop={handleDrop}
          slots={eggPlaceholders}
          getDroppedEgg={getDroppedEgg}
          isDeploying={false}
        />
        <NetworkFeePanel fee={paymentAmount} />
        <div className="egg-merge-action--wrapper">
          <Button
            disabled={shouldDisableMergeButton}
            btnStyle="5"
            onClick={onStartMergingHandler}
            className={'btn--nft-action inside-nft-detail btn--start-merging'}
            size="large"
          >
            <img
              className="icon icon--merge"
              src={iconMerge}
              alt="Start Merging"
            />
            START MERGING
          </Button>
          <button
            onClick={onClickMergeInstructionModal}
            className={'icon--how-to-merge'}
          >
            <QuestionCircleFill />
          </button>
        </div>
      </div>
      <div
        className={cn('egg-merge--right-column', {
          'is-loading': isLoading,
        })}
      >
        {renderBoxes()}
      </div>
    </div>
  );
};

const MockEggMergingLayout = () => {
  const isDevice = useDevice();
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const { isStarted, nftsModal, currentGuideStep } = store;

  // Filter Eggs only
  const viewData = useMemo(() => {
    return nftsModal.data
      .filter((item) => item.name === NFTTypeEnum.EGG && item.metadata)
      .map((item) => {
        const transformed = getNFTDetails(item);

        return {
          ...item,
          classNFT: transformed?.classNFT?.value ?? '',
          imageUrl: transformed?.token_uri?.value ?? '',
        };
      });
  }, [nftsModal.data]);

  const onCloseHandler = useCallback(() => {
    dispatch(EggForceJourneyDemoModuleActions.hideNFTMergeStatusModal());
    if (isStarted && currentGuideStep === GuideStep.wait) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step7b));
    }
  }, [currentGuideStep, dispatch, isStarted]);

  return (
    <div className="egg-merging-layout">
      <div className="header">
        <Heading h={2}>Egg Merging</Heading>
        <Heading h={4}>Increase your chances for a Legendary Dragon</Heading>
        <Balancer className="body">{messages.intro.defaultMessage}</Balancer>
      </div>
      <DndProvider backend={isDevice ? TouchBackend : HTML5Backend}>
        <EggMergingMain
          isValidating={false}
          refetch={noop}
          isLoading={false}
          eggs={viewData}
          hasMore={false}
          fetchMore={noop}
        />
        <EggMergingStatusModal
          selector={selectEggForceJourneyMergeStatus}
          onClose={onCloseHandler}
        />
      </DndProvider>
      <EggMergingInstructionModal />
    </div>
  );
};

export default MockEggMergingLayout;

// import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import useWalletConnector from '@/hooks/useWalletConnector';
import useInitWalletInstances from '@/hooks/useInitWalletInstances';
// import SnowStorm from './effectSnowFall';
import GlobalFireflies from '../GlobalFireflies';

const Layout = () => {
  /**
   * Should init important initialization here
   */
  useInitWalletInstances();
  useWalletConnector();
  const location = useLocation();
  const shouldHideHeaderFooter = Boolean(location.pathname === '/world');
  const shouldHideHeader = Boolean(location.pathname === '/SNC-airdrop');

  // useEffect(() => {
  //   const snow = new SnowStorm();
  // }, []);

  return (
    <div className="App">
      <Header hidden={shouldHideHeader || shouldHideHeaderFooter} />
      <Outlet />
      <Footer hidden={shouldHideHeaderFooter} />
      <GlobalFireflies />
    </div>
  );
};

export default Layout;

import OptionItem from '@/components/OptionItem';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectLeaderboardModule } from '@/modules/Leaderboards/selectors';
import CustomScrollbar from '@/components/CustomScrollbar';
import CSPRValue from '@/components/CSPRValue';
import { formatXPValue } from '@/modules/NFTDetail/utils';
import imgHatch from '@/assets/images/icon--unhatch.png';
import LeaderboardTopValidator from './LeaderboardTopValidator';
import LeaderboardRankNumber from './LeaderboardRankNumber';

const LeaderboardValidators = () => {
  const { data } = useSelector(selectLeaderboardModule);

  return (
    <div>
      <div className="leaderboard-item--top-validators">
        <LeaderboardTopValidator rank={2} data={data?.[1]} />
        <LeaderboardTopValidator rank={1} data={data?.[0]} />
        <LeaderboardTopValidator rank={3} data={data?.[2]} />
      </div>
      <div className="table-leaderboard--wrapper for-top-validators">
        <CustomScrollbar>
          {data.map((item: any, index) => (
            <div className="item--row" key={`leaderboard-row--${index}`}>
              <div className="column column-1">
                <LeaderboardRankNumber value={index + 1} />
              </div>
              <div className="column column-2">
                <OptionItem
                  className="is-leaderboard-item"
                  text={item.name}
                  src={item.logoUrl}
                />
              </div>
              <div className="column column-3">
                {item.hatchingEggs && (
                  <div className="leaderboard-item--text-inline eggs">
                    <img
                      loading="lazy"
                      className="icon icon-egg"
                      src={imgHatch}
                      alt="Eggs"
                    />
                    <span className="value">
                      {`${item.hatchingEggs} ${
                        item.hatchingEggs > 1 ? 'Eggs' : 'Egg'
                      }`}
                    </span>
                  </div>
                )}
              </div>
              <div className="column column-4">
                <div className="leaderboard-item--text-inline cspr">
                  <CSPRValue
                    reverse
                    shouldFormat={false}
                    value={formatXPValue(item.stakedAmount)}
                  />
                </div>
              </div>
            </div>
          ))}
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default LeaderboardValidators;

import { animated, useSpring } from 'react-spring';
import paths from './paths';

const Crack = ({ isRewardInview, currentStep }: any) => {
  const style = {
    overflow: 'hidden',
    display: 'block',
  };
  const wrapperStyles  = useSpring({
    reset: true,
    to: {
      opacity: isRewardInview ? 0 : 1,
      transform: isRewardInview ? `translate(16px, -90%)` : `translate(16px, -100%)`,
    }
  });

  const crackingProps = useSpring({
    crack: paths[currentStep],
    delay: () => {
      if (currentStep === 1)
        return 750;
      return 650;
    }
  });

  return (
    <animated.div style={wrapperStyles} className="egg-crack--path">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 108 332"
        style={style}
      >
        <defs></defs>
        <g id="two-0" transform="matrix(1 0 0 1 0 0)" opacity="1"></g>
        <animated.path
          fill="#F9F871"
          d={crackingProps.crack}
        ></animated.path>
      </svg>
    </animated.div>
  );
};

export default Crack;

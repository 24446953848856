import { useCallback } from 'react';
import Button from '@/components/GButton';
import iconLeaderboard from '@/assets/images/icon--leaderboard.png';
import { leaderboardActions } from '@/modules/Leaderboards/store';
import useDevice from '@/hooks/useDevice';
import {
  // useAppSelector as useSelector,
  useAppDispatch as useDispatch,
} from '@/app/hooks';

const WorldSecondaryMenu = () => {
  const dispatch = useDispatch();
  const isDevice = useDevice();
  const onViewLeaderboard = useCallback(() => {
    dispatch(leaderboardActions.showModal());
  }, [dispatch]);

  if (isDevice) {
    return null;
  }

  return (
    <div>
      <Button
        // tooltip="View NFT Collection"
        className="btn--icon-leaderboard btn--icon2"
        size="small"
        btnStyle="7"
        onClick={onViewLeaderboard}
      >
        <img style={{ width: 36 }} src={iconLeaderboard} alt="Leaderboard" />
        <span>Leaderboard</span>
      </Button>
    </div>
  );
};

export default WorldSecondaryMenu;

import React from 'react';
import imgProfileError from '@/assets/images/qa--status-failed.png';
import imgProfileActive from '@/assets/images/qa--status-active.png';
import { WalletLinks } from '@/constants/publicURL';

const AnswerComponent = () => {
  return (
    <div>
      <p>
        The tiny dot above Selvyn avatar has its own meaning. This tells whether
        the connection between EggForce website and Casper wallet is working
        properly or not. Please help to check the following table for further
        explanation:
      </p>
      <table>
        <tr>
          <td>
            <img
              src={imgProfileError}
              alt=""
              style={{ maxWidth: 64, height: 'auto' }}
              className="gameplay-icon icon-dragon"
            />
          </td>
          <td>
            <div style={{ padding: 8 }}>
              This indicates EggForce website couldn't connect to Casper wallet
              extension, or the extension wasn't installed before hand. Make
              sure you{' '}
              <a
                style={{ color: '#FFB356' }}
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={WalletLinks.casperDash}
              >
                have Casper wallet installed
              </a>{' '}
              and refresh the page before trying again.
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <img
              src={imgProfileActive}
              alt=""
              style={{ maxWidth: 64, height: 'auto' }}
              className="gameplay-icon icon-dragon"
            />
          </td>
          <td>
            <div style={{ padding: 8 }}>
              This indicates EggForce website connects successfully, and Casper
              wallet is working properly.
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default React.memo(AnswerComponent);

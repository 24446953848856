import React from 'react';
import Homepage from '@/screens/Homepage';
import StoryPage from '@/screens/StoryPage';
import GameplayPage from '@/screens/Gameplay';
import Worldmap from '@/screens/Worldmap';
import StyleguidePage from '@/screens/Styleguide';
import Rewardpage from '@/screens/Rewardpage';
import SNCPage from '@/screens/SNCpage';
import DragonsPage from '@/screens/DragonsPage';

export interface IRoute {
  // key?: number
  to: string;
  label: string;
  component: React.ComponentType; // HERE
  enabled: boolean;
  visible: boolean;
}

const routes = [
  {
    to: '/home',
    label: 'Home',
    enabled: true,
    visible: true,
    component: Homepage,
  },
  {
    to: '/story',
    label: 'Story',
    enabled: true,
    visible: true,
    component: StoryPage,
  },
  {
    to: '/gameplay',
    label: 'Gameplay',
    enabled: true,
    visible: true,
    component: GameplayPage,
  },
  {
    to: '/SNC-airdrop',
    label: 'SNC',
    enabled: true,
    visible: true,
    component: SNCPage,
  },
  {
    to: '/journey',
    label: 'Journey',
    enabled: true,
    visible: true,
    component: DragonsPage,
  },
  {
    to: '/world',
    label: 'Callista',
    enabled: true,
    visible: false,
    component: Worldmap,
  },
  {
    to: '/about',
    label: 'About',
    enabled: false,
    visible: false,
    component: Homepage,
  },
  {
    to: '/gallery',
    label: 'Gallery',
    enabled: false,
    visible: false,
    component: Homepage,
  },
  {
    to: '/community',
    label: 'Community',
    enabled: false,
    visible: false,
    component: Homepage,
  },
  {
    to: '/styleguide',
    label: 'Styleguide',
    enabled: true,
    visible: false,
    component: StyleguidePage,
  },
  {
    to: '/reward',
    label: 'reward',
    enabled: true,
    visible: false,
    component: Rewardpage,
  },
];

export default routes;

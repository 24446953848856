import { useState } from 'react';
import GModal from '@/components/GModal';
import Button from '@/components/GButton';
import { Heading } from '@/components/Typography';
import CustomScrollbar from '@/components/CustomScrollbar';

const ExampleModalAnnouncenents = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button size="small" onClick={() => setShowModal(true)}>
        Announcements Modal
      </Button>
      <GModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={
          <Heading h={3} className="mt-1">
            Announcements
          </Heading>
        }
      >
        <div style={{ overflow: 'hidden', height: 600 }}>
          <CustomScrollbar isAlwaysVisible>
            <div className="announcenents-block">
              <Heading h={4} className="mb-1">
                October 9, 2022 Update Notice
              </Heading>
              <div className="modal-announcenents-content">
                <p>How to Participate:</p>
                <ul>
                  <li>
                    Step 1: Tap on the top-right corner of the Binance App
                    homepage to access Binance Pay.
                  </li>
                  <li>Step 2: Tap on the “Send” icon.</li>
                  <li>
                    Step 3: Enter your recipient’s email address, phone number,
                    Pay ID or Binance ID, and tap “Continue”. If you cannot find
                    a Binance friend before the end of the activity, you can
                    still qualify for the reward by selecting the Binance Pay
                    official account as a recipient, which will automatically
                    appear as an option in this step. Download the latest
                    Binance App, or upgrade your Binance App to iOS v2.47.0 or
                    Android v2.47.0, or above, to send to the Binance Pay
                    official account.
                  </li>
                </ul>
              </div>
            </div>
            <div className="announcenents-block">
              <Heading h={4} className="mb-1">
                October 9, 2022 Update Notice
              </Heading>
              <div className="modal-announcenents-content">
                <p>How to Participate:</p>
                <ul>
                  <li>
                    Step 1: Tap on the top-right corner of the Binance App
                    homepage to access Binance Pay.
                  </li>
                  <li>Step 2: Tap on the “Send” icon.</li>
                  <li>
                    Step 3: Enter your recipient’s email address, phone number,
                    Pay ID or Binance ID, and tap “Continue”. If you cannot find
                    a Binance friend before the end of the activity, you can
                    still qualify for the reward by selecting the Binance Pay
                    official account as a recipient, which will automatically
                    appear as an option in this step. Download the latest
                    Binance App, or upgrade your Binance App to iOS v2.47.0 or
                    Android v2.47.0, or above, to send to the Binance Pay
                    official account.
                  </li>
                </ul>
              </div>
            </div>
            <div className="announcenents-block">
              <Heading h={4} className="mb-1">
                October 9, 2022 Update Notice
              </Heading>
              <div className="modal-announcenents-content">
                <p>How to Participate:</p>
                <ul>
                  <li>
                    Step 1: Tap on the top-right corner of the Binance App
                    homepage to access Binance Pay.
                  </li>
                  <li>Step 2: Tap on the “Send” icon.</li>
                  <li>
                    Step 3: Enter your recipient’s email address, phone number,
                    Pay ID or Binance ID, and tap “Continue”. If you cannot find
                    a Binance friend before the end of the activity, you can
                    still qualify for the reward by selecting the Binance Pay
                    official account as a recipient, which will automatically
                    appear as an option in this step. Download the latest
                    Binance App, or upgrade your Binance App to iOS v2.47.0 or
                    Android v2.47.0, or above, to send to the Binance Pay
                    official account.
                  </li>
                </ul>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </GModal>
    </>
  );
};

export default ExampleModalAnnouncenents;

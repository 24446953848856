import { createSlice } from '@reduxjs/toolkit';
import { IModal } from '@/types/modal';

type TypeSelectedNFT = {
  nftType: string;
  tokenId: string;
};

export type TypeBurnStatusModal = IModal & {
  result: boolean;
};

interface HammerBurnModuleSpec extends IModal {
  selectedNFT: TypeSelectedNFT | null;
  burnInstructionModal: IModal;
  burnStatusModal: TypeBurnStatusModal;
}

const initialState: HammerBurnModuleSpec = {
  selectedNFT: null,
  loading: false,
  open: false,
  burnStatusModal: {
    loading: false,
    open: false,
    result: false,
  },
  burnInstructionModal: {
    loading: false,
    open: false,
  },
};

const HammerBurnModule = createSlice({
  name: 'HammerBurnModule',
  initialState,
  reducers: {
    setLoading: (state: HammerBurnModuleSpec, action) => {
      state.loading = action.payload;
    },
    showModal: (state: HammerBurnModuleSpec) => {
      state.open = true;
    },
    hideModal: (state: HammerBurnModuleSpec) => {
      state.open = false;
    },
    selectNFT: (state: HammerBurnModuleSpec, action) => {
      state.selectedNFT = action.payload ?? null;
    },
    setBurnStatusLoading: (state: HammerBurnModuleSpec, action) => {
      state.burnStatusModal.loading = action.payload;
    },
    setBurnStatusResult: (state: HammerBurnModuleSpec, action) => {
      state.burnStatusModal.result = action.payload;
    },
    showBurnStatusModal: (state: HammerBurnModuleSpec) => {
      state.burnStatusModal.open = true;
    },
    hideBurnStatusModal: (state: HammerBurnModuleSpec) => {
      state.burnStatusModal.open = false;
    },
    setBurnInstructionLoading: (state: HammerBurnModuleSpec, action) => {
      state.burnInstructionModal.loading = action.payload;
    },
    showBurnInstructionModal: (state: HammerBurnModuleSpec) => {
      state.burnInstructionModal.open = true;
    },
    hideBurnInstructionModal: (state: HammerBurnModuleSpec) => {
      state.burnInstructionModal.open = false;
    },
  },
});
const hammerBurnActions = {
  ...HammerBurnModule.actions,
};

export { hammerBurnActions };
export default HammerBurnModule.reducer;

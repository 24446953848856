import React, { useMemo } from 'react';
import Big from 'big.js';
import GModal from '@/components/GModal';
import Table from 'react-bootstrap/Table';
import { Heading } from '@/components/Typography';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectInstallmentPaymentsDetailModal } from '@/modules/InstallmentsPayment/selectors';
import { formateDate } from '@/helpers/datetime';
import Button from '@/components/GButton';
import { IDetailInstallmentPayment } from '@/types/package';
import CSPRValue from '@/components/CSPRValue';
import useSiteConfigurations from '@/hooks/useSiteConfigurations';

const InstallmentRefundReviewData = ({
  isDeploying,
  onClose,
  onConfirm,
  open,
}: any) => {
  const detailData = useSelector(selectInstallmentPaymentsDetailModal);
  const data: IDetailInstallmentPayment = detailData.data;
  const siteConfig = useSiteConfigurations();
  const {
    id,
    purchaseDate,
    paidAmount,
    remainingAmount,
    totalAmount,
    refundAmount,
    cancelFee,
    cancelRate,
  } = useMemo(() => {
    if (!data) {
      return {
        id: undefined,
        purchaseDate: '',
        paidAmount: undefined,
        totalAmount: undefined,
        remainingAmount: undefined,
        refundAmount: undefined,
        cancelFee: undefined,
        cancelRate: undefined,
      };
    }

    const paidAmount = Big(data?.paidAmount ?? 0);
    const remainingAmount = Big(data?.remainingAmount ?? 0);
    const totalAmount = paidAmount.add(remainingAmount);
    const penaltyAmount = Big(data?.cancelFeeRate ?? 0);
    const cancelFee = paidAmount.mul(penaltyAmount);
    const refundAmount = paidAmount.minus(cancelFee);

    return {
      id: data?.installmentId,
      purchaseDate: formateDate(data.at),
      paidAmount: paidAmount.toNumber(),
      totalAmount: totalAmount.toNumber(),
      remainingAmount: remainingAmount.toNumber(),
      refundAmount: refundAmount.toNumber(),
      cancelFee: cancelFee.toNumber(),
      cancelRate: penaltyAmount.mul(100),
    };
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <GModal
      show={open}
      closeButton={false}
      blurOverlay
      className={'installment-plan--refund-review'}
    >
      <div className="purchase-installment--details">
        <div className="purchase-installment--info">
          <div className="header">
            <Heading h={5}>Refund</Heading>
          </div>
          <div className="table-wrapper">
            <div className="each-table-block">
              <div className="mini-header">
                <Heading h={5}>Installment Package summary</Heading>
              </div>
              <Table className="table-result" striped hover variant="dark">
                <tbody>
                  <tr>
                    <td>Installment Payment ID</td>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <td>Purchase Date</td>
                    <td>{purchaseDate}</td>
                  </tr>
                  <tr>
                    <td>Total Amount</td>
                    <td>
                      <CSPRValue value={totalAmount} />
                    </td>
                  </tr>
                  <tr>
                    <td>Paid Amount</td>
                    <td>
                      <CSPRValue value={paidAmount} />
                    </td>
                  </tr>
                  <tr>
                    <td>Remaining Amount</td>
                    <td>
                      <CSPRValue value={remainingAmount} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="each-table-block">
              <div className="mini-header">
                <Heading h={5}>Refund Details</Heading>
              </div>
              <Table className="table-result" striped hover variant="dark">
                <tbody>
                  <tr>
                    <td>Paid Amount</td>
                    <td>
                      <CSPRValue value={paidAmount} />
                    </td>
                  </tr>
                  <tr>
                    <td>Cancellation Fee ({`${cancelRate}%`})</td>
                    <td>
                      <CSPRValue value={cancelFee} />
                    </td>
                  </tr>
                  <tr>
                    <td>Refund Amount</td>
                    <td>
                      <CSPRValue className="text-strong" value={refundAmount} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="each-table-block">
              <div className="mini-header">
                <Heading h={5}>Transaction Details</Heading>
              </div>
              <Table className="table-result" striped hover variant="dark">
                <tbody>
                  <tr>
                    <td>Gas fee</td>
                    <td>
                      <CSPRValue
                        className="total-amount"
                        value={siteConfig.PAYMENT_COMMON!}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* <HelperClaim noun="gas" fee={siteConfig.PAYMENT_COMMON!} /> */}
          </div>
          <div className="section--footer">
            <Button
              disabled={isDeploying}
              onClick={onClose}
              size="small"
              className="detail-view btn-installemnt-payment--action btn-installemnt-payment--pay"
              btnStyle="3"
            >
              Cancel
            </Button>
            <Button
              disabled={isDeploying}
              size="small"
              className="detail-view btn-installemnt-payment--action btn-installemnt-payment--refund"
              btnStyle="6"
              onClick={onConfirm}
            >
              Refund
            </Button>
          </div>
        </div>
      </div>
    </GModal>
  );
};

export default InstallmentRefundReviewData;

import React from 'react';

const TextUnhatch = () => (
  <p>
    <strong>Stop Incubate</strong>: This action involves changing your Egg
    status from <strong>Incubating</strong> to <strong>Stopped</strong>. This
    step is required in case you want to change to another EggForce validator.{' '}
    <br />
    Once stopped,{' '}
    <strong>
      the amount of staked CSPR still remains on Casper Network chain as
      staking.
    </strong>
    <br />
    When you choose to incubate again on the same egg, the staking amount will
    be resetted and starting from 0. Noting that you might see a mismatch in
    CSPR you've staked compared to the one on CSPR faucet, and this is normal
    behaviour.
    <br />
    <strong>
      For any case, we recommend choosing Undelegate to make sure everthing is
      synced to each other.
    </strong>
  </p>
);

const TextUnhatchWithUndelegate = () => (
  <p>
    <strong>Stop Incubate</strong>: This action involves changing your Egg
    status from <strong>Incubating</strong> to <strong>Stopped</strong>. This
    step is required in case you want to change to another EggForce validator.{' '}
    <br />
    Once unhatched,{' '}
    <strong>
      the staking CSPR will be stopped, and transfer back to User's wallet.
    </strong>
    <br />
    Noting that the redemption process will{' '}
    <strong>take approximately 14 hours (7 era)</strong> to completely finished.
  </p>
);

const TextUndelegate = () => (
  <p>
    <strong>
      Stop Incubate & Undelegate <i>(default)</i>
    </strong>
    : Similar to <strong>Stop Incubate</strong> action, in addition to the above
    is the staking CSPR will be stopped, and transfer back to User's wallet.{' '}
    <strong>
      Noting that the redemption process will take approximately 14 hours (7
      era) to completely finished.
    </strong>
  </p>
);

const QAHatchingButtonsGroup = () => {
  return (
    <div>
      <p>
        <strong>Incubate</strong>: Incubating is one of the methods to earn SNC
        (<strong>Selvyn's Nodule Coin</strong>). Simply put, Hatchers{' '}
        <strong>
          choose the egg and stake their CSPR into a verified EggForce Validator
        </strong>
        . The staking rewards are accumulated in order to produce SNC.
      </p>
      {/* <TextUnhatchWithUndelegate /> */}
      <TextUnhatch />
      <TextUndelegate />
    </div>
  );
};

export { TextUnhatchWithUndelegate, TextUnhatch, TextUndelegate };
export default React.memo(QAHatchingButtonsGroup);

import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import Big from 'big.js';
import Table from 'react-bootstrap/Table';
import { Heading } from '@/components/Typography';
import { ChevronUp } from 'react-bootstrap-icons';
import { useAppSelector as useSelector } from '@/app/hooks';
import { selectInstallmentPaymentsDetailModal } from '@/modules/InstallmentsPayment/selectors';
import type { TypePreviewInstallmentPayment } from '@/types/package';
import CSPRValue from '@/components/CSPRValue';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { moteToCspr } from '@/helpers/balance';

const LabelWithTooltip = ({
  id,
  children,
  label,
}: {
  id: string;
  children: React.ReactElement;
  label: string;
}) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{children}</Tooltip>}
    >
      <span>{label}</span>
    </OverlayTrigger>
  );
};

const TableInstallmentPackageSummary = ({ packageData }: any) => {
  const detailData = useSelector(selectInstallmentPaymentsDetailModal);
  const previewData: TypePreviewInstallmentPayment = detailData.data;
  const [showCompact, setCompact] = useState(true);

  const { firstPayment, downPayment, totalPayment } = useMemo(() => {
    const paymentAmount = Big(previewData.paymentAmount);
    const downPayment = Big(previewData.downPayment);
    const totalPayment = Big(previewData.totalAmount);

    return {
      firstPayment: downPayment.plus(paymentAmount).toNumber(),
      downPayment: downPayment.toNumber(),
      totalPayment,
    };
  }, [
    previewData.downPayment,
    previewData.paymentAmount,
    previewData.totalAmount,
  ]);

  return (
    <div className="table-package--wrapper installment-table">
      <div className="header">
        <Heading h={5}>Installment Package Summary</Heading>
        <button
          onClick={() => setCompact(!showCompact)}
          className="btn--toggle"
        >
          <ChevronUp
            className={cn({
              icon: true,
              'is-compact': showCompact,
            })}
          />
          <span className="text">Show {showCompact ? 'Details' : 'Less'}</span>
        </button>
      </div>
      <div className="table-wrapper">
        {!showCompact && (
          <Table
            className="table-result"
            style={{ marginBottom: 24 }}
            striped
            hover
            variant="dark"
          >
            <tbody>
              <tr>
                <td>Package</td>
                <td>{packageData.packageLabel}</td>
              </tr>
              <tr>
                <td>Plan duration</td>
                <td>{previewData.duration} months</td>
              </tr>
              <tr>
                <td>Original Package value</td>
                <td>
                  <CSPRValue value={previewData.packageAmount} />
                </td>
              </tr>
              {!showCompact && (
                <tr>
                  <td>Interest Rate</td>
                  <td>{previewData.rate}%</td>
                </tr>
              )}
              <tr>
                <td>Provider Fee</td>
                <td>
                  <CSPRValue value={previewData.providerFee} />
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <LabelWithTooltip
                      id="bnpl--total-purchase-amount"
                      label="Total Purchase amount (excluding minting fee)"
                    >
                      <div>
                        Total Purchase amount is the amount you pay in total
                        after 3 months, this is{' '}
                        <strong>
                          excluding minting fee, or any penalty fee
                        </strong>
                        . Here it equals{' '}
                        <strong>
                          Package value ({moteToCspr(previewData.packageAmount)}{' '}
                          CSPR)
                        </strong>{' '}
                        plus{' '}
                        <strong>
                          Provider fee ({moteToCspr(previewData.providerFee)}{' '}
                          CSPR)
                        </strong>
                      </div>
                    </LabelWithTooltip>
                  </div>
                </td>
                <td>
                  <CSPRValue value={totalPayment} />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        {!showCompact && <Heading h={5}>First Purchase Details</Heading>}
        <Table className="table-result" striped hover variant="dark">
          <tbody>
            {showCompact && (
              <tr>
                <td>Package</td>
                <td>{packageData.packageLabel}</td>
              </tr>
            )}
            <tr>
              <td>
                <div>
                  <LabelWithTooltip
                    id="bnpl--downpayment"
                    label="Down payment amount"
                  >
                    <div>
                      Down payment is the amount at which you pay at the first
                      time of purchase. Here it equals{' '}
                      <strong>
                        Monthly payment ({moteToCspr(previewData.planPayment)}{' '}
                        CSPR)
                      </strong>{' '}
                      plus{' '}
                      <strong>
                        Provider fee ({moteToCspr(previewData.providerFee)}{' '}
                        CSPR)
                      </strong>
                    </div>
                  </LabelWithTooltip>
                </div>
              </td>
              <td>{<CSPRValue value={downPayment} />}</td>
            </tr>
            <tr>
              <td>
                <div>
                  <LabelWithTooltip id="bnpl--mintingfee" label="Minting fee">
                    <div>The network gas fee of Casper Blockchain</div>
                  </LabelWithTooltip>
                </div>
              </td>
              <td>{<CSPRValue value={previewData.paymentAmount} />}</td>
            </tr>
            <tr>
              <td>
                <div>
                  <LabelWithTooltip
                    id="bnpl--total-first-payout"
                    label="Total first payment amount"
                  >
                    <div>
                      Total first payment is the amount you pay in the first
                      time, including minting fee. Here it equals{' '}
                      <strong>
                        Down payment ({moteToCspr(downPayment)} CSPR)
                      </strong>{' '}
                      plus{' '}
                      <strong>
                        Minting fee ({moteToCspr(previewData.paymentAmount)}{' '}
                        CSPR)
                      </strong>
                    </div>
                  </LabelWithTooltip>
                </div>
              </td>
              <td>
                {<CSPRValue className="total-amount" value={firstPayment} />}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TableInstallmentPackageSummary;

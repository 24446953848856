import { useMemo } from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { useAppDispatch as useDispatch } from '@/app/hooks';
// import useCurrentUser from '@/hooks/useCurrentUser';
// import { showConnectCSModal } from '@/modules/CasperSigner/store';
// import useDevice from '@/hooks/useDevice';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { SNCModuleActions } from '@/modules/SNCModule/store';
import imgSNC from '@/assets/images/icon--snc.png';

const MarkerSNCMine = () => {
  const dispatch = useDispatch();
  // const user = useCurrentUser();
  // const isDevice = useDevice();

  const xPos = 81.09;
  const yPos = 51.59;
  const markerIcon = imgSNC;

  const eventHandlers = useMemo(
    () => ({
      click: () => {
        // if (isDevice) {
        //   dispatch(packagesDetailActions.showCustomPackModal());
        //   return;
        // }

        // if (!user?.activeKey) {
        //   dispatch(showConnectCSModal());
        //   return;
        // }

        /** TODO: Add tracking here if you want */
        dispatch(SNCModuleActions.showModal());
      },
    }),
    [dispatch],
  );

  if (!MODULES_PERMISSION.USE_NFT_MINTING) {
    return null;
  }

  return (
    <Marker
      icon={L.icon({
        iconUrl: markerIcon,
        iconSize: [64, 64],
        className: 'validator-marker ignore-shadow internal',
      })}
      position={[xPos, yPos]}
      eventHandlers={eventHandlers}
    ></Marker>
  );
};

export default MarkerSNCMine;

import { useCallback } from 'react';
import Big from 'big.js';
import useCurrentUser from '@/hooks/useCurrentUser';
import useConfirmDeploy from '@/hooks/useConfirmDeploy';
import { getTransferNFTDeploy } from '@/services/transferNFTService';
import messages from '@/components/EggHatchSelectingModal/messages';
import { csprToMote } from '@/helpers/balance';
import { TransferEggDetailsSpec } from '@/types/EggActions';

const useEggTransfer = () => {
  const user = useCurrentUser();
  const { executeDeployWithoutPut, isDeploying, isError } = useConfirmDeploy();
  const buildTransferNFTDeployFn = useCallback(
    (details: TransferEggDetailsSpec) => () => getTransferNFTDeploy(details),
    [],
  );

  const onTransferNFTHandler = useCallback(
    async (transferNFTDetails: TransferEggDetailsSpec) => {
      console.log(`🚀 ~ >>> transferNFTDetails:`, transferNFTDetails);
      if (!user) {
        return undefined;
      }

      const result = await executeDeployWithoutPut(
        buildTransferNFTDeployFn(transferNFTDetails),
        transferNFTDetails.fromAddress,
        transferNFTDetails.recipient,
      );
      console.log(`🚀 ~ onTransferNFTHandler::result:`, result);

      return result;
    },
    [user, executeDeployWithoutPut, buildTransferNFTDeployFn],
  );

  const validate = useCallback(
    (data: any) => {
      let errors: any = {};
      const { balance } = user;
      const { fee, amount, minAmount } = data;
      const minAmount_MOTE = csprToMote(minAmount);
      const amount_MOTE = csprToMote(amount);
      const fee_MOTE = csprToMote(fee);
      if (!balance) {
        errors.balance = 'Error reading balance from current user';
      }
      const balanceUser = Big(balance ?? 0);

      if (amount === undefined || Big(amount_MOTE).lte(0)) {
        errors.amount = messages.errorMoreThanZero.defaultMessage;
      } else if (Big(amount_MOTE).gt(0) && balance) {
        const total = Big(amount_MOTE).plus(Big(fee_MOTE));

        if (total.gt(balanceUser)) {
          errors.amount = messages.errorLargerThanBalance.defaultMessage;
        }
      }

      // eslint-disable-next-line no-mixed-operators
      if (
        !errors.amount &&
        ((balance && Big(balance).lte(minAmount_MOTE)) ||
          Big(amount_MOTE).lt(minAmount_MOTE))
      ) {
        errors.amount = messages.errorLessThanMinAmount.defaultMessage;
      }

      // if (
      //   selectedValidator &&
      //   !selectedValidator.hasDelegated &&
      //   selectedValidator.numOfDelegator >= getConfigKey('MAX_DELEGATOR_PER_VALIDATOR')
      // ) {
      //   errors.validator = 'Max delegators';
      // }

      return errors;
    },
    [user],
  );

  return {
    transfer: onTransferNFTHandler,
    isDeploying,
    isError,
    validate,
  };
};

export default useEggTransfer;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MeLemLogo from '@/components/MeLemLogo';
import DragonSectionWrapper from '../DragonSectionWrapper';

const DragonRarityCollections = () => {
  return (
    <DragonSectionWrapper name="tokenomic">
      <Container className="">
        <Row>
          <Col md={4}>
            <div className="each-tokenomic--option">
              <div className="column-left">10,000</div>
              <div className="column-right">
                High quality
                <br /> Egg NFTs
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="each-tokenomic--option">
              <div className="column-left">8+</div>
              <div className="column-right">
                Casper Network <br />
                Landlord Validators
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="each-tokenomic--option">
              <div className="column-left">
                <MeLemLogo skipText />
              </div>
              <div className="column-right">Buy Now, Pay Layer support</div>
            </div>
          </Col>
        </Row>
      </Container>
    </DragonSectionWrapper>
  );
};

export default DragonRarityCollections;

import { useMemo } from 'react';
import { Heading } from '@/components/Typography';

type UserRank = number;

const LeaderboardCurrentUserRank = ({ rank }: { rank: UserRank }) => {
  const UserRank = useMemo(() => {
    if (rank <= 0) {
      return (
        <>
          <p className="title">Your rank</p>
          <Heading h={6} className="rank unranked">
            Unranked
          </Heading>
          <p className="subtitle">(Out of top 1000)</p>
        </>
      );
    }

    return (
      <>
        <p className="title">Your rank</p>
        <Heading className="rank">{rank}</Heading>
      </>
    );
  }, [rank]);

  return (
    <div className="leaderboard--current-user">
      <div className="leaderboard-item--user-rank">{UserRank}</div>
    </div>
  );
};

export default LeaderboardCurrentUserRank;

import React, { useCallback, useMemo } from 'react';
import Big from 'big.js';
import { sharedToastProps, toast } from '@/services/toast';
import GModal from '@/components/GModal';
import LoadingBox from '@/components/LoadingBox';
import { Heading } from '@/components/Typography';
import { reloadCurrentUser } from '@/modules/Auth/actions';
import {
  useAppDispatch as useDispatch,
  useAppSelector as useSelector,
} from '@/app/hooks';
import Button from '@/components/GButton';
import OptionItem from '@/components/OptionItem';
import {
  selectRefundReviewModal,
  selectInstallmentPaymentsDetailModal,
} from '@/modules/InstallmentsPayment/selectors';
import { NFTDetailModalActions } from '@/modules/NFTDetail/store';
import MeLemLogo from '@/components/MeLemLogo';
import { InstallmentsPaymentActions } from '@/modules/InstallmentsPayment/store';
import BoxInfo from '@/components/BoxInfo';
import GProgress from '@/components/GProgress';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatCSPRFull } from '@/helpers/balance';
import isBefore from 'date-fns/isBefore';
import { InstallmentPaymentStatus } from '@/types/package';
import logoCSPR from '@/assets/images/logo--cspr.svg';
import useCurrentUser from '@/hooks/useCurrentUser';
import commonMessages from '@/constants/commonMessages';
import useInstallmentPayment from '@/hooks/useInstallmentPayment';
import { IDetailInstallmentPayment } from '@/types/package';
import InstallmentRefundReviewData from '@/components/InstallmentsListModal/InstallmentRefundReviewData';
import { formateDate } from '@/helpers/datetime';
// import { getNFTDetails } from '@/modules/NFTDetail/utils';
import Sample2 from '@/assets/images/TR-08.png';
import { handleDeployResult } from '@/modules/EggPurchase/actions';
import { selectNFTCollections } from '@/modules/EggCollection/selectors';
import { selectNFTDetailModal } from '@/modules/NFTDetail/selectors';
import messages from '@/modules/InstallmentsPayment/messages';
import { removeEggWidthIds } from '@/modules/EggCollection/actions';
import InfoProcessingPanel from '@/components/InfoProcessingPanel';
import useCheckBalance from '@/hooks/useCheckBalance';
import useSiteConfigurations from '@/hooks/useSiteConfigurations';

const InstallmentPaymentDetailModal = () => {
  const dispatch = useDispatch();
  const siteConfig = useSiteConfigurations();
  const NFTDetailStore = useSelector(selectNFTDetailModal);
  const paymentDetailModal = useSelector(selectInstallmentPaymentsDetailModal);
  const { open: openRefundReviewModal } = useSelector(selectRefundReviewModal);
  const { data: nftDataDetail, open: isOpeningNFTDetailModal } = NFTDetailStore;
  const nftCollection = useSelector(selectNFTCollections);
  const { open, loading } = paymentDetailModal;
  const data: IDetailInstallmentPayment = paymentDetailModal.data;
  const user = useCurrentUser();
  const { checkBalanceAgainstAmount } = useCheckBalance();
  const { pay, refund, isDeploying } = useInstallmentPayment();

  const {
    tokenIds,
    isPayable,
    status,
    planLength,
    isOverdue,
    paidAmount,
    totalAmount,
    percent,
    purchaseDate,
    nextPaymentDate,
    remainingAmount,
    paidSchedules,
    idInstallment,
    nextAmount,
    paymentAmount,
    balanceForNextPayment,
  } = useMemo(() => {
    if (!data) {
      return {
        tokenIds: [],
        planLength: 0,
        isOverdue: null,
        paidAmount: 0,
        totalAmount: 0,
        planPayment: 0,
        percent: 0,
        purchaseDate: '',
        nextPaymentDate: '',
        remainingAmount: 0,
        remainingSchedules: 0,
        paidSchedules: null,
        idInstallment: null,
        count: 0,
        nextAmount: null,
        paymentAmount: null,
        isPayable: false,
        balanceForNextPayment: null,
      };
    }
    const {
      installmentId,
      tokenIds,
      planLength,
      status,
      nextPaymentSchedule,
      paidSchedules,
      nextAmount,
      paymentAmount,
      isPayable = false,
    } = data;
    const paidAmount = Big(data.paidAmount ?? 0);
    const planPayment = Big(data.planPayment ?? 0);
    const paymentAmountBig = Big(data.paymentAmount ?? 0);
    const remainingAmount = Big(data.remainingAmount ?? 0);
    const remainingSchedules = data.remainingSchedules;
    const nextAmountBig = Big(nextAmount ?? 0);

    const totalAmount = paidAmount.add(remainingAmount);
    const percent =
      paidAmount.gt(0) && totalAmount.gt(0)
        ? paidAmount.div(totalAmount).mul(100)
        : Big(0);
    const purchaseDate = data.at ? formateDate(data.at) : '';
    const nextPaymentDate = nextPaymentSchedule
      ? formateDate(nextPaymentSchedule)
      : 'Not available';
    const isOverdue = nextPaymentSchedule
      ? isBefore(new Date(nextPaymentSchedule), new Date())
      : false;
    let leftLabel = '';

    switch (status) {
      case InstallmentPaymentStatus.ACTIVE:
        leftLabel = `Next payment: ${nextPaymentDate}`;
        break;
      case InstallmentPaymentStatus.COMPLETED:
        leftLabel = 'Finished';
        break;
      case InstallmentPaymentStatus.CANCEL:
        leftLabel = 'Cancelled';
        break;
      default:
        break;
    }

    return {
      tokenIds,
      isPayable,
      status,
      nextAmount,
      paymentAmount,
      idInstallment: installmentId,
      count: tokenIds?.length,
      planLength,
      purchaseDate: purchaseDate,
      nextPaymentDate: nextPaymentDate,
      paidAmount: formatCSPRFull(paidAmount.toNumber()),
      totalAmount: formatCSPRFull(totalAmount.toNumber()),
      percent: percent.toNumber(),
      remainingAmount: remainingAmount.toNumber(),
      remainingSchedules,
      planPayment: planPayment.toNumber(),
      balanceForNextPayment: nextAmountBig.plus(paymentAmountBig).toNumber(),
      leftLabel,
      isOverdue,
      paidSchedules,
    };
  }, [data]);

  const tokensBNPL = useMemo(() => {
    if (!tokenIds || (!tokenIds?.length && !nftCollection?.length)) {
      return [];
    }

    const result = tokenIds
      ?.map((token: any) => {
        /**
         * Use axios to load specific NFT data
         */
        // const item = undefined;
        // if (!item) {
        //   return undefined;
        // }

        // const transformed = getNFTDetails(item);
        // return {
        //   ...item,
        //   imageUrl: transformed?.token_uri?.value ?? '',
        // };
        return {
          tokenId: token,
        };
      })
      .filter(Boolean);

    return result;
  }, [nftCollection, tokenIds]);

  const onClickRefundHandler = useCallback(() => {
    dispatch(InstallmentsPaymentActions.showRefundReviewModal());
  }, [dispatch]);

  const onCloseRefundReview = useCallback(() => {
    dispatch(InstallmentsPaymentActions.hideRefundReviewModal());
  }, [dispatch]);

  const onCloseHandler = useCallback(() => {
    dispatch(InstallmentsPaymentActions.hideInstallmentDetailModal());
    dispatch(InstallmentsPaymentActions.clearInstallmentDetail());
  }, [dispatch]);

  const onPayInstallmentPayment = useCallback(async () => {
    try {
      if (
        !user ||
        !user?.balance ||
        !idInstallment ||
        !paymentAmount ||
        balanceForNextPayment === null
      ) {
        return;
      }

      if (!checkBalanceAgainstAmount(balanceForNextPayment)) {
        toast.warn(commonMessages.errNotEnoughBalance.defaultMessage, {
          ...sharedToastProps,
          toastId: commonMessages.errNotEnoughBalance.id,
        });
        return;
      }

      const result: any = await pay({
        id: idInstallment,
        amount: nextAmount,
        paymentAmount,
      });

      if (result) {
        await dispatch(
          handleDeployResult({
            result,
            message: messages.paySuccess.defaultMessage,
            metadata: {
              from: 'bnpl',
              id: idInstallment,
              action: 'pay',
            },
          }),
        );

        dispatch(reloadCurrentUser(user.activeKey));
        onCloseHandler();
      }
    } catch (err: any) {
      toast.warn(messages.commonFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
    }
  }, [
    user,
    idInstallment,
    paymentAmount,
    balanceForNextPayment,
    checkBalanceAgainstAmount,
    pay,
    nextAmount,
    dispatch,
    onCloseHandler,
  ]);

  const onCloseAndClearNFTDetail = useCallback(() => {
    if (isOpeningNFTDetailModal && nftDataDetail) {
      dispatch(NFTDetailModalActions.hideModal());
      dispatch(NFTDetailModalActions.resetData());
    }
  }, [dispatch, isOpeningNFTDetailModal, nftDataDetail]);

  const onRefundInstallmentPayment = useCallback(async () => {
    try {
      if (!user || !user?.balance || !idInstallment) {
        return;
      }

      if (!checkBalanceAgainstAmount(siteConfig.PAYMENT_COMMON!)) {
        toast.warn(commonMessages.errNotEnoughBalance.defaultMessage, {
          ...sharedToastProps,
          toastId: commonMessages.errNotEnoughBalance.id,
        });
        return;
      }

      const result: any = await refund({
        id: idInstallment,
        paymentAmount: siteConfig.PAYMENT_COMMON!,
      });

      if (result) {
        await dispatch(
          handleDeployResult({
            result,
            message: messages.refundSuccess.defaultMessage,
            metadata: {
              from: 'bnpl',
              id: idInstallment,
              action: 'refund',
            },
          }),
        );

        dispatch(reloadCurrentUser(user.activeKey));

        onCloseRefundReview();
        onCloseHandler();
        /**
         * Close NFT Detail modal if it was opened via NFT detail after Refunding
         */
        onCloseAndClearNFTDetail();
        /**
         * Remove token Ids in BNPL from redux store (egg collection and cache NFTs)
         */
        dispatch(removeEggWidthIds(tokenIds));
      }
    } catch (err: any) {
      toast.warn(messages.commonFailed.defaultMessage, {
        ...sharedToastProps,
        autoClose: true,
      });
    }
  }, [
    user,
    idInstallment,
    checkBalanceAgainstAmount,
    refund,
    siteConfig.PAYMENT_COMMON,
    dispatch,
    onCloseRefundReview,
    onCloseHandler,
    onCloseAndClearNFTDetail,
    tokenIds,
  ]);

  return (
    <GModal
      show={open}
      onHide={onCloseHandler}
      blurOverlay
      className={'installment-plan--detail'}
      backdrop="static"
      keyboard={false}
    >
      {isOverdue && (
        <InfoProcessingPanel
          style={{ flex: '0 0 auto', width: '100%', margin: '0 0 24px' }}
          message={messages.overduePayment.defaultMessage}
        />
      )}
      <div className="header">
        <Heading h={4}>Installment Payment Details</Heading>
        <MeLemLogo />
      </div>
      {loading ? (
        <div className="">
          <LoadingBox />
        </div>
      ) : (
        <React.Fragment>
          <div>
            <GProgress
              leftTopLabel={`Purchase Date: ${purchaseDate}`}
              rightTopLabel={
                <span className="box--right">
                  Paid Schedule: {paidSchedules}/{planLength}
                </span>
              }
              tooltipLabel={`${paidAmount} / ${totalAmount}`}
              percent={percent}
            />
            <div className="installment-plan--details">
              <BoxInfo
                label="Next Payment Date"
                value={nextPaymentDate}
                isHorizontal={false}
                className="value--large"
              />
              <BoxInfo
                label="Remaining"
                value={`${formatCSPRFull(remainingAmount)} CSPR`}
                isHorizontal={false}
                className="value--large"
              />
              <div className="monthly-payment--summary">
                <div className="text">
                  <span className="top">Payment</span>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`bnpl--next-payment`}>
                      <div>Next payment: {formatCSPRFull(nextAmount)} CSPR</div>
                      <div>
                        Minting Fee: {formatCSPRFull(paymentAmount)} CSPR
                      </div>
                    </Tooltip>
                  }
                >
                  <div className="value">
                    <img className="icon" src={logoCSPR} alt="CSPR" />
                    <span className="amount">
                      {formatCSPRFull(balanceForNextPayment)} CSPR
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="installment-plan--tokens-wrapper">
                <Heading h={5}>Eggs Allocation</Heading>
                {/**
                 * TODO
                 * Loading specific Tokens in BNPL
                 *  */}
                <div className="installment-plan--tokens-list">
                  {tokensBNPL?.map((item: any) => (
                    <OptionItem
                      className="is-egg-nft"
                      key={`bnpl-egg--${item.tokenId}`}
                      text={`Egg #${item.tokenId}`}
                      src={Sample2}
                      // src={item.imageUrl ? item.imageUrl : Sample2}
                      isVertical
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {status === InstallmentPaymentStatus.ACTIVE && (
            <div className="section--footer">
              <Button
                disabled={isDeploying || !isPayable}
                onClick={onPayInstallmentPayment}
                size="small"
                className="detail-view btn-installemnt-payment--action btn-installemnt-payment--pay"
                btnStyle="5"
              >
                PAY {formatCSPRFull(balanceForNextPayment)} CSPR
              </Button>
              <Button
                disabled={isDeploying}
                size="small"
                className="detail-view btn-installemnt-payment--action btn-installemnt-payment--refund"
                btnStyle="6"
                onClick={onClickRefundHandler}
              >
                Refund
              </Button>
            </div>
          )}
          {openRefundReviewModal && (
            <InstallmentRefundReviewData
              open
              isDeploying={isDeploying}
              onClose={onCloseRefundReview}
              onConfirm={onRefundInstallmentPayment}
            />
          )}
        </React.Fragment>
      )}
    </GModal>
  );
};

export default InstallmentPaymentDetailModal;

import type { RootState } from '@/app/store';

const selectCustomEventModal = (state: RootState) => state.customEventModal;

const selectAdHocEventModal = (state: RootState) =>
  state.customEventModal.adHocEventModal;

const selectAdHocResultEventModal = (state: RootState) =>
  state.customEventModal.adHocResultEventModal;

export {
  selectAdHocResultEventModal,
  selectAdHocEventModal,
  selectCustomEventModal,
};

import cn from 'classnames';
import { Heading } from '@/components/Typography';

const DragonHeaderPrimary = ({ children, heading, className }: any) => {
  return (
    <div className={cn(`header--primary`, className)}>
      <Heading className="heading-primary text-gradient text-center mb-2">
        {heading}
      </Heading>
      {children}
    </div>
  );
};

export default DragonHeaderPrimary;

import cn from 'classnames';
import { Heading } from '@/components/Typography';
import CSPRValue from '@/components/CSPRValue';
import { formatXPValue } from '@/modules/NFTDetail/utils';
interface LeaderboardTopValidatorSpec {
  rank: number;
  data: {
    logoUrl: string;
    stakedAmount: number;
    name: string;
    validator: string;
  };
}

const LeaderboardTopValidator = ({
  rank = 0,
  data,
}: LeaderboardTopValidatorSpec) => {
  if (!data) {
    return null;
  }

  return (
    <div
      className={cn('leaderboard-item--top-validator', {
        'rank-1': rank === 1,
        'rank-2': rank === 2,
        'rank-3': rank === 3,
      })}
    >
      <img
        loading="lazy"
        className="image"
        src={data.logoUrl}
        alt={data.name}
      />
      <p className="validator-name">{data.name}</p>
      <Heading className="amount">
        <CSPRValue
          hasCSPRLogo={false}
          reverse
          shouldFormat={false}
          value={formatXPValue(data.stakedAmount)}
        />
      </Heading>
    </div>
  );
};

export default LeaderboardTopValidator;

import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import useDevice from '@/hooks/useDevice';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import useCurrentUser from '@/hooks/useCurrentUser';
import { MinigamesModalActions } from '@/modules/Minigames/store';
import { showConnectCSModal } from '@/modules/CasperSigner/store';
import markerIcon from '@/assets/images/icon--cave-of-fun.png';
import iconGameLixi from '@/assets/images/game--lixi.png';
import iconGameMaze from '@/assets/images/game--maze.png';
import iconGameTarot from '@/assets/images/game--tarot.png';

const StyledPopup = styled(Popup)`
  .leaflet-popup-tip {
    background-color: #0c1839;
  }
`;

/**
 * Settings to show/hide specific minigame
 */
interface IGamesSettings {
  luckyMoney?: boolean;
  mazeGame?: boolean;
  tarot?: boolean;
}
const GAMES_SETTINGS: IGamesSettings = {
  luckyMoney: false,
  mazeGame: false,
  tarot: false,
};

const MarkerMiniGame = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const isDevice = useDevice();
  const isAllGamesDisabled = useMemo(() => {
    return Object.keys(GAMES_SETTINGS).every(
      (game) => GAMES_SETTINGS[game as keyof IGamesSettings] === false,
    );
  }, []);

  const onClickHandler = useCallback(
    (game: string) => {
      if (!user?.activeKey) {
        dispatch(showConnectCSModal());
        return;
      }

      dispatch(MinigamesModalActions.selectGame(game));
    },
    [dispatch, user],
  );

  if (isAllGamesDisabled) {
    return null;
  }

  return (
    <Marker
      icon={L.icon({
        iconUrl: markerIcon,
        iconSize: [82, 99],
        className: 'validator-marker internal',
      })}
      position={[56.75, 11.68]}
    >
      <StyledPopup className="game-mini--popup-wrapper" keepInView>
        {isDevice ? (
          <div className="mobile--notification">
            Currently we haven't support this feature on mobile. Please use
            browser on desktop to continue, sorry for this inconvenience.
          </div>
        ) : (
          <div className="game-mini--modal">
            {GAMES_SETTINGS.luckyMoney && (
              <div className="game-mini--icon-item">
                <button
                  className="game-mini--icon"
                  onClick={() => onClickHandler('game-lixi')}
                >
                  <img
                    loading="lazy"
                    className="icon"
                    src={iconGameLixi}
                    alt="Game Lixi"
                  />
                  <span className="label">#HBDEggForce Challenge</span>
                </button>
              </div>
            )}
            {GAMES_SETTINGS.mazeGame && (
              <div className="game-mini--icon-item">
                <button
                  className="game-mini--icon"
                  onClick={() => onClickHandler('game-maze')}
                >
                  <img
                    loading="lazy"
                    className="icon"
                    src={iconGameMaze}
                    alt="Maze game"
                  />
                  <span className="label">The Maze</span>
                </button>
              </div>
            )}
            <div className="game-mini--icon-item">
              <button
                className="game-mini--icon"
                onClick={() => onClickHandler('game-card-flipping')}
              >
                <img
                  loading="lazy"
                  className="icon"
                  src={iconGameTarot}
                  alt="Tarot game"
                />
                <span className="label">Lottery</span>
              </button>
            </div>
          </div>
        )}
      </StyledPopup>
    </Marker>
  );
};

export default MarkerMiniGame;

import { useMemo } from 'react';
import { useAppSelector as useSelector } from '@/app/hooks';
import { differenceInCalendarDays } from 'date-fns';
import { selectEggForceJourneyModule } from '@/modules/EggForceJourneyDemoModule/selectors';
import Button from '@/components/GButton';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import { EggForceJourneyDemoModuleActions } from '@/modules/EggForceJourneyDemoModule/store';
import { formateDate } from '@/helpers/datetime';
import { GuideStep } from '@/modules/EggForceJourneyDemoModule/store';
import iconMerge from '@/assets/images/icon--merge.png';
import iconHatch from '@/assets/images/icon--hatch.png';
import imgMintEggs from '@/assets/images/icon--unhatch.png';
import MockQuickBuyModal from './MockPackagesModal';
import MockNFTList from './MockNFTList';
import SelvynnTree from './SelvynnTree';
import MockEggIncubateSelector from './MockEggIncubateSelector';
import MockDragonHatchModal from './MockDragonHatchModal';
import MockProfileMenu from './MockProfileMenu';
import DragonGuideStep1 from './DragonGuideStep1';
import DragonGuideStep2 from './DragonGuideStep2';
import DragonGuideStep3 from './DragonGuideStep3';
import DragonGuideStep6 from './DragonGuideStep6';
import LuckyNumberRegistrationModal from '@/components/DragonsLandingSubComponents/LuckyNumberRegistrationModal';
import DragonGuideStep7 from './DragonGuideStep7';
import MockEggMergeModal from './MockEggMergeModal';
import iconLuckyNumber from '@/assets/images/journey-step--4.png';

const BoxWrapper = ({ children, style = {} }: any) => (
  <div style={{ position: 'relative', ...style }}>{children}</div>
);

const DragonDemoGameplay = () => {
  const dispatch = useDispatch();
  const store = useSelector(selectEggForceJourneyModule);
  const {
    isFinished,
    isStarted,
    timelineController,
    currentGuideStep,
    nftsModal,
  } = store;
  const data = useMemo(() => {
    const currDate = formateDate(timelineController.startDate);
    const futureDate = timelineController.futureDate
      ? formateDate(timelineController.futureDate)
      : '';
    const diff = futureDate
      ? differenceInCalendarDays(new Date(futureDate), new Date(currDate))
      : 0;
    const combineDate = futureDate
      ? `${currDate} - ${futureDate} (${diff} days)`
      : currDate;
    return {
      currentDate: currDate,
      futureDate,
      combineDate,
      diff,
    };
  }, [timelineController.futureDate, timelineController.startDate]);

  const onClickMintEgg = () => {
    dispatch(EggForceJourneyDemoModuleActions.setPackagesListModal(true));
    if (currentGuideStep === GuideStep.step1) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step2));
    }
  };
  const onClickNFTs = () => {
    dispatch(EggForceJourneyDemoModuleActions.showNFTListModal());
    if (currentGuideStep === GuideStep.step3) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step3b));
    }
  };
  const onOpenMergeModal = () => {
    dispatch(EggForceJourneyDemoModuleActions.showNFTMergeModal());
    if (currentGuideStep === GuideStep.step7) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step7a));
    }
  };

  const onClickConnect = () => {
    dispatch(EggForceJourneyDemoModuleActions.start());
    if (currentGuideStep === GuideStep.step1) {
      dispatch(EggForceJourneyDemoModuleActions.updateGuide(GuideStep.step2));
    }
  };
  const onClickReset = () => {
    dispatch(EggForceJourneyDemoModuleActions.reset());
  };
  const onClickLuckyNumber = () => {
    dispatch(EggForceJourneyDemoModuleActions.showLuckyNumberFormModal());
  };

  return (
    <div className="mock--eggforce-world">
      <div className="sandbox">Callista Sandbox</div>
      <div className="mock--coord mock--top-left">
        <span className="mock--timeline">
          {data.futureDate ? data.combineDate : data.currentDate}
        </span>
      </div>

      <div className="mock--coord mock--left">
        {isStarted && Boolean(nftsModal.data.length > 0) && (
          <>
            <BoxWrapper>
              <Button
                style={{ marginLeft: 24, marginRight: 24, marginBottom: 24 }}
                ignoreShadowStyle
                tooltip="NFTs"
                className="btn--icon large use--elemental-background"
                onClick={onClickNFTs}
              >
                <img
                  style={{ width: 36 }}
                  src={iconHatch}
                  alt="NFTs Collection"
                />
                <span className="label">NFTs</span>
              </Button>
              <DragonGuideStep3 />
            </BoxWrapper>
            <BoxWrapper>
              <DragonGuideStep7 />
              <Button
                ignoreShadowStyle
                tooltip="Merging Hub"
                className="btn--icon large use--elemental-background btn--merging-hub"
                onClick={onOpenMergeModal}
              >
                <img style={{ width: 36 }} src={iconMerge} alt="Merging Hub" />
                <span className="label">Merging Hub</span>
              </Button>
            </BoxWrapper>
          </>
        )}
      </div>
      <div className="mock--coord mock--top-center"></div>
      <div className="mock--coord mock--top-right">
        {isStarted && <MockProfileMenu />}
      </div>
      <div className="mock--coord mock--bottom-center mock--mint-section">
        {!isStarted && (
          <>
            <Button size="small" onClick={onClickConnect}>
              Get Started
            </Button>
            <DragonGuideStep1 />
          </>
        )}
        {isStarted && (
          <>
            <BoxWrapper style={{ display: 'flex', columnGap: '24px' }}>
              <BoxWrapper>
                <Button
                  size="small"
                  className="open-mint-modal-mock"
                  onClick={onClickMintEgg}
                >
                  <img className="icon" src={imgMintEggs} alt="MINT EGGS" />
                  <span className="text-shadow">MINT EGG</span>
                </Button>
                <DragonGuideStep2 />
              </BoxWrapper>
              {isFinished && (
                <BoxWrapper>
                  <Button
                    size="small"
                    className="open-lucky-number-modal"
                    onClick={onClickLuckyNumber}
                  >
                    <img
                      className="icon"
                      src={iconLuckyNumber}
                      alt="Register Lucky number"
                    />
                    <span className="text-shadow">
                      Register <br /> Lucky Number
                    </span>
                  </Button>
                </BoxWrapper>
              )}
            </BoxWrapper>
            <MockQuickBuyModal className="mock--quickbuy-modal" />
            <MockNFTList dataTimeline={data} />
            <MockEggIncubateSelector />
            <MockDragonHatchModal />
            <MockEggMergeModal />
          </>
        )}
        <LuckyNumberRegistrationModal />
      </div>
      <div className="mock--coord mock--center-right">
        {isStarted && (
          <Button size="small" onClick={onClickReset}>
            Reset
          </Button>
        )}
      </div>
      <div className="mock--coord mock--bottom-right">
        {/**
         * TODO: Comment out this button when API is finished
         */}
        {/* {isStarted && (
          <Button
            size="small"
            onClick={() =>
              dispatch(EggForceJourneyDemoModuleActions.enableFinishedState())
            }
          >
            Set Finished
          </Button>
        )} */}
      </div>
      <div className="mock--coord mock--bottom-left">
        {/* <DragonGuide /> */}
      </div>
      <div className="mock--coord mock--center">
        <SelvynnTree />
        {!isFinished && currentGuideStep === GuideStep.step6 && (
          <DragonGuideStep6 />
        )}
      </div>
    </div>
  );
};

export default DragonDemoGameplay;

import { useCallback, useEffect } from 'react';
import MODULES_PERMISSION from '@/constants/modulesPermission';
import { useAppDispatch as useDispatch } from '@/app/hooks';
import { unlockCasperSigner } from '@/modules/Auth/actions';
import { isValidPublicKey } from '@/modules/CasperSigner/helpers';
import { CustomEventModalActions } from '@/modules/CustomEventModule/store';
import { isWhitelistedAccount } from '@/modules/Account/actions';
import { updateAccountPermissions } from '@/modules/Auth/store';
import useAccountWallet from './useAccountWallet';

const useWalletConnector = () => {
  const { publicKey } = useAccountWallet();
  const dispatch = useDispatch();

  const onWalletConnected = useCallback(
    async (publicKey: string) => {
      const result = await dispatch(
        unlockCasperSigner({
          data: {
            activeKey: publicKey,
          },
          eventType: 'useWallet',
        }),
      ).unwrap();

      if (MODULES_PERMISSION.USE_HAPPY_HOUR && result?.activeKey) {
        const isWhitelistAccount = await dispatch(
          isWhitelistedAccount(publicKey),
        ).unwrap();

        dispatch(
          updateAccountPermissions({
            key: 'isWhitelistAccount',
            value: isWhitelistAccount,
          }),
        );
        dispatch(CustomEventModalActions.showModal());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!publicKey) {
      return;
    }

    if (isValidPublicKey(publicKey)) {
      onWalletConnected(publicKey);
    }
  }, [dispatch, onWalletConnected, publicKey]);
};

export default useWalletConnector;

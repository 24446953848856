import Container from 'react-bootstrap/Container';
import { Heading } from '@/components/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Balancer from 'react-wrap-balancer';
import DragonSectionWrapper from '../DragonSectionWrapper';
import DragonHeaderPrimary from '../DragonHeaderPrimary';
import eggLevelRock from '@/assets/images/egg-level--rock.png';
import eggLevelSilver from '@/assets/images/egg-level--silver.png';
import eggLevelGold from '@/assets/images/egg-level--gold.png';
import eggLevelPLatinum from '@/assets/images/egg-level--platinum.png';
import imgIcon from '@/assets/images/icon--arrow-yellow.png';
import imgMerge from '@/assets/images/bg-merge-dots.png';
import Balancer from 'react-wrap-balancer';

const DragonsLevelUp = () => {
  return (
    <DragonSectionWrapper name="egg-level-ups">
      <Container className="">
        <Row>
          <Col md={12}>
            <DragonHeaderPrimary heading="Egg Levelling System">
              {/* <Balancer className="body-content">
                Stake your CSPR, and boost NFT levels
              </Balancer> */}
            </DragonHeaderPrimary>
            <div className="body">
              <div className="demo-wrapper">
                <div className="level-up-eggs--wrapper">
                  <div className="column--each-egg-level">
                    <div className="egg-level egg-level--rock">
                      <img
                        src={imgIcon}
                        alt="Up"
                        className="icon-arrow to--silver"
                      />
                      <div className="header">
                        <p>Level 1</p>
                        <Heading h={2}>Rock</Heading>
                      </div>
                      <div className="image-egg">
                        <img
                          src={eggLevelRock}
                          className="img-egg"
                          alt="Rock"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column--each-egg-level">
                    <div className="egg-level egg-level--silver">
                      <img
                        src={imgIcon}
                        alt="Up"
                        className="icon-arrow to--gold"
                      />
                      <div className="header">
                        <p>Level 2</p>
                        <Heading h={2}>Silver</Heading>
                      </div>
                      <div className="image-egg">
                        <img
                          src={eggLevelSilver}
                          className="img-egg"
                          alt="Rock"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column--each-egg-level">
                    <div className="egg-level egg-level--gold">
                      <img
                        src={imgIcon}
                        alt="Up"
                        className="icon-arrow to--platinum mobile"
                      />
                      <img
                        src={imgIcon}
                        alt="Up"
                        className="icon-arrow to--platinum desktop"
                      />

                      <div className="header">
                        <p>Level 3</p>
                        <Heading h={2}>Gold</Heading>
                      </div>
                      <div className="image-egg">
                        <img
                          src={eggLevelGold}
                          className="img-egg"
                          alt="Rock"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column--each-egg-level">
                    <div className="egg-level egg-level--platinum">
                      <div className="header">
                        <p>Level 4</p>
                        <Heading h={2}>Platinum</Heading>
                      </div>
                      <div className="image-egg">
                        <img
                          src={eggLevelPLatinum}
                          className="img-egg"
                          alt="Rock"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="egg-level-ups--footer">
                  <Heading className="heading-primary text-gradient text-center mb-2">
                    Stake your CSPR, boost your NFT levels
                  </Heading>
                  <Balancer>
                    Stake CSPR and add more value to your NFT collection by
                    leveling up your egg(s)
                  </Balancer>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="egg-merge-introduction--wrapper">
              <DragonHeaderPrimary heading="Create better eggs by merging multiple eggs">
                <Balancer>
                  Every time you merge eggs{' '}
                  <strong>(of the same element)</strong> together the remaining
                  egg will get a Lucky Point bonus. The higher the Lucky Point
                  of the egg, the higher the chances for a better dragon.
                </Balancer>
              </DragonHeaderPrimary>
              <div className="body">
                <div className="demo-wrapper">
                  <div className="egg-image-sample">
                    <img
                      src={imgMerge}
                      alt="Create better eggs by merging multiple eggs"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </DragonSectionWrapper>
  );
};

export default DragonsLevelUp;
